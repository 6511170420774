import React, { useState, useRef, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BILLING_TYPE,
  errorMessages,
  PROJECT_HEALTH,
  PROJECT_LEVEL,
  PROJECT_STATUS,
  PROJECT_TYPE,
  REPLACE_REGEX,
} from "../../constants/common";
import { useForm } from "../../helpers/validations/useForm";
import {
  getMasterProject,
  getProjectDetails,
  signupProject,
} from "../../store/actions/Project";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import { getAllCustomerList } from "../../store/actions/Customers";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { getCustomerUserByName } from "../../store/actions/Customer";
import { ProfileType } from "../../utility/commonTypes";

interface Project {
  id: any;
  active: any;
  company_customer_id: any;
  project_name: any;
  project_level: any;
  project_type: any;
  phase: any;
  billing_type: any;
  total_pool_hours: any;
  project_health: any;
  project_status: any;
  master_project: any;
  engagement_partner: any;
  engagement_director: any;
  engagement_sr_manager: any;
  engagementSrManager:any
  engagement_manager: any;
  opportunity_id: any;
  engagement_id: any;
  user_id:any;
  notes: string;
  partner_email_id:any;
  director_email_id:any;
  srmanager_email_id:any;
  manager_email_id:any;

  actionType: any;
}

const FormProject: FC = (props) => {
  const { projectDetail } = props;
  const customerRef = useRef({});
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [masterRowData, setMasterRowData] = useState([]);
  const [res, setRes] = useState([]);
  const [actionType, setActionType] = useState("create");
  const [isMasterProject, setIsMasterProject] = useState(false);
  const [masterProjectList, setMasterProjectList] = useState([]);
  const [messages, setMessage] = useState([]);
  const location = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const [checkbox, setCheckBox] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [partnerSuggestions, setPartnerSuggestions] = useState(false);
  const [directorSuggestions, setDirectorSuggestions] = useState(false);
  const [srManagerSuggestions, setSrManagerSuggestions] = useState(false);
  const [managerSuggestions, setManagerSuggestions] = useState(false);

  const [partnerresourceUserId, setPartnerresourceUserId] = useState<string>("");
  const [directorresourceUserId, setDirectorresourceUserId] = useState<string>("");
  const [srmanagerresourceUserId, setSrManagerresourceUserId] = useState<string>([]);
  const [managerresourceUserId, setManagerresourceUserId] = useState<string>("");
  const [partner, setPartner] = useState([]);
  const [director, setDirector] = useState([]);
  const [srManager, setsrManager] = useState([]);
  const [manager, setManager] = useState([]);
const[srManagerName,setSrManagerName]=useState("")  
  const handleCheckBox = (e: any) => {
    setCheckBox(e.target.checked);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleKeyUpPartner = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setPartnerSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpDirector = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setDirectorSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpSrManager = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setSrManagerSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpManager = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setManagerSuggestions({
            ...res,
          });
        }
      )
    );
  };

  const {
    handleSubmit,
    handleChange,
    data: project,
    setData,
    errors,
  } = useForm<Project>({
    validations: {
      company_customer_id: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
      },
      project_name: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Project name",1)
        },
        maxlength: {
          value: 200,
          message: errorMessages?.maxlength("Project name",200)
        },
      },
      project_level: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      master_project: {
        required: {
          value: !isMasterProject,
          message: "Please select master project"
        }
      },
      phase:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITHOUT_SPECIAL_CHARS,
          message:errorMessages?.patternWithOutSpecialChars()
        },
        maxlength: {
          value: 2,
          message: errorMessages?.maxlength("Phase",2)
        },
      },
      total_pool_hours:{
        pattern:{
          value:REPLACE_REGEX?.NUMBERS_WITH_DECIMALS,
          message:errorMessages?.patternWithOnlyDecimalNumbers()
        },
        maxlength: {
          value: 6,
          message: errorMessages?.allowedRange("Total pool hours"),
        },
      },
      opportunity_id:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Opportunity Id",50)
        },
    },
    engagement_manager: {
      required: {
        value: true,
        message: errorMessages?.required(),
      }
    },
      engagement_id: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Engagement Id",50)
        },
      },
      notes: {
        pattern: {
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Notes",5000)
        },
    }
  },
    onError: (errors: any) => {      
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        signupProject(
          {
            payload: {
              active: checkbox,
              id: project.id,
              company_customer_id: project.company_customer_id,
              project_name: project.project_name,
              project_level:
                isMasterProject === true ? "Master_Project" : "Sub_Project",
              project_type: project.project_type,
              phase: project.phase,
              billing_type: project.billing_type,
              total_pool_hours: project.total_pool_hours,
              project_health: project.project_health,
              project_status: project.project_status,
              master_project: project.master_project,
              engagement_partner: project?.partner_email_id,
              engagement_director: project?.director_email_id,
              engagement_sr_manager: project?.srmanager_email_id,
              engagement_manager: project?.manager_email_id,
              opportunity_id: project.opportunity_id,
              engagement_id: project.engagement_id,
              notes: project.notes,
              actionType: actionType,
            },
          },
          onSignUpProjectCallback
        )
      );
    },
  });

  useEffect(() => {
    setActionType("create");
    dispatch(
      getAllCustomerList({}, function (data: any) {
        const rows = data?.map((row: any) => ({
          id: row?.id,
          company_name: row?.company_name,
        }));
        setMasterProjectList(rows);
      })
    );

  }, []);
  const handleEvent = (val: any) => {
    if (val === "Master_Project") {
      project.master_project = "";
      setIsMasterProject(true);
    } else {
      getMasterProjectData(project.company_customer_id);
      setIsMasterProject(false);
    }
  };

  const getProjectDetailData = (projectId: any) => {
    dispatch(
      getProjectDetails({ id: projectId }, function (res: any) {
        res?.id && setActionType("update");
        //  setRes(res)
        projectDetail(res);
        handleEvent(res.project_level);
        setData({
          ...res,
          active: res.active,
          id: res.id,
          company_customer_id: res.company_customer_id,
          project_name: res.project_name,
          project_level: res.project_level,
          project_type: res.project_type,
          phase: res.phase,
          wave: res.wave,
          billing_type: res.billing_type,
          total_pool_hours: res.total_pool_hours,
          project_health: res.project_health,
          project_status: res.project_status,
          master_project: res.master_project,
          engagement_partner: res.engagement_partner || "",
          engagement_director: res.engagement_director || "",
          engagement_sr_manager: res.engagement_sr_manager || "",
          engagement_manager: res.engagement_manager,
          opportunity_id: res.opportunity_id,
          engagement_id: res.engagement_id,
          notes: res.notes,
        });
        setCheckBox(res?.active);

        if (res?.Sub_Project) {
          getMasterProjectData(stateData?.customerId);
        }
      })
    );
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });

  const getMasterProjectData = (customerId: any) => {    
    dispatch(
      getMasterProject(
        {
          company_customer_id: customerId,
        },
        function (data: any) {
          const rows = data?.map((row: any) => ({
            id: row?.id,
            project_name: row?.project_name,
          }));
          setMasterRowData(rows);
        }
      )
    );
  };
  useEffect(() => {
    setLoader(true);
    if (stateData == null) {
      setActionType("create");
      setTimeout(() => {
        setData({
          ...project,
          active: false,
          id: "",
          company_customer_id: "",
          project_name: "",
          project_level: "",
          project_type: "",
          phase: "",
          wave: "",
          billing_type: "",
          total_pool_hours: "",
          project_health: "",
          project_status: "",
          master_project: "",
          engagement_partner: "",
          engagement_director: "",
          engagement_sr_manager: "",
          engagement_manager: "",
          opportunity_id: "",
          engagement_id: "",
          notes: "",
        });
        setMasterRowData([]);
        setLoader(false);
      }, 1000);
    } else {
      stateData?.projectId && getProjectDetailData(stateData?.projectId);      
      stateData?.customerId && setData({
        ...project,
        company_customer_id: stateData?.customerId
      })
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [stateData,setData]);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  const onSignUpProjectCallback = (data: any, error: any) => {
    setLoader(false);
    if (data === false) {
      setMessage(error);
      setErrorPopup(true);

      // navigate("/project");
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
        if(data?.project_level === "Sub_Project"){
          navigate(
            `/project/manage-sub-project/${data?.master_project}/${data?.company_customer_id}/${data?.id}`,
            {
              state: {
                masterProjectId: data?.master_project,
                companyCustomerId: data?.company_customer_id,
                subProjectId: data?.id,
              },
              replace: true,
            }
          );
          window.location.reload();
        }else{
          navigate(
            `/project/${data?.id}/${data?.company_customer_id}`,
            {
              state: {
                projectId: data?.id,
                customerId: data?.company_customer_id,                
              },
              replace: true,
            }
          );
          window.location.reload();
        }
      }, 2000);
    }
  };
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showDirectorSuggestion, setshowDirectorSuggestion] = useState(false);
  const [showSrManager, setshowSrManagerSuggestion] = useState(false);
  const [showManagerSuggestion, setManagerShowSuggestion] = useState(false);

  let partnersuggestRef = useRef();
  let directorsuggestRef = useRef();
  let srmanagersuggestRef = useRef();

  let managersuggestRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!partnersuggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!directorsuggestRef?.current?.contains(event.target)) {
        setshowDirectorSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!srmanagersuggestRef?.current?.contains(event.target)) {
        setshowSrManagerSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!managersuggestRef?.current?.contains(event.target)) {
        setManagerShowSuggestion(false);
      }
    });
  }, []);

  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="col-md-12 pull-left  mb-4 vendor_contact">Main</div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={(e: any) => {
                        handleChange("active");
                        handleCheckBox(e);
                      }}
                      value={project?.active}
                      checked={checkbox}
                      className="form-check-input mx-2"
                    />
                    <label
                      htmlFor="checked"
                      className="form-check-label mt-1"
                      style={{ width: "80px" }}
                    >
                      Active
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Customer Name*
                    </label>

                    <select
                      name="customer_name"
                      id="customer_name"
                      //value={project.company_customer_id || ""}
                      onChange={handleChange("company_customer_id", (val) => {
                        project.project_level = "";
                        project.master_project = "";
                        setIsMasterProject(true);
                        return val;
                      })}
                      className="form-select"
                    >
                      <option value="">Select Customer Name</option>
                      {masterProjectList.map((company) => {
                          
                          let selCust = (stateData?.customerId === company.id) ? true : false;
                          //console.log(stateData?.customerId +"==="+ company?.id + "====="+ selCust);
                          return (
                            <option value={company.id} selected={selCust}>
                              {company.company_name}
                            </option>
                          )
                        })
                      }
                    </select>

                    {errors.company_customer_id && (
                      <p className="error-text" style={{ marginTop: 1 }}>
                        {errors.company_customer_id}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Name*
                    </label>

                    <input
                      name="project_name"
                      id="project_name"
                      value={project?.project_name}
                      onChange={handleChange("project_name")}
                      className="form-control"
                    />

                    {errors.project_name && (
                      <p className="error-text">{errors.project_name}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Level *
                    </label>

                    <select
                      name="project_level"
                      id="project_level"
                      onChange={handleChange("project_level", (val) => {
                        handleEvent(val);                        
                        return val;
                      })}
                      className="form-select"
                    >
                      <option value="">Select Project Level</option>
                      {PROJECT_LEVEL.map((level) => {                        
                        let projectLevelSel =
                          project.project_level === level.key
                            ? true
                            : stateData?.subProjectId &&
                              level.key === "Sub_Project"
                            ? true
                            : false;
                        return (
                          <option value={level.key} selected={projectLevelSel}>
                            {level.label}
                          </option>
                        );
                      })}
                    </select>
                    {errors.project_level && (
                      <p className="error-text" style={{ marginTop: 1 }}>
                        {errors.project_level}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Type
                    </label>

                    <select
                      name="project_type"
                      value={project?.project_type || null}
                      onChange={handleChange("project_type")}
                      className="form-select"
                    >
                      <option value="">Select Project Type</option>

                      {PROJECT_TYPE.map((type) => (
                        <option value={type.key}>{type.label}</option>
                      ))}
                    </select>
                    {errors.project_type && (
                      <p className="error-text">{errors.project_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Phase
                    </label>

                    <input
                      name="phase"
                      value={project?.phase || null}
                      onChange={handleChange("phase")}
                      className="form-control"
                      placeholder="Phase"
                    />
                    {errors.phase && (
                      <p className="error-text">{errors.phase}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Billing Type
                    </label>

                    <select
                      name="billing_type"
                      value={project?.billing_type || null}
                      onChange={handleChange("billing_type")}
                      className="form-select"
                    >
                      <option value="">Select Billing Type</option>
                      {BILLING_TYPE.map((billing) => (
                        <option value={billing.key}>{billing.label}</option>
                      ))}
                    </select>
                    {errors.billing_type && (
                      <p className="error-text">{errors.billing_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Total Pool Hours SOW
                    </label>

                    <input
                      name="total_pool_hours"
                      type="text"
                      value={project?.total_pool_hours || null}
                      onChange={handleChange("total_pool_hours")}
                      className="form-control"
                    />
                    {errors.total_pool_hours && (
                      <p className="error-text">{errors.total_pool_hours}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Health
                    </label>

                    <select
                      name="project_health"
                      id="project_health"
                      value={project?.project_health || null}
                      onChange={handleChange("project_health")}
                      className="form-select"
                    >
                      <option value="">Select Project Health</option>
                      {PROJECT_HEALTH.map((health) => (
                        <option value={health.key}>{health.label}</option>
                      ))}
                    </select>
                    {errors.project_health && (
                      <p className="error-text">{errors.project_health}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Notes
                    </label>

                    <textarea
                      name="notes"
                      type="text"
                      value={project?.notes || null}
                      onChange={handleChange("notes")}
                      className="form-textarea"
                      placeholder="Notes"
                    ></textarea>
                    {errors.notes && (
                      <p className="error-text">{errors.notes}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Status
                    </label>

                    <select
                      name="project_status"
                      id="status"
                      value={project?.project_status || null}
                      onChange={handleChange("project_status")}
                      className="form-select"
                    >
                      <option value="">Select Project Status</option>
                      {PROJECT_STATUS.map((status) => (
                        <option value={status.key}>{status.label}</option>
                      ))}
                    </select>
                    {errors.project_status && (
                      <p className="error-text">{errors.project_status}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Master Project
                    </label>

                    <select
                      name="master_project"
                      id="master_project"
                      value={project?.master_project || null}
                      onChange={handleChange("master_project")}
                      className="form-select"
                      disabled={isMasterProject}
                    >
                      <option value="">Select Master Project</option>
                      {!isMasterProject && masterRowData.map((d) => {
                        let selProj =
                          stateData?.projectId && stateData?.projectId === d.id
                            ? true
                            : false;                            
                        return (
                          <option value={d.id} selected={selProj}>
                            {d.project_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.master_project && (
                      <p className="error-text">{errors.master_project}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Partner
                    </label>

                    <input
                      name="engagement_partner"
                      id="engagement_partner"
                      value={project?.engagement_partner}
                      // value={customer.company_logo}
                      onChange={handleChange("engagement_partner",(val)=>{
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpPartner}

                      className="form-control"
                      placeholder="Engagement Partner"
                    />
                    {errors.engagement_partner && (
                      <p className="error-text">{errors.engagement_partner}</p>
                    )}
                     {showSuggestion && (
                      <ul
                        className="list-group"
                        ref={partnersuggestRef}
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }}
                      >
                        {" "}
                        {Object.entries(partnerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                               
                                onClick={(e) => {                                  
                                  setShowSuggestion(false)
                                  // setPartnerresourceUserId(i?.id);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_partner: i?.ResourceName,
                                    partner_email_id:i?.emailid
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Director
                    </label>

                    <input
                      name="engagement_director"
                      id="engagement_director"
                      value={project?.engagement_director}
                      onChange={handleChange("engagement_director",(val)=>{
                        setshowDirectorSuggestion(true)
                        return val;
                      })}
                      onKeyUp={handleKeyUpDirector}
                      className="form-control"
                      placeholder="Engagement Director"
                    />
                    {errors.engagement_director && (
                      <p className="error-text">{errors.engagement_director}</p>
                    )}
                     {showDirectorSuggestion && (
                      <ul
                        className="list-group"
                        ref={directorsuggestRef}
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }}                      >
                        {" "}
                        {Object.entries(directorSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setshowDirectorSuggestion(false)
                                  setDirectorresourceUserId(i?.id);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_director: i?.ResourceName,
                                    director_email_id:i?.emailid
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Sr.Manager
                    </label>

                    <input
                      name="engagement_sr_manager"
                      id="engagement_sr_manager"
                      value={project?.engagement_sr_manager}
                      onChange={handleChange("engagement_sr_manager",(val)=>{
                        setshowSrManagerSuggestion(true)
                        return val;
                      })}
                      onKeyUp={handleKeyUpSrManager}
                      className="form-control"
                      placeholder="Engagement Sr.Manager"
                    />
                    {errors.engagement_sr_manager && (
                      <p className="error-text">
                        {errors.engagement_sr_manager}
                      </p>
                    )}
                     {showSrManager && (
                      <ul
                        className="list-group"
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }} 
                      ref={srmanagersuggestRef}
                      >
                        {" "}
                        {Object.entries(srManagerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setshowSrManagerSuggestion(false)
                                  setSrManagerresourceUserId(i?.ResourceName);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_sr_manager: i?.ResourceName,
                                    srmanager_email_id:i?.emailid
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Manager*
                    </label>

                    <input
                      name="engagement_manager"
                      id="engagement_manager"
                      value={project?.engagement_manager}
                      onChange={handleChange("engagement_manager",(val)=>{
                        setManagerShowSuggestion(true)
                        return val;
                      })}
                      onKeyUp={handleKeyUpManager}
                      className="form-control"
                      placeholder="Engagement Manager"
                    />
                    {errors.engagement_manager && (
                      <p className="error-text">{errors.engagement_manager}</p>
                    )}
                     {showManagerSuggestion && (
                      <ul
                        className="list-group"
                        ref={managersuggestRef}
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }}                        
                      >
                        {" "}
                        {Object.entries(managerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setManagerShowSuggestion(false)
                                  setManagerresourceUserId(i?.id);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_manager: i?.ResourceName,
                                    manager_email_id:i?.emailid
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Opportunity ID
                    </label>

                    <input
                      name="opportunity_id"
                      id="opportunity_id"
                      value={project?.opportunity_id || null}
                      onChange={handleChange("opportunity_id")}
                      className="form-control"
                    />
                    {errors.opportunity_id && (
                      <p className="error-text">{errors.opportunity_id}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement ID*
                    </label>

                    <input
                      name="engagement_id "
                      id="engagement_id "
                      value={project?.engagement_id}
                      onChange={handleChange("engagement_id")}
                      className="form-control"
                    />
                    {errors.engagement_id && (
                      <p className="error-text">{errors.engagement_id}</p>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="btn-block">
                   {ProfileType?.ADMIN === getProfileType &&   
                     <button type="submit" className="thame-btn w-100">
                          {actionType === "create" ? "Submit" : "Update"}
                        </button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <CustomModal
        title="Project Profile"
        onOpen={errorPopup}
        content={messages}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Project Profile"
        onOpen={successPopup}
        content={"Project data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Error on page, please validate!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default FormProject;

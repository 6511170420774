import React, { useState, useEffect, FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BILLING_TYPE,
  errorMessages,
  PROJECT_HEALTH,
  PROJECT_LEVEL,
  PROJECT_STATUS,
  PROJECT_TYPE,
  REPLACE_REGEX,
} from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getMasterProject,
  getProjectDetails,
  signupProject,
} from "../../../store/actions/Project";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { getAllCustomerList } from "../../../store/actions/Customers";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { getCustomerUserByName } from "../../../store/actions/Customer";
import { ProfileType } from "../../../utility/commonTypes";

interface Project {
  id: any;
  active: any;
  company_customer_id: any;
  project_name: any;
  project_level: any;
  project_type: any;
  partner_email_id: any;
  director_email_id: any;
  srmanager_email_id: any;
  manager_email_id: any;
  phase: any;
  wave: number;
  billing_type: any;
  total_pool_hours: any;
  project_health: any;
  project_status: any;
  master_project: any;
  engagement_partner: any;
  engagement_director: any;
  engagement_sr_manager: any;
  engagement_manager: any;
  opportunity_id: any;
  engagement_id: any;
  notes: string;
  user_id: any;
  actionType: any;
}

const SubProjectForm: FC = (props) => {
  const { projectDetail }: any = props;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [masterProjectRowData, setMasterProjectRowData] = useState([]);
  const [res, setRes] = useState([]);
  const [actionType, setActionType] = useState("create");
  const [isMasterProject, setIsMasterProject] = useState(false);
  const [companyCustomerList, setCompanyCustomerList] = useState([]);
  const location = useLocation();
  const params = useParams();
  console.log({ location, params });
  const [messages, setMessage] = useState([]);
  //const stateData: any = location.state;
  const stateData: any = params;
  const [open, setOpen] = React.useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showDirectorSuggestion, setshowDirectorSuggestion] = useState(false);
  const [showSrManager, setshowSrManagerSuggestion] = useState(false);
  const [showManagerSuggestion, setManagerShowSuggestion] = useState(false);
  const [partnerSuggestions, setPartnerSuggestions] = useState(false);
  const [directorSuggestions, setDirectorSuggestions] = useState(false);
  const [srManagerSuggestions, setSrManagerSuggestions] = useState(false);
  const [managerSuggestions, setManagerSuggestions] = useState(false);

  let partnersuggestRef = useRef();
  let directorsuggestRef = useRef();
  let srmanagersuggestRef = useRef();

  let managersuggestRef = useRef();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    handleSubmit,
    handleChange,
    data: project,
    setData,
    errors,
  } = useForm<Project>({
    validations: {
      company_customer_id: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      project_name: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern(),
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Project name", 1),
        },
        maxlength: {
          value: 200,
          message: errorMessages?.maxlength("Project name", 200),
        },
      },
      project_level: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      phase: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITHOUT_SPECIAL_CHARS,
          message: errorMessages?.patternWithOutSpecialChars(),
        },
        maxlength: {
          value: 2,
          message: errorMessages?.maxlength("Phase", 2),
        },
      },
      total_pool_hours: {
        pattern: {
          value: REPLACE_REGEX?.NUMBERS_WITH_DECIMALS,
          message: errorMessages?.patternWithOnlyDecimalNumbers(),
        },
        maxlength: {
          value: 6,
          message: errorMessages?.allowedRange("Total pool hours"),
        },
      },
      opportunity_id: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern(),
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Opportunity Id", 50),
        },
      },
      engagement_manager: {
        required: {
          value: true,
          message: errorMessages?.required(),
        }
      },
      engagement_id: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern(),
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Engagement Id", 50),
        },
      },
      notes: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern(),
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Notes", 5000),
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        signupProject(
          {
            payload: {
              active: checkbox,
              id: stateData?.subProjectId,
              company_customer_id: stateData?.companyCustomerId,
              project_name: project.project_name || "",
              project_level: "Sub_Project",
              project_type: project.project_type || "",
              phase: project.phase || "",
              wave: project.wave || 0,
              billing_type: project.billing_type || "",
              total_pool_hours: project.total_pool_hours || "",
              project_health: project.project_health || "",
              project_status: project.project_status || "",
              master_project: project.master_project || masterProjectId,
              engagement_partner: project.partner_email_id,
              engagement_director: project.director_email_id,
              engagement_sr_manager: project.srmanager_email_id,
              engagement_manager: project.manager_email_id,
              opportunity_id: project.opportunity_id || null,
              engagement_id: project.engagement_id || "",
              notes: project.notes || "",
              actionType: actionType,
            },
          },
          onSignUpProjectCallback
        )
      );
    },
  });
  const handleEvent = (event: any) => {
    if (event.target.value === "1") {
      setIsMasterProject(true);
    } else {
      setIsMasterProject(false);
    }
  };
  const getSubProjectCustomerList = () => {
    dispatch(
      getAllCustomerList({}, function (data: any) {
        console.log({ data });
        const rows = data?.map((row: any) => ({
          id: row?.id,
          company_name: row?.company_name,
        }));
        rows.map((custRow: any) => {
          if (custRow?.id === stateData?.companyCustomerId) {
            setData({
              ...project,
              company_customer_id: custRow?.id,
              project_level: "Sub_Project",
            });
          }
        });
        setCompanyCustomerList(rows);
      })
    );
  };

  const getMasterProjectData = () => {
    dispatch(
      getMasterProject(
        {
          company_customer_id: stateData?.companyCustomerId,
        },
        function (data: any) {
          const rows = data?.map((row) => ({
            id: row?.id,
            project_name: row?.project_name,
          }));
          rows.map((d) => {
            stateData?.masterProjectId === d.id && setMasterProjectId(d.id);
          });
          setMasterProjectRowData(rows);
          setLoader(false);
        }
      )
    );
  };
  const getSubProjectDetail = (setData: any, callback: Function) => {
    dispatch(
      getProjectDetails({ id: stateData?.subProjectId }, function (res: any) {
        res?.id && setActionType("update");
        projectDetail(res);
        //  setRes(res)
        console.log("res", res);
        setTimeout(() => {
          setData({
            ...res,
            active: res.active,
            id: res.id,
            company_customer_id: res.company_customer_id,
            project_name: res.project_name || "",
            project_level: res.project_level || "",
            project_type: res.project_type || "",
            phase: res.phase || "",
            wave: res.wave || 0,
            billing_type: res.billing_type || "",
            total_pool_hours: res.total_pool_hours || "",
            project_health: res.project_health || "",
            project_status: res.project_status || "",
            master_project: res.master_project || "",
            engagement_partner: res.engagement_partner || "",
            engagement_director: res.engagement_director || "",
            engagement_sr_manager: res.engagement_sr_manager || "",
            engagement_manager: res.engagement_manager || "",
            opportunity_id: res.opportunity_id || "",
            engagement_id: res.engagement_id || "",
            notes: res.notes || "",
          });
        }, 500);
       
        setCheckBox(res?.active);

        // projectDetail(res)
        //Get Master Project Data
        stateData?.companyCustomerId && getMasterProjectData();
        res && callback(res);
      })
    );
  };
  const [masterProjectId, setMasterProjectId] = useState("");
  useEffect(() => {
    setLoader(true);
    //Get Company CustomerList
    getSubProjectCustomerList();
    //Get Sub Project Detail - Edit Mode
    stateData?.subProjectId &&
      getSubProjectDetail(setData, (res:any) => {        
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      });
    !stateData?.subProjectId &&
          stateData?.companyCustomerId &&
          getMasterProjectData();
  }, [setData]);
  const [partnerresourceUserId, setPartnerresourceUserId] = useState<string>(
    ""
  );
  const [directorresourceUserId, setDirectorresourceUserId] = useState<string>(
    ""
  );
  const [srmanagerresourceUserId, setSrManagerresourceUserId] = useState<
    string
  >([]);
  const [managerresourceUserId, setManagerresourceUserId] = useState<string>(
    ""
  );

  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const waveData = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
  ];
  const onSignUpProjectCallback = (data: any, error: any) => {
    setLoader(false);
    if (data === false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
        navigate(
          `/project/manage-sub-project/${stateData?.masterProjectId}/${stateData?.companyCustomerId}/${data?.id}`,
          {
            state: {
              projectId: stateData?.masterProjectId,
              customerId: stateData?.companyCustomerId,
              subProjectId: data?.id,
            },
            replace: true,
          }
        );
        window.location.reload();
      }, 2000);
    }
  };
  const handleKeyUpPartner = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setPartnerSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpDirector = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setDirectorSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpSrManager = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setSrManagerSuggestions({
            ...res,
          });
        }
      )
    );
  };
  const handleKeyUpManager = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setManagerSuggestions({
            ...res,
          });
        }
      )
    );
  };

  const handleCheckBox = (e: any) => {
    setCheckBox(e.target.checked);
  };
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!partnersuggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!directorsuggestRef?.current?.contains(event.target)) {
        setshowDirectorSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!srmanagersuggestRef?.current?.contains(event.target)) {
        setshowSrManagerSuggestion(false);
      }
    });
    document.addEventListener("mousedown", (event) => {
      if (!managersuggestRef?.current?.contains(event.target)) {
        setManagerShowSuggestion(false);
      }
    });
  }, []);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
console.log("engagement manager",project?.engagement_manager)
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="col-md-12 pull-left  mb-4 vendor_contact">Main</div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-check-label">
                      Active
                    </label>
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={(e: any) => {
                        handleChange("active");
                        handleCheckBox(e);
                      }}
                      value={project?.active}
                      checked={checkbox}
                      className="form-check-input mx-2"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Customer Name*
                    </label>

                    <select
                      name="company_customer_id"
                      id="company_customer_id"
                      //value={stateData?.companyCustomerId || ""}
                      onChange={handleChange("company_customer_id", (val) => {
                        return val;
                      })}
                      className="form-select"
                      disabled="disabled"
                    >
                      <option value="">Select Customer</option>
                      {companyCustomerList.map((company) => {
                        let selCustomer =
                          stateData?.companyCustomerId === company.id
                            ? "selected"
                            : "";
                        return (
                          <option value={company.id} selected={selCustomer}>
                            {company.company_name}
                          </option>
                        );
                      })}
                    </select>

                    {errors.company_customer_id && (
                      <p className="error-text">{errors.company_customer_id}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Name*
                    </label>

                    <input
                      name="project_name"
                      id="project_name"
                      value={project?.project_name}
                      onChange={handleChange("project_name")}
                      className="form-control"
                      placeholder="Project Name"
                    />

                    {errors.project_name && (
                      <p className="error-text">{errors.project_name}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Level *
                    </label>

                    <select
                      name="project_level"
                      id="project_level"
                      onChange={(e: any) => {
                        handleChange("project_level");
                        handleEvent(e);
                      }}
                      //value={project.project_level}
                      className="form-select"
                      disabled
                    >
                      <option value="">Select Project Level</option>
                      {PROJECT_LEVEL.map((level) => {
                        let subProjSel =
                          project.project_level === level.key
                            ? true
                            : location?.state?.subProject &&
                              level.key === "Sub_Project"
                            ? true
                            : false;
                        return (
                          <option value={level.key} selected={subProjSel}>
                            {level.label}
                          </option>
                        );
                      })}
                    </select>
                    {errors.project_level && (
                      <p className="error-text">{errors.project_level}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Type
                    </label>

                    <select
                      name="project_type"
                      value={project?.project_type}
                      onChange={handleChange("project_type")}
                      className="form-select"
                    >
                      <option value="">Select Project Type</option>

                      {PROJECT_TYPE.map((type) => (
                        <option value={type.key}>{type.label}</option>
                      ))}
                    </select>
                    {errors.project_type && (
                      <p className="error-text">{errors.project_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Phase
                    </label>

                    <input
                      name="phase"
                      value={project?.phase}
                      onChange={handleChange("phase")}
                      className="form-control"
                      placeholder="Phase"
                    />
                    {errors.phase && (
                      <p className="error-text">{errors.phase}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Wave
                    </label>
                    <select
                      name="wave"
                      value={project?.wave}
                      onChange={handleChange("wave")}
                      className="form-select"
                    >
                      <option value="">Select Wave</option>
                      {waveData.map((nd: any) => {
                        let selWave = project.wave === nd.value ? true : false;
                        return (
                          <option value={nd.value} selected={selWave}>
                            {nd.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Billing Type
                    </label>

                    <select
                      name="billing_type"
                      value={project?.billing_type}
                      onChange={handleChange("billing_type")}
                      className="form-select"
                    >
                      <option value="">Select Billing Type</option>
                      {BILLING_TYPE.map((billing) => {
                        let selBilling =
                          project?.billing_type === billing.value
                            ? true
                            : false;
                        return (
                          <option value={billing.value} selected={selBilling}>
                            {billing.label}
                          </option>
                        );
                      })}
                    </select>
                    {errors.billing_type && (
                      <p className="error-text">{errors.billing_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Total Pool Hours SOW
                    </label>

                    <input
                      name="total_pool_hours"
                      type="text"
                      value={project?.total_pool_hours}
                      onChange={handleChange("total_pool_hours")}
                      className="form-control"
                      placeholder="Total Pool Hours SOW"
                    />
                    {errors.total_pool_hours && (
                      <p className="error-text">{errors.total_pool_hours}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Project Health
                    </label>

                    <select
                      name="project_health"
                      id="project_health"
                      value={project?.project_health || ""}
                      onChange={handleChange("project_health")}
                      className="form-select"
                    >
                      <option value="">Select Project Health</option>
                      {PROJECT_HEALTH.map((health) => (
                        <option value={health.key}>{health.label}</option>
                      ))}
                    </select>
                    {errors.project_health && (
                      <p className="error-text">{errors.project_health}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Notes
                    </label>

                    <textarea
                      name="notes"
                      type="text"
                      value={project?.notes}
                      onChange={handleChange("notes")}
                      className="form-textarea"
                      placeholder="Notes"
                    ></textarea>
                    {errors.notes && (
                      <p className="error-text">{errors.notes}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Status
                    </label>

                    <select
                      name="project_status"
                      id="status"
                      value={project?.project_status}
                      onChange={handleChange("project_status")}
                      className="form-select"
                    >
                      <option value="">Select Status</option>
                      {PROJECT_STATUS.map((status) => (
                        <option value={status.key}>{status.label}</option>
                      ))}
                    </select>
                    {errors.project_status && (
                      <p className="error-text">{errors.project_status}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Master Project
                    </label>

                    <select
                      name="master_project"
                      id="master_project"
                      //value={project?.master_project}
                      // value={customer.company_logo}
                      onChange={handleChange("master_project", (val) => {
                        setMasterProjectId(val);
                        return val;
                      })}
                      className="form-select"
                      disabled
                    >
                      <option value="">Select Master Project</option>
                      {masterProjectRowData.map((d) => {
                        let selProj =
                          stateData?.masterProjectId &&
                          stateData?.masterProjectId === d.id
                            ? true
                            : false;

                        return (
                          <option value={d.id} selected={selProj}>
                            {d.project_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.master_project && (
                      <p className="error-text">{errors.master_project}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Partner
                    </label>

                    <input
                      name="engagement_partner"
                      id="engagement_partner"
                      value={project?.engagement_partner}
                      // value={customer.company_logo}
                      onChange={handleChange("engagement_partner", (val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpPartner}
                      className="form-control"
                      placeholder="Engagement Partner"
                    />
                    {errors.engagement_partner && (
                      <p className="error-text">{errors.engagement_partner}</p>
                    )}
                    {showSuggestion && (
                      <ul
                        className="list-group"
                        ref={partnersuggestRef}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          overflow: "auto",
                          right: 0,
                          left: 0,
                          width: "100%",
                          height: 130,
                          top: 92,
                        }}
                      >
                        {" "}
                        {Object.entries(partnerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setShowSuggestion(false);
                                  setPartnerresourceUserId(i?.id);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_partner: i?.ResourceName,
                                    partner_email_id: i?.emailid,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Director
                    </label>

                    <input
                      name="engagement_director"
                      id="engagement_director"
                      value={project?.engagement_director}
                      onChange={handleChange("engagement_director", (val) => {
                        setshowDirectorSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpDirector}
                      className="form-control"
                      placeholder="Engagement Director"
                    />
                    {errors.engagement_director && (
                      <p className="error-text">{errors.engagement_director}</p>
                    )}
                    {showDirectorSuggestion && (
                      <ul
                        className="list-group"
                        ref={directorsuggestRef}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          overflow: "auto",
                          right: 0,
                          left: 0,
                          width: "100%",
                          height: 130,
                          top: 92,
                        }}
                      >
                        {" "}
                        {Object.entries(directorSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setshowDirectorSuggestion(false);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_director: i?.ResourceName,
                                    director_email_id: i?.emailid,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Sr.Manager
                    </label>

                    <input
                      name="engagement_sr_manager"
                      id="engagement_sr_manager"
                      value={project?.engagement_sr_manager}
                      onChange={handleChange("engagement_sr_manager", (val) => {
                        setshowSrManagerSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpSrManager}
                      className="form-control"
                      placeholder="Engagement Sr.Manager"
                    />
                    {errors.engagement_sr_manager && (
                      <p className="error-text">
                        {errors.engagement_sr_manager}
                      </p>
                    )}
                    {showSrManager && (
                      <ul
                        className="list-group"
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          overflow: "auto",
                          right: 0,
                          left: 0,
                          width: "100%",
                          height: 130,
                          top: 92,
                        }}
                        ref={srmanagersuggestRef}
                      >
                        {" "}
                        {Object.entries(srManagerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setshowSrManagerSuggestion(false);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_sr_manager: i?.ResourceName,
                                    srmanager_email_id: i?.emailid,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement Manager*
                    </label>

                    <input
                      name="engagement_manager"
                      id="engagement_manager"
                      value={project?.engagement_manager}
                      onChange={handleChange("engagement_manager", (val) => {
                        setManagerShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpManager}
                      className="form-control"
                      placeholder="Engagement Manager"
                    />
                    {errors.engagement_manager && (
                      <p className="error-text">{errors.engagement_manager}</p>
                    )}
                    {showManagerSuggestion && (
                      <ul
                        className="list-group"
                        ref={managersuggestRef}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          overflow: "auto",
                          right: 0,
                          left: 0,
                          width: "100%",
                          height: 130,
                          top: 92,
                        }}
                      >
                        {" "}
                        {Object.entries(managerSuggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setManagerShowSuggestion(false);
                                  setData({
                                    ...project,
                                    user_id: i?.id,
                                    engagement_manager: i?.ResourceName,
                                    manager_email_id: i?.emailid,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Opportunity ID
                    </label>

                    <input
                      name="opportunity_id"
                      id="opportunity_id"
                      value={project?.opportunity_id}
                      onChange={handleChange("opportunity_id")}
                      className="form-control"
                      // placeholder="Only Numbers are accepted"
                    />
                    {errors.opportunity_id && (
                      <p className="error-text">{errors.opportunity_id}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Engagement ID*
                    </label>

                    <input
                      name="engagement_id "
                      id="engagement_id "
                      value={project?.engagement_id}
                      onChange={handleChange("engagement_id")}
                      className="form-control"
                      placeholder="Only Numbers are accepted"
                    />
                    {errors.engagement_id && (
                      <p className="error-text">{errors.engagement_id}</p>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="btn-block">
                      {ProfileType?.ADMIN === getProfileType &&       <button type="submit" className="thame-btn w-100">
                          {actionType === "create" ? "Submit" : "Update"}
                        </button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <CustomModal
        title="Project Profile"
        onOpen={errorPopup}
        content={messages}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Project Profile"
        onOpen={successPopup}
        content={"Project data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Error on page, please validate!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default SubProjectForm;

import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../actions/auth";
import {
  requirementActions, setRequirementTest, 
} from "../../actions/Requirement";
import { APIResponse } from "../../../utility/commonTypes";
import { getRequirementTechnicalAsync, getRequirementTestAsync, updateRequirementTestAsync } from "../../../api/common";

function* RequirementTest(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
     
        updateRequirementTestAsync,
      payload
    );
    console.log(res.data);
   
 if (res?.status === 200) {
      yield put(setRequirementTest({ ...res.data },function(){}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetRequirementTest(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getRequirementTestAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      yield put(setRequirementTest({ ...res.data },function(){}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchRequirementTest() {
  yield takeLeading(requirementActions.GET_REQUIREMENT_TEST, GetRequirementTest);
  yield takeLeading(requirementActions.UPDATE_REQUIREMENT_TEST, RequirementTest);
}

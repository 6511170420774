import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router-dom";
import FormProject from "./FormProject";
import ScopeModules from "./ScopeModules";
import ProjectDates from "./ProjectDates";
import ProjectTasks from "./ProjectTask/index";
import ProjectResources from "./ProjectResources";
import SubProject from "./SubProjects/index";
import { activeTabClass, blankTabClass } from "./style";
import CustomTab from "./CustomTab";
import SubProjectForm from "./SubProjectModule/SubProjectForm";

const Project = () => {
  const [value, setValue] = useState(0);
  const [operationDone, setOperationDone] = React.useState(false);
  const location = useLocation();
  const params: any = useParams();
  const [projectData, setProjectData] = useState({});
  const stateData = params;
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const isDisabled = (tabIndex: number) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    setOperationDone(false);
    if (stateData?.projectId) {
      setOperationDone(true);
    }
  }, [stateData]);

  const getProjectDetail = (project: object) => {
    setProjectData(project);
  };
  return (
    <>
      <Box
        className="my-3"
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: "40px",
          width: "100%",
        }}
      >
        <CustomTab
          indexNumber={0}
          title={projectData?.project_name}
          locationPath={location.pathname}
        />
        <Tabs
          value={value}
          aria-label="project-list"
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab sx={activeTabClass} label="Project Profile" />
          {/* <Tab sx={activeTabClass} disabled={isDisabled(1)}
label="Scope" /> */}
          <Tab
            sx={activeTabClass}
            disabled={isDisabled(2)}
            label="Project Dates"
          />
          <Tab sx={activeTabClass} disabled={isDisabled(3)} label="Tasks" />
          <Tab sx={activeTabClass} disabled={isDisabled(4)} label="Resources" />
          {/* <Tab sx={activeTabClass} label="Files" />
        <Tab sx={activeTabClass} label="Status Updates" />
        <Tab sx={activeTabClass} label="Pending Items" /> */}
          <Tab
            sx={activeTabClass}
            disabled={isDisabled(4)}
            label="Sub Projects"
          />
          {/* <Tab sx={activeTabClass} label="Forecasts" /> */}
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
        </Tabs>
        <div
          style={{
            border: "1px solid #007CB0",
            borderTop: "1px solid transparent",
            padding: "2px",
          }}
        >
          {value === 0 ? (
            <FormProject projectDetail={getProjectDetail} />
          ) : null}

          {/* {value === 1 && <ScopeModules />} */}
          {value === 1 && <ProjectDates />}
          {value === 2 && <ProjectTasks />}
          {value === 3 && <ProjectResources />}
          {/* {value === 5 && <div className="p-4">Files</div>}
        {value === 6 && <div className="p-4">Status Updates</div>}
        {value === 7 && <div className="p-4">Pending Items</div>} */}
          {value === 4 && <SubProject />}
          {/* {value === 9 && <div className="p-4">Forecasts</div>} */}
        </div>
      </Box>
    </>
  );
};

export default Project;

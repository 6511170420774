import { useState } from "react";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  fetchMasterProjectAsync,
  getProjectAsync,
  projectUpdateAsync,
  signupProjectAsync,
} from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";
import { setAuthToken } from "../../actions/auth";
import { setProjectList } from "../../actions/Customer";
import {
  actions,
  setMasterProject,
  setProjectDetails,
} from "../../actions/Project";

function* SignupProject(action: any) {
  const { payload, callback } = action;
  // const[errror,setError]=useState(false)
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType == "create"
        ? signupProjectAsync
        : projectUpdateAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      yield put(setProjectList({ ...res.data }));
      callback(res?.data);
    } else {
      // setError(res?.data?.Error)

      callback(false, res?.data?.Error);
    }
  } catch (error) {
    console.log("error", error);
    callback(false, error);
  }
}

function* GetMasterProjectList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(fetchMasterProjectAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //  yield put(setMasterProject({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* getProjectDetail(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getProjectAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setProjectDetails({ ...res.data }));
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchSignUpProject() {
  yield takeLeading(actions.GET_MASTER_PROJECT_LIST, GetMasterProjectList);
  yield takeLeading(actions.SIGNUP_PROJECT, SignupProject);
  yield takeLeading(actions.GET_PROJECT_DETAILS, getProjectDetail);
  yield takeLeading(actions.UPDATE_PROJECT_LIST, SignupProject);
}

import { call } from "redux-saga/effects";
import {
  getTaskProgressData,
  updateTaskProgressData,
} from "../../../../api/Tasks";

export function* FetchTaskProgressAsync(action: any): any {
  const { payload, callback, task_id } = action;

  try {
    const res = yield call(getTaskProgressData, payload, task_id);

    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* UpdateTaskProgressAsync(action: any): any {
  const { payload, callback, task_id } = action;
  try {
    const res = yield call(updateTaskProgressData, payload, task_id);

    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

import apiClient from "../apiClient";
import { END_POINTS } from "../common/endpoint";

export const getAllCustomer = async (payload: any) => {
  if (payload.size || payload.page) {
    return await apiClient.get(
      END_POINTS.GET_ALL_CUSTOMER +
        `?size=${payload.size}` +
        `&page=${payload.page}`,
      payload
    );
  } else {
    return await apiClient.get(END_POINTS.GET_ALL_CUSTOMER, payload);
  }
};
// export const getCustomerContactAsync = async (payload: Record<string, any>) => {

export const CustomerContactListAsync = async (
  payload: Record<string, any>
) => {
  if (payload.size || payload.page) {

  return await apiClient.get(
    END_POINTS.CUSTOMER_CONTACT_LIST +
      `${payload.id}` +
      `/contact` +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
}
else
{
  return await apiClient.get(
    END_POINTS.CUSTOMER_CONTACT_LIST +
      `${payload.id}`,
    payload
  );
}
}

export const getCustomerContactAsync = async (payload: Record<string, any>) => {
  return await apiClient.get(
    END_POINTS.CUSTOMER_CONTACT_LIST +
      `${payload.id}` +
      `/contact` +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { normalize } from "path";
import { formatDate } from "../../../utility/idelTimer";
const ProgressData = ({
  register,
  Cnt,
  CntK,
  errors,
  startDate,
  setStartDate,
  completedDate,
  setCompletedDate,
  data,
  task_id,
  setValue,
  watch,
}) => {
  //console.log(data);
  let CntKK = CntK + (Cnt + 1);
  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData?.firstname+" "+state?.auth?.globalAuthData?.lastname;
  });

  const [progressData, setProgressData] = useState<Object>(data);
  const [targetDateDisabled, setTargetDateDisabled] = useState(false);
  //console.log({ progressData });

  useEffect(() => {
    progressData?.TaskProgresses[0]?.actual_end_date &&
      setValue(
        `tickets.${progressData?.id}.actual_end_date`,
        `${progressData?.TaskProgresses[0]?.actual_end_date}`
      );
    progressData?.TaskProgresses[0]?.completed_by &&
      setValue(
        `tickets.${progressData?.id}.completed_by`,
        progressData?.TaskProgresses[0]?.completed_by
      );
    if (progressData?.TaskProgresses[0]?.not_applicable) {      
    }
  }, []);
  const handleChangeCompleted = (e) => {
    let upDate = moment().format("YYYY-MM-DD");
    let userName = userDetail;
    if (!e.target.checked) {
      userName = "";
      upDate = "";
    }
    
    setValue(`tickets.${progressData?.id}.not_applicable`, false);
    setTargetDateDisabled(false);
    setValue(`tickets.${progressData?.id}.completed_by`, `${userName}`);
    setValue(`tickets.${progressData?.id}.actual_end_date`, `${upDate}`);
    setProgressData((prevVal) => ({
      ...prevVal,
      TaskProgressesNew: {
        ...prevVal.TaskProgressesNew,
        actual_end_date: `${upDate}`,
        completed_by: `${userName}`,
        not_applicable: false,
      },
    }));
  };

  const handleChangeNotApplicable = (e) => {
    let upDate = moment().format("YYYY-MM-DD");
    let userName = userDetail;
    if (!e.target.checked) {
      userName = "";
      upDate = "";
      setTargetDateDisabled(false);
    } else {
      setTargetDateDisabled(true);
    }
      setValue(`tickets.${progressData?.id}.completed`, false);
      setValue(`tickets.${progressData?.id}.completed_by`, `${userName}`);
      setValue(`tickets.${progressData?.id}.target_end_date`, "");
      setValue(`tickets.${progressData?.id}.actual_end_date`, `${upDate}`);
      setProgressData((prevVal) => ({
        ...prevVal,
        TaskProgressesNew: {
          ...prevVal.TaskProgressesNew,
          actual_end_date: `${upDate}`,
          completed_by: `${userName}`,
          target_end_date: "",
        },
      }));
    
  };
const handleOnTargetEndDateChange=(e:any)=>{
  setValue(`tickets.${progressData?.id}.target_end_date`, e.target.value);
  setProgressData((prevVal) => ({
    ...prevVal,
    TaskProgressesNew: {
      ...prevVal.TaskProgressesNew,
      target_end_date: e.target.value
    },
  }));
}
  return (
    <tr>
      <td>
        <input
          type="hidden"
          name={`tickets[${progressData?.id}]progress_phase_item_id`}
          id={`tickets[${progressData?.id}]progress_phase_item_id`}
          {...register(`tickets.${progressData?.id}.progress_phase_item_id`)}
          defaultValue={progressData?.id}
        />

        <input
          type="hidden"
          name={`tickets[${progressData?.id}]task_id`}
          id={`tickets[${progressData?.id}]task_id`}
          {...register(`tickets.${progressData?.id}.task_id`)}
          defaultValue={progressData?.TaskProgressesNew?.task_id}
        />
        <input
          type="hidden"
          name={`tickets[${progressData?.id}]id`}
          id={`tickets[${progressData?.id}]id`}
          {...register(`tickets.${progressData?.id}.id`)}
          defaultValue={progressData?.TaskProgressesNew?.id}
        />

        <div style={{ textAlign: "left" }}>
          <input
            type="checkbox"
            className="form-check-input mt-0 progressCheckbox"
            name={`tickets[${progressData?.id}]completed`}
            id={`tickets[${progressData?.id}]completed`}
            {...register(`tickets.${progressData?.id}.completed`)}
            onChange={(e) => {
              handleChangeCompleted(e);
            }}
            defaultChecked={progressData?.TaskProgressesNew?.completed}
          />
        </div>
      </td>
      <td>
        <div style={{ textAlign: "left" }}>
          <input
            type="checkbox"
            className="form-check-input mt-0 progressCheckbox"
            name={`tickets[${progressData?.id}]not_applicable`}
            id={`tickets[${progressData?.id}]not_applicable`}
            {...register(`tickets.${progressData?.id}.not_applicable`)}
            defaultChecked={progressData?.TaskProgressesNew?.not_applicable}
            onChange={(e) => {
              handleChangeNotApplicable(e);
            }}
          />
        </div>
      </td>
      <td>
        <div style={{ textAlign: "left" }}>
          <Tooltip title={progressData?.name} followCursor>
            <input
              name={`tickets[${progressData?.id}]initiaterequirements`}
              {...register(`tickets.${progressData?.id}.initiaterequirements`)}
              className={`form-control ${
                errors.tickets?.[`${progressData?.id}`]?.initiaterequirements
                  ? "is-invalid"
                  : ""
              }`}
              readOnly={true}
              type="text"
              defaultValue={progressData?.name}
            />
          </Tooltip>
          <div className="invalid-feedback">
            {errors.tickets?.[CntKK]?.initiaterequirements && (
              <span>This field is required</span>
            )}
          </div>
        </div>
      </td>
      <td>
        <div style={{ textAlign: "left" }}>
          <input
            name={`tickets[${progressData?.id}]target_end_date`}
            {...register(`tickets.${progressData?.id}.target_end_date`)}
            className={`form-control ${
              errors.tickets?.[`${progressData?.id}`]?.target_end_date
                ? "is-invalid"
                : ""
            }`}
            type="date"
            style={{ display: "inline-block"}}
            disabled={
              progressData?.TaskProgressesNew?.not_applicable === true
                ? true
                : targetDateDisabled
            }
            onChange={handleOnTargetEndDateChange}
            // data-date={progressData?.TaskProgressesNew?.target_end_date ? formatDate(progressData?.TaskProgressesNew?.target_end_date):"DD-MMM-YYYY"}
            defaultValue={progressData?.TaskProgressesNew?.target_end_date}
          />
          <div className="invalid-feedback">
            {errors.tickets?.[progressData?.id]?.target_end_date && (
              <span>
                {
                  errors.tickets?.[progressData?.id]["target_end_date"][
                    "message"
                  ]
                }
              </span>
            )}
          </div>
        </div>
      </td>
      {/* <td> */}
        {/* <div style={{ textAlign: "center", padding: "5px" }}>
          <input
            name={`tickets[${progressData?.id}]assigned_hours`}
            {...register(`tickets.${progressData?.id}.assigned_hours`)}
            className={`form-control ${
              errors.tickets?.[`${progressData?.id}`]?.assigned_hours
                ? "is-invalid"
                : ""
            }`}
            type="text"
            readOnly={true}
            defaultValue={`${progressData?.TaskProgressesNew?.assigned_hours}`}
          />
        </div>
         */}
      {/* </td> */}
      <td>
        <Tooltip title={progressData?.project_role} followCursor>
          <div style={{ textAlign: "center" }}>
            <input
              name={`tickets[${progressData?.id}]project_role`}
              {...register(`tickets.${progressData?.id}.project_role`)}
              className={`form-control ${
                errors.tickets?.[`${progressData?.id}`]?.project_role
                  ? "is-invalid"
                  : ""
              }`}
              disabled
              type="text"
              defaultValue={progressData?.project_role}
            />
          </div>
        </Tooltip>
      </td>
      <td>
        <div style={{ textAlign: "center" }}>
          <input
            name={`tickets[${progressData?.id}]completed_by`}
            {...register(`tickets.${progressData?.id}.completed_by`)}
            className={`form-control ${
              errors.tickets?.[`${progressData?.id}`]?.completed_by
                ? "is-invalid"
                : ""
            }`}
            type="text"
            disabled
            value={progressData?.TaskProgressesNew?.completed_by}
            defaultValue={progressData?.TaskProgressesNew?.completed_by}
          />
        </div>
      </td>
      <td>
        <div style={{ textAlign: "left" }}>
          {/* {console.log("test", progressData?.TaskProgressesNew)} */}
          <input
            name={`tickets[${progressData?.id}]actual_end_date`}
            {...register(`tickets.${progressData?.id}.actual_end_date`)}
            className={`form-control ${
              errors.tickets?.[`${progressData?.id}`]?.actual_end_date
                ? "is-invalid"
                : ""
            }`}
          style={{ display: "inline-block",width:127,minWidth:127 }}
            type="date"
            disabled
            value={progressData?.TaskProgressesNew?.actual_end_date}
            defaultValue={progressData?.TaskProgressesNew?.actual_end_date}
          />
          <div className="invalid-feedback">
            {errors.tickets?.[progressData?.id]?.actual_end_date && (
              <span>This field is required</span>
            )}
          </div>
        </div>
      </td>
      <td>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <textarea
            name={`tickets[${progressData?.id}]comments`}
            {...register(`tickets.${progressData?.id}.comments`, { 
              pattern: {
                value: /^[A-Za-z](?:[a-zA-Z0-9\\s#_-])*$/,
                message: 'Please enter valid comment',
              },
              minLength: {
                value: 10,
                message: "Less than 10"
              }, 
              maxLength: 100 })}
            className={`${
              errors.tickets?.[`${progressData?.id}`]?.comments
                ? "is-invalid"
                : ""
            }`}
            rows={100}
            cols={200}
            defaultValue={progressData?.TaskProgressesNew?.comments}
            style={{ fontWeight: "normal", fontSize: "14px",height:40,width:200,resize:"both"}}
          />
          <div className="invalid-feedback">            
            {errors.tickets?.[`${progressData?.id}`]?.comments && (
              <span>{errors.tickets[`${progressData?.id}`].comments.message}</span>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ProgressData;

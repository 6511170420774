const columns: any = () => {
  return [
    {
      id: 'field_order',
      label: 'Sr. no',
      minWidth: 50,
      align: "center"
    },
    {
      id: 'Source_Value',
      label: 'Source Value',
      minWidth: 50,
      align: "center"
    },
    {
      id: 'Target_Value',
      label: 'Target Value',
      minWidth: 50,
      align: "center"
    },
    {
      id: 'Notes',
      label: 'Notes',
      minWidth: 50,
      align: "center"
    },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 50,
      align: "center"
    },
  ]
};

export default columns;
import { useState, useRef, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import { useForm } from "../../helpers/validations/useForm";
import { forgotPasswordHandler } from "../../store/actions/User";
import EmailSuccess from "./emailSuccess";

interface Details {
  email: any
}

const ForgotPassword: FC = ({openModalPopUp,setOpenModalPopup}:any) => {
  const customerRef = useRef({});
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
const[open,setOpen]=useState(false)
  const {
    handleSubmit,
    handleChange,
    data: details,
    errors,
  } = useForm<Details>({
    validations: {



      email: {
        required: {
          value: true,
          message: "Email ID should not be empty",
        },
        pattern: {
          value: "^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+.)+[A-Za-z]{2,4}$",
          message: "Please provide correct Email ID",
        },
      },

    },

    onSubmit: () => {
      setLoader(true);
      dispatch(
        forgotPasswordHandler(
          {
            payload: {
              email: details?.email
            }
          },

          onSuccess
        )
        
      );
    },
  });


  const onSuccess = (data: any) => {
    setLoader(false)
    if (data===false) {
      setErrorPopup(true)

    }
    else {
      setSuccessPopup(true)
    }
    setOpenModalPopup(false)

    setLoader(false)

   }
  const onResetSuccess=()=>{
    // setOpenModalPopup(false)
    setSuccessPopup(true)
  }
   useEffect(()=>{
    openModalPopUp && setOpenModalPopup(true)
    
  
   },[openModalPopUp])
   return (

    <div>
        <CustomModal
        
        title=""
        onOpen={openModalPopUp}
        content={<><h2>Password Reset</h2><form onSubmit={handleSubmit}>

        <input type="email"
          onChange={handleChange("email")}
          className="form-control" placeholder="Email" />
        
        {errors.email && (
                            <p className="error-text">{errors.email}</p>
                          )}
        <div className="btn-block">
          <button type="submit" className="thame-btn w-100">Reset</button>
        </div>
        </form>
        </>
        }
                width={"sm"}
               
        OnClickCloseIcon={() => {
          setOpenModalPopup(false)
          setSuccessPopup(false);
        }}
      /> 

      <CustomModal
        title="Password Reset"
        onOpen={errorPopup}
        content={"Invalid EmailId"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
        <CustomModal
        title=""
        onOpen={successPopup}
        content={<EmailSuccess/>}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onResetSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      /> 
     
    </div>
  );
};

export default ForgotPassword;

import { forwardRef, useImperativeHandle, useState } from "react";

const TimeoutWrapper = forwardRef((props, ref) => {
    const [timeoutObject, setTimeoutObject] = useState({});
    useImperativeHandle(ref, () => ({

        createTimeoutObject(callback,timeout) {
           const timeoutValue= setTimeout(callback,timeout);
           setTimeoutObject(timeoutValue)
        },
        clearTimeoutObject() {
            if(timeoutObject){
            clearTimeout(timeoutObject)
            setTimeoutObject({});
            }
        },
    }));
    return null;
});
export default TimeoutWrapper;
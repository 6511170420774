import { useNavigate } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Container,
  MenuItem,
  MenuList,
  Grid
} from '@mui/material';

import footerMenu from './footerItems';


const CustomFooter = () => {
  const menuList = footerMenu()
  const navigate = useNavigate();

  return (
    <div>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: "transparent" }} >
        <Container maxWidth="xl">
          <Toolbar
            className="my-2"
            disableGutters
            sx={{
              minHeight: "70px !important",
              justifyItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}>
            <Grid spacing={0}>
              <MenuList
                key={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "400",
                  color: '#d2d2d2',
                  padding: 0,
                  fontFamily: 'Arial, Helvetica, sans-serif',
                  fontSize: '15px'
                }}
              >
                {/* {menuList.map((item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{
                        '&:hover': {
                          background: 'transparent',
                        },
                        '&:focus': {
                          background: 'transparent',
                        }
                      }}
                      key={item.id}
                      onClick={() => {
                        navigate(item.path);
                      }}>
                      {item.title} {item.hide ? null : <span style={{ marginRight: "-1.5rem", marginLeft: ".5rem" }}>|</span>}
                    </MenuItem>
                  )
                })} */}
              </MenuList>
              <Grid className="d-flex justify-content-center" sx={{ color: "black" }}>                  {" © Copyright "}
      Deloitte  LLP&nbsp;
       {new Date().getFullYear()}
</Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar >
    </div >
  );
};
export default CustomFooter;

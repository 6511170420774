import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomPagination from "../../../components/CustomPagination";
import { getAllProjectList } from "../../../store/actions/Projects";
import columns from "./columnsDef";
import { BoxStyle, HeaderStyle } from "./style";
import rows from "./allprojects.json";

const AllProjectsList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const fetchAllProjectList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getAllProjectList(
          {
            role: "admin",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllProjectList(0, "ON_LOAD");
  }, []);

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        All Projects
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchAllProjectList(data, "NEXT_PAGE");
          }}
          // components={{
          //   Pagination: CustomPagination,
          //   toolbar: GridToolbar
          // }}
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   }
          // }}
        />
      </Box>
    </>
  );
};

export default AllProjectsList;

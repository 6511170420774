import React from 'react'
import { useState, useRef, useEffect, FC } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { errorMessages, REPLACE_REGEX, TECHNICAL } from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getRequirementTechnical,
  updateRequirementTechnical,
} from "../../../store/actions/Requirement";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import moment from 'moment';
import { formatDate } from '../../../utility/idelTimer';
import { ProfileType } from '../../../utility/commonTypes';

interface RequirementTechnical {
  id: any;
  field_data: any;
  requirement_id: any;
  Integration_Run_Frequency: any;
  Anticipated_First_Run_Date: any;
  Scheduling_Specifics: any;
  Trigger: any;
  Dependent_or_required_for_Workday_payroll_Activities: any;
  Launch_Parameters: any;
  Web_Services_Used: any;
  Annual_growth_anticipated_volume: any;
  Performance_Testing_Required: any;
  Audit_File_Requirement: any;
  Exception_Handling_Requirement: any;
  Notification_Requirement: any;
  Notes_and_Additional_Comments: any;
}
const Technical = (props:any) => {
  const [successPopup, setSuccessPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = useParams();
  console.log({ location, params });
  const stateData: any = params;
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const createButton = {
    textAlign: "right",
  };
  const [open, setOpen] = useState(false);
const handleClose = (
  event?: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }

  setOpen(false);
};

  const {
    handleSubmit,
    handleChange,
    data: technical,
    setData,
    errors,
  } = useForm<RequirementTechnical>({
    validations: {
      Integration_Run_Frequency: {
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Entered value is not Valid.Please provide only numeric values",
        },
        maxlength: {
          value: 100,
          message:"Entered value should be less than 100 character length",
        },
      },
      Annual_growth_anticipated_volume: {
        pattern: {
          value: REPLACE_REGEX?.NUMBERS_WITH_DECIMALS,
          message:errorMessages?.patternWithOnlyDecimalNumbers()
        },
        maxlength: {
          value: 5,
          message: errorMessages?.allowedRange("Annual Growth anticipated volume"),
        },
      },
      Scheduling_Specifics: {
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Trigger:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 100,
          message: "Entered value should be less than 50 character length",
        },
      },
      Launch_Parameters:{
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Web_Services_Used: {
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Web service used should be less than 5000 character length",
        },
      }, 
      Audit_File_Requirement:{
        pattern: {
          value: "(^w|_d\n)*[A-Za-z\n].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 100,
          message: "Entered value should be less than 50 character length",
        },
      },
      Exception_Handling_Requirement:{
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Entered value should be combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Web service used should be less than 5000 character length",
        },
      },
      Notification_Requirement:{
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Entered value should be combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Notes_and_Additional_Comments:{
        pattern: {
          value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
          message:
            "Entered value should be combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      }    
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      // setLoader(true);
      let res: any = {};
      for (const i in technical) {
        const technicalValue = technical[i];
        res[i] = {
          field_desc: technicalValue !== undefined ? technicalValue : "",
          test_cases: 23,
        };
      }
      dispatch(
        updateRequirementTechnical(
          {
            payload: {
              id: technical?.id,
              requirement_id: stateData?.requirementId,
              field_data: res,
              test_cases: 23,
            },
          },
          onUpdateTechnical
        )
      );
    },
  });
  const currentTabData=()=>{
    dispatch(
      getRequirementTechnical({ id: stateData?.requirementId }, function (
        result: any
      ) {
        setData({
          ...result,
          id: result?.id,
          requirement_id: stateData?.requirementId,
          Integration_Run_Frequency:
            result?.field_data?.Integration_Run_Frequency?.field_desc,
          Anticipated_First_Run_Date:
            result?.field_data?.Anticipated_First_Run_Date?.field_desc,
          Scheduling_Specifics:
            result?.field_data?.Scheduling_Specifics?.field_desc,
          Trigger: result?.field_data?.Trigger?.field_desc,
          Dependent_or_required_for_Workday_payroll_Activities:
            result?.field_data
              ?.Dependent_or_required_for_Workday_payroll_Activities
              ?.field_desc,
          Launch_Parameters: result?.field_data?.Launch_Parameters?.field_desc,
          Web_Services_Used: result?.field_data?.Web_Services_Used?.field_desc,
          Annual_growth_anticipated_volume:
            result?.field_data?.Annual_growth_anticipated_volume?.field_desc,
          Performance_Testing_Required:
            result?.field_data?.Performance_Testing_Required?.field_desc,
          Audit_File_Requirement:
            result?.field_data?.Audit_File_Requirement?.field_desc,
          Exception_Handling_Requirement:
            result?.field_data?.Exception_Handling_Requirement?.field_desc,
          Notification_Requirement:
            result?.field_data?.Notification_Requirement?.field_desc,
          Notes_and_Additional_Comments:
            result?.field_data?.Notes_and_Additional_Comments?.field_desc,
        });
      })
    );
  }
  
  const [errorPopup, setErrorPopup] = useState(false);
  const onUpdateTechnical = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
        setSuccessPopup(false)
        window.scrollTo(0,0)   
        currentTabData()   
            }, 2000);
    }
  };
  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
  const isLocked =  selector?.locked === true       
  useEffect(() => {
    currentTabData()
  }, [setData]);

  return (
    <div className="container-fluid ">
      <div className="col-xl-10 col-xxl-7">
        <div className="frame_outer">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left  mt-2 mb-3 vendor_contact">
                  Technical
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="mt-2 pull-left vendor_contact">Test case</div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Integration run frequency
                  </label>
                  <input
                    type="text"
                    id="Integration_Run_Frequency"
                    name="Integration_Run_Frequency"
                    value={technical?.Integration_Run_Frequency}
                    onChange={handleChange("Integration_Run_Frequency")}
                    className="form-control"
                    placeholder=" "
                    disabled={isLocked}
                  />
                  {errors.Integration_Run_Frequency && (
                    <p className="error-text">
                      {errors.Integration_Run_Frequency}
                    </p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Anticipated first run date
                  </label>
                  <input
                    type="date"
                    id="Anticipated_First_Run_Date"
                    name="Anticipated_First_Run_Date"
                    value={technical?.Anticipated_First_Run_Date}
                    onChange={handleChange("Anticipated_First_Run_Date")}
                    className="form-control"
                    placeholder="DD-MMM-YYYY"
                    data-date={technical?.Anticipated_First_Run_Date ? formatDate(technical?.Anticipated_First_Run_Date):"DD-MMM-YYYY"}
                    disabled={isLocked}
                  />
                  {errors.Anticipated_First_Run_Date && (
                    <p className="error-text">{errors.Anticipated_First_Run_Date}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Scheduling specifics
                  </label>
                  <textarea
                    name="Scheduling_Specifics"
                    id="Scheduling_Specifics"
                    value={technical?.Scheduling_Specifics}
                    onChange={handleChange("Scheduling_Specifics")}
                    className="form-textarea"
                    disabled={isLocked}
                  >
                    {technical?.Scheduling_Specifics}
                  </textarea>
                  {errors.Scheduling_Specifics && (
                    <p className="error-text">{errors.Scheduling_Specifics}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Trigger
                  </label>
                  <input
                    name="Trigger"
                    id="Trigger"
                    type="text"
                    value={technical?.Trigger}
                    onChange={handleChange("Trigger")}
                    className="form-control"
                    placeholder=" "
                    disabled={isLocked}
                  />
                  {errors.Trigger && (
                    <p className="error-text">{errors.Trigger}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Dependent or required for Workday payroll Activities?
                  </label>

                  <select
                    name="Dependent_or_required_for_Workday_payroll_Activities"
                    id="Dependent_or_required_for_Workday_payroll_Activities"
                    value={
                      technical?.Dependent_or_required_for_Workday_payroll_Activities
                    }
                    onChange={handleChange(
                      "Dependent_or_required_for_Workday_payroll_Activities"
                    )}
                    className="form-select"
                    aria-label="Default select example"
                    disabled={isLocked}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((technical) => {
                      let deptSelectedVal =
                        technical?.Dependent_or_required_for_Workday_payroll_Activities ===
                        technical.value
                          ? true
                          : false;
                      return (
                        <option
                          value={technical.key}
                          selected={deptSelectedVal}
                        >
                          {technical.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Launch parameters
                  </label>
                  <textarea
                    id="Launch_Parameters"
                    name="Launch_Parameters"
                    onChange={handleChange("Launch_Parameters")}
                    value={technical?.Launch_Parameters}
                    className="form-textarea"
                    disabled={isLocked}
                  >
                    {technical?.Launch_Parameters}
                  </textarea>
                  {errors.Launch_Parameters && (
                    <p className="error-text">{errors.Launch_Parameters}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Web services used
                  </label>
                  <textarea
                    type="text"
                    id="Web_Services_Used"
                    name="Web_Services_Used"
                    onChange={handleChange("Web_Services_Used")}
                    value={technical?.Web_Services_Used}
                    placeholder=" "
                    disabled={isLocked}
                    className="form-textarea"
                  >
                  </textarea>
                  {errors.Web_Services_Used && (
                  <p className="error-text">{errors.Web_Services_Used}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Annual growth anticipated (volume) %
                  </label>
                  <input
                    type="text"
                    id="Annual_growth_anticipated_volume"
                    name="Annual_growth_anticipated_volume"
                    onChange={handleChange("Annual_growth_anticipated_volume")}
                    value={technical?.Annual_growth_anticipated_volume}
                    className="form-control"
                    placeholder="Only Numeric values are accepted"
                    disabled={isLocked}
                  />
                  {errors.Annual_growth_anticipated_volume && (
                    <p className="error-text">
                      {errors.Annual_growth_anticipated_volume}
                    </p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Performance testing required?
                  </label>
                  <select
                    name="Performance_Testing_Required"
                    id="Performance_Testing_Required"
                    onChange={handleChange("Performance_Testing_Required")}
                    className="form-select"
                    value={technical?.Performance_Testing_Required}
                    aria-label="Default select example"
                    disabled={isLocked}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((technical) => {
                      let performanceSelectedVal =
                        technical?.Performance_Testing_Required ===
                        technical.value
                          ? true
                          : false;
                      return (
                        <option
                          value={technical.key}
                          selected={performanceSelectedVal}
                        >
                          {technical.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Audit file requirement
                  </label>
                  <textarea
                    type="text"
                    id="Audit_File_Requirement"
                    name="Audit_File_Requirement"
                    onChange={handleChange("Audit_File_Requirement")}
                    value={technical?.Audit_File_Requirement}
                    placeholder=" "
                    disabled={isLocked}
                    className="form-textarea"
                  >
                    </textarea>
                  {errors.Audit_File_Requirement && (
                  <p className="error-text">{errors.Audit_File_Requirement}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Exception handling requirement
                  </label>
                  <textarea
                    type="text"
                    id="Exception_Handling_Requirement"
                    name="Exception_Handling_Requirement"
                    onChange={handleChange("Exception_Handling_Requirement")}
                    value={technical?.Exception_Handling_Requirement}
                    placeholder=" "
                    className="form-textarea"
                    disabled={isLocked}
                  >
                  </textarea>
                  {errors.Exception_Handling_Requirement && (
                  <p className="error-text">{errors.Exception_Handling_Requirement}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Notification requirement
                  </label>
                  <textarea
                    id="naNotification_Requirementme"
                    name="Notification_Requirement"
                    onChange={handleChange("Notification_Requirement")}
                    value={technical?.Notification_Requirement}
                    placeholder=" "
                    disabled={isLocked}
                    className="form-textarea"
                  >
                    {technical?.Notification_Requirement}
                  </textarea>
                  {errors.Notification_Requirement && (
                  <p className="error-text">{errors.Notification_Requirement}</p>
                  )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Notes and additional comments
                  </label>
                  <textarea
                    id="Notes_and_Additional_Comments"
                    name="Notes_and_Additional_Comments"
                    value={technical?.Notes_and_Additional_Comments}
                    onChange={handleChange("Notes_and_Additional_Comments")}
                    className="form-textarea"
                    disabled={isLocked}
                  >
                    {technical?.Notes_and_Additional_Comments}
                  </textarea>
                  {errors.Notes_and_Additional_Comments && (
                  <p className="error-text">{errors.Notes_and_Additional_Comments}</p>
                  )}
                </div>
                <div className="btn-block">
                { props?.toggleValue === false &&  isLocked === false && ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType?  
               <button type="submit" className="thame-btn w-100">
                    Update
                  </button>:null}
                </div>
              </div>

              {/* <div className="col-md-2">
                <div className="testcase_list_wrapper">
                  <div className="test_case_list">
                    <div className="test_case_list pt-2">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselist"
                      >
                        test
                      </a>
                    </div>

                    <div className="test_case_list">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselistfill"
                      >
                        test 2
                      </a>
                    </div>
                    <div className="test_case_list mt-4">
                      <a href="#">3</a>
                    </div>
                    <div className="test_case_list mt-5">
                      <a href="#">4</a>
                    </div>
                    <div className="test_case_list mt-3">
                      <a href="#">5</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">6</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">7</a>
                    </div>
                    <div className="test_case_list">
                      <a href="#">8</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">9</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">10</a>
                    </div>
                    <div className="test_case_list">
                      <a href="#">11</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">12</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">13</a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <CustomModal
        title="Requirement Technical"
        onOpen={errorPopup}
        content={"Error!Please try again"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Requirement Technical"
        onOpen={successPopup}
        content={"Requirement Technical data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </div>
  );
};
export default Technical;

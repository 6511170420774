import { actions } from "../../actions/apiDebugger";

type APILog = {
  request: string;
  response: string;
  key: string;
};
const initialState: { apiLogs: APILog[] } = {
  apiLogs: [],
};

export default function (
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case actions.API_DEBUGGER:
      return {
        ...state,
        apiLogs: [action.payload, ...state.apiLogs].splice(0, 20),
      };
    case actions.CLEAR_API_DEBUGGER:
      return {
        ...state,
        apiLogs: [],
      };
    default:
      return state;
  }
}

import { AnyAction } from 'redux';

export const actions = {
    SET_CONNECTED_STATUS: 'SET_CONNECTED_STATUS',
};

export const setConnectedStatus = (isConnected: boolean = true): AnyAction => {
    return {
        type: actions.SET_CONNECTED_STATUS,
        isConnected,
    };
};

import { MODAL_TYPES } from "../../utility/commonTypes";

export const BtnClass = {
    fontSize: "14px",
    fontWeight: "bold"
}

export const colors = {
    ERROR_RED: '#e42313',
    SUCCESS_GREEN: '#046A38',
    WARNING_YELLOW: '#f39000',
    DEFAULT_COLOR: "rgba(0, 0, 0, 0.87)"
};

export const titleCSS = {
    fontWeight: "bold"
}

export const ModalTypeStyles = (modalType: any) => {
    let color = colors.DEFAULT_COLOR;
    let border = `8px solid`
    switch (modalType) {
        case MODAL_TYPES.WARNING:
            color = colors.WARNING_YELLOW;
            border = `${border} ${colors.WARNING_YELLOW}`
            break;
        case MODAL_TYPES.SUCCESS:
            color = colors.SUCCESS_GREEN;
            border = `${border} ${colors.SUCCESS_GREEN}`
            break;
        case MODAL_TYPES.ERROR:
            color = colors.ERROR_RED;
            border = `${border} ${colors.ERROR_RED}`
            break;
        default:
            color = colors.DEFAULT_COLOR;
            border = ''
            break;
    }
    return { color, border };
};
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import { Checkbox, Tooltip } from "@mui/material";
import { PRIORITY, TASK_STATUS } from "../../../constants/common";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,

  flex: 1,
};

const Columns = (
  editRequirementPendingItems: any,
  deleteRequirementPendingItems: any,
  handleUserDetails:any
) => {
  return [
    {
      field: "fieldId",
      headerName: "#",

      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return params?.row?.fieldId + 1;
      },
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined||null ? 'NA' : formatDate(params?.value)

     },
    },
    {
      field: "issue",
      headerName: "Issue",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.issue ? (
              <Tooltip title={params?.row?.issue} followCursor>
                <div>{params?.row?.issue}</div>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => <Tooltip title={params?.row?.type} followCursor>
      <div>{params?.row?.type||"NA"}</div>
    </Tooltip>,
    },
    {
      field: "priority",
      headerName: "Priority",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PRIORITY.find((o) => o.key === params?.row?.priority);
        return obj?.label?(
          <Tooltip title={obj?.label} followCursor>
          <div>{obj?.label}</div>
        </Tooltip>
        ) : (
          "NA"
          );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = TASK_STATUS.find((o) => o.key === params?.row?.status);
        return obj?.label?(
          <Tooltip title={obj?.label} followCursor>
          <div>{obj?.label}</div>
        </Tooltip>
        ) : (
          "NA"
          );      },
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.assigned_to ? (
              <Tooltip title={params?.row?.assigned_to} followCursor>
                <div><a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.pendingItemHandler?.assignedTo_id);return false}}>{params?.row?.assigned_to}</a></div>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined||null ? 'NA' : formatDate(params?.value)

     },
    },

    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.created_by ? (
              <Tooltip title={params?.row?.created_by} followCursor>
                <div><a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.pendingItemCreator?.createdBy_id);return false}}>{params?.row?.created_by}</a></div>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "internal",
      headerName: "Internal",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Checkbox
            checked={params?.row?.internal === true}
            inputProps={{ "aria-label": "controlled" }}
            disabled
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 122,
      ...commonColumnsDef,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: "#000000" }} />}
          label="Edit"
          onClick={(e: any) => editRequirementPendingItems(e, params?.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#c70000" }} />}
          label="Delete"
          onClick={(e: any) =>
            deleteRequirementPendingItems(e, params?.row?.id)
          }
        />,
      ],
    },
  ];
};

export default Columns;

import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router-dom";

import PendingItems from "./PendingItems/pendingItems";

import CreateUpdate from "../../components/organism/Tasks/CreateUpdate";
import TaskProgress from "./create/progress";
import Files from "./Files";
import UnitTests from "./UnitTests";
import TestCases from "./TestCases/PendingItems";
import CustomTab from "../../components/CustomTab";
import { activeTabClass, blankTabClass } from "./style";
import Resources from "./Resources/resources";

const TaskLanding = () => {
  const [value, setValue] = useState(0);
  const [operationDone, setOperationDone] = React.useState(false);
  const [taskData, setTaskData] = useState({});
  const location = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const isDisabled = (tabIndex: number) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    setOperationDone(false);
    if (stateData?.taskId) {
      setOperationDone(true);
    }
  }, [stateData]);

  const getProjectTaskDetail = (task: object) => {
    setTaskData(task);
  };

  return (
    <>
      <CustomTab
        indexNumber={0}
        title={taskData?.integration_id + "-" + taskData?.task_name}
        requirementId={taskData?.Requirement?.id}
        locationPath={location.pathname}
        moduleType="task"
      />
      <Box
        className="my-3"
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: "40px",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          aria-label="project-list"
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab sx={activeTabClass} label="Main" />
          {/* <Tab sx={activeTabClass} label="Contacts" /> */}
          <Tab sx={activeTabClass} disabled={isDisabled(1)} label="Progress" />
          {/* <Tab sx={activeTabClass} label="Messages" /> */}
          <Tab sx={activeTabClass} disabled={isDisabled(2)} label="Resources" />
          <Tab sx={activeTabClass} disabled={isDisabled(3)} label="Files" />
          <Tab sx={activeTabClass} disabled={isDisabled(4)} label="Unit Testing" />
          <Tab sx={activeTabClass} disabled={isDisabled(5)} label="Test Cases" />
          <Tab
            sx={activeTabClass}
            disabled={isDisabled(6)}
            label="Pending Items"
          />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
        </Tabs>
        <div
          style={{
            border: "1px solid #007CB0",
            borderTop: "1px solid transparent",
            padding: "2px",
          }}
        >
          {value === 0 ? (
            <CreateUpdate taskDetail={getProjectTaskDetail} />
          ) : null}
          {/* {value === 1 ? "Contacts Page" : null} */}
          {value === 1 ? <TaskProgress /> : null}
          {/* {value === 3 ? <DataTab /> : null} */}
          {value === 2 ? <Resources /> : null}
          {value === 3 ? <Files /> : null}
          {/* {value === 6 ? "Item5" : null}
        {value === 7 ? "Item1" : null} */}
          {value === 4 ? <UnitTests /> : null}
          {value === 5 ? <TestCases /> : null}
          {value === 6 ? <PendingItems /> : null}
        </div>
      </Box>
    </>
  );
};

export default TaskLanding;

import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import {
  projectDeleteResourceAsync,
  projectResourceAsync,
  projectResourceUpdateAsync,
  searchUserAsync,
  signupProjectResourceAsync,
} from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";
import {
  actions,
  deleteProjectResources,
  setProjectResources,
  setUserDetails,
} from "../../actions/Project";

function* SignupProjectResource(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType==="create"?
      signupProjectResourceAsync:
      projectResourceUpdateAsync, payload);
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } 
    else if(res?.status===200){
      callback(res?.data);

    }
      else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* ProjectResources(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(projectResourceAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setProjectResources({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}


function* ProjectResourceDelete(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(projectDeleteResourceAsync, payload);
    console.log("deleted data", res.data);

    if (res?.status === 200) {
      //yield put(deleteProjectResources({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

function* SearchUsers(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(searchUserAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setUserDetails({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchSignUpProjectResource() {
  yield takeLeading(actions.GET_USER_BY_NAME, SearchUsers);
  yield takeLeading(actions.SIGNUP_PROJECT_RESOURCES, SignupProjectResource);
  yield takeLeading(actions.GET_PROJECT_RESOURCES, ProjectResources);
  yield takeLeading(actions.UPDATE_PROJECT_RESOURCES, SignupProjectResource);
  yield takeLeading(actions.DELETE_PROJECT_RESOURCES, ProjectResourceDelete);
}

import React, { useMemo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./columnsDef";
import Stack from '@mui/material/Stack';
import { BoxStyle } from "../style";
import CustomPagination from "../../../components/CustomPagination";

const MyTasksList = ({ projectData, loading }: any) => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const NoRowsOverlay=()=> {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No Task Assigned
      </Stack>
    );
  }

  // const [getProjectData, setProjectData] = useState([]);
  // const [loadingStatus, setLoadingStatus] = useState(false);


  // useEffect(() => {
  //   // setLoadingStatus(true)
  //   // setProjectData([])
  //   const delay = 1000;
  //   const timeoutId = setTimeout(() => {
  //     setLoadingStatus(false)
  //     setProjectData(projectData)
  //   }, delay);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [projectData]);

  
  return (
    <>
      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={projectData}
          // rowCount={projectData.length}
          // page={0}
          // components={{NoRowsOverlay}}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          // hideFooterPagination
          loading={loading}
          components={{
            NoRowsOverlay,
            Pagination: CustomPagination,
          }}
        />
      </Box>
    </>
  );
};

export default MyTasksList;

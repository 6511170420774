import { useState } from "react";

const ProfileMenuItems: any = () => {
  return [

    {
      name: "Name",
      profileMenuItems: [
        {
          id: "1",
          title: "Profile",
          path:"/user/view",
          showProfileName: true,
          showProfileDesignation: false
        },
        {
          id: "2",
          title: "Designation",
          showProfileName: false,
          showProfileDesignation: true
        },
        // {
        //   id: "3",
        //   title: "User Profile",
        //   path: "/user/view",
        //   showProfileName: false,
        //   showProfileDesignation: false
        // },
        // {
        //   id: "4",
        //   title: "My DPN Profile",
        //   path: "/register",
        //   showProfileName: false,
        //   showProfileDesignation: false
        // },
        {
          id: "5",
          title: "Report An Issue",
          path: "/report",
          showProfileName: false,
          showProfileDesignation: false
        },
        {
          id: "6",
          title: "Logout",
          showProfileName: false,
          showProfileDesignation: false
        }

      ],

    },

  ];


};

export default ProfileMenuItems;
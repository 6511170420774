import { ACTION_TYPES } from "../../../actionTypes";

const {
  GET_ALL_PROJECT_LIST,
  GET_MY_PROJECT_LIST,
  GET_PROJECT_BY_CUSTOMER,
  GET_CUSTOMER_PROJECT,
  GET_ALL_PROJECT_LIST_DASHBOARD,
} = ACTION_TYPES.PROJECT;

export const getAllProjectList = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_ALL_PROJECT_LIST,
    payload,
    callback,
  };
};

//
export const getMyProjectListDashboard = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_ALL_PROJECT_LIST_DASHBOARD,
    payload,
    callback,
  };
};

export const getMyProjectList = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_MY_PROJECT_LIST,
    payload,
    callback,
  };
};

export const getProjectByCustomerList = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_PROJECT_BY_CUSTOMER,
    payload,
    callback,
  };
};

export const getCustomerProject = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_CUSTOMER_PROJECT,
    payload,
    callback,
  };
};

import { ActionTypes } from "@mui/base";
import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import {
  CustomerContactCreateAsync,
  CustomerContactDeleteAsync,
  CustomerContactUpdateAsync,
  searchUserAsync,
} from "../../../api/common";
import { getCustomerContactAsync } from "../../../api/Customer";
import { setAuthToken } from "../../actions/auth";
import {
  customerActions,
  setCustomerContactList,
} from "../../actions/Customer";
import { ACTION_TYPES } from "../../actionTypes";
import { APIResponse } from "../../../utility/commonTypes";

function* CustomerContactCreate(action: any) {
  console.log("=====Customer Contact Saga====");
  const { payload, callback } = action;
  console.log("payload is",payload)
  console.log("=====Contact Saga====");
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType === "create"
        ? CustomerContactCreateAsync
        : CustomerContactUpdateAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      
      callback(res?.data);
    } else if (res?.status === 200) {
      // yield put(setCustomerContactList({ ...res.data}))
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* getCustomerContact(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getCustomerContactAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setCustomerContactList({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* SearchCustomerUsers(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(searchUserAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* deleteCustomerContact(action:any) {
    const { payload, callback } = action;

    try {

        let res:APIResponse = yield call(CustomerContactDeleteAsync,payload)
        console.log(res.data)
        if (res?.status === 200) {
            callback(res?.data)
           
        }

    }
    catch (error) {
        console.log(error)
    }
}

export function* watchCustomerContact() {
  yield takeLeading(
    customerActions.GET_CUSTOMER_USER_BY_NAME,
    SearchCustomerUsers
  );
  yield takeLeading(
    ACTION_TYPES.CUSTOMER.CUSTOMER_CONTACT_CREATE,
    CustomerContactCreate
  );
  yield takeLeading(
    customerActions.GET_CUSTOMER_CONTACT_LIST,
    getCustomerContact
  );
  yield takeLeading(
    customerActions.UPDATE_CUSTOMER_CONTACT,
    CustomerContactCreate
  );
  yield takeLeading(
    customerActions.DELETE_CUSTOMER_CONTACT,
    deleteCustomerContact
  );

}

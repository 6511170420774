import React from 'react'
import Warning from '../../assets/images/warning_image.jpg';

const NotAuthorized = () => {
    return (
        <div className={'not_authorize'}>
            <img src={Warning} alt={'warning'} />
            <h4>You are not authorized to view this page.</h4>
        </div>
    )
}

export default NotAuthorized
import { useEffect, useState } from "react";
import { Grid, Switch, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { activeCustomTabClass } from "./style";
import { useNavigate } from "react-router-dom";

const CustomTab = ({
  indexNumber,
  locationPath,
  title,
  requirementId,
}: any) => {
  const [value, setValue] = useState(indexNumber);
  const navigate = useNavigate();
  const location: any = useLocation();
  const stateData: any = location?.state;
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  useEffect(() => {
    setIsDisabled(true);
    if (stateData?.projectId || stateData?.subProjectId) {
      setIsDisabled(false);
    }
  }, [stateData]);
  return (
    <>
      {title && ( 
     <Grid
        container
        direction="row"
        justifyContent="left"
        justifyItems="left"
        className="px-4 py-2 my-4"
        sx={{
          backgroundColor: "#EFEFEF",
          marginBottom: "-20px",
        }}
      >
       
       
            <>
            
     View Project : 
            <div className="mx-4" style={{
              color:"blue"
            }}>
              <a href="">
              {title}
              </a>
              </div>
          
          
          
             
            </>
        
         
      </Grid> 
        
      )}
    </>
  );
};

export default CustomTab;

import React, { useMemo } from 'react';
import { formatDate } from '../../../../../utility/idelTimer';
import columns from './columnsDef';


const PendingItemList = ({ pendingItemList,requirementTemplate }: any) => {
  const defaultColumns: any = useMemo(() => {
    return columns();
  }, []);
console.log("req",requirementTemplate)
  return (
    <>
      <div className="doucment_information style-three page-break">
      {requirementTemplate === null ?     <h2>7. Pending Items</h2> :  <h2>8. Pending Items</h2>}

        <div className="table-responsive">
          {/* <div className="customer-contact">Customer Contact</div> */}
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                {defaultColumns?.map((column: any) => (
                  <th>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingItemList?.length === 0 &&
                <tr>
                  <td className="text-center" colSpan={12}>
                    <strong>No Rows</strong>
                  </td>
                </tr>
              }
              {pendingItemList?.map((row: any, index: any) => {
                row['fieldId']=index+1
                return (
                  <>
                    <tr>
                      {defaultColumns.map((column: any,i:number) => {
                        // ['fieldId']=i
                        console.log("row is",row)
                        
                        let valueData = row?.[column?.id] || row?.field_attributes?.[column?.id];
                        console.log("valuedata",valueData)
                        if(valueData === "In_Progress")
                        {
                          valueData="In Progress"
                        }
                        return (
                          <>
                            <td>{column?.id === "date" || column?.id === "due_date" ? formatDate(valueData):valueData}</td>
                          </>
                        );
                      })}
                    </tr>
                    {row?.status == 'Completed' ?
                        <tr>
                          <td colSpan={9}>
                            <div className={'d-flex'}>
                              <div className={'px-2'}>Remarks:</div>
                              <div>
                                {row?.resolve_comments}
                              </div>
                            </div>
                          </td>
                        </tr> : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default PendingItemList;
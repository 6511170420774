import React from "react"
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../components/CustomPagination";
import columns from "./columnsDef";
import { BoxStyle, HeaderStyle } from "./style";
import { getAllUserList } from "../../store/actions/User";

const AllUsersList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const fetchAllUserList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getAllUserList(
          {
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUserList(0, "ON_LOAD");
  }, []);
  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        All Users
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
             fetchAllUserList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default AllUsersList;

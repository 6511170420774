import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Checkbox, Tooltip } from "@mui/material";

import moment from "moment";
import { FUNCTIONAL_AREA, INTEGRATION_TYPE, TEMPLATE_DROPDOWNS } from "../../../constants/common";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "company_name",
      headerName: "Customer Name",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.Task?.Project?.Customer?.company_name ? (
              <Tooltip
                title={params?.row?.Task?.Project?.Customer?.company_name}
                followCursor
              >
                <Link
                  to={`/customer/${params?.row?.Task?.Project?.Customer?.id}`}
                  state={{
                    customerId: params?.row?.Task?.Project?.Customer?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Task?.Project?.Customer?.company_name}
                </Link>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "project_name",
      headerName: "Project Name",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.Task?.Project?.project_level === "Sub_Project" ? (
              <Tooltip
                title={params?.row?.Task?.Project?.project_name}
                followCursor
              >
                <Link
                  to={`/project/manage-sub-project/${params?.row?.Task?.Project?.master_project}/${params?.row?.Task?.Project?.Customer?.id}/${params?.row?.Task?.Project?.id}`}
                  state={{
                    masterProjectId: params?.row?.Task?.Project?.master_project,
                    companyCustomerId: params?.row?.Task?.Project?.Customer?.id,
                    subProjectId: params?.row?.Task?.Project?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Task?.Project?.project_name}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip
                title={params?.row?.Task?.Project?.project_name}
                followCursor
              >
                <Link
                  to={`/project/${params?.row?.Task?.Project?.id}/${params?.row?.Task?.Project?.Customer?.id}`}
                  state={{
                    projectId: params?.row?.Task?.Project?.id,
                    customerId: params?.row?.Task?.Project?.Customer?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Task?.Project?.project_name}
                </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "task_name",
      headerName: "Task Name",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.Task?.task_name ? (
              <Tooltip title={params?.row?.Task?.integration_id+"-"+params?.row?.Task?.task_name} followCursor>
                <Link
                  to={`/create-update-task/${params?.row?.Task?.Project?.id}/${params?.row?.Task?.Project?.Customer?.id}/${params?.row?.Task?.id}`}
                  state={{
                    subProject:
                      params?.row?.Task?.Project?.project_level ===
                      "Sub_Project"
                        ? true
                        : false,
                    projectId: params?.row?.Task?.Project?.id,
                    customerId: params?.row?.Task?.Project?.Customer?.id,
                    action: "create",
                    taskId: params?.row?.Task?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Task?.integration_id+"-"+params?.row?.Task?.task_name}
                </Link>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "functional_area",
      headerName: "Functional Area",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let searchVal = FUNCTIONAL_AREA.find(
          (o) => o.key === params?.row?.functional_area
        );
        // return <>{searchVal === undefined ? "NA" : searchVal["label"]}</>;
        return searchVal?.label ? (
          <Tooltip title={searchVal?.label} followCursor>
            <div>{searchVal?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "integration_type",
      headerName: "Integration Type",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let searchVal = INTEGRATION_TYPE.find(
          (o) => o.key === params?.row?.integration_type
        );
        // return <>{searchVal === undefined ? "NA" : searchVal["label"]}</>;
        return searchVal?.label ? (
          <Tooltip title={searchVal?.label} followCursor>
            <div>{searchVal?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "requirement_template",
      headerName: "Requirement Template",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        const functionalArea=params?.row?.functional_area
        const template_functional_area=TEMPLATE_DROPDOWNS?.find(o=>o.key === functionalArea)
        const searchVal=template_functional_area?.subFields?.find(o=>o.key === params?.row?.requirement_template)
        return searchVal?.value ? (
          <Tooltip title={searchVal?.value} followCursor>
            <div>{searchVal?.value}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Add to Project",
      width: 120,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/project/${params?.row?.id}`}
            state={{ projectId: params?.row?.id }}
            style={{ color: "#1976d2" }}
          >
            <AddIcon sx={{ color: "#B4B2B2" }} />
          </Link>
        );
      },
    },
  ];
};

export default columns;

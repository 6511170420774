import { AnyMxRecord } from "dns";
import { ACTION_TYPES } from "../../actionTypes";
import { actions } from "../User";
export const taskActions = {
  CREATE_TASK_PENDING_ITEMS: "CREATE_TASK_PENDING_ITEMS",
  GETALL_UNIT_TESTING: "GETALL_UNIT_TESTING",
  GETALL_TEST_CASE: "GETALL_TEST_CASE",
  CREATE_TASK_PENDING_ITEMS_UNIT_TEST: "CREATE_TASK_PENDING_ITEMS_UNIT_TEST",
  SAVE_ITEMS_UNIT_TEST: "SAVE_ITEMS_UNIT_TEST",
  SAVE_ITEMS_TEST_CASE: "SAVE_ITEMS_TEST_CASE",
  UPDATE_TASK_PENDING_ITEMS: "UPDATE_TASK_PENDING_ITEMS",
  GET_TASK_PENDING_ITEMS: "GET_TASK_PENDING_ITEMS",
  SET_TASK_PENDING_ITEMS: " SET_TASK_PENDING_ITEMS",
  DELETE_TASK_PENDING_ITEMS: "DELETE_TASK_PENDING_ITEMS",
  GET_TASK_PROJECTS:"GET_TASK_PROJECTS",
  GET_TASK_PROGRESS:"GET_TASK_PROGRESS",
  UPLOAD_TASK_FILES: "UPLOAD_TASK_FILES",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  UPDATE_TASK_FILES: "UPDATE_TASK_FILES",
  GET_TASK_FILES: "GET_TASK_FILES",
  CREATE_TASK: "CREATE_TASK",
  UPDATE_TASK_DETAILS: "UPDATE_TASK_DETAILS",
  GET_TASK_DETAILS: "GET_TASK_DETAILS",
  SET_TASK_DETAILS: "SET_TASK_DETAILS",
  CREATE_TASK_RESOURCES: "CREATE_TASK_RESOURCES",
  UPDATE_TASK_RESOURCES: "UPDATE_TASK_RESOURCES",
  DELETE_TASK_RESOURCES: "DELETE_TASK_RESOURCES",
  GET_TASK_RESOURCES: "GET_TASK_RESOURCES",
  SET_TASK_RESOURCES: "SET_TASK_RESOURCES",
  SEARCH_TASK_NAME: "SEARCH_TASK_NAME",
  SEARCH_ALL_USERS: "SEARCH_ALL_USERS",
  GET_TASK_TECHNICAL_FILES: "GET_TASK_TECHNICAL_FILES",
  UPDATE_TASK_TECHNICAL_FILES:"UPDATE_TASK_TECHNICAL_FILES",
  UPLOAD_TASK_TECHNICAL_FILES: "UPLOAD_TASK_TECHNICAL_FILES",
  DELETE_TECHNICAL_TASK_FILES: "DELETE_TECHNICAL_TASK_FILES",
  DELETE_TASK_FILES: "DELETE_TASK_FILES",
  DOWNLOAD_TASK_FILES: "DOWNLOAD_TASK_FILES",
  DOWNLOAD_TASK_TECHNICAL_FILES: "DOWNLOAD_TASK_TECHNICAL_FILES",
  SET_TASK_FILES: "SET_TASK_FILES",
  GENERATE_SIGNED_URL:"GENERATE_SIGNED_URL",
  GENERATE_SIGNED_URL_TECHNICAL:"GENERATE_SIGNED_URL_TECHNICAL",
  SAVE_EDITABLE_TASK:"SAVE_EDITABLE_TASK",
  CREATE_UNIT_TESTING:"CREATE_UNIT_TESTING",
  GET_UNIT_TESTING:"GET_UNIT_TESTING",
};
const {
  GET_MY_TASKS_LIST,
  GET_TASK_PROGRESS,
  UPDATE_TASK_PROGRESS,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST,
} = ACTION_TYPES.TASKS;
export interface PendingItems {
  id: any;
  task_id: any;
  type: any;
  date: any;
  due_date: any;
  issue: any;
  priority: any;
  status: any;
  assigned_to: any;
  internal: any;
  allow_notifications: any;
  actionType: any;
  created_by: any;
}
export interface TaskFiles {
  file_name: any;
  uploaded_by: any;
  timestamp: any;
  comments: any;
  category: any;
  view_by: any;
  task_uploaded_file: File;
}
export interface GetTaskFiles {
  task_technical: any;
}
interface Task {
  id: any;
  company_customer_id: any;
  project_id: any;
  integration_id: any;
  task_name: any;
  task_type: any;
  task_sub_type: any;
  functional_area: any;
  health: any;
  task_status: any;
  task_phase: any;
  description: any;
  estimated_hours: any;
  priority: any;
  complexity: any;
  crno_relatedrequest: any;
  isEditable:any;
}
export interface TaskResources {
  task_id: any;
  resource_name: any;
  user_id: any;
  role: any;
  start_date: any;
  end_date: any;
  notes: any;
  created_by: any;
  totals: any;
}
export interface TechnicalTaskFiles {
  file_name: any;
  uploaded_by: any;
  timestamp: any;
  comments: any;
  category: any;
  view_by: any;
  task_uploaded_file: any;
  file_type: any;
}
export interface UnitTesting {
  task_id: any;
  unitTest: any;
}
export const createPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.CREATE_TASK_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const createPendingItemsUnitTest = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.CREATE_TASK_PENDING_ITEMS_UNIT_TEST,
    payload,
    callback,
  };
};
export const saveItemsUnitTest = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.SAVE_ITEMS_UNIT_TEST,
    payload,
    callback,
  };
};
export const getTaskPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GET_TASK_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const setTaskPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.SET_TASK_PENDING_ITEMS,
    payload,
    callback,
  };
};

export const updateTaskPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: taskActions.UPDATE_TASK_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const deleteTaskPendingItems = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.DELETE_TASK_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const uploadTaskFiles = (
  payload: any,
  task_id: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.UPLOAD_TASK_FILES,
    payload,
    task_id,
    callback,
  };
};
export const generateSignedUrl = (
  payload: any,
  task_id:any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GENERATE_SIGNED_URL,
    payload,
    task_id,
    callback,
  };
};
export const generateSignedUrlTechnical = (
  payload: any,
  task_id:any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GENERATE_SIGNED_URL_TECHNICAL,
    payload,
    task_id,
    callback,
  };
};


export const downloadTaskFiles = (payload: any, callback: CallableFunction) => {
  return {
    type: taskActions.DOWNLOAD_TASK_FILES,
    payload,
    callback,
  };
};
export const downloadTaskTechnicalFiles = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.DOWNLOAD_TASK_TECHNICAL_FILES,
    payload,
    callback,
  };
};
export const deleteTaskFiles = (
  payload: TaskFiles,
  callback: CallableFunction
) => {
  return {
    type: taskActions.DELETE_TASK_FILES,
    payload,
    callback,
  };
};
export const deleteTechnicalTaskFiles = (
  payload: TechnicalTaskFiles,
  callback: CallableFunction
) => {
  return {
    type: taskActions.DELETE_TECHNICAL_TASK_FILES,
    payload,
    callback,
  };
};

export const getTaskFiles = (
  payload: TaskFiles,
  // task_id: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GET_TASK_FILES,
    payload,
    // task_id,
    callback,
  };
};
export const setTaskFiles = (
  payload: TaskFiles,
  task_id: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.SET_TASK_FILES,
    payload,
    task_id,
    callback,
  };
};
export const getTaskTechnicalFiles = (
  payload: TechnicalTaskFiles,
  // task_id: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GET_TASK_TECHNICAL_FILES,
    payload,
    // task_id,
    callback,
  };
};
export const uploadTechnicalTaskFiles = (
  payload: any,
  task_id: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.UPLOAD_TASK_TECHNICAL_FILES,
    payload,
    task_id,
    callback,
  };
};
export const updateTechnicalTaskFiles = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.UPLOAD_TASK_FILES,
    payload,
    callback,
  };
};
export const updateTaskFiles = (
  payload: TaskFiles,
  callback: CallableFunction
) => {
  return {
    type: taskActions.UPDATE_TASK_FILES,
    payload,
    callback,
  };
};
export const createTask = (payload: Task, callback: CallableFunction) => {
  return {
    type: taskActions.CREATE_TASK,
    payload,
    callback,
  };
};
export const getTaskDetails = (payload: Task, callback: CallableFunction) => {
  return {
    type: taskActions.GET_TASK_DETAILS,
    payload,
    callback,
  };
};
export const saveEditableTask = (id, isEditable) => {
  return {
    type: taskActions.SAVE_EDITABLE_TASK,
    id,
    isEditable
  };
};
export const getTaskProjects = (payload: Task, callback: CallableFunction) => {
  return {
    type: taskActions.GET_TASK_PROJECTS,
    payload,
    callback,
  };
};

export const setTaskDetails = (
  payload: Task
  // callback: CallableFunction
) => {
  return {
    type: taskActions.SET_TASK_DETAILS,
    payload,
    // callback,
  };
};

// export const getTaskProgressData = (
//   payload: any,
//   task_id: any,
//   callback: Function
// ) => {
//   return {
//     type: GET_TASK_PROGRESS,
//     payload,
//     task_id,
//     callback,
//   };
// };
// export const updateTaskProgressData = (
//   payload: any,
//   task_id: any,
//   callback: Function
// ) => {
//   return {
//     type: UPDATE_TASK_PROGRESS,
//     payload,
//     task_id,
//     callback,
//   };
// };
export const createTaskResources = (
  payload: TaskResources,
  callback: CallableFunction
) => {
  return {
    type: taskActions.CREATE_TASK_RESOURCES,
    payload,
    callback,
  };
};
export const getTaskResources = (
  payload: TaskResources,
  callback: CallableFunction
) => {
  return {
    type: taskActions.GET_TASK_RESOURCES,
    payload,
    callback,
  };
};
export const setTaskResources = (
  payload: TaskResources,
  callback: CallableFunction
) => {
  return {
    type: taskActions.SET_TASK_RESOURCES,
    payload,
    callback,
  };
};
export const deleteTaskResources = (
  payload: TaskResources,
  callback: CallableFunction
) => {
  return {
    type: taskActions.DELETE_TASK_RESOURCES,
    payload,
    callback,
  };
};
export const getTaskProgressData = (
  payload: any,
  task_id: any,
  callback: Function
) => {
  return {
    type: GET_TASK_PROGRESS,
    payload,
    task_id,
    callback,
  };
};
export const updateTaskProgressData = (
  payload: any,
  task_id: any,
  callback: Function
) => {
  return {
    type: UPDATE_TASK_PROGRESS,
    payload,
    task_id,
    callback,
  };
};
export const getByTaskName = (payload: any, callback: Function) => {
  return {
    type: taskActions.SEARCH_TASK_NAME,
    payload,
    callback,
  };
};
export const getAllUsers = (payload: any, callback: Function) => {
  return {
    type: taskActions.SEARCH_ALL_USERS,
    payload,
    callback,
  };
};
export const createUnitTesting = (
  payload: UnitTesting,
  callback: CallableFunction
) => {
  return {
    type: taskActions.CREATE_UNIT_TESTING,
    payload,
    callback,
  };
};
export const getAllUnitTests = (payload: any, callback: Function) => {
  return {
    type: taskActions.GET_UNIT_TESTING,
    payload,
    callback,
  };
};
export const getALLUT = (payload: any, callback: Function
) => {
  return {
    type: taskActions.GETALL_UNIT_TESTING,
    payload,
    callback,
  };
};
export const saveItemsTestCase = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: taskActions.SAVE_ITEMS_TEST_CASE,
    payload,
    callback,
  };
};
export const getAllTestCase = (payload: any, callback: Function) => {
  return {
    type: taskActions.GETALL_TEST_CASE,
    payload,
    callback,
  };
};

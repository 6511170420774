export const actions={
    VERIFY_USER: "VERIFY_USER",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER:"LOGOUT_USER",
    SIGNUP_USER:"SIGNUP_USER",
    GET_USER_BY_NAME:"GET_USER_BY_NAME",
    FORGOT_PASSWORD_HANDLER:"FORGOT_PASSWORD_HANDLER",
    UPDATE_USER:"UPDATE_USER",
    GET_USER_DETAILS:"GET_USER_DETAILS",
    SET_USER_DETAILS:"SET_USER_DETAILS",
    USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
    GET_NEW_TOKEN: "GET_NEW_TOKEN",
    ALL_USER_LIST:"ALL_USER_LIST"
  
}

export interface verifyUserPayload {
    customerId: string;
    forgetMpin?: boolean;
  }
  
  export interface loginPayload {
    email: string;
    password: string;
  }
export interface forgotPassword{
  email:any
}
  export interface createPayload {
    active:any;
    allow_logon:any;
    allow_notifications:any;
    firstname: string;
    lastname: string;
    company_customer_id: string;
    usertype: string;
    work_phone: any;
    mobile_phone: any;
    emailid: string;
    notes: string;
  }
  
export const verifyUser = (
    payload: verifyUserPayload,
    callback: CallableFunction
  ) => {
    return {
      type: actions.VERIFY_USER,
      payload,
      callback,
    };
  };
  
  export const loginUser = (
    payload: loginPayload,
    callback: CallableFunction
  ) => {
    return {
      type: actions.LOGIN_USER,
      payload,
      callback,
    };
  };
  export const getAllUserList = ( payload: object,
    callback: CallableFunction) => {
    return {
      type: actions.ALL_USER_LIST,
      payload,
      callback
    };
  };
  
  export const signupUser = (
    
    payload: createPayload,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SIGNUP_USER,
      payload,
      callback,
    };
  };
  export const updateUser = (
    payload: createPayload,
    callback: CallableFunction
  ) => {
    return {
      type: actions.UPDATE_USER,
      payload,
      callback,
    };
  };
  export const forgotPasswordHandler = (
    payload: forgotPassword,
    callback: CallableFunction
  ) => {
    return {
      type: actions.FORGOT_PASSWORD_HANDLER,
      payload,
      callback,
    };
  };
  export const getUserDetails = (
    payload: object,
    callback: CallableFunction
  ) => {
    return {
      type: actions.GET_USER_DETAILS,
      payload,
      callback,
    };
  };
  export const setTaskResources = (
    payload: createPayload,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SET_USER_DETAILS,
      payload,
      callback,
    };
  };
  
  
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {BASE_URL} from "../../../../api/apiClient";
import moment from "moment";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const Columns = (editTaskFiles: any, deleteTaskFiles: any,handleUserDetails:any) => {
  const location: any = useLocation();
  const params: any = useParams();
  const stateData = params;
  // const navigate=useNavigate()
  return [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {  
        const fileName = params?.row?.file_name?.split(/(?=.*)\.[a-zA-Z]{3,4}/)[0];  
        return (      
        <Tooltip title={fileName.substring(0, fileName.length - 14) + " " + params?.row?.file_name?.split(/.*(?=\.[a-zA-Z]{3,4})/)[1] || "NA"} followCursor>
            <a
            href={`${BASE_URL}/task/${stateData?.taskId}/file/download/${params?.row?.file_name}`}
            target="_blank"
            download
          >  
             <div>  {fileName.substring(0, fileName.length - 14) + " " + params?.row?.file_name?.split(/.*(?=\.[a-zA-Z]{3,4})/)[1] || "NA"}</div>  

        
          </a>  
          </Tooltip>
      ) 
        },
    },
    {
      field: "comments",
      headerName: "Description",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => (
        <Tooltip title={params?.row?.comments} followCursor>
          <div> {params?.row?.comments || "NA"}</div>
        </Tooltip>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => (
        <Tooltip title={params?.row?.category} followCursor>
          <div>{params?.row?.category || "NA"}</div>
        </Tooltip>
      ),
    },

    {
      field: "uploaded_by",
      headerName: "Uploaded By",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return params?.row?.uploaded_by ? (
          <a
            href="#"
            onClick={(e: any) => {
              handleUserDetails(e, params?.row?.user_id);
              return false;
            }}
          >
            {params?.row?.uploaded_by}
          </a>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "timestamp",
      headerName: "TimeStamp",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => (
        <Tooltip title={moment(params?.row?.timestamp)?.format("DD-MMM-YYYY HH:mm:ss")} followCursor>
          <div>{moment(params?.row?.timestamp)?.format("DD-MMM-YYYY HH:mm:ss") || "NA"}</div>
        </Tooltip>
      ),
    },

    {
      field: "viewBy",
      headerName: "View By",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => (
        <Tooltip title={params?.row?.view_by} followCursor>
          <div>{params?.row?.view_by || "NA"}</div>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 122,
      ...commonColumnsDef,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: "#000000" }} />}
          label="Edit"
          onClick={(e: any) => editTaskFiles(e, params?.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#c70000" }} />}
          label="Delete"
          onClick={(e: any) => deleteTaskFiles(e, params?.row?.file_name)}
        />,
      ],
    },
  ];
};

export default Columns;

import {
    ACTION_TYPES
} from "../../../actionTypes";
import { customerContact } from "../../Customer";

const {
    GET_CUSTOMER_CONTACT_LIST,
    GET_CUSTOMER_CONTACT,
} = ACTION_TYPES.CUSTOMER;

export const getCustomerContactList = (
    payload: customerContact,
    callback: CallableFunction
) => {
    return {
        type: GET_CUSTOMER_CONTACT_LIST,
        payload,
        callback,
    };
}
export const getCustomerContact = (
    payload: any,
    callback: CallableFunction
) => {
    return {
        type: GET_CUSTOMER_CONTACT,
        payload,
        callback,
    };
}

export const actions = {
  SET_LOGIN_DATA: "SET_LOGIN_DATA",
  SET_USER_PREFERENCE: "SET_USER_PREFERENCE",
  LOGOUT_USER: "LOGOUT_USER",
  SET_CURRENT_PERSONA: "ONE_APP_SET_CURRENT_PERSONA",
  SET_CUSTOMER_TYPE: "SET_CUSTOMER_TYPE",
  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SET_CURRENT_ACCOUNT_ID: "SET_CURRENT_ACCOUNT_ID",
  // SET_IDLE_TIME:"SET_IDLE_TIME",
  GET_AUTH_TOKEN: "GET_AUTH_TOKEN",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  GET_NEW_TOKEN: "GET_NEW_TOKEN",
  SSO_LOGIN:"SSO_LOGIN",
  ALL_USER_LIST:"ALL_USER_LIST"
};

export const userLogoutRequest = () => ({
  type: actions.USER_LOGOUT_REQUEST,
});

export const userLogoutSuccess = (callback: Function) => ({
  type: actions.USER_LOGOUT_SUCCESS,
  callback,
});

export const setGlobalCurrentAccountId = (accountId) => ({
  type: actions.SET_CURRENT_ACCOUNT_ID,
  accountId,
});

/* ********************************************
 *  Login
 * *********************************************/

export interface loginSuccessData {
  customerId?: string;
  isLoggedIn?: boolean;
  authData?: Record<string, any>;
}
export interface userPreferencesPayload {
  whatsAppSelected: boolean;
  smsSelected: boolean;
}

export const getAuthToken = () => {
  return {
    type: actions.GET_AUTH_TOKEN,
  };
};
// export const setIdleTime = (payload:number) => {
//   return {
//     type: actions.SET_IDLE_TIME,
//     payload
//   };
// };
export const setAuthToken = (payload: object) => {
  return {
    type: actions.SET_AUTH_TOKEN,
    payload,
  };
};
export const ssoLogin = (token:any) => {
  return {
    type: actions.SSO_LOGIN,
    token,
  };
};

export const LogoutUser = (payload: any, callback: Function) => {
  return {
    type: actions.LOGOUT_USER,
    payload,
    callback,
  };
};
export const getNewToken = (payload: any, callback: Function) => {
  return {
    type: actions.GET_NEW_TOKEN,
    payload,
    callback,
  };
};
export const setLogInData = (payload: loginSuccessData) => {
  return {
    type: actions.SET_LOGIN_DATA,
    payload,
  };
};

export const setUserPreference = (payload: userPreferencesPayload) => {
  return {
    type: actions.SET_USER_PREFERENCE,
    payload,
  };
};

export const aplAppLogoutAction = () => {
  return {
    type: actions.LOGOUT_USER,
  };
};

export const aplAppSetPersona = (persona: string) => {
  return {
    type: actions.SET_CURRENT_PERSONA,
    payload: persona,
  };
};

export const saveCustomerType = (payload: string) => {
  return {
    type: actions.SET_CUSTOMER_TYPE,
    payload,
  };
};

import React, { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../helpers/validations/useForm";
import CustomModal from "../../components/CustomModalPop";
import setLoader from "../../components/CustomLoader/loaderState";
import {
  getRequirementDetails,
  updateRequirement,
} from "../../store/actions/Requirement";
import {
  FLOW_OF_INTEGRATION,
  FUNCTIONAL_AREA,
  INTEGRATION_TYPE,
  TEMPLATE_DROPDOWNS,
  REQUIREMENT_TEMPLATE,
  REPLACE_REGEX,
  errorMessages,
} from "../../constants/common";
import { Autocomplete, Box, IconButton, List, TextField } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import {
  getCustomerDetails,
  getProjectList,
} from "../../store/actions/Customer";
import { getProjectDetails } from "../../store/actions/Project";
import { getTaskDetails } from "../../store/actions/Task";
import { getSourceTargetSystem } from "../../store/actions/Repository";
import { CustomConfirmPopup } from "../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { ProfileType } from "../../utility/commonTypes";

interface Requirement {
  id: any;
  company_customer_id: any;
  project_id: any;
  task_id: any;
  task_name: any;
  integration_id: any;
  purpose: any;
  integration_description: any;
  assumptions: any;
  source_system: any;
  target_system: any;
  flow_of_integration_workday: any;
  existing_integration: any;
  initial_proposed_integration: any;
  actual_integration_template: any;
  functional_area: any;
  requirement_template: any;
  integration_type: any;
  existing_requirements_specification_document: any;
  locked:any;
  actionType: any;
}

const RequirementMain: FC = (props:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [actionType, setActionType] = useState("create");
  const location = useLocation();
  const [customerData, setCustomerData] = useState<any>({});
  const [projectData, setProjectData] = useState<any>({});
  const [taskData, setTaskData] = useState<any>({});
  const params = useParams();
  const [projectList, setProjectList] = useState([]);
  console.log({ location, params });
  const [focusValue, setFocusValue] = useState("");
  const stateData: any = location.state;
  const [messages, setMessages] = useState("");
  const pathName: any = location.pathname;
  const [isMasterProject, setIsMasterProject] = useState([]);
  const [sourceTargetSystem, setSourceTargetSystem] = useState([]);
  const [sourceValue, setSourceValue] = useState({ value: "", label: "" });
  const [targetValue, setTargetValue] = useState({ value: "", label: "" });
  const [openSourceTargetPopUp, setOpenSourceTargetPopUp] = useState(false);
  const [sourceTargetValue, setSourceTargetValue] = useState("");
  const [openExistPopup, setOpenExistPopup] = useState(false);
  const selector = useSelector((state: any) => {
    return state?.requirementDetails?.data;
  });
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

console.log("source",sourceValue?.value)
  const {
    handleSubmit,
    handleChange,
    data: requirement,
    errors,
    setData,
  } = useForm<Requirement>({
    validations:{
      purpose:{
        pattern:{
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Purpose",5000),
        },
      },
      integration_description:{
        pattern:{
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Description",5000),
        },
      },
      assumptions:{
        pattern:{
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Assumptions",5000),
        },
      },
      source_system:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength:{
          value:100,
          message:errorMessages?.maxlength("Source system",100)
        }
      },
      target_system:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength:{
          value:100,
          message:errorMessages?.maxlength("Target system",100)
        }
      },
    },
    onSubmit: () => {
      setLoader(true);
      console.log(
        requirement,
        "requirement",
        requirement?.requirement_template,
        requirement?.functional_area
      );
      console.log("details", requirement?.functional_area);
      dispatch(
        updateRequirement(
          {
            payload: {
              id: stateData?.requirementId,
              // company_customer_id: "ABC Corp",
              // project_id: "9d083f56-eda3-4e4d-a85b-4895e2f7329b",
              // task_id: "8c83a8a6-7f29-4722-918a-00e0bdba243c"
              company_customer_id: stateData?.customerId
                ? stateData?.customerId
                : customerData?.id,
              project_id: projectData?.project_name,
              task_id: requirement?.task_id,
              purpose: requirement?.purpose,
              integration_description: requirement?.integration_description,
              assumptions: requirement?.assumptions,
              source_system: sourceValue?.value?.value||sourceValue?.value,
              target_system: targetValue?.value?.value||targetValue?.value,
              flow_of_integration_workday:
                requirement?.flow_of_integration_workday,
              existing_integration: requirement?.existing_integration,
              initial_proposed_integration:
                requirement?.initial_proposed_integration,
              actual_integration_template:
                requirement?.actual_integration_template,
              functional_area:
                requirement?.functional_area !== ""
                  ? requirement?.functional_area
                  : null,
              requirement_template:
                requirement?.functional_area !== ""
                  ? requirement?.requirement_template
                  : null,
              integration_type: requirement?.integration_type,
              locked:selector?.locked,
              existing_requirements_specification_document:
                requirement?.existing_requirements_specification_document,
              actionType: actionType,
            },
          },

          onRequirementCallback
        )
      );
    },
  });
  console.log("id is", stateData?.requirementId);
  useEffect(() => {
    setLoader(true);
    dispatch(
      getRequirementDetails({ id: stateData?.requirementId }, function (
        res: any
      ) {
        res?.id && setActionType("update");
        setSourceValue({
          label: res?.source_system,
          value: res?.source_system,
        });
        setTargetValue({
          label: res?.target_system,
          value: res?.target_system,
        });
        
        setData({
          ...res,
          id: stateData?.requirementId,
          locked:res?.locked,
          company_customer_id: stateData?.customerId
            ? stateData?.customerId
            : customerData?.id,
          project_id: res?.project_id,
          task_id: res?.task_id,
          purpose: res?.purpose,
          integration_description: res?.integration_description,
          assumptions: res?.assumptions,
          source_system: res?.source_system,
          target_system: res?.target_system,
          flow_of_integration_workday: res?.flow_of_integration_workday,
          existing_integration: res?.existing_integration,
          initial_proposed_integration: res?.initial_proposed_integration,
          actual_integration_template: res?.actual_integration_template,
          functional_area: res?.functional_area,
          requirement_template: res?.requirement_template,
          integration_type: res?.integration_type,
          existing_requirements_specification_document:
            res?.existing_requirements_specification_document,
        });
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
    );
  }, [setData, params, stateData]);
  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (stateData?.customerId) {
      setLoader(true);
      dispatch(
        getCustomerDetails({ id: stateData?.customerId }, function (res: any) {
          setCustomerData(res);
          dispatch(
            getProjectDetails({ id: stateData?.projectId }, function (
              resProjectData: any
            ) {
              console.log("Project", resProjectData);
              setProjectData(resProjectData);
              stateData?.taskId &&
                dispatch(
                  getTaskDetails({ id: stateData?.taskId }, function (
                    res: any
                  ) {
                    console.log({ res });
                    setTaskData(res);
                  })
                );
            })
          );
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })
      );
    }
  }, []);
  // const getCustomerProjects = (company_customer_id: any) => {

  // };

  useEffect(() => {
    dispatch(
      getProjectList(
        {
          id: stateData?.customerId,
          // id:company_customer_id,
          size: 100,
          page: 0,
        },
        (data: any) => {
          if (data?.rowsReturned?.length > 0) {
            setProjectList(data?.rowsReturned);
          } else {
            setProjectList([]);
          }
        }
      )
    );
    dispatch(
      getSourceTargetSystem({}, function (data: any) {
        const rows = data?.map((row: any) => ({
          value: row?.value,
          label: row?.label,
        }));
        setSourceTargetSystem(rows);
      })
    );
  }, []);
  console.log("project data",projectData)
  const onRequirementCallback = (data: any, error: any) => {
    if (data === false) {
      setErrorPopup(true);
      setMessages(error);
    } else {
      setSuccessPopup(true);
      /*setTimeout(() => {
        navigate(
          "/requirement/" +
            (stateData?.projectId
              ? stateData?.projectId
              : stateData?.subProjectId
              ? stateData?.subProjectId
              : null) +
            "/" +
            (stateData?.customerId
              ? stateData?.customerId
              : stateData?.companyCustomerId
              ? stateData?.companyCustomerId
              : null) +
            "/" +
            stateData?.taskId +
            "/" +
            stateData?.requirementId,
          {
            state: {
              subProject: stateData?.subProjectId ? true : false,
              projectId: stateData?.projectId
                ? stateData?.projectId
                : stateData?.subProjectId
                ? stateData?.subProjectId
                : null,
              customerId: stateData?.customerId
                ? stateData?.customerId
                : stateData?.companyCustomerId
                ? stateData?.companyCustomerId
                : null,
              action: "create",
              taskId: stateData?.taskId
                ? stateData?.taskId
                : null,
              requirementId: stateData?.requirementId,
            },
          }
        )
        window.location.reload();
      }, 2000);*/
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setLoader(false);
  };
  console.log("values are", sourceValue, targetValue);
  const requirementDropownList = TEMPLATE_DROPDOWNS.filter((val: any) => {
    return val.key === requirement?.functional_area;
  });
  const handleEnter = (e: any) => {
    e.key === "Enter" && e.preventDefault();
  };
  const handleSourceTargetPopUp = (e: any) => {
    console.log("source values", sourceTargetValue);

    if (e.key === "Enter") {
      setSourceTargetValue(e.target.value);
      console.log("given value", e.target.value);
      const result = sourceTargetSystem.find(
        (o) => o.value.toLowerCase() === e.target.value.toLowerCase()
      );
      console.log("result is", result);
      if (!result?.value) {
        setOpenSourceTargetPopUp(true);
      }

      // for(var i=0;i<sourceTargetSystem.length;i++)
      // {

      // console.log("final values",sourceTargetSystem)

      //     if(e.target.value === sourceTargetSystem[i]?.value)
      //     {
      //       setOpenExistPopup(true)
      //     }
      //     else{
      //       console.log("inside else",e.target.value,sourceTargetSystem[i]?.value)
      //       setOpenSourceTargetPopUp(true)

      //     }
      //   }
    }
  };
  console.log("list", projectList);
  const handleProjectChange = (val: any) => {
    if (!val) return;
  };
  const onClickOk = () => {
    setOpenSourceTargetPopUp(false);
  };
  const onClickCancel = () => {
    setSourceValue({ value: "", label: "" });

    setOpenSourceTargetPopUp(false);
  };
  const addValue = () => {
    return <span className="font-weight-bold">{sourceTargetValue}</span>;
  };
  console.log("options", sourceTargetSystem);
  console.log("selector value",selector)
  const isLocked =  selector?.locked === true       
console.log("islocked",isLocked)
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-xl-10 col-xxl-7">
            <div className="frame_outer">
              <div className="col-md-12 pull-left  mb-4 vendor_contact">
                Main
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label customername">
                        Customer Name
                      </label>
                      <div>
                        <Link
                          to={`/customer/${customerData?.id}/${customerData?.company_name}`}
                          state={{
                            customerId: customerData?.id,
                            companyId: customerData?.company_name,
                          }}
                          style={{ color: "#1976d2", cursor: "pointer" }}
                        >
                          <input
                            type="text"
                            id="name"
                            readOnly={true}
                            className="form-control"
                            placeholder={customerData?.company_name}
                            style={{ cursor: "pointer" }}
                          />
                        </Link>
                        {/* {errors.company_customer_id && (
                          <p className="error-text">
                            {errors.company_customer_id}
                          </p>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Project
                      </label>
                      <div>
                        {projectData?.project_level === "Sub_Project" ? (
                          <Link
                            to={`/project/manage-sub-project/${projectData?.master_project}/${projectData?.company_customer_id}/${projectData?.id}`}
                            state={{
                              masterProjectId: projectData?.master_project,
                              companyCustomerId:
                                projectData?.company_customer_id,
                              subProjectId: projectData?.id,
                            }}
                            style={{ color: "#1976d2" }}
                          >
                        {/* <select
                          id="name"
                          className="form-control"
                          style={{ cursor: "pointer" }}
                          value={projectData?.id || requirement?.project_id}
                          onChange={handleChange("project_id", (val) => {
                            handleProjectChange(val);
                            return val;
                          })}
                        >
                          {projectList.map((project) => {
                            console.log(
                              requirement?.company_customer_id +
                                "===" +
                                stateData?.customerId
                            );
                            let selvaltemp =
                              requirement?.company_customer_id ===
                              stateData?.customerId
                                ? true
                                : false;

                            return (
                              <option value={project.id} selected={selvaltemp}>
                                {project.project_name}
                              </option>
                            );
                          })}
                        </select> */}
  <select
                              id="name"
                              className="form-control"
                              placeholder={projectData?.project_name}
                              style={{ cursor: "pointer" }}
                              disabled
                            >
                             {/* {projectList.map((list) => ( */}
                      <option value={projectData?.id} selected>{projectData.project_name}</option>
                    {/* ))} */}
                                </select>
                        </Link>
                         ) : (
                          <Link
                            to={`/project/${projectData?.id}/${projectData?.company_customer_id}`}
                            state={{
                              projectId: projectData?.id,
                              customerId: projectData?.company_customer_id,
                            }}
                            style={{ color: "#1976d2" }}
                          > 
                         <select
                              id="name"
                              className="form-control"
                              placeholder={projectData?.project_name}
                              style={{ cursor: "pointer" }}
                              disabled
                            >

<option value={projectData?.id} selected>{projectData.project_name}</option>

                             {/* {projectList.map((list) => (
                      <option value={list.id} selected>{list.project_name}</option>
                    ))} */}
                                </select>
                          </Link>
                        )}

                        {/* {errors.project_id && (
                          <p className="error-text">{errors.project_id}</p>
                        )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Task
                      </label>
                      <div>
                        <Link
                          to={`/create-update-task/${
                            (stateData?.projectId
                              ? stateData?.projectId
                              : stateData?.subProjectId
                              ? stateData?.subProjectId
                              : null) +
                            "/" +
                            (stateData?.customerId
                              ? stateData?.customerId
                              : stateData?.companyCustomerId
                              ? stateData?.companyCustomerId
                              : null) +
                            "/" +
                            stateData?.taskId
                          }`}
                          state={{
                            subProject: stateData?.subProjectId ? true : false,
                            projectId: stateData?.projectId
                              ? stateData?.projectId
                              : stateData?.subProjectId
                              ? stateData?.subProjectId
                              : null,
                            customerId: stateData?.customerId
                              ? stateData?.customerId
                              : stateData?.companyCustomerId
                              ? stateData?.companyCustomerId
                              : null,
                            action: "update",
                            taskId: stateData?.taskId
                              ? stateData?.taskId
                              : null,
                          }}
                        >
                          <input
                            type="text"
                            id="name"
                            readOnly={true}
                            className="form-control"
                            placeholder={taskData?.task_name}
                            style={{
                              cursor: "pointer",
                            }}
                          />

                          {errors.task_id && (
                            <p className="error-text">{errors.task_id}</p>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Purpose
                      </label>
                      <textarea
                        name="purpose"
                        id="purpose"
                        onChange={handleChange("purpose")}
                        value={requirement?.purpose}
                        className="form-textarea"
                        disabled={isLocked}
                      >
                        {requirement?.purpose}
                      </textarea>
                      {errors.purpose && (
                            <p className="error-text">{errors.purpose}</p>
                          )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Integration description
                      </label>
                      <textarea
                        name="integration_description"
                        id="integration_description"
                        onChange={handleChange("integration_description")}
                        value={requirement?.integration_description}
                        className="form-textarea"
                        disabled={isLocked}

                      >
                        {requirement?.integration_description}
                      </textarea>
                      {errors.integration_description && (
                            <p className="error-text">{errors.integration_description}</p>
                          )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Assumptions
                      </label>
                      <textarea
                        name="assumptions"
                        onChange={handleChange("assumptions")}
                        value={requirement?.assumptions}
                        className="form-textarea"
                        disabled={isLocked}

                      >
                        {requirement?.assumptions}
                      </textarea>
                      {errors.assumptions && (
                            <p className="error-text">{errors.assumptions}</p>
                          )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="name" className="form-label">
                            Source system
                          </label>
                        </div>
                      </div>

                      <Autocomplete
                        options={sourceTargetSystem}
                        onKeyPress={(e: any) => {
                          console.log("val", e.target.value);

                          handleEnter(e);
                          handleSourceTargetPopUp(e);
                        }}
                        autoHighlight
                        renderInput={(params) => (
                          <TextField {...params} name="source_system" />
                        )}
                        value={sourceValue?.value?.value||sourceValue?.value}
                        onChange={(event: any, newValue: any) => {
                          console.log("new value", newValue);
                          setSourceValue({ value: newValue, label: newValue });
                        }}
                        freeSolo
                        disabled={isLocked}

                      />
                      
                    </div>
                    {errors.source_system && (
                            <p className="error-text">{errors.source_system}</p>
                          )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="name" className="form-label">
                            Target system
                          </label>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="addnew pull-right">

                            <IconButton>
                              <AddCircleOutline />
                            </IconButton>{" "}
                            Add
                          </div>
                        </div> */}
                      </div>

                      <Autocomplete
                        options={sourceTargetSystem}
                        onKeyPress={(e: any) => {
                          console.log("val", e.target.value);

                          handleEnter(e);
                          handleSourceTargetPopUp(e);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="target_system" />
                        )}
                        value={targetValue?.value?.value||targetValue?.value}
                        onChange={(event: any, newValue: any) => {
                          console.log("new value", newValue);
                          setTargetValue({ value: newValue, label: newValue });
                        }}
                        autoHighlight
                        freeSolo
                        disabled={isLocked}

                      />
                      
                    </div>
                    {errors.target_system && (
                            <p className="error-text">{errors.target_system}</p>
                          )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Integration Direction
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="flow_of_integration_workday"
                        value={requirement?.flow_of_integration_workday}
                        onChange={handleChange("flow_of_integration_workday")}
                        disabled={isLocked}

                      >
                        <option value="">Select</option>
                        {FLOW_OF_INTEGRATION.map((integration) => (
                          <option value={integration.key}>
                            {integration.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Is there an existing integration?
                      </label>
                      <input
                        className="form-control"
                        name="existing_integration"
                        id="existing_integration"
                        type="text"
                        value={requirement?.existing_integration}
                        onChange={handleChange("existing_integration")}
                        disabled={isLocked}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label customername">
                        Functional area
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="functional_area"
                        value={requirement?.functional_area}
                        onChange={handleChange("functional_area")}
                        disabled={isLocked}
                      >
                        <option value={""}>Select</option>
                        {FUNCTIONAL_AREA.map((funVal) => {
                          let selvaltemp =
                            requirement?.functional_area === funVal.key
                              ? true
                              : false;
                          return (
                            <option
                              key={funVal.key}
                              value={funVal.key}
                              selected={selvaltemp}
                            >
                              {funVal.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Requirement template
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="requirement_template"
                        value={requirement?.requirement_template}
                        onChange={handleChange("requirement_template")}
                        disabled={isLocked}
                      >
                        <option value={""}>Select</option>
                        {requirementDropownList.map((template) => {
                          return template.subFields.map((opt) => {
                            let selvaltemp =
                              requirement?.requirement_template === opt.key
                                ? true
                                : false;
                            return (
                              <option value={opt.key} selected={selvaltemp}>
                                {opt.value}
                              </option>
                            );
                          });
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Integration type
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="integration_type"
                        value={requirement?.integration_type}
                        onChange={handleChange("integration_type")}
                        disabled={isLocked}
                      >
                        <option selected>Select</option>
                        {INTEGRATION_TYPE.map((type) => {
                          let selval =
                            requirement?.integration_type === type.value
                              ? true
                              : false;
                          return (
                            <option
                              key={type.value}
                              value={type.key}
                              selected={selval}
                            >
                              {type.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Is there an existing requirements specification
                        document?
                      </label>
                      <input
                        type="text"
                        id="existing_requirements_specification_document"
                        className="form-control"
                        name="existing_requirements_specification_document"
                        value={
                          requirement?.existing_requirements_specification_document
                        }
                        onChange={handleChange(
                          "existing_requirements_specification_document"
                        )}
                        disabled={isLocked}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="btn-block">
               { props?.toggleValue === false && isLocked === false &&  ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) ||ProfileType?.ADMIN  === getProfileType  ? 
      
               <button type="submit" className="thame-btn w-100">
                          {actionType === "update" ? "Update" : "Create"}
                        </button>:null}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title="Task Requirement"
        onOpen={errorPopup}
        content={messages}
        modalType={"error"}
        width={"md"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Requirement"
        onOpen={successPopup}
        content={"Requirement data saved!"}
        modalType={"success"}
        width={"md"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <CustomConfirmPopup
        open={openSourceTargetPopUp}
        close={() => {
          setOpenSourceTargetPopUp(false);
        }}
        closeAndSubmit={() => {}}
        onClickYes={onClickOk}
        onClickNo={onClickCancel}
        title={`Confirmation`}
        btnText={`${"Ok"}`}
        btnText1={`${"CANCEL"}`}
        description={
          <>
            {`Are you sure you want to add the value `}

            <i style={{ fontWeight: "bold", color: "green", fontSize: 25 }}>
              {sourceTargetValue}
            </i>
          </>
        }
        //  successIcon={false}
      />
      {/* <CustomModal
        title="Already Exists"
        onOpen={openExistPopup}
        content={"Given Value Already Exists"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setOpenExistPopup(false);

        }}
      /> */}
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};

export default RequirementMain;

import { Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useState } from 'react';
import CustomModal from '../../../components/CustomModalPop';
import ViewForm from "../../Register/viewPage"
import Tooltip from "@mui/material/Tooltip";

const commonColumnsDef = {
    headerClassName: 'super-app-theme--header',
    sortable: false,
    flex: 1
}

const Columns = (editCustomerContacts: any,deleteCustomerContacts:any,handleUserDetails:any) => {

    return [
     
        {
            field: 'name',
            headerName: 'Resource Name',
            minWidth: 115,
            ...commonColumnsDef,
            renderCell: (params: any) =>
            {
                return (
                    <>

                   {params?.row?.User?.name ? (
                <Tooltip title={params?.row?.User?.name} followCursor>

                        <a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.User?.id);return false} }
                        >
                        {params?.row?.User?.name} 
                        </a> 
                    </Tooltip>
                    ): ("NA")}
                   
                    </>
                )

            }

            //{params?.row?.User?.name ? <a href={params?.row?.User?.name}>
            //{params?.row?.User?.name} </a> : "NA"},
        },
        {
            field: 'emailid',
            headerName: 'Email Id',
            minWidth: 100,
            ...commonColumnsDef,
            renderCell: (params: any) =>
            
            <Tooltip title={params?.row?.User?.emailid} followCursor>

             <div>{params?.row?.User?.emailid}</div>
             </Tooltip>
            
            
            
        },
        {
            field: 'active',
            headerName: 'Active',
            minWidth: 70,
            ...commonColumnsDef,
            renderCell: (params: any) => {
                return (
                    <Checkbox
                        checked={params?.row?.User?.active === true}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled
                    />
                );
            }
        },
        {
            field: 'allow_logon',
            headerName: 'Allow Logon',
            minWidth: 128,
            ...commonColumnsDef,
            renderCell: (params: any) => {
                return (
                    <Checkbox
                        checked={params?.row?.User?.allow_logon === true}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled
                    />
                );
            }
        },
        {
            field: 'role',
            headerName: 'Role',
            minWidth: 70,
            ...commonColumnsDef,
            renderCell: (params: any) =>
            
            <Tooltip title={params?.row?.role} followCursor>

             <div>{params?.row?.role}</div>
             </Tooltip>
        },
        {
            field: 'team',
            headerName: 'Team',
            minWidth: 70,
            ...commonColumnsDef,
            renderCell: (params: any) =>
            
            <Tooltip title={params?.row?.team} followCursor>

             <div>{params?.row?.team}</div>
             </Tooltip>
            
        },
        {
            field: 'start_date',
            headerName: 'Start Date',
            minWidth: 100,
            ...commonColumnsDef,
            // valueFormatter: (params: any) => {
            //     return params?.value === undefined ? 'NA' : moment(params?.value).format("YYYY-MM-DD")
            // },
            renderCell: (params: any) =>
            <Tooltip title={params?.row?.start_date.split("T")[0]} followCursor>
              <div>
            {params?.row?.start_date.split("T")[0] || "NA"}
            </div>
            </Tooltip>
        },
        {
            field: 'end_date',
            headerName: 'End Date',
            minWidth: 100,
            ...commonColumnsDef,
            // valueFormatter: (params: any) => {
            //     return params?.value === undefined ? 'NA' : moment(params?.value).format("YYYY-MM-DD")
            // },
            renderCell: (params: any) =>
            <Tooltip title={params?.row?.end_date.split("T")[0]} followCursor>
              <div>
            {params?.row?.end_date.split("T")[0] || "NA"}
            </div>
            </Tooltip>
        },
        {
            field: 'notes',
            headerName: 'Note',
            minWidth: 80,
            ...commonColumnsDef,
            renderCell: (params: any) => 
                <Tooltip title={params?.row?.notes} followCursor>
                    <div>{params?.row?.notes}</div>
                </Tooltip>
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            minWidth: 122,
            ...commonColumnsDef,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditIcon sx={{ color: "#000000" }} />}
                    label="Edit"
                    onClick={(e: any) => editCustomerContacts(e, params?.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon sx={{ color: "#c70000" }} />}
                    label="Delete"
                    onClick={(e:any) => deleteCustomerContacts(e, params?.row?.id)}

                />,

               
                
            ]
            
            
        },
       
        

    ];
};

export default Columns;
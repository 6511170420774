import React, { useState } from "react";
import { useSelector } from "react-redux";

const FieldRequirementForm = ({
  fieldRequirementVal,
  handleChange,
  indexNo,
  ondeleteRequirementFields,
  errors,
  props
  
}: any) => {
  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
  const isLocked =  selector?.locked=== true       

  return (
    <>
      <div className="row my-2 mx-4">
        <div className="d-flex col-md-12">
          <div className="col-md-6 my-2">
            <div className="vendor_contact">File {indexNo} requirements</div>
          </div>
          <div className="col-md-6 my-2">
            <div className="btn-block text-end">
            { props?.toggleValue === false && isLocked === false &&      
           <button type="submit" className="thame-btn mx-2">
                Submit
              </button>}
              { props?.toggleValue === false && isLocked === false &&      
        <button type="button" onClick={ondeleteRequirementFields} data-dismiss="modal"
                aria-label="Close" className="thame-btn-danger">
                Delete File
              </button>}
            </div>
          </div>
        </div>
      </div>
      <div className="child_tab">

        <div className="tab-content outer_border" id="myTabContent">
          <div className="tab-pane fade active show" id="file1-tab-pane" role="tabpanel" aria-labelledby="file1-tab">
            <div className="col-xl-12 col-xxl-12">
              <div className="col-md-12">
                <div className="row gx-5">
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Number of Fields</label>
                      <input
                        type="text"
                        id="Number_of_fields"
                        className="form-control"
                        placeholder="Number of files"
                        // pattern="^[0-9]+$"
                        value={fieldRequirementVal?.Number_of_fields?.field_desc}
                        // title="Entered Value is not valid.Please Enter only Numeric Values"
                        onChange={handleChange(`Number_of_fields`, "field_data", "", "field_desc", "test_cases")}
                        disabled={isLocked}
                      />
                      {errors?.Number_of_fields && (
                        <p className="error-text">
                          {errors?.Number_of_fields}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label customername">Field requirements type</label>
                      <select
                        id={"Field_Requirements_Type"}
                        name={"Field_Requirements_Type"}
                        onChange={handleChange(`Field_Requirements_Type`, "field_data", "", "field_desc", "test_cases")}
                        className="form-select"
                        disabled={isLocked}
                        aria-label="Default select example">
                        <option selected>Select</option>
                        <option value="Fixedwidth" selected={fieldRequirementVal?.Field_Requirements_Type?.field_desc === "Fixedwidth"}>Fixed Width</option>
                        <option value="Delimited" selected={fieldRequirementVal?.Field_Requirements_Type?.field_desc === "Delimited"}>Delimited</option>
                        <option value="NA" selected={fieldRequirementVal?.Field_Requirements_Type?.field_desc === "NA"}>NA</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">File description</label>
                      <input
                        type="text"
                        id="File_Description"
                        className="form-control"
                        placeholder="File description"
                        disabled={isLocked}
                        value={fieldRequirementVal?.File_Description?.field_desc}
                        onChange={handleChange(`File_Description`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.File_Description && (
                        <p className="error-text">
                          {errors?.File_Description}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">A Header row will be included in the data file</label>
                      <select
                        id={"A_header_row_will_be_included_in_the_data_file"}
                        name={"A_header_row_will_be_included_in_the_data_file"}
                        onChange={handleChange(`A_header_row_will_be_included_in_the_data_file`, "field_data", "", "field_desc", "test_cases")}
                        className="form-select"
                        disabled={isLocked}
                        aria-label="Default select example">
                        <option selected>Select</option>
                        <option value="Yes" selected={fieldRequirementVal?.A_header_row_will_be_included_in_the_data_file?.field_desc === "Yes"}>Yes</option>
                        <option value="No" selected={fieldRequirementVal?.A_header_row_will_be_included_in_the_data_file?.field_desc === "No"}>No</option>
                        <option value="NA" selected={fieldRequirementVal?.A_header_row_will_be_included_in_the_data_file?.field_desc === "NA"}>NA</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">A trailer row will be included in the data file</label>
                      <select
                        id={"A_trailer_row_will_be_included_in_the_data_file"}
                        name={"A_trailer_row_will_be_included_in_the_data_file"}
                        onChange={handleChange(`A_trailer_row_will_be_included_in_the_data_file`, "field_data", "", "field_desc", "test_cases")}
                        className="form-select"
                        disabled={isLocked}
                        aria-label="Default select example">
                        <option selected>Select</option>
                        <option value="Yes" selected={fieldRequirementVal?.A_trailer_row_will_be_included_in_the_data_file?.field_desc === "Yes"}>Yes</option>
                        <option value="No" selected={fieldRequirementVal?.A_trailer_row_will_be_included_in_the_data_file?.field_desc === "No"}>No</option>
                        <option value="NA" selected={fieldRequirementVal?.A_trailer_row_will_be_included_in_the_data_file?.field_desc === "NA"}>NA</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">The file being processed is to be delimited by a</label>
                      <select
                        id={"The_file_being_processed_is_to_be_delimited_by_a"}
                        name={"The_file_being_processed_is_to_be_delimited_by_a"}
                        onChange={handleChange(`The_file_being_processed_is_to_be_delimited_by_a`, "field_data", "", "field_desc", "test_cases")}
                        className="form-select"
                        disabled={isLocked}
                        aria-label="Default select example">
                        <option selected>Select</option>
                        <option value="comma" selected={fieldRequirementVal?.The_file_being_processed_is_to_be_delimited_by_a?.field_desc === "comma"}>Comma</option>
                        <option value="pipe" selected={fieldRequirementVal?.The_file_being_processed_is_to_be_delimited_by_a?.field_desc === "pipe"}>Pipe</option>
                        <option value="tab" selected={fieldRequirementVal?.The_file_being_processed_is_to_be_delimited_by_a?.field_desc === "tab"}>Tab</option>
                        <option value="FixedWidth" selected={fieldRequirementVal?.The_file_being_processed_is_to_be_delimited_by_a?.field_desc === "FixedWidth"}>Fixed Width</option>
                        <option value="Others" selected={fieldRequirementVal?.The_file_being_processed_is_to_be_delimited_by_a?.field_desc === "Others"}>Others</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">The data transfer will be formatted as</label>
                      <select
                        id={"The_data_transfer_will_be_formatted_as"}
                        name={"The_data_transfer_will_be_formatted_as"}
                        onChange={handleChange(`The_data_transfer_will_be_formatted_as`, "field_data", "", "field_desc", "test_cases")}
                        className="form-select"
                        disabled={isLocked}
                        aria-label="Default select example">
                        <option selected>Select</option>
                        <option value="csv" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "csv"}>csv</option>
                        <option value="text" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "text"}>text</option>
                        <option value="xls" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "xls"}>xls</option>
                        <option value="xlsx" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "xlsx"}>xlsx</option>
                        <option value="pdf" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "pdf"}>pdf</option>
                        <option value="other" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "other"}>other</option>
                        <option value="NA" selected={fieldRequirementVal?.The_data_transfer_will_be_formatted_as?.field_desc === "Na"}>NA</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Filtering Requirements</label>
                      <input
                        type="text"
                        id="Filtering_Requirements"
                        className="form-control"
                        placeholder="Filtering Requirements"
                        disabled={isLocked}
                        value={fieldRequirementVal?.Filtering_Requirements?.field_desc}
                        onChange={handleChange(`Filtering_Requirements`, "field_data", "", "field_desc", "test_cases")} />
                        {errors?.Filtering_Requirements && (
                        <p className="error-text">
                          {errors?.Filtering_Requirements}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">The expected volume of records to be processed by this
                        integration is</label>
                      <input
                        type="text"
                        id="The_expected_volume_of_records_to_be_processed_by_this_integration_is"
                        className="form-control"
                        disabled={isLocked}
                        placeholder="The Expected Maximum number of records that the Integration will handle is"
                        value={fieldRequirementVal?.The_expected_volume_of_records_to_be_processed_by_this_integration_is?.field_desc}
                        onChange={handleChange(`The_expected_volume_of_records_to_be_processed_by_this_integration_is`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.The_expected_volume_of_records_to_be_processed_by_this_integration_is && (
                        <p className="error-text">
                          {errors?.The_expected_volume_of_records_to_be_processed_by_this_integration_is}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">The Expected Maximum number of records that the
                        Integration will handle is</label>
                      <input
                        type="text"
                        id="The_expected_maximum_number_of_records_that_the_integration_will_handle_is"
                        className="form-control"
                        placeholder="The Expected Maximum number of records that the Integration will handle is"
                        value={fieldRequirementVal?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is?.field_desc}
                        disabled={isLocked}
                        onChange={handleChange(`The_expected_maximum_number_of_records_that_the_integration_will_handle_is`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is && (
                        <p className="error-text">
                          {errors?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">File naming standard - prod</label>
                      <input
                        type="text"
                        id="File_Naming_Standard_Prod"
                        className="form-control"
                        placeholder=""
                        disabled={isLocked}
                        value={fieldRequirementVal?.File_Naming_Standard_Prod?.field_desc}
                        onChange={handleChange(`File_Naming_Standard_Prod`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.File_Naming_Standard_Prod && (
                        <p className="error-text">
                          {errors?.File_Naming_Standard_Prod}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">File naming standard - test</label>
                      <input
                        type="text"
                        id="File_Naming_Standard_Test"
                        className="form-control"
                        placeholder="File naming standard - test"
                        disabled={isLocked}
                        value={fieldRequirementVal?.File_Naming_Standard_Test?.field_desc}
                        onChange={handleChange(`File_Naming_Standard_Test`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.File_Naming_Standard_Test && (
                        <p className="error-text">
                          {errors?.File_Naming_Standard_Test}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Will input or output contains special characters?</label>
                      <input
                        type="text"
                        id="Will_input_or_output_contains_special_characters"
                        className="form-control"
                        placeholder=""
                        disabled={isLocked}
                        value={fieldRequirementVal?.Will_input_or_output_contains_special_characters?.field_desc}
                        onChange={handleChange(`Will_input_or_output_contains_special_characters`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.Will_input_or_output_contains_special_characters && (
                        <p className="error-text">
                          {errors?.Will_input_or_output_contains_special_characters}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Encoding</label>
                      <input
                        type="text"
                        id="Encoding"
                        className="form-control"
                        placeholder=""
                        disabled={isLocked}
                        value={fieldRequirementVal?.Encoding?.field_desc}
                        onChange={handleChange(`Encoding`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.Encoding && (
                        <p className="error-text">
                          {errors?.Encoding}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">What type of data will be sent?</label>
                      <input
                        type="text"
                        id="what_type_of_data_will_be_sent"
                        className="form-control"
                        placeholder=""
                        disabled={isLocked}
                        value={fieldRequirementVal?.what_type_of_data_will_be_sent?.field_desc}
                        onChange={handleChange(`what_type_of_data_will_be_sent`, "field_data", "", "field_desc", "test_cases")}
                      />
                      {errors?.what_type_of_data_will_be_sent && (
                        <p className="error-text">
                          {errors?.what_type_of_data_will_be_sent}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Sample File</label>
                      <textarea
                        name="Sample_File"
                        id="Sample_File"
                        placeholder=""
                        disabled={isLocked}
                        value={fieldRequirementVal?.Sample_File?.field_desc}
                        className="form-textarea"
                        onChange={handleChange(`Sample_File`, "field_data", "", "field_desc", "test_cases")}> </textarea>
                        {errors?.Sample_File && (
                        <p className="error-text">
                          {errors?.Sample_File}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label customername">Notes and additional comments</label>
                      <textarea
                        name="Notes_and_Additional_requirements"
                        id="Notes_and_Additional_requirements"
                        placeholder=""
                        disabled={isLocked}
                        className="form-textarea"
                        value={fieldRequirementVal?.Notes_and_Additional_requirements?.field_desc}
                        onChange={handleChange(`Notes_and_Additional_requirements`, "field_data", "", "field_desc", "test_cases")}> </textarea>
                        {errors?.Notes_and_Additional_requirements && (
                        <p className="error-text">
                          {errors?.Notes_and_Additional_requirements}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldRequirementForm;

export const actions = {
  API_DEBUGGER: "API_DEBUGGER",
  CLEAR_API_DEBUGGER: "CLEAR_API_DEBUGGER",
};

export const logApiResponse = (payload: object) => ({
  type: actions.API_DEBUGGER,
  payload: payload,
});
export const clearApiDebuggerResponse = () => ({
  type: actions.CLEAR_API_DEBUGGER,
});


import apiClient from "../apiClient";
import { END_POINTS } from "../common/endpoint";
import axios from "axios";
import {BASE_URL as baseURL} from "../apiClient";

export const getRequirementTemplateQuestions = async (payload: Record<string, any>) => {
  return await apiClient.get(END_POINTS.REQUIREMENT + `${payload.id}` + `/dynamic`, payload);
};

export const updateRequirementTemplateQuestions = async (payload: Record<string, any>) => {
  return await apiClient.post(END_POINTS.REQUIREMENT + `${payload.payload.id}` + `/dynamic/update/`, payload);
};

export const updateNumberOfFields = async (payload: Record<string, any>) => {
  return await apiClient.post(END_POINTS.REQUIREMENT + `${payload.payload.id}` + `/fieldreq/filemap/update`, payload);
};

export const createFormFieldRequirement = async (payload: Record<string, any>) => {
  return await apiClient.post(END_POINTS.REQUIREMENT + `${payload.id}` + `/fieldreq/filereq/create `, payload);
};

export const getNumberOfFields = async (payload: Record<string, any>) => {
  return await apiClient.get(END_POINTS.REQUIREMENT + `${payload.id}` + `/fieldreq/filemap`, payload);
};

export const getFormFieldRequirement = async (payload: Record<string, any>) => {
  return await apiClient.get(END_POINTS.REQUIREMENT + `${payload.id}/fieldreq/filereq/${payload.fieldId}`, payload);
};

export const updateFormFieldRequirement = async (payload: Record<string, any>) => {
  return await apiClient.post(END_POINTS.REQUIREMENT + `${payload.payload.id}/fieldreq/filereq/${payload.payload.fieldId}/update`, payload);
};

export const deleteFormFieldRequirement = async (payload: Record<string, any>) => {
  return await apiClient.delete(END_POINTS.REQUIREMENT + `${payload.id}/fieldreq/filereq/${payload.fieldId}/delete`, payload);
};

export const getPdfRequirement = async (payload: Record<string, any>) => {
  return await apiClient.get(END_POINTS.REQUIREMENT + `${payload.id}/pdf`, payload);
};

export const uploadProcessFlow = async (payload: Record<string, any>) => {  
  const client = axios.create({
    baseURL,
    // timeout: 30,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // headers: { "Content-Type": "application/json"

    withCredentials: true,
  });
  const postPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      //  console.log(data)
      //  return await apiClient.post(urlNew, data, config);
      const result = await client.post(urlNew, data, config);      

      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await postPublic(END_POINTS.REQUIREMENT + `${payload?.get('id')}/processflow/upload`, payload);
};
export const getProcessFlow = async (payload: Record<string, any>) => {
  return await apiClient.get(END_POINTS.REQUIREMENT + `${payload?.id}/processflow/`, payload);
};
import { useNavigate } from "react-router-dom";
import "./emailSuccess.css";

const EmailSuccess = () => {
  const navigate=useNavigate()
  const handleLogin=(e:any)=>{
window.location.reload()
    navigate("/login")
  }
  return (
    <div>
       <div className="img-block">
          <img src="assets/images/reset-successful.svg" alt="Reset Successful"/>
        </div>
         <h2>Reset Successful</h2>
      <h4>Check your mailbox for password reset email</h4>
        <button type="submit" className="thame-btn w-100" onClick={handleLogin}>Return to Login</button>

      </div>
  
  );
};

export default EmailSuccess;

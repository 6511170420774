import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import CustomModal from "../../../components/CustomModalPop";
import { createUnitTesting, getAllUnitTests, saveItemsUnitTest, saveItemsTestCase, getAllTestCase, getAllUsers } from "../../../store/actions/Task";
import moment from "moment";
import setLoader from "../../../components/CustomLoader/loaderState";
import { BoxStyle, HeaderStyle, ButtonStyle } from "../style";
import { useParams } from "react-router-dom";
import { TYPE, REQUIREMENTS, TEST_CASE_STATUS, TEST_ID_TYPE } from "../../../constants/common";
import { Typeahead } from 'react-bootstrap-typeahead';
import SearchIcon from '@mui/icons-material/Search';
import { exportToExcel } from 'react-json-to-excel';
import { JsonToExcel } from "react-json-to-excel";
import './pendingItemForm.css'
import { CONVERTED_KEYS } from "../../../constants/common";
import rows from "./dynamic_test_case.json";

const TestCases = () => {

  const params: any = useParams();
  const stateData: any = params;
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openModalPop, setOpenModalPop] = useState(false)
  const [resourceName, setResourceName] = useState()
  const [resourceIndex, setResourceIndex] = useState()

  // console.log("task id", stateData?.taskId)

  const formatDate = (data: Date) => {
    return moment(new Date(data)).format("DD-MMM-YYYY");
  }

  const unit_test_cases = [
    {
      "order_id": 1,
      "task_id": stateData?.taskId,
      "description": "Validate that the launch parameters incorporated in the build are in line with the requirement",
      "requirement": "Technical",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 2,
      "task_id": stateData?.taskId,
      "description": "Integration notification are configured and tested as per the requirement",
      "requirement": "Technical",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 3,
      "task_id": stateData?.taskId,
      "description": "Validate the data extracted/imported are based on the selection criteria",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 4,
      "task_id": stateData?.taskId,
      "description": "Validate and confirm the integration extract/import the data based on the mode (full file/changes only) as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 5,
      "task_id": stateData?.taskId,
      "description": "Validate the terminated records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 6,
      "task_id": stateData?.taskId,
      "description": "Validate the new hire records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 7,
      "task_id": stateData?.taskId,
      "description": "Validate the  rehire records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 8,
      "task_id": stateData?.taskId,
      "description": "Validate if active records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 9,
      "task_id": stateData?.taskId,
      "description": "Validate if terminated records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 10,
      "task_id": stateData?.taskId,
      "description": "Validate if contigent worker records are handled as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 11,
      "task_id": stateData?.taskId,
      "description": "Validate if the extract is required to be grouped or sorted",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 12,
      "task_id": stateData?.taskId,
      "description": "Validate if the inactive records are handled in the integration as per the requirement",
      "requirement": "Data",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 13,
      "task_id": stateData?.taskId,
      "description": "Validate the number of files generated in case of outbound integrations are per requirement",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 14,
      "task_id": stateData?.taskId,
      "description": "Validate if the inbound integration is able to process single/multiple files as per the requirement",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 15,
      "task_id": stateData?.taskId,
      "description": "Validate the file delimiter and it should be in line with the requirement",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 16,
      "task_id": stateData?.taskId,
      "description": "Validate the header row in the file",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 17,
      "task_id": stateData?.taskId,
      "description": "Validate the trailer row in the file",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 18,
      "task_id": stateData?.taskId,
      "description": "Validate and confirm the test and prod file name",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 19,
      "task_id": stateData?.taskId,
      "description": "Fields in the file are as specified ein the file specification layout",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 20,
      "task_id": stateData?.taskId,
      "description": "Validate the field mapping values (wherever applicable)",
      "requirement": "Field Requirements",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 21,
      "task_id": stateData?.taskId,
      "description": "Validate the test endpoint configuration and test the connectivity",
      "requirement": "Transport",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 22,
      "task_id": stateData?.taskId,
      "description": "Validate the production endpoint configuration and test the connectivity",
      "requirement": "Transport",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 23,
      "task_id": stateData?.taskId,
      "description": "Document retention policy is configured and set to the number of days as mentioned in the requirement",
      "requirement": "Transport",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 24,
      "task_id": stateData?.taskId,
      "description": "If file encryption is required, validate integration to check if the appropriate encryption key is used for the same",
      "requirement": "Transport",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    },
    {
      "order_id": 25,
      "task_id": stateData?.taskId,
      "description": "Delete file after retrieval to be set up as per the requirement (in case of inbound integration)",
      "requirement": "Transport",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "default"
    }
  ]

  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });

  if (selector && selector?.requirement_template) {
    // console.log('arjun get selector data', selector?.requirement_template ? CONVERTED_KEYS[selector?.requirement_template] : '')
    // console.log('arjun get selector key', selector?.requirement_template)  

    // console.log('dynamic_tests for ob', rows[selector?.requirement_template])

    const dy_test_cases = rows[selector?.requirement_template]

    dy_test_cases?.map((value) => {
      const orderId = Math.max(...unit_test_cases.map(o => o.order_id)) + 1
      const testObj = {
        "order_id": orderId,
        "task_id": stateData?.taskId,
        "description": value,
        "requirement": "",
        "testedBy": "",
        "dateTested": formatDate(new Date()),
        "TestIdType": "",
        "TestId": "",
        "Status": "",
        "Results": "",
        "Type": selector?.requirement_template
      }
      unit_test_cases.push(testObj)
    })

    console.log('unit_test_cases', unit_test_cases)
    // setTestCaseValues(newFeatures)

  }

  const [testCaseValues, setTestCaseValues] = useState<any>(unit_test_cases)
  const [testCaseValuesExport, setTestCaseValuesExport] = useState<any>()

  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData;
  });

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [user_details, setUserDetails] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const fetchMyUnitTestsList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoader(true);
      dispatch(
        getAllTestCase(
          {
            task_id: stateData?.taskId
          },
          (data: any) => {
            if (data?.length > 0) {
              const sortedValues = data?.sort(function (a, b) {
                return a.order_id - b.order_id
              })
              // setTestCaseValues(data);
              // formatDataforExport(data)

              var indKey;
              for (var i = 0; i < data.length; i++) {
                if (data[i].Type === selector?.requirement_template) {
                  indKey = i
                  break;
                }
              }

              if (indKey) {
                setTestCaseValues(data);
                formatDataforExport(data)
              } else {
                if (selector && selector?.requirement_template) {              
                  const dy_test_cases = rows[selector?.requirement_template]

                  // var defaultKey = 0;
                  // for (var i = 0; i < data.length; i++) {
                  //   if (data[i].Type === 'default') {
                  //     defaultKey++;
                  //   } else {
                  //     break;
                  //   }
                  // }

                  const defaultArr = [] as any
                  const userAddedArr = [] as any

                  for (var i = 0; i < data.length; i++) {
                    if ((data[i].Type === 'default')) {
                      defaultArr.push(data[i])
                    } else if((data[i].Type === 'user_added')) {
                      userAddedArr.push(data[i])
                    } else {
                      // data.splice(i,1);
                    }
                  }

                  // console.log('defaultArr', defaultArr)
                  // console.log('userAddedArr', userAddedArr)

                  const templateDyanmicArr = [] as any
              
                  // picking up new test cases as per dynamic tab
                  dy_test_cases?.map((value, index) => {
                    // const orderId = Math.max(...data.map(o => o.order_id)) + 1
                    const orderId = defaultArr.length + (index + 1)
                    const testObj = {
                      "order_id": orderId,
                      "task_id": stateData?.taskId,
                      "description": value,
                      "requirement": "",
                      "testedBy": "",
                      "dateTested": formatDate(new Date()),
                      "TestIdType": "",
                      "TestId": "",
                      "Status": "",
                      "Results": "",
                      "Type": selector?.requirement_template
                    }
                    templateDyanmicArr.push(testObj)
                    // data.splice((defaultKey + index), 0, testObj);
                  })

                  console.log('templateDyanmicArr', templateDyanmicArr)

                  let finalArr = [...defaultArr, ...templateDyanmicArr ,...userAddedArr]
                  // console.log('finalArr', finalArr)
              
                  // console.log('data cases new template', data)
                  setTestCaseValues(finalArr);
                  formatDataforExport(finalArr)
              
                } else {
                  setTestCaseValues(data);
                  formatDataforExport(data)
                }
              }

              // console.log('indKey', indKey)

            } else {
              setTestCaseValues(unit_test_cases)
              formatDataforExport(unit_test_cases)
            }
            setLoader(false);
          }
        )
      );
    } catch (e) {
      setLoader(false);
      console.log(e, "error");
    }
  };

  const formatDataforExport = (data) => {
    let arrayObj = data
    var i;
    let formatObj = [] as any
    for (i = 0; i < arrayObj.length; i++) {
      let obj = {
        'S No': (i + 1),
        'Description': arrayObj[i]['description'],
        'Requirement': arrayObj[i]['requirement'],
        'Tested By': arrayObj[i]['testedBy'],
        'Date Tested': new Date(arrayObj[i]['dateTested']),
        'Test Id Type': arrayObj[i]['TestIdType'],
        'Test Id': arrayObj[i]['TestId'],
        'Status': arrayObj[i]['Status'],
        'Results': arrayObj[i]['Results']
      }
      formatObj.push(obj);
    }
    setTestCaseValuesExport(formatObj)
  }

  const handleSave = () => {
    setLoader(true);
    dispatch(
      saveItemsTestCase(
        {
          payload: {
            task_id: stateData?.taskId,
            unitTest: testCaseValues
          },
        },

        onCreateUnitTestingCallback
      )
    );
  }

  const handleSelectResource = () => {
    const newFeatures = [...testCaseValues]
    const index = resourceIndex as any
    const name = resourceName as any
    newFeatures[index].testedBy = name ? name[0].value : ''
    setTestCaseValues(newFeatures)
    setOpenModalPop(false)
  }

  const onCreateUnitTestingCallback = (data: any) => {
    if (data == 'success') {
      setSuccessPopup(true);
      setLoader(false);
      formatDataforExport(testCaseValues)
    } else {
      setLoader(false);
      setErrorPopup(true)
    }
  };

  const handleChangeCompleted = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].completed = e.target.checked;

    if (e.target.checked) {
      let user = userDetail.firstname + " " + userDetail.lastname
      setUserDetails(user)
      newFeatures[index].na = false
      newFeatures[index].completedBy = user
      newFeatures[index].completion_date = moment(new Date()).format("DD-MMM-YYYY");
    } else {
      setUserDetails("")
      newFeatures[index].completedBy = ""
      newFeatures[index].completion_date = null

    }
    setTestCaseValues(newFeatures)
  };

  const handleNACompleted = (e, index) => {
    const newFeatures = [...testCaseValues]

    if (e.target.checked) {
      let user = userDetail.firstname + " " + userDetail.lastname
      setUserDetails(user)
      newFeatures[index].na = false
      newFeatures[index].completedBy = user
      newFeatures[index].completion_date = moment(new Date()).format("DD-MMM-YYYY");
    } else {
      setUserDetails("")
      newFeatures[index].completedBy = ""
      newFeatures[index].completion_date = null

    }

    newFeatures[index].na = e.target.checked
    newFeatures[index].completed = false
    setTestCaseValues(newFeatures)
  };

  const setCommentsState = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].description = e
    setTestCaseValues(newFeatures)
  }

  const setTestedBy = (e) => {
    // console.log('selected', e);
    // console.log('index', index);
    // alert(index, selected)
    // const newFeatures = [...testCaseValues]

    // console.log('selected value', e)
    setResourceName(e)
    // newFeatures[index].testedBy = e
    // //newFeatures[index].testedBy = e[0].value
    // setTestCaseValues(newFeatures)
  }

  const setTestedDate = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].dateTested = e
    setTestCaseValues(newFeatures)
  }

  const setTestIdType = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].TestIdType = e
    setTestCaseValues(newFeatures)
  }

  const setTestId = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].TestId = e
    setTestCaseValues(newFeatures)
  }

  const setStatus = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].Status = e
    setTestCaseValues(newFeatures)
  }

  const setResults = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].Results = e
    setTestCaseValues(newFeatures)
  }

  const deleteRecord = (index) => {
    setLoader(true);
    const newFeatures = [...testCaseValues]

    newFeatures.splice(index, 1)

    setTimeout(function () {
      setLoader(false);
      formatDataforExport(newFeatures)
      setTestCaseValues(newFeatures)
    }.bind(this), 1000); 

    // newFeatures[index].Results = e
    // setTestCaseValues(newFeatures)
  }

  const openResModal = (index) => {
    setResourceIndex(index);
    setOpenModalPop(true)
  }

  const [sugesstions, setSuggestions] = useState([]);

  const handleKeyUpEvent = (event: any) => {
    dispatch(
      getAllUsers(
        { searchWord: event.searchName, id: stateData?.taskId },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          } else {
            // console.log(res)
            const response_arr = [];
            res.map((value)=>{
              const user = {
                'label': value.User.ResourceName,
                'value': value.User.ResourceName
              }
              response_arr.push(user);
            })
            setSuggestions(
              response_arr
            );
          }

          // setSuggestions({
          //   ...res,
          // });
          // const radios = [
          //   { label: 'Justify (default)', value: 'justify' },
          //   { label: 'Align left', value: 'left' },
          //   { label: 'Align right', value: 'right' },
          // ];
          // setSuggestions(
          //   radios,
          // );
        }
      )
    );
  };

  const handleReqChange = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].requirement = e
    setTestCaseValues(newFeatures)
  }

  const addNewReq = () => {
    setLoader(true);
    const newFeatures = [...testCaseValues]
    const orderId = Math.max(...newFeatures.map(o => o.order_id)) + 1

    const testObj = {
      "order_id": orderId,
      "task_id": stateData?.taskId,
      "description": "",
      "requirement": "",
      "testedBy": "",
      "dateTested": formatDate(new Date()),
      "TestIdType": "",
      "TestId": "",
      "Status": "",
      "Results": "",
      "Type": "user_added"
    }

    newFeatures.push(testObj)
    setTimeout(function () {
      setLoader(false);
      setTestCaseValues(newFeatures)
    }.bind(this), 1000); 

    var objDiv = document.getElementById("border-box")
    // objDiv.scrollIntoView(false);
    if(objDiv) {
      objDiv.scrollTo(0, (objDiv.scrollHeight ))
    }
    
    var limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
      document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
      window.scrollTo(0,limit)  
  }

  useEffect(() => {
    fetchMyUnitTestsList(0, "ON_LOAD");
  }, []);

  const handleClose = () => {
    setOpenModalPop(false)
  }

  const getCustomIndex = () => {
    var indKey;
    for(var i=0 ; i < testCaseValues.length; i++){
      if(testCaseValues[i].Type === 'user_added'){
        indKey = i
        break;
      }
    }
    return indKey
  }

  const getDynamicIndex = () => {
    var indKey;
    for(var i=0 ; i < testCaseValues.length; i++){
      if(testCaseValues[i].Type === selector?.requirement_template){
        indKey = i
        break;
      }
    }
    return indKey
  }

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4"> 
        <div className="position-relative my-3">
          {/* <div className="position-absolute top-0 start-0">Test Cases</div> */}
          {/* <div className="position-absolute top-0 end-0" style={{marginRight: '120px'}}>
            <JsonToExcel
              title="Export to Excel"
              data={testCaseValuesExport}
              fileName="test-cases"
              size="medium"
              btnColor="#007cb0"
              btnClassName="export_btn"
            />
            </div> */}
          <div className="position-absolute top-0 end-0">
            <Button
              variant="contained"
              onClick={addNewReq}
              size="medium"
              className="py-2"
              sx={ButtonStyle}
            >
              Add
            </Button>
          </div>
        </div>
      </Typography>

      <div className="row mt-5" style={{ padding: "15px" }}>
          <div className="col-sm-1 fw-bold">No</div>
          <div className="col-sm-2 fw-bold">Description</div>
          <div className="col-sm-1 fw-bold">Requirement</div>
          <div className="col-sm-1 fw-bold text-center">Tested By</div>
          <div className="col-sm-2 fw-bold text-center">Date Tested</div>
          <div className="col-sm-1 fw-bold">Test ID Type</div>
          <div className="col-sm-1 fw-bold">Test ID</div>
          <div className="col-sm-1 fw-bold">Status</div>
          <div className="col-sm-1 fw-bold">Results</div>
          <div className="col-sm-1 fw-bold">Action</div>
        </div>

      <Box sx={{ ...BoxStyle }} style={{ height: "2400px", overflowY: 'scroll' }} id="border-box">
        <div style={{backgroundColor : "#f5f6f7", marginLeft: "-25px", marginRight: "-25px"}} >
          <label style={{marginLeft:"15px"}}>System Generated Requirements</label>
        </div>
        {testCaseValues?.map((row: any, index: number) => (
          <div>
            {(index === (getCustomIndex()) ?
              (<div className="mt-2" style={{ backgroundColor: "#f5f6f7", marginLeft: "-25px", marginRight: "-25px" }} >
                <label style={{ marginLeft: "15px" }}>Custom Requirements</label>
              </div>)
              : (<h1></h1>)
            )}
            {(index === (getDynamicIndex()) ?
              (<div className="mt-2" style={{ backgroundColor: "#f5f6f7", marginLeft: "-25px", marginRight: "-25px" }} >
                <label style={{ marginLeft: "15px" }}>{ CONVERTED_KEYS[row.Type] }</label>
              </div>)
              : (<h1></h1>)
            )}
            <div className="row mt-3">
              <div className="col-sm-1 mt-2">
                {index + 1}
                {/* <br /> { row.Type } */}
              </div>
              <div className="col-sm-2 mt-2">
                <textarea rows="3" disabled={row?.Type !== 'user_added'} style={{ height: "60px", marginLeft: "-70px", width: "240px" }} value={row?.description} name={`inputComments` + index} id={`inputComments` + index} onChange={e => setCommentsState(e.target.value, index)}></textarea>
              </div>
              <div className="col-sm-1 mt-2">
                <select
                  onChange={e => handleReqChange(e.target.value, index)}
                  name={`requirements` + index}
                  id={`requirements` + index}
                  value={row?.requirement}
                  className="form-control"
                  disabled={row?.Type === 'default'}
                >
                  <option value="">Select</option>
                  {REQUIREMENTS.map((type) => (
                    <option value={type.key}>{type.label}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1 mt-2 text-center">
                <div className="input-group mb-3" style={{width:"105px"}}>
                  <input className="form-control" title={row?.testedBy} disabled id={`basic_addoninp` + index} value={row?.testedBy} type="text" aria-aria-describedby={`basic_addon` + index}></input>
                  <span className="input-group-text" id={`basic_addon` + index} onClick={e => openResModal(index)} style={{ height: "40px", width: "20px", cursor: "pointer" }}><SearchIcon style={{ marginLeft: "-12px" }} /></span>
                </div>
                {/* <div class="">
                  <span class="d-inline-block">{row?.testedBy}</span>
                  <span class="d-inline-block btn float-right">
                    <span onClick={e => openResModal(index)}>&#9432;</span>
                  </span>
                </div> */}
                {/* <input type="text" disabled class="form-control" value={row?.testedBy} name={`testedBy` + index} id={`testedBy` + index} onChange={e => setTestedBy(e.target.value, index)} />
              <span onClick={e => openResModal(index)}>&#9432;</span> */}
                {/* <Typeahead
                name={`testedBy` + index} 
                id={`testedBy` + index}
                options={sugesstions}
                value={row?.testedBy}
                onChange={e => setTestedBy(e, index)}
                onInputChange={(searchName) => handleKeyUpEvent({searchName})}
                placeholder="Resource"
              /> */}
              </div>
              <div className="col-sm-2 mt-2">
                <input
                  type="date"
                  value={row?.dateTested}
                  onChange={e => setTestedDate(e.target.value, index)}
                  name={`testedDate` + index}
                  id={`testedDate` + index}
                  // placeholder="Date Tested"
                  style={{ width: "140px", marginLeft: '30px' }}
                  data-date={row?.dateTested ? formatDate(row?.dateTested) : ""}
                  className="form-control"
                />
              </div>
              <div className="col-sm-1 mt-2">
                {/* <input type="text" class="form-control" value={row?.TestIdType} name={`TestIdType` + index} id={`TestIdType` + index} onChange={e => setTestIdType(e.target.value, index)} /> */}
                <select
                  onChange={e => setTestIdType(e.target.value, index)}
                  name={`TestIdType` + index}
                  id={`TestIdType` + index}
                  value={row?.TestIdType}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {TEST_ID_TYPE.map((type) => (
                    <option value={type.key}>{type.label}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1 mt-2">
                <input type="text" class="form-control" value={row?.TestId} name={`TestId` + index} id={`TestId` + index} onChange={e => setTestId(e.target.value, index)} />
              </div>
              <div className="col-sm-1 mt-2">
                <select
                  onChange={e => setStatus(e.target.value, index)}
                  name={`status` + index}
                  id={`status` + index}
                  value={row?.Status}
                  className="form-control"
                >
                  <option value="">Select</option>
                  {TEST_CASE_STATUS.map((type) => (
                    <option value={type.key}>{type.label}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-1 mt-2">
                {/* <input type="text" class="form-control" value={row?.Results} name={`results` + index} id={`results` + index} onChange={e => setResults(e.target.value, index)} /> */}
                <textarea style={{ height: "50px", width:"100px" }} value={row?.Results} name={`results` + index} id={`results` + index} onChange={e => setResults(e.target.value, index)}></textarea>
              </div>
              <div className="col-sm-1 mt-2 text-center">
                {/* {(row?.Type === 'default' ?
                  (<span></span>)
                  : (<svg style={{ cursor: 'pointer' }} onClick={e => deleteRecord(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                  </svg>)
                )} */}
                <svg style={{ cursor: 'pointer' }} onClick={e => deleteRecord(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                </svg>
              </div>

            </div>
          </div>
        ))}
      </Box>
      <div className="form-group">
        <div className="btn-block" style={{marginLeft:"20px"}}>
          <button className="thame-btn w-auto" onClick={() => handleSave()}>Save</button>
        </div>
      </div>
      <CustomModal
        title="Test Cases"
        onOpen={successPopup}
        content={"Data Saved Successfully!"}
        modalType={"success"}
        width={"xs"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <CustomModal
        title="Test Cases"
        onOpen={errorPopup}
        content={"Error Saving Record"}
        modalType={"error"}
        width={"xs"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Select Resource Name"
        onOpen={openModalPop}
        height="50%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <div>
              <Typeahead
                name='testedByTH'
                id={`testedByTH`}
                options={sugesstions}
                // value={row?.testedBy}
                onChange={e => setTestedBy(e)}
                onInputChange={(searchName) => handleKeyUpEvent({ searchName })}
                placeholder="Resource"
              />
            </div>
            <div className="form-group">
              <div className="btn-block">
                <button className="thame-btn w-auto" onClick={() => handleSelectResource()}>Add</button>
              </div>
            </div>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
    </>
  );
};

export default TestCases;
import { call, put, takeLatest } from "redux-saga/effects";
import { CustomerAMSCreateAsync, CustomerAMSDetails, CustomerAMSSearch, CustomerAMSUpdateAsync, getMasterAMS } from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import { customerActions } from "../../actions/Customer";
import { setUserEmailId } from "../../actions/Customer";
import { APIResponse } from "../../../utility/commonTypes";

function* CreateCustomerAMS(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(payload.payload.actionType=="create"?
    CustomerAMSCreateAsync:
    CustomerAMSUpdateAsync, payload);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      callback(res?.data);

    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* getAMSMaster(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(getMasterAMS, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* getCustomerAMSDetails(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(CustomerAMSDetails, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchCustomerAMS() {
  yield takeLatest(customerActions.CREATE_CUSTOMER_AMS, CreateCustomerAMS);
  yield takeLatest(customerActions.GET_AMS_MASTER, getAMSMaster);
  yield takeLatest(customerActions.GET_CUSTOMER_AMS_DETAILS, getCustomerAMSDetails);

}

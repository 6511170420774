import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router-dom";
import SubProjectForm from "./SubProjectForm";
import ProjectDates from "./SubProjectDates";
import ProjectTasks from "../ProjectTask/index";
import ProjectResources from "../ProjectResources";
import { activeTabClass, blankTabClass } from "../style";
import ProjectScope from "../ProjectScope";
import ScopeModules from "../ScopeModules";
import CustomTab from "../CustomTab";

const SubProjectModule = () => {
  const [value, setValue] = useState(0);
  const [operationDone, setOperationDone] = React.useState(false);
  const location: any = useLocation();
  const params: any = useParams();
  const [projectData, setProjectData] = useState({});

  //const stateData: any = location?.state;
  const stateData: any = params;
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const isDisabled = (tabIndex: number) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    setOperationDone(false);
    if (stateData?.subProjectId) {
      setOperationDone(true);
    }
  }, [stateData]);
  const getProjectDetail = (project: object) => {
    setProjectData(project);
  };
  return (
    <Box
      className="my-3"
      sx={{
        paddingRight: "20px",
        paddingLeft: "20px",
        marginBottom: "40px",
        width: "100%",
      }}
    >
      <CustomTab
        indexNumber={0}
        title={projectData?.project_name}
        locationPath={location.pathname}
      />
      <Tabs
        value={value}
        aria-label="project-list"
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Tab sx={activeTabClass} label="Sub Project Profile" />
        {/* <Tab
          sx={activeTabClass}
          disabled={isDisabled(1)}
          label="Scope"
        /> */}
        <Tab
          sx={activeTabClass}
          disabled={isDisabled(2)}
          label="Project Dates"
        />
        <Tab sx={activeTabClass} disabled={isDisabled(3)} label="Tasks" />
        <Tab sx={activeTabClass} disabled={isDisabled(4)} label="Resources" />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
      </Tabs>
      <div
        style={{
          border: "1px solid #007CB0",
          borderTop: "1px solid transparent",
          padding: "2px",
        }}
      >
        {value === 0 ? (
          <SubProjectForm projectDetail={getProjectDetail} />
        ) : null}
        {/* {value === 1 && <ScopeModules/>} */}
        {value === 1 && <ProjectDates />}
        {value === 2 && <ProjectTasks />}
        {value === 3 && <ProjectResources />}
      </div>
    </Box>
  );
};

export default SubProjectModule;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoading } from "../../../../store/actions/loaderState";
import { getPdfRequirementData, getProcessFlow, getRequirementTemplateQuestionList } from "../../../../store/actions/Requirement";
import FieldRequirement from "./FieldRequirement";
import PendingItemList from "./PendingItem";
import "./index.css";
import { useReactToPrint } from "react-to-print";
import { getRequirementDetails } from "../../../../store/actions/Requirement";
import setLoader from "../../../../components/CustomLoader/loaderState"; //"../../../CustomLoader/loaderState";
import {
  DATAPROCESSED,
  DELIVERY_TRANSPORT,
  FORCHANGEFILE,
  HIRE_RECORDS,
  Mode,
  TERMINATED,
} from "../../../../constants/common";
import { Field_TYPE } from "../../DynamicTab/constants";
import { formatDate } from "../../../../utility/idelTimer";
const backgroundImageBanner = "/assets/images/bannerimage.jpg";

const RequirementPdf = () => {
  const params = useParams();
  const [requirementPdfData, setRequirementPdfData] = useState<Object>({});
  console.log({ params });
  const dispatch = useDispatch();
  const [requirementPdf, setRequirementPdf] = useState<any>({});

  const componentRef = useRef();
  const [requirementData, setRequirementData] = useState([]);
  // let templateVal: any = {};
  let templateVal: any = [];
  console.log(
    "original format",
    requirementPdf?.RequirementTemplateDatum?.["field_data"]
  );
  const dynamicData = requirementPdf?.RequirementTemplateDatum?.["field_data"];
  console.log("dynamic", dynamicData);
  console.log("type is", typeof dynamicData);
  // const result=JSON.stringify(dynamicData)
  // let r=[]
  let dynamicKeys = [];
  let dynamicValues = [];
  try {
    const r = JSON.parse(dynamicData);
    dynamicKeys = Object?.keys(r);
    dynamicValues = Object?.values(r);
    console.log("keys", dynamicKeys, dynamicValues);
  } catch (err) {
    // 👇️ This runs
    console.log("Error: ", err.message);
  }
  const find = "_";
  const re = new RegExp(find, "g");
const current=new Date()
let defaultDate=""
if (current.getDate() >= 1 && current.getDate() <= 9 && current.getMonth()+1>=1 && current.getMonth()+1<=9) {
  defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
    "0" + current.getDate()
  }`;
}
  else if(current.getDate() >= 1 && current.getDate() <= 9){
    defaultDate = `${current.getFullYear()}-${current.getMonth()+1}-${
      "0" + current.getDate()
    }`;
  }
  else if(current.getMonth()+1 >= 1 && current.getMonth()+1 <= 9){
    defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
      current.getDate()
    }`;
  }
else {
  defaultDate = `${current.getFullYear()}-${
  current.getMonth() + 1
  }-${current.getDate()}`;
}
const[processFlowData,setProcessFlowData]=useState({location:"",file_name:""})

const fetchPdfRequirement = () => {
    try {
      setLoader(true);
      dispatch(
        getPdfRequirementData({ id: params?.requirementId }, (data: any) => {
          setRequirementPdf(data);
          dispatch(
            getRequirementDetails({ id: params?.requirementId }, function (
              res: any
            ) {
              console.log({ res });
              setRequirementData(res);
              setLoader(false);
            })
          );



          dispatch(
            getRequirementTemplateQuestionList(
              { id: params?.requirementId },
              (data: any) => {
                setRequirementPdfData(data?.field_data);
                // setData(data?.field_data);
              }
            )
          );

         }))
      //  );
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoader(false);
    }
  };
console.log("items",requirementPdf?.File?.fileContent)
console.log("type",typeof(requirementPdf?.File?.fileContent))
console.log("filename",requirementPdf?.File?.file_name,processFlowData)

  useEffect(() => {
    fetchPdfRequirement();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

  });
  let modchange = Mode.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data?.Mode_or_Change_Detection
        ?.field_desc
  );
  let forChangeFile = FORCHANGEFILE.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data
        ?.For_changes_only_files_should_records_be_sent_when_entered_or_effective
        ?.field_desc
  );
  let hireRecord = HIRE_RECORDS.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data
        ?.How_will_Terminations_be_handled?.field_desc
  );
  let hireTerminationRecord = HIRE_RECORDS.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data
        ?.How_are_new_hires_records_handled?.field_desc
  );
  let newHires = HIRE_RECORDS.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data
        ?.How_are_rehire_records_handled?.field_desc
  );
  let inActive = HIRE_RECORDS.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementDatum?.field_data
        ?.what_happen_to_inactive_records?.field_desc
  );
  let deliveryTransport = DELIVERY_TRANSPORT.find(
    (o) =>
      o.key ===
      requirementPdf?.RequirementTransport?.field_data
        ?.Delivery_Transport_Production?.field_desc
  );
  console.log("interface", requirementPdf?.File);
  return (
    <section className="page-wrap pt-4 pb-4 d-flex">
      <button className="pdfbutton" onClick={handlePrint}>
        Generate PDF
      </button>
      <div id="divToPrint" className="container" ref={componentRef}>
      <img src="https://logos-world.net/wp-content/uploads/2021/08/Deloitte-Logo-700x394.png" className="logoWeb"></img>
        <div className="row justify-content-center">
          <div className="col-12">
            <div
              className="banner"
              style={{
                backgroundImage: backgroundImageBanner,
              }}
            >
              <div className="bannertext">
                <div className="bannerheading">
                {`${requirementData?.Task?.integration_id}-${requirementData?.Task?.task_name}`}
                </div>
              </div>
            </div>
            {/* <!-- docuemnt information --> */}
            <div className="doucment_information style-two page-break">
              <h2>Document Information</h2>
              <div className="table-responsive">
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td>
                        <span>Document Name</span>
                      </td>
                      <td>{`${requirementData?.Task?.integration_id}-${requirementData?.Task?.task_name}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Project Name</span>
                      </td>
                      <td>{requirementPdf?.Task?.Project?.project_name}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Client</span>
                      </td>
                      <td>
                        {requirementPdf?.Task?.Project?.Customer?.company_name}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Document Version</span>
                      </td>
                      <td>1.0</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Document Status</span>
                      </td>
                      <td>Ready</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Date Released</span>
                      </td>
                      <td>{defaultDate?formatDate(defaultDate):""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="doucment_information_two page-break">
              <h2>Document Review/Approval History</h2>
              <table className="table table-bordered table-sm">
                <tbody>
                  <tr style={{backgroundColor: '#e9ecef'}}>
                    <th className="col-2 text-center" scope="col">Date</th>
                    <th className="col-4 text-center" scope="col">Name</th>
                    <th className="col-3 text-center" scope="col">Organization/Title</th>
                    <th className="col-3 text-center" scope="col">Comments</th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                  <tr style={{backgroundColor: '#fff'}}>
                    <th className="col-2" scope="col"><span>&nbsp;</span></th>
                    <th className="col-4" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                    <th className="col-3" scope="col"><span>&nbsp;</span></th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="doucment_information_two page-break">
              <div className="table-responsive">
                <div className="customer-contact"><h2>Table of Contents</h2></div>
                <table className="">
                  <thead>
                    <tr>
                      <th>
                        1. <span>Integration Summary</span>
                      </th>
                      {/* <th className="text-end">4</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        1.1. <span>Purpose/Justification</span>
                      </td>

                      {/* <td className="text-end">4</td> */}
                    </tr>
                    <tr>
                      <td>
                        1.2. <span>Integration Description and Overview</span>
                      </td>

                      {/* <td className="text-end">4</td> */}
                    </tr>
                    <tr>
                      <td>
                        1.3. <span>Assumptions</span>
                      </td>

                    </tr>
                  </tbody>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        2. <span>Functional Requirements</span>
                      </th>
                      {/* <th className="text-end">5</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        2.1. <span>Integration Details</span>
                      </td>
                      {/* <td className="text-end">5</td> */}
                    </tr>
                  </tbody>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        3. <span>Data</span>
                      </th>
                      {/* <th className="text-end">7</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        3.1. <span>Data Requirement</span>
                      </td>
                      {/* <td className="text-end">7</td> */}
                    </tr>
                  </tbody>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        4. <span>Field Requirements</span>
                      </th>
                      {/* <th className="text-end">8</th> */}
                    </tr>
                  </thead>
                  <tbody>
                  {requirementPdf?.RequirementFileMapping?.RequirementFiles.map((file, ind)=> {
                    return (
                        <>
                          <tr>
                            <td>
                              {`4.${ind + 1}.`}
                              <span>{`File ${ind + 1}`}</span>
                            </td>
                            {/* <td className="text-end">8</td> */}
                          </tr>

                          <tr>
                            <td>
                              {`4.${ind + 1}.1.`}<span>{`File Requirements`}</span>
                            </td>
                            {/* <td className="text-end">8</td> */}
                          </tr>

                          <tr>
                            <td>
                              {`4.${ind+1}.2.`}<span>{`Field Requirements`}</span>
                            </td>
                            {/* <td className="text-end">8</td> */}
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        {/* 5. <span>HCM</span> */}
                        {`5. ${requirementPdf?.requirement_template?.replace(re, " ")}`}
                      </th>
                      {/* <th className="text-end">11</th> */}
                    </tr>
                  </thead>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        6. <span>Transport</span>
                      </th>
                      {/* <th className="text-end">12</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        6.1. <span>Transport Requirements</span>
                      </td>
                      {/* <td className="text-end">12</td> */}
                    </tr>
                  </tbody>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        7. <span>Test</span>
                      </th>
                      {/* <th className="text-end">13</th> */}
                    </tr>
                  </thead>
                </table>

                <table className="">
                  <thead>
                    <tr>
                      <th>
                        8. <span>Pending Items</span>
                      </th>
                      {/* <th className="text-end">14</th> */}
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            {/* <!-- 1. Integration Summary --> */}
            <div className="doucment_information_two page-break">
              <h2>1. Integration Summary</h2>
              <h3>1.1. Purpose/Justification</h3>
              <p>{requirementPdf?.purpose}</p>
              <h3>1.2. Integration Description and Overview</h3>
              <p>{requirementPdf?.integration_description}</p>
              <h3>1.3. Assumptions</h3>
              <p>{requirementPdf?.assumptions}</p>   
                 <div>
                 { requirementPdf?.File?.fileContent===undefined ?
                 <div></div>
                 :

                <img
                src={`data:image/png;base64,${requirementPdf?.File?.fileContent}`}
                //  src={`data:image/png;base64,${file1}`}
                 alt="upload preview"
                  className="img-fluid"
                />
                }
                </div>

            </div>

            {/* <!-- 2  Functional Requirements --> */}
            <div className="doucment_information style-two page-break">
              <h2>2. Functional Requirements</h2>
              <div className="table-responsive">
                <div className="customer-contact">2.1. Integration Details</div>
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td>
                        <span>Integration System Name</span>
                      </td>
                      <td>

                      {requirementData?.Task?.integration_id+" "+requirementData?.Task?.task_name}

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Integration Source</span>
                      </td>
                      <td>{requirementPdf?.source_system}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Integration Destination</span>
                      </td>
                      <td>{requirementPdf?.target_system}</td>
                    </tr>
                    {/* <tr>
                      <td>
                        <span>Delivery or Retrieval Method</span>
                      </td>
                      <td>SFTP with PGP encryption</td>
                    </tr> */}
                    <tr>
                      <td>
                        <span>Integration Type</span>
                      </td>
                      <td>{requirementPdf?.integration_type?.replace(re,"")}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Integration Direction</span>
                      </td>
                      <td>{requirementPdf?.flow_of_integration_workday}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Is there an existing integration?</span>
                      </td>
                      <td>{requirementPdf?.existing_integration}</td>
                    </tr>

                    {/* <tr>
                      <td>
                        <span>
                          Initial Proposed Integration <br />
                          type for this integration
                        </span>
                      </td>
                      <td>{requirementPdf?.initial_proposed_integration}</td>
                    </tr> */}

                    {/* <tr>
                      <td>
                        <span>
                          Actual Integration template <br />
                          used for this integration
                        </span>
                      </td>
                      <td>{requirementPdf?.actual_integration_template}</td>
                    </tr> */}

                    <tr>
                      <td>
                        <span>Functional Area</span>
                      </td>
                      <td>{requirementPdf?.functional_area}</td>
                    </tr>

                    <tr>
                      <td>
                        <span>Requirement Template</span>
                      </td>
                      <td>
                        {requirementPdf?.requirement_template?.replace(re, " ")}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Is there an existing
                          requirements
                          specification document?
                        </span>
                      </td>
                      <td>{requirementPdf?.existing_integration}</td>
                    </tr>

                    <tr>
                      <td>
                        <span>Integration Run Frequency</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Integration_Run_Frequency?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Anticipated First Run Date</span>
                      </td>
                      <td>
                        {
                         requirementPdf?.RequirementTechnical?.field_data
                         ?.Anticipated_First_Run_Date?.field_desc ? formatDate(requirementPdf?.RequirementTechnical?.field_data
                            ?.Anticipated_First_Run_Date?.field_desc):""
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Scheduling Specifics</span>
                      </td>
                      <td>{
                            requirementPdf?.RequirementTechnical?.field_data?.Scheduling_Specifics?.field_desc
                          }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Trigger</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Trigger?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Dependent or required for
                          Workday payroll Activities?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Dependent_or_required_for_Workday_payroll_Activities
                            ?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Launch parameters</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Launch_Parameters?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Web Services Used</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Web_Services_Used?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Annual growth anticipated
                          (volume)%
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Annual_growth_anticipated_volume?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Performance Testing Required?</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Performance_Testing_Required?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Audit File Requirement</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Audit_File_Requirement?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Exception Handling Requirement</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Exception_Handling_Requirement?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Notification Requirement</span>
                      </td>

                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Notification_Requirement?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Notes and Additional
                          Comments
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTechnical?.field_data
                            ?.Notes_and_Additional_Comments?.field_desc
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!--    3   Data --> */}
            <div className="doucment_information style-two page-break">
              <h2>3. Data</h2>
              <div className="table-responsive">
                <div className="customer-contact">3.1. Data Requirement</div>
                <table className="table table-borderless m-0">
                  <tbody>
                    <tr>
                      <td>
                        <span>Selection Criteria</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Selection_Criteria?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Mode/ Change Detection</span>
                      </td>
                      <td>{modchange?.label}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          For changes only files,
                          should records be sent
                          when entered or effective?
                        </span>
                      </td>
                      <td>
                        {
                          // requirementPdf?.RequirementDatum?.field_data
                          //   ?.For_changes_only_files_should_records_be_sent_when_entered_or_effective
                          //   ?.field_desc
                          forChangeFile?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Will data processed by this
                          integration be treated as
                          sensitive?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Will_data_processed_by_this_integration_be_treated_as_sensitive
                            ?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          How will Terminations be
                          handled?
                        </span>
                      </td>
                      <td>
                        {
                          // requirementPdf?.RequirementDatum?.field_data
                          //   ?.How_will_Terminations_be_handled?.field_desc
                          hireRecord?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          How are new hires records
                          handled?
                        </span>
                      </td>
                      <td>
                        {
                          // requirementPdf?.RequirementDatum?.field_data
                          //   ?.How_are_new_hires_records_handled?.field_desc
                          hireTerminationRecord?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          How are rehire records
                          handled?
                        </span>
                      </td>
                      <td>
                        {
                          // requirementPdf?.RequirementDatum?.field_data
                          //   ?.How_are_rehire_records_handled?.field_desc
                          newHires?.label
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Send Active Employees</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Send_Active_Employees?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Send Contingent Workers</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Send_Contingent_Workers?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Send Terminations</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Send_Terminations?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          How are transactions
                          grouped or sorted?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.How_are_transactions_grouped_or_sorted?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Does the integration
                          always output current
                          data? Any future
                          transactions? Explain
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain
                            ?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Can the record become
                          inactive?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Can_the_record_become_inactive?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          What happen to inactive
                          records?
                        </span>
                      </td>
                      <td>
                        {
                          // requirementPdf?.RequirementDatum?.field_data
                          //   ?.what_happen_to_inactive_records?.field_desc
                          inActive?.label
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          What type of data
                          will be sent?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.what_type_of_data_will_be_sent?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Notes and Additional
                          Comments
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementDatum?.field_data
                            ?.Notes_and_Additional_Comments?.field_desc
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- 4  Field Requirements  --> */}
            <div className={'page-break unbreak_div'}>
            <div className="doucment_information style-two">
              <h2>4. Field Requirements </h2>
              <div className="customer-contact">
                Number of Files:{" "}
                {requirementPdf?.RequirementFileMapping?.num_files||0}{" "}
              </div>
            </div>
            <FieldRequirement
              requirementFieldPdf={
                requirementPdf?.RequirementFileMapping?.RequirementFiles
              }
            />
            </div>

            {/* <!-- 5  Payroll Interface --> */}
           {requirementPdf?.requirement_template &&    (
           <div className="doucment_information style-two page-break">
              <h2>
                {`5. ${requirementPdf?.requirement_template?.replace(re, " ")}`}
              </h2>
              <div className="table-responsive">
                <table className="table table-borderless m-0">
                  <tbody>

                          {Object?.values(dynamicKeys)
                            /*?.sort((fieldA: any, fieldB: any) =>
                  fieldA?.field_order > fieldB?.field_order ? 1 : -1
                )*/
                            ?.map((id) => {
                              // console.log("entries",Object?.entries(dynamicKeys))
                              const TextValue = Field_TYPE.TEXT_FIELD.hasOwnProperty(
                                id
                              );
                              const DropDownValue = Field_TYPE.DROP_DOWN.hasOwnProperty(
                                id
                              );

                              const DropDownValue1 = Field_TYPE.DROP_DOWN1.hasOwnProperty(
                                id
                              );

                              const RecruitingDropDown = Field_TYPE.RECRUITING_HIRE_INBOUND_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const SubsetDropDown = Field_TYPE.SUBSET_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const PIConnectorDropDown = Field_TYPE.DROPDOWN_PI_CONNECTOR.hasOwnProperty(
                                id
                              );
                              const PayrollDropDown = Field_TYPE.PAYROLL_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const PaymentDropDown = Field_TYPE.PAYMENT_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const PayrollInputDropDown = Field_TYPE.PAYROLL_INPUT_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const HireProcessDropDown = Field_TYPE.HIRE_PROCESS_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const systemRecordDropDown = Field_TYPE.SYSTEM_RECORD_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const staffingModalRecordDropDown = Field_TYPE.STAFFING_MODAL_DROP_DOWN.hasOwnProperty(
                                id
                              );

                              const CreditCardDropDown = Field_TYPE.CREDIT_CARD_DROP_DOWN.hasOwnProperty(
                                id
                              );

                              const CreditCardDropDownPr = Field_TYPE.CREDIT_CARD_DROP_DOWN_PR.hasOwnProperty(
                                id
                              );

                              const CurrencyRateDropDown = Field_TYPE.CURRENCY_RATE_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const GLInboundRateDropDown = Field_TYPE.GL_INBOUND_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const GeneralLedgerDropDown1 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const GeneralLedgerDropDown2 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_2.hasOwnProperty(
                                id
                              );
                              const GeneralLedgerDropDown3 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_3.hasOwnProperty(
                                id
                              );
                              const GeneralLedgerDropDown4 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_4.hasOwnProperty(
                                id
                              );
                              const GeneralLedgerDropDown5 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_5.hasOwnProperty(
                                id
                              );
                              const ReceivablesDropDown = Field_TYPE.RECEIVABLES_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const TaxDropDown = Field_TYPE.TAX_DROP_DOWN.hasOwnProperty(
                                id
                              );
                              const TaxOutboundDropDown1 = Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const TaxOutboundDropDown2 = Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_2.hasOwnProperty(
                                id
                              );
                              const PayrollInterfaceDropDown = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN.hasOwnProperty(
                                id
                              );
                              const PayrollInterfaceDropDown1 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_1.hasOwnProperty(
                                id
                              );
                              const PayrollInterfaceDropDown2 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_2.hasOwnProperty(
                                id
                              );
                              const PayrollInterfaceDropDown3 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_3.hasOwnProperty(
                                id
                              );
                              const PayrollInterfaceDropDown4 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_4.hasOwnProperty(
                                id
                              );
                              const AbsentManagementTimeoff = Field_TYPE.ABSENT_MANAGEMENT_TIMEOFF.hasOwnProperty(
                                id
                              );
                              const BenefitsboundDropDown1 = Field_TYPE.BENEFITS_OUTBOUND_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const BarbidirectionalDropDown1 = Field_TYPE.BAR_BI_DIRECTIONAL_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const LifeDisabilityDropDown1 = Field_TYPE.LIFE_DISABILITY_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const HsaFsaDropDown1 = Field_TYPE.HSAFSA_DROP_DOWN_1.hasOwnProperty(
                                id
                              );
                              const FourZeroDropDown1 = Field_TYPE.FourzeroOne_DROP_DOWN_1.hasOwnProperty(
                                id
                              );

                            let answer =   requirementPdfData[id]?.['field_desc']

                              return (
                                <tr>
                                  <td>
                                    <span>
                                    {" "}
                                    { TextValue
                                      ? Field_TYPE.TEXT_FIELD[id]

                                      : DropDownValue
                                      ? Field_TYPE.DROP_DOWN[id]

                                      : DropDownValue1
                                      ? Field_TYPE.DROP_DOWN1[id]

                                      : SubsetDropDown
                                      ? Field_TYPE.SUBSET_DROP_DOWN[id]

                                      : PayrollDropDown
                                      ? Field_TYPE.PAYROLL_DROP_DOWN[id]

                                      : PaymentDropDown
                                      ? Field_TYPE.PAYMENT_DROP_DOWN[id]

                                      : PayrollInputDropDown
                                      ? Field_TYPE.PAYROLL_INPUT_DROP_DOWN[id]

                                      : PIConnectorDropDown
                                      ? Field_TYPE.DROPDOWN_PI_CONNECTOR[id]

                                      : HireProcessDropDown
                                      ? Field_TYPE.HIRE_PROCESS_DROP_DOWN[id]

                                      : systemRecordDropDown
                                      ? Field_TYPE.SYSTEM_RECORD_DROP_DOWN[id]

                                      : staffingModalRecordDropDown
                                      ? Field_TYPE.STAFFING_MODAL_DROP_DOWN[id]

                                      : CreditCardDropDown
                                      ? Field_TYPE.CREDIT_CARD_DROP_DOWN[id]

                                      : CreditCardDropDownPr
                                      ? Field_TYPE.CREDIT_CARD_DROP_DOWN_PR[id]

                                      : CurrencyRateDropDown
                                      ? Field_TYPE.CURRENCY_RATE_DROP_DOWN[id]

                                      : GLInboundRateDropDown
                                      ? Field_TYPE.GL_INBOUND_DROP_DOWN[id]

                                      : GeneralLedgerDropDown1
                                      ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_1[id]

                                      : GeneralLedgerDropDown2
                                      ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_2[id]

                                      : GeneralLedgerDropDown3
                                      ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_3[id]

                                      : GeneralLedgerDropDown4
                                      ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_4[id]

                                      : GeneralLedgerDropDown5
                                      ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_5[id]

                                      : ReceivablesDropDown
                                      ? Field_TYPE.RECEIVABLES_DROP_DOWN[id]

                                      : TaxDropDown
                                      ? Field_TYPE.TAX_DROP_DOWN[id]

                                      : TaxOutboundDropDown1
                                      ? Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_1[id]

                                      : TaxOutboundDropDown2
                                      ? Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_2[id]

                                      : BenefitsboundDropDown1
                                      ? Field_TYPE.BENEFITS_OUTBOUND_DROP_DOWN_1[id]

                                      : BarbidirectionalDropDown1
                                      ? Field_TYPE.BarbidirectionalDropDown1[id]

                                      : LifeDisabilityDropDown1
                                      ? Field_TYPE.LIFE_DISABILITY_DROP_DOWN_1[id]

                                      : HsaFsaDropDown1
                                      ? Field_TYPE.HSAFSA_DROP_DOWN_1[id]

                                      : FourZeroDropDown1
                                      ? Field_TYPE.FourzeroOne_DROP_DOWN_1[id]

                                      : PayrollInterfaceDropDown
                                      ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN[id]

                                      : PayrollInterfaceDropDown1
                                      ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_1[id]

                                      : PayrollInterfaceDropDown2
                                      ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_2[id]

                                      : PayrollInterfaceDropDown3
                                      ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_3[id]

                                      : PayrollInterfaceDropDown4
                                      ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_4[id]

                                      : AbsentManagementTimeoff
                                      ? Field_TYPE.ABSENT_MANAGEMENT_TIMEOFF[id]

                                      : id
                                      ? Field_TYPE
                                          .RECRUITING_HIRE_INBOUND_DROP_DOWN[id]
                                      : RecruitingDropDown
                                      
                                      }
                                    <br />
                                    </span>
                                  </td>
                                  <td>{answer}</td>
                                </tr>
                              );
                            })}

                  </tbody>
                </table>
              </div>
            </div>)}

            {/* <div className="doucment_information style-two">
              <h2>Notes and Additional Requirements</h2>
            </div> */}

            {/* <!-- 4.2    File 2 Requirements and Field Requirements --> */}
            <div className="doucment_information style-two page-break">
         {requirementPdf?.RequirementTemplateDatum === null ?     <h2>5. Transport</h2> :  <h2>6. Transport</h2>}
              <div className="">
                <div className="customer-contact">
                {requirementPdf?.RequirementTemplateDatum === null ?     "5.1. Transport Requirements" :  "6.1. Transport Requirements"}


                </div>
                <table className="styled-two">
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          Transport Details available
                          with the required
                          infrastructure?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Transport_Details_available_with_the_required_infrastructure
                            ?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Use Workday Temp File
                          Functionality
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Use_Workday_Temp_File_Functionality?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Delivery Transport -
                          Production
                        </span>
                      </td>
                      <td>{deliveryTransport?.label}</td>
                    </tr>
                    <tr>
                      <td>
                        <span>Address/URL – Production</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.AddressorURL_Production?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Directory – Production</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Directory_Production?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Username – Production</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Username_Production?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Authentication - Production</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Authentication_Production?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Delivery Transport – Test</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Delivery_Transport_Test?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Address/URL – Test </span>
                      </td>

                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.AddressorURL_Test?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Directory – Test </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Directory_Test?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Username – Test</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Username_Test?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Authentication – Test</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Authentication_Test?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Document Retention
                          Period
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Document_Retention_Period?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Encryption Required?</span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Encryption_Required?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>
                          Delete file after retrieval
                          (Applicable for inbound)
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Delete_file_after_retrieval_applicable_for_inbound_integration
                            ?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Notes </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTransport?.field_data
                            ?.Notes?.field_desc
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- 7  Test --> */}

            <div className="doucment_information style-two page-break">
            {requirementPdf?.RequirementTemplateDatum === null ?     <h2>6. Test</h2> :  <h2>7. Test</h2>}

              <div className="">
                <table className="styled-two">
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          Is a test environment available or is one
                          required?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTest?.field_data
                            ?.Is_a_test_environment_available_or_is_one_required
                            ?.field_desc
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Are there any Dependencies or
                          timing requirements for testing?
                        </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTest?.field_data
                            ?.Are_there_any_Dependencies_or_timing_requirements_for_testing
                            ?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Recommended number of testing iterations </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTest?.field_data
                            ?.Recommended_number_of_testing_iterations
                            ?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Test Plan/Protocol </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTest?.field_data
                            ?.Test_PlanorProtocol?.field_desc
                        }
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span>Notes and Additional requirements </span>
                      </td>
                      <td>
                        {
                          requirementPdf?.RequirementTest?.field_data
                            ?.Notes_and_Additional_requirements?.field_desc
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- 8  Pending Items --> */}

            <PendingItemList pendingItemList={requirementPdf?.Task?.PendingItems} requirementTemplate={requirementPdf?.RequirementTemplateDatum} />

            {/* <!-- Important notice --> */}

            <div className="doucment_information_two page-break">
              <h2>Important notice</h2>

              <p>
                This document has been prepared by Deloitte Consulting for the
                sole purpose of providing a proposal to the parties to whom it
                is addressed in order that they may evaluate the capabilities of
                Deloitte Consulting to supply the proposed services.
              </p>
              <p>
                The information contained in this document has been compiled by
                Deloitte Consulting and includes material which may have been
                obtained from information provided by various sources and
                discussions with management but has not been verified or
                audited. This document also contains confidential material
                proprietary to Deloitte Consulting. Except in the general
                context of evaluating our capabilities, no reliance may be
                placed for any purposes whatsoever on the contents of this
                document or on its completeness. No representation or warranty,
                express or implied, is given and no responsibility or liability
                is or will be accepted by or on behalf of Deloitte Consulting or
                by any of its partners, members, employees, agents or any other
                person as to the accuracy, completeness or correctness of the
                information contained in this document or any other oral
                information made available, and any such liability is expressly
                disclaimed.
              </p>
              <p>
                This document and its contents are confidential and may not be
                reproduced, redistributed or passed on, directly or indirectly,
                to any other person in whole or in part without our prior
                written consent.
              </p>
              <p>
                This document is not an offer and is not intended to be
                contractually binding. Should this proposal be acceptable to
                you, and following the conclusion of our internal acceptance
                procedures, we would be pleased to discuss terms and conditions
                with you prior to our appointment.
              </p>
              <p>
                In this document, references to Deloitte are references to
                Deloitte Consulting which is an affiliate of Deloitte Southeast
                Asia Ltd, a member firm of Deloitte Touche Tohmatsu Limited.
              </p>
              <p>&copy; 2020 Deloitte Consulting</p>
            </div>

            <div className="doucment_information_two">
              <h2>About Deloitte</h2>
              <p>
                Deloitte provides audit, tax, consulting, and financial advisory
                services to public and private clients spanning multiple
                industries. With a globally connected network of member firms in
                140 countries, Deloitte brings world-class capabilities and deep
                local expertise to help clients succeed wherever they operate.
                Deloitte's 165,000 professionals are committed to becoming the
                standard of excellence.
              </p>
              <p>
                Deloitte's professionals are unified by a collaborative culture
                that fosters integrity, outstanding value to markets and
                clients, commitment to each other, and strength from cultural
                diversity. They enjoy an environment of continuous learning,
                challenging experiences, and enriching career opportunities.
                Deloitte's professionals are dedicated to strengthening
                corporate responsibility, building public trust, and making a
                positive impact in their communities.
              </p>
              <p>
                Deloitte refers to one or more of Deloitte Touche Tohmatsu, a
                Swiss Verein, and its network of member firms, each of which is
                a legally separate and independent entity. Please see
                www.deloitte.com/about for a detailed description of the legal
                structure of Deloitte Touche Tohmatsu and its member firms.
                Please see http://www.deloitte.com/us/about for a detailed
                description of the legal structure of Deloitte LLP and its
                subsidiaries.
              </p>
              <p>Internal Usage Statement</p>
              <p>
                This publication is for internal distribution and use only among
                personnel of Deloitte Touche Tohmatsu, its member firms, and its
                and their affiliates. Deloitte Touche Tohmatsu, its member
                firms, and its and their affiliates shall not be responsible for
                any loss whatsoever sustained by any person who relies on this
                publication. Copyright © 2017 Deloitte Development LLC. All
                rights reserved.
              </p>
              <p>Member of Deloitte Touche Tohmatsu</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequirementPdf;

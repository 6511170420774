export const actions={
    GET_INTEGRATION_REPOSITORY_TABLE:"GET_INTEGRATION_REPOSITORY_TABLE",
    SET_INTEGRATION_REPOSITORY_TABLE:"SET_INTEGRATION_REPOSITORY_TABLE",
    GET_INTEGRATION_REPOSITORY_CATALOGUE:"GET_INTEGRATION_REPOSITORY_CATALOGUE",
    SET_INTEGRATION_REPOSITORY_CATALOGUE:"SET_INTEGRATION_REPOSITORY_CATALOGUE",
    SET_REPOSITORY_LIST:"SET_REPOSITORY_LIST",
    GET_REPOSITORY_LIST:"GET_REPOSITORY_LIST",
    SET_SOURCE_TARGET_SYSTEM:"SET_SOURCE_TARGET_SYSTEM",
    GET_SOURCE_TARGET_SYSTEM:"GET_SOURCE_TARGET_SYSTEM"
}

interface integrationRepository{
    vendor:any;
    source_system:any;
    target_system:any;
    functional_area:any;
    requirement_template:any;
    task_name:any;
    company_name:any;
    project_name:any,
    flow_of_integration:any,
    sourceOrTargetSystem:any
    
}
interface sourceTargetSystem{
  source_system:any;
  target_system:any
}
export const setIntegrationRepositoryTable = (
    payload: integrationRepository,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SET_INTEGRATION_REPOSITORY_TABLE,
      payload,
      callback,
    };
  };
  export const getIntegrationRepositoryTable = (
    payload: object,
    callback: CallableFunction
  ) => {
    return {
      type: actions.GET_INTEGRATION_REPOSITORY_TABLE,
      payload,
      callback,
    };
  };
  export const setIntegrationRepositoryCatalogue = (
    payload: integrationRepository,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SET_INTEGRATION_REPOSITORY_CATALOGUE,
      payload,
      callback,
    };
  };
  export const getIntegrationRepositoryCatalogue = (
    payload: object,
    callback: CallableFunction
  ) => {
    return {
      type: actions.GET_INTEGRATION_REPOSITORY_CATALOGUE,
      payload,
      callback,
    };
  };
  export const setRepositoryList = (
    payload: integrationRepository,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SET_REPOSITORY_LIST,
      payload,
      callback,
    };
  };
  export const getRepositoryList = (
    payload: integrationRepository,
    callback: CallableFunction
  ) => {
    return {
      type: actions.GET_REPOSITORY_LIST,
      payload,
      callback,
    };
  };
  export const setSourceTargetSystem = (
    payload: sourceTargetSystem,
    callback: CallableFunction
  ) => {
    return {
      type: actions.SET_SOURCE_TARGET_SYSTEM,
      payload,
      callback,
    };
  };
  export const getSourceTargetSystem = (
    payload: sourceTargetSystem,
    callback: CallableFunction
  ) => {
    return {
      type: actions.GET_SOURCE_TARGET_SYSTEM,
      payload,
      callback,
    };
  };

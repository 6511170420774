import { DialogContent, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";
import styles from "./CustomConfirmPopup.module.scss";
import {CustomButton} from "../../CustomButton/CustomButton"
import Dialog from "@material-ui/core/Dialog";
// import { useSelector } from "react-redux";
import success from "../../../assets/images/checked.png"
const cancelIcon = "/assets/images/Cancel_Icon.svg";
// const successIcon="/assets/images/checked.png"
export const CustomConfirmPopup = (props: any) => {
  

  const {
    open,
    close,
    closeAndSubmit,
    rowId,
    taskId,
    cancelIcon,
    title,
    onClickYes,
    onClickNo,
    description,
    btnText,
    btnText1,
    // success,
    orderIdsList,
    indentdescription,
    overrideTitleStyle = {},
    overrideDescriptionStyle = {},
  } = props;

  // const store: any = useSelector((state) => state);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent
        className={`d-flex flex-column align-items-center py-3 py-sm-4 px-2 px-sm-4 ${styles.pc}`}
      >
        <Typography
          className={`root pb-4 ${styles.popStyles} text-center`}
          variant="h5"
          color="primary"
          style={{ ...overrideTitleStyle }}
        >
          {title}
        </Typography>

        <Hidden xsDown>
          <img
            className={`${styles.illustration} ${styles.icon}`}
            src={success}
            alt="success"
          />
        </Hidden>
        <Hidden smUp>
          <img
            className={`${styles.illustration}`}
            src={success}
            alt="success"
          />
        </Hidden>
        {indentdescription && (
          <Typography
            className={`pb-4 pt-3 ${styles.description}`}
            variant="subtitle1"
            color="textPrimary"
          >
            {indentdescription}
          </Typography>
        )}
        <Grid
          container
          spacing={2}
          justify="flex-start"
          className={`${styles.vehicleId}`}
        >
          {orderIdsList?.map((item, index) => (
            <Grid
              key={index}
              item
              xs={4}
              sm={4}
              className={`${styles.orderIdsList}`}
            >
              {item.orderId ? item.orderId : item}
            </Grid>
          ))}
        </Grid>
        <Typography
          className={`pb-4 pt-3 ${styles.description}`}
          variant="subtitle1"
          color="textPrimary"
          style={{ ...overrideDescriptionStyle }}
        >
      {description}
        </Typography>
       
        {btnText && (
          <CustomButton
            className={`${styles.button}`}
            color="primary"
            variant="contained"
            onClick={()=>{
              onClickYes(rowId,taskId)
            }}
          >
            {btnText}
          </CustomButton>

        )}&nbsp;&nbsp;&nbsp;&nbsp;
       {btnText1 && (<CustomButton
            className={`${styles.button}`}
            color="primary"
            variant="contained"
            onClick={onClickNo}
          >
            {btnText1}
          </CustomButton>
       )}
       
      </DialogContent>
    </Dialog>
  );
};

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { TASK_ROLE } from "../../../constants/common";
import Tooltip from "@mui/material/Tooltip";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const Columns = (editResource: any, deleteResource: any,handleUserDetails:any) => {
  return [
    {
      field: "ResourceName",
      headerName: "Name",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => 
      // params?.row?.User?.ResourceName || "NA",
      {
        return (
            params?.row?.ResourceName ? 
                <a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.User?.resourceName_user_id);return false} }
                >
                {params?.row?.ResourceName} 
                </a> 
            : "NA"
        )
    }

    },

    {
      field: "role",
      headerName: "Role",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = TASK_ROLE.find((o) => o.key === params?.row?.role);
        return obj?.label?(
          <Tooltip title={obj?.label} followCursor>
          <div>{obj?.label}</div>
        </Tooltip>
        ) : (
          "NA"
          );
      },    },
    {
      field: "assigned_date",
      headerName: "Assignment Date",
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined||null ? 'NA' : formatDate(params?.value)

     },    },
    {
      field: "created_by",
      headerName: "Assigned By",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) =>  {
        return (
            params?.row?.created_by ? 
                <a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.resourceCreator?.createdBy_id);return false} }
                >
                {params?.row?.created_by} 
                </a> 
            : "NA"
        )
    }
    },

    {
      field: "start_date",
      headerName: "Start Date",
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined||null ? 'NA' : formatDate(params?.value)

     },  
    },
    {
      field: "end_date",
      headerName: "End Date",
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined||null ? 'NA' : formatDate(params?.value)

     },  
    },

    {
      field: "totals",
      headerName: "Hours",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.totals || "NA",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 122,
      ...commonColumnsDef,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: "#000000" }} />}
          label="Edit"
          onClick={(e: any) => editResource(e, params?.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#c70000" }} />}
          label="Delete"
          onClick={(e: any) => deleteResource(e, params?.row?.id)}
        />,
      ],
    },
  ];
};

export default Columns;

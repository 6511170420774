import React, { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import MyTasksList from "./MyTasks";
import { useDispatch, useSelector } from "react-redux";
import setLoader from "../../components/CustomLoader/loaderState";
import IntegrationRepository from "../IntegrationRepository/integrationRepository";
import {
  getAllProjectList,
  getMyProjectListDashboard,
} from "../../store/actions/Projects";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { getTasksByCustomerAndProjectList } from "../../store/actions/Tasks";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";
import { getMyTasksList } from "../../store/actions/Tasks";
import { BoxStyle, SummaryStyle } from "./style";
import { formatDate } from "../../utility/idelTimer";

const Dashboard = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [rowData, setRowData] = useState<any>([]);
  const [rowDataLength, setRowDataLength] = useState(1);
  const [projectVal, setProjectVal] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData;
  });

  const fetchAllProjectList = () => {
    try {
      setLoader(true);
      dispatch(
        getMyProjectListDashboard(
          {
            user_id: userDetail?.id,
            size: 100,
            page: 0,
            pagetype: 'home',
          },
          (data: any) => {
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
              setRowDataLength(data?.rowsReturned.length);
              fetchMyTasksList(data?.rowsReturned?.[0]?.Project?.id);
            } else {
              setRowDataLength(0);
              setRowData([]);
            }
            setTimeout(() => {
              setLoader(false);
            }, 1000);
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    fetchAllProjectList();
  }, []);

  const handleChange = (panel: string, projectId?: any) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
    fetchMyTasksList(projectId);
  };

  const fetchMyTasksList = (projectName?: any) => {
    try {
      setProjectVal([]);
      setLoading(true);
      dispatch(
        getMyTasksList(
          {
            user_id: userDetail?.id,
            size: 50,
            page: 0,
            isMyTask: 1,
            project_id: projectName,
            pagetype: 'home',
          },
          (data: any) => {
            //setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setProjectVal(data?.rowsReturned);
            } else {
              setProjectVal([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoading(false);
    }
  };
  let customerName=""
  // {projectVal?.map((p:any,i:any)=>{
  //   // customerName=p?.customer_name
  //    }   )    }      
  

  return (
    <section className="main-contant d-tow">
      {rowDataLength ? (
        <div
          className="container"
          style={{ border: "none", paddingTop: "10px" }}
        >
          <div className="view_customer" style={{ paddingLeft: "20px" }}>
            Project
          </div>
          <Box sx={{ ...BoxStyle }}>
            <div>
              {rowData?.map((rowVal: any, index: any) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(
                      `panel${index}`,
                      rowVal?.Project?.id
                    )}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{ ...SummaryStyle }}
                    >
                      <Grid container className="mt-2">
                        <Grid item xs={12} className="p-0">
                          <Typography lineHeight=".5">
                            Project : {rowVal?.Project?.project_name}
                          </Typography>
                        </Grid>
                        <div className="d-inline-flex p-0">
                          <Typography
                            lineHeight=".5"
                            fontSize="13px"
                            color={"#6e6e6e"}
                          >
                            Start Date :{" "}
                            {/* {rowVal?.start_date
                              ? moment(rowVal?.start_date).format("YYYY-MM-DD")
                              : "NA"} */}
                              {rowVal?.Project?.ProjectDate?.plan_phase_start_date?formatDate(rowVal?.Project?.ProjectDate?.plan_phase_start_date?.split("T")[0]):"NA"}
                          </Typography>
                          <Typography
                            lineHeight=".5"
                            fontSize="13px"
                            color={"#6e6e6e"}
                            marginLeft=".5rem"
                          >
                            {" "}
                            &#x2022; End Date :{" "}
                            
                            {rowVal?.Project?.ProjectDate?.hypercare_end_date?formatDate(rowVal?.Project?.ProjectDate?.hypercare_end_date?.split("T")[0]):"NA"}

                            {/* {rowVal?.end_date
                              ? moment(rowVal?.end_date).format("YYYY-MM-DD")
                              : "NA"}{" "} */}
                          </Typography>
                        </div>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    Project : {" "}
                      {rowVal?.Project?.project_level === "Sub_Project" ? (
              <Tooltip title={rowVal?.Project?.project_name} followCursor>
                <Link
                  to={`/project/manage-sub-project/${rowVal?.Project?.master_project}/${rowVal?.Project?.company_customer_id}/${rowVal?.Project?.id}`}
                  state={{
                    masterProjectId: rowVal?.Project?.master_project,
                    companyCustomerId: rowVal?.Project?.company_customer_id,
                    subProjectId: rowVal?.Project?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {rowVal?.Project?.project_name}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title={rowVal?.Project?.project_name} followCursor>
                <Link
                  to={`/project/${rowVal?.Project?.id}/${rowVal?.Project?.company_customer_id}`}
                  state={{
                    projectId: rowVal?.Project?.id,
                    customerId: rowVal?.Project?.company_customer_id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {rowVal?.Project?.project_name}
                </Link>
              </Tooltip>
            )} &nbsp;
                       Customer : {" "}
                      {rowVal?.Project?.Customer?.company_name ? (
              <Tooltip title={rowVal?.Project?.Customer?.company_name} followCursor>
                <Link
                  to={`/customer/${rowVal?.Project?.company_customer_id}/${rowVal?.Project?.Customer?.company_name}`}
                  state={{
                    customerId:rowVal?.Project?.company_customer_id,
                    companyId:rowVal?.Project?.Customer?.company_name,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {rowVal?.Project?.Customer?.company_name}
       </Link>
              </Tooltip>
            ) : (
              <Link
                to={`/customer/${rowVal?.Project?.company_customer_id}`}
                state={{ customerId:rowVal?.Project?.company_customer_id}}
                style={{ color: "#1976d2" }}
              >
                                  {rowVal?.Project?.Customer?.company_name}

        {/* {projectVal?.map((p:any)=>(
        p?.customer_name
        )    )    }   */}
              </Link>
            )}
                      <MyTasksList projectData={projectVal} loading={loading} />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Box>
        </div>
      ) : (
        <IntegrationRepository />
      )}
    </section>
  );
};
export default Dashboard;

import moment from "moment";
import { useDispatch } from "react-redux";
// import { setIdleTime } from "../store/actions/auth";

interface ConfigInterface {
    timeout: number; // in seconds
    
}

class IdleTimer {
    static eventsToTrack: Array<string> = [
        'load',
        'mousemove',
        'mousedown',
        'touchstart',
        'click',
        'keypress',
        'scroll',
    ];
    config: ConfigInterface;
    eventHandler: EventListenerOrEventListenerObject;
    constructor(conf: ConfigInterface) {
        this.config = conf;
        this.eventHandler = this.updateIdleTime.bind(this);
        this.tracker()
       
      
    }
   
    updateIdleTime() {
        if (typeof window !== 'undefined') {
            localStorage.setItem('idleTime', new Date().getTime().toString());
        }
        
      
    }
    
    tracker() {
        IdleTimer.eventsToTrack.forEach((event) => {
            window.addEventListener(event, this.eventHandler);
        });
    }
    cleanup() {
      
        IdleTimer.eventsToTrack.forEach((event) => {
            window.removeEventListener(event, this.eventHandler);
        });
    }
   
}
export const getIdleTimeFromStorage = () => {
    if (typeof window !== 'undefined') {
        return Number(localStorage.getItem('idleTime')||0);
    }
    return '';
};
export const setIdleTimeFromStorage = (timer:any) => {
    if (typeof window !== 'undefined') {
    localStorage.setItem('idleTime',timer?.toString());
    }
};

export default IdleTimer;
export const formatDate = (date:any) => {
    let newDate = new Date(Date.parse(date));
    const locale = navigator.language;
    return moment(newDate)?.format("DD-MMM-YYYY");
   };

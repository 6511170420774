import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import {
  DATAPROCESSED,
  FORCHANGEFILE,
  HIRE_RECORDS,
  Mode,
  TERMINATED,
} from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getRequirementData,
  updateRequirementData,
} from "../../../store/actions/Requirement";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { ProfileType } from "../../../utility/commonTypes";

interface RequirementDataMap {
  id: any;
  field_data: any;
  requirement_id: any;
  Selection_Criteria: any;
  Mode_or_Change_Detection: any;
  For_changes_only_files_should_records_be_sent_when_entered_or_effective: any;
  Will_data_processed_by_this_integration_be_treated_as_sensitive: any;
  How_will_Terminations_be_handled: any;
  How_are_new_hires_records_handled: any;
  How_are_rehire_records_handled: any;
  Send_Active_Employees: any;
  Send_Contingent_Workers: any;
  Send_Terminations: any;
  How_are_transactions_grouped_or_sorted: any;
  Does_the_integration_always_output_current_data_Any_future_transactions_Explain: any;
  Can_the_record_become_inactive: any;
  what_happen_to_inactive_records: any;
  what_type_of_data_will_be_sent: any;
  Notes_and_Additional_Comments: any;
}

const DataTab = (props: any) => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("create");
  const [successPopup, setSuccessPopup] = useState(false);

  const location = useLocation();
  const params: any = useParams();
  const stateData = params;

  console.log({location, params, stateData});
  const navigate = useNavigate();
  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });
  const [open, setOpen] = useState(false);
const handleClose = (
  event?: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }

  setOpen(false);
};

  const {
    handleSubmit,
    handleChange,
    data: requirementdata,
    errors,
    setData,
  } = useForm<RequirementDataMap>({
    validations: {
      Selection_Criteria: {
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      How_are_transactions_grouped_or_sorted:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Does_the_integration_always_output_current_data_Any_future_transactions_Explain:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      what_type_of_data_will_be_sent:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Notes_and_Additional_Comments:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      }
    },
    onSubmit: () => {
      setLoader(true);
      console.log("requirementdata", requirementdata);
      let res: any = {};

      for (const i in requirementdata) {
        const requirementValue = requirementdata[i];
        res[i] = {
          field_desc: requirementValue !== undefined ? requirementValue : "",
          test_cases: 23,
        };
      }
      dispatch(
        updateRequirementData(
          {
            payload: {
              id: requirementdata?.id,
              requirement_id: stateData?.requirementId,
              field_data: res,
              test_cases: 23,
            },
          },

          onUpdateData
        )
      );
    },
  });
  const [errorPopup, setErrorPopup] = useState(false);

  const onUpdateData = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
        setSuccessPopup(false)
        window.scrollTo(0,0)      
      }, 2000);
    }
  };
  const isLocked =  selector?.locked=== true       

  useEffect(() => {
    dispatch(
      getRequirementData({ id: stateData?.requirementId }, function (
        result: any
      ) {
        result?.id && setActionType("update");
        setData({
          ...result,
          id: result?.id,
          requirement_id: stateData?.requirementId,
          Selection_Criteria:
            result?.field_data?.Selection_Criteria?.field_desc,
          Mode_or_Change_Detection:
            result?.field_data?.Mode_or_Change_Detection?.field_desc,
          For_changes_only_files_should_records_be_sent_when_entered_or_effective:
            result?.field_data
              ?.For_changes_only_files_should_records_be_sent_when_entered_or_effective
              ?.field_desc,
          Will_data_processed_by_this_integration_be_treated_as_sensitive:
            result?.field_data
              ?.Will_data_processed_by_this_integration_be_treated_as_sensitive
              ?.field_desc,
          How_will_Terminations_be_handled:
            result?.field_data?.How_will_Terminations_be_handled?.field_desc,
          How_are_new_hires_records_handled:
            result?.field_data?.How_are_new_hires_records_handled?.field_desc,
          How_are_rehire_records_handled:
            result?.field_data?.How_are_rehire_records_handled?.field_desc,
          Send_Active_Employees:
            result?.field_data?.Send_Active_Employees?.field_desc,
          Send_Contingent_Workers:
            result?.field_data?.Send_Contingent_Workers?.field_desc,
          Send_Terminations: result?.field_data?.Send_Terminations?.field_desc,
          How_are_transactions_grouped_or_sorted:
            result?.field_data?.How_are_transactions_grouped_or_sorted
              ?.field_desc,
          Does_the_integration_always_output_current_data_Any_future_transactions_Explain:
            result?.field_data
              ?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain
              ?.field_desc,
          Can_the_record_become_inactive:
            result?.field_data?.Can_the_record_become_inactive?.field_desc,
          what_happen_to_inactive_records:
            result?.field_data?.what_happen_to_inactive_records?.field_desc,
          what_type_of_data_will_be_sent:
            result?.field_data?.what_type_of_data_will_be_sent?.field_desc,
          Notes_and_Additional_Comments:
            result?.field_data?.Notes_and_Additional_Comments?.field_desc,
        });
      })
    );
  }, [setData]);

  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left  mt-2 mb-3 vendor_contact">
                  Data Requirement
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="mt-2 pull-left vendor_contact">Test case</div>
              </div> */}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Selection criteria
                    </label>
                    <textarea
                      id="Selection_Criteria"
                      name="Selection_Criteria"
                      value={requirementdata?.Selection_Criteria}
                      onChange={handleChange("Selection_Criteria")}
                      disabled={isLocked}
                      className="form-textarea"
                    ></textarea>
                     {errors?.Selection_Criteria && (
                      <p className="error-text">{errors?.Selection_Criteria}</p>
                    )}
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Mode / change detection
                    </label>
                    <select
                      id="modeofchangedetection"
                      name="modeofchangedetection"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.Mode_or_Change_Detection}
                      onChange={handleChange("Mode_or_Change_Detection")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>
                      {Mode.map((mode) => {
                        let performanceSelectedVal =
                          mode?.Mode_or_Change_Detection === mode.value
                            ? true
                            : false;
                        return (
                          <option
                            value={mode.key}
                            selected={performanceSelectedVal}
                          >
                            {mode.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      For changes only files, should records be sent when
                      entered or effective?
                    </label>
                    <select
                      id="effectiverecord"
                      name="effectiverecord"
                      className="form-select"
                      aria-label="Default select example"
                      value={
                        requirementdata?.For_changes_only_files_should_records_be_sent_when_entered_or_effective
                      }
                      onChange={handleChange(
                        "For_changes_only_files_should_records_be_sent_when_entered_or_effective"
                      )}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {FORCHANGEFILE.map((file) => {
                        let performanceSelectedVal =
                          file?.For_changes_only_files_should_records_be_sent_when_entered_or_effective ===
                          file.value
                            ? true
                            : false;
                        return (
                          <option
                            value={file.key}
                            selected={performanceSelectedVal}
                          >
                            {file.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Will data processed by this integration be treated as
                      sensitive?
                    </label>
                    <select
                      id="integrationsensitive"
                      name="integrationsensitive"
                      className="form-select"
                      aria-label="Default select example"
                      value={
                        requirementdata?.Will_data_processed_by_this_integration_be_treated_as_sensitive
                      }
                      onChange={handleChange(
                        "Will_data_processed_by_this_integration_be_treated_as_sensitive"
                      )}
                      disabled={isLocked}

                    >
                      <option value="">Select</option>

                      {DATAPROCESSED.map((data) => {
                        let performanceSelectedVal =
                          data?.Will_data_processed_by_this_integration_be_treated_as_sensitive ===
                          data.value
                            ? true
                            : false;
                        return (
                          <option
                            value={data.key}
                            selected={performanceSelectedVal}
                          >
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      How will terminations be handled?
                    </label>

                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="handletermination"
                      name="handletermination"
                      value={requirementdata?.How_will_Terminations_be_handled}
                      onChange={handleChange(
                        "How_will_Terminations_be_handled"
                      )}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {HIRE_RECORDS.map((hire) => {
                        let performanceSelectedVal =
                          hire?.How_will_Terminations_be_handled === hire.value
                            ? true
                            : false;
                        return (
                          <option
                            value={hire.key}
                            selected={performanceSelectedVal}
                          >
                            {hire.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      How are new hires records handled?
                    </label>
                    <select
                      id="handlerecords"
                      name="handlerecords"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.How_are_new_hires_records_handled}
                      onChange={handleChange(
                        "How_are_new_hires_records_handled"
                      )}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {HIRE_RECORDS.map((hire) => {
                        let performanceSelectedVal =
                          hire?.How_are_new_hires_records_handled === hire.value
                            ? true
                            : false;
                        return (
                          <option
                            value={hire.key}
                            selected={performanceSelectedVal}
                          >
                            {hire.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      How are rehire records handled?
                    </label>
                    <select
                      id="rehiresrecord"
                      name="rehiresrecord"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.How_are_rehire_records_handled}
                      onChange={handleChange("How_are_rehire_records_handled")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {HIRE_RECORDS.map((hire) => {
                        let performanceSelectedVal =
                          hire?.How_are_rehire_records_handled === hire.value
                            ? true
                            : false;
                        return (
                          <option
                            value={hire.key}
                            selected={performanceSelectedVal}
                          >
                            {hire.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Send active employees
                    </label>
                    <select
                      id="activeemployees"
                      name="activeemployees"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.Send_Active_Employees}
                      onChange={handleChange("Send_Active_Employees")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {DATAPROCESSED.map((data) => {
                        let performanceSelectedVal =
                          data?.Send_Active_Employees === data.value
                            ? true
                            : false;
                        return (
                          <option
                            value={data.key}
                            selected={performanceSelectedVal}
                          >
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Send contingent workers
                    </label>
                    <select
                      id="contingentworkers"
                      name="contingentworkers"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.Send_Contingent_Workers}
                      onChange={handleChange("Send_Contingent_Workers")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {DATAPROCESSED.map((data) => {
                        let performanceSelectedVal =
                          data?.Send_Contingent_Workers === data.value
                            ? true
                            : false;
                        return (
                          <option
                            value={data.key}
                            selected={performanceSelectedVal}
                          >
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Send terminations
                    </label>
                    <select
                      id="sendterminatinos"
                      name="sendterminatinos"
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.Send_Terminations}
                      onChange={handleChange("Send_Terminations")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {DATAPROCESSED.map((data) => {
                        let performanceSelectedVal =
                          data?.Send_Terminations === data.value ? true : false;
                        return (
                          <option
                            value={data.key}
                            selected={performanceSelectedVal}
                          >
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      How are transactions grouped or sorted?
                    </label>
                    <textarea
                      id="groupedtransactions"
                      name="groupedtransactions"
                      value={
                        requirementdata?.How_are_transactions_grouped_or_sorted
                      }
                      onChange={handleChange(
                        "How_are_transactions_grouped_or_sorted"
                      )}
                      className="form-textarea"
                      disabled={isLocked}
                    >
                      {requirementdata?.How_are_transactions_grouped_or_sorted}
                    </textarea>
                    {errors?.How_are_transactions_grouped_or_sorted && (
                      <p className="error-text">{errors?.How_are_transactions_grouped_or_sorted}</p>
                    )}
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Does the integration always output current data? any
                      future transactions? explain
                    </label>
                    <textarea
                      id="futuretransactions"
                      name="futuretransactions"
                      value={
                        requirementdata?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain
                      }
                      onChange={handleChange(
                        "Does_the_integration_always_output_current_data_Any_future_transactions_Explain"
                      )}
                      className="form-textarea"
                      disabled={isLocked}
                    >
                      {
                        requirementdata?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain
                      }
                    </textarea>
                    {errors?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain && (
                      <p className="error-text">{errors?.Does_the_integration_always_output_current_data_Any_future_transactions_Explain}</p>
                    )}
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Can the record become inactive?
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.Can_the_record_become_inactive}
                      onChange={handleChange("Can_the_record_become_inactive")}
                      disabled={isLocked}

                    >
                      <option value="">Select</option>
                      {DATAPROCESSED.map((data) => {
                        let performanceSelectedVal =
                          data?.Send_Terminations === data.value ? true : false;
                        return (
                          <option
                            value={data.key}
                            selected={performanceSelectedVal}
                          >
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      What happen to inactive records?
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={requirementdata?.what_happen_to_inactive_records}
                      onChange={handleChange("what_happen_to_inactive_records")}
                      disabled={isLocked}
                    >
                      <option value="">Select</option>

                      {HIRE_RECORDS.map((hire) => {
                        let performanceSelectedVal =
                          hire?.what_happen_to_inactive_recordsfwill ===
                          hire.value
                            ? true
                            : false;
                        return (
                          <option
                            value={hire.key}
                            selected={performanceSelectedVal}
                          >
                            {hire.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      What type of data will be sent?
                    </label>
                    <textarea
                      id="datatype"
                      name="datatype"
                      value={requirementdata?.what_type_of_data_will_be_sent}
                      onChange={handleChange("what_type_of_data_will_be_sent")}
                      disabled={isLocked}
                      className="form-textarea"

                    >
                      {requirementdata?.what_type_of_data_will_be_sent}
                    </textarea>
                    {errors?.what_type_of_data_will_be_sent && (
                      <p className="error-text">{errors?.what_type_of_data_will_be_sent}</p>
                    )}
                  </div>
                  <div className="form-group ">
                    <label htmlFor="name" className="form-label customername">
                      Notes and Additional Comments
                    </label>
                    <textarea
                      id="datatype"
                      name="datatype"
                      value={requirementdata?.Notes_and_Additional_Comments}
                      onChange={handleChange("Notes_and_Additional_Comments")}
                      disabled={isLocked}
                      className="form-textarea"
                    >
                      {requirementdata?.Notes_and_Additional_Comments}
                    </textarea>
                    {errors?.Notes_and_Additional_Comments && (
                      <p className="error-text">{errors?.Notes_and_Additional_Comments}</p>
                    )}
                  </div>
                  <div className="btn-block">
        { props?.toggleValue === false && isLocked === false && ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType  ?  

          <button type="submit" className="thame-btn w-100">
                      Update
                    </button>:null}
                  </div>
                </div>

                {/* <div className="col-md-2">
                  <div className="testcase_list_wrapper">
                    <div className="test_case_list">
                      <div className="test_case_list pt-5">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#testcaselist"
                        >
                          test
                        </a>
                      </div>
                      <div className="test_case_list pt-6">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#testcaselistfill"
                        >
                          test 2
                        </a>
                      </div>
                      <div className="test_case_list mt-4">
                        <a href="#">3</a>
                      </div>
                      <div className="test_case_list mt-4">
                        <a href="#">4</a>
                      </div>
                      <div className="test_case_list mt-3">
                        <a href="#">5</a>
                      </div>
                      <div className="test_case_list mt-2">
                        <a href="#">6</a>
                      </div>
                      <div className="test_case_list mt-2">
                        <a href="#">7</a>
                      </div>
                      <div className="test_case_list">
                        <a href="#">8</a>
                      </div>
                      <div className="test_case_list mt-2">
                        <a href="#">9</a>
                      </div>
                      <div className="test_case_list mt-1">
                        <a href="#">10</a>
                      </div>
                      <div className="test_case_list pt-8">
                        <a href="#">11</a>
                      </div>
                      <div className="test_case_list mt-4 pt-10">
                        <a href="#">12</a>
                      </div>
                      <div className="test_case_list mt-1 pt-6">
                        <a href="#">13</a>
                      </div>
                      <div className="test_case_list mt-1 pt-5">
                        <a href="#">14</a>
                      </div>
                      <div className="test_case_list mt-1 pt-6">
                        <a href="#">15</a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
        <CustomModal
          title="Requirement Data"
          onOpen={errorPopup}
          content={"Error!Please try again"}
          modalType={"error"}
          width={"sm"}
          OnClickCloseIcon={() => {
            setErrorPopup(false);
          }}
        />
        <CustomModal
          title="Requirement Data"
          onOpen={successPopup}
          content={"Requirement Data saved!"}
          modalType={"success"}
          width={"sm"}
          OnClickCloseIcon={() => {
            setSuccessPopup(false);
          }}
        />
          <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </React.Fragment>
  );
};
export default DataTab;

import { combineReducers } from "redux";
import auth from "./auth";

import apiDebugger from "./apiDebugger";
import configuration from "./configuration";
import isConnected from "./isConnected";
import loaderState from "./loaderState";
import taskDetails from "./Task/index";
import requirementDetails from "./Requirement/requirement";
const rootReducer = combineReducers({
  auth,
  apiDebugger,
  configuration,
  isConnected,
  loaderState,
  taskDetails,
  requirementDetails,
  
});

export default rootReducer;

import React from "react"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  Container,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";

import { Search, SearchIconWrapper, StyledInputBase } from "./Search";
import ProfileMenuItems from "./profileMenuItems";
import MenuItemList from "./menuItem";

import LOGO_ICON from "./logo.png";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMsal } from "@azure/msal-react";

import { customStyleOnTab, menuItemStyle, tabStyle } from "./style";
import CustomModal from "../CustomModalPop";
import { logoutUserAsync } from "../../api/common";
import { LogoutUser, ssoLogout } from "../../store/actions/auth";
import setLoader from "../CustomLoader/loaderState";

const CustomHeader = () => {
  const header = MenuItemList();
  const profileMenuItems = ProfileMenuItems();
  const [successPopup, setSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData;
  });
  let isSSOLogged = useSelector((store: any) => {
    return store.auth.isSSOLogin;
  });  
  const [state, setState] = useState<any>({
    value: 0,
    open: false,
    anchorEl: null,
    profileOpen: false,
    profileAnchorEl: null,
    valueName: "",
  });

  const handleMenuOpen = (index: any, name: any, event: any) => {
    const { currentTarget } = event;
    setState({
      open: true,
      anchorEl: currentTarget,
      value: index,
      profileOpen: false,
      profileAnchorEl: null,
      valueName: name,
    });
  };

  const handleOpenUserMenu = (index: any, name: any, event: any) => {
    const { currentTarget } = event;
    setState({
      open: false,
      anchorEl: null,
      value: index,
      profileOpen: true,
      profileAnchorEl: currentTarget,
      valueName: name,
    });
  };

  const handleMenuClose = () => {
    setState({
      value: 0,
      open: false,
      anchorEl: null,
      profileOpen: false,
      profileAnchorEl: null,
      valueName: "",
    });
  };
  const filteredMenu = header.filter((menuVal: any) => {
    return menuVal.name === state.valueName;
  });
  const logoutUser = () => {
    dispatch(
      LogoutUser({}, function (res: any) {
        if (res === true) {
          // alert("Logged out Successfully");
          navigate("/");
        }
      })
    );
  };
  const ssoLogoutUser = () => {
    dispatch(
      LogoutUser({}, function (res: any) {
        // if (res === true) {
          isSSOLogged = false;
          instance?.logoutRedirect();
          // alert("Logged out Successfully");
          // navigate("/");
        // }
      })
    );
    //  dispatch( ssoLogout() );
    
    
    };
  const { instance } = useMsal();


  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const jobTitle = useSelector((store: any) => {
    return store.auth.globalAuthData?.profile?.jobTitle;
  });
  return (
    <div>
      <AppBar
        position="static"
        className="navbar navbar-expand-lg navbar-dark bg-dark"
        onMouseLeave={handleMenuClose}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ minHeight: "90px !important" }}>
            <Typography
              noWrap
              sx={{ display: { xs: "none", md: "flex" }, ml: -1, mr: 1 }}
            >
              <img src={LOGO_ICON} />
            </Typography>
            <Tabs
              value={state.value}
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="mb-2 mb-lg-0"
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
              aria-label="simple tabs example"
            >
              {header.map((menu: any, index: any) => {
                return (
                  <>
                  {menu?.access.includes(getProfileType) && <Tab
                      key={index}
                      onMouseEnter={(e) => handleMenuOpen(index, menu.name, e)}
                      onClick={() => {
                        if (menu.path) {
                          navigate(menu.path, { replace: true });
                        }
                      }}
                      data-key={index}
                      label={<span style={customStyleOnTab}>{menu.name}</span>}
                      aria-owns={state.open ? `${menu.name}` : undefined}
                      aria-haspopup={"true"}
                      className="nav-link"
                      sx={tabStyle}
                    />}
                    <Popper
                      key={menu.name}
                      open={state.open}
                      anchorEl={state.anchorEl}
                      id="menu-list-grow"
                      sx={{ zIndex: 9999 }}
                    >
                      <Paper
                        sx={{
                          backgroundColor: "#393939",
                          marginTop: "28px",
                        }}
                      >
                        <MenuList key={menu.name}>
                          {filteredMenu.map((item: any, index: any) => {
                            return item?.menuItems?.map(
                              (val: any, index: any) => {
                                return (
                                  <>{val?.access.includes(getProfileType) && <MenuItem
                                    sx={menuItemStyle}
                                    key={val.id}
                                    onClick={() => {
                                      if (
                                        val.path === "/create-task" ||
                                        val.path === "/customer-create" ||
                                        val.path === "/project-create"
                                      ) {
                                        window.location.href = val.path;
                                      }
                                      else if(val?.externalLink === true){
                                       window.open(val?.path,"_blank")
                                      }
                                       else {
                                        navigate(val.path, { replace: true });
                                      }
                                    }}
                                  >
                                    {val.title}
                                  </MenuItem>}</>
                                );
                              }
                            );
                          })}
                        </MenuList>
                      </Paper>
                    </Popper>
                  </>
                );
              })}
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "#B4B2B2" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
            </Tabs>

            <Box sx={{ flexGrow: 0, ml: 2 }}>
              <>
                <Tab
                  key={1}
                  label={
                    <Grid
                      className="d-inline-flex justify-content-center"
                      onMouseEnter={(e) => handleOpenUserMenu(0, "setting", e)}
                      sx={{
                        marginLeft: "20px",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid className="user_profile">
                        <Avatar
                          alt="avatar"
                          src={""}
                          sx={{ width: 50, height: 50 }}
                        >
                          {userDetail?.firstname?.charAt(0)?.toUpperCase() +
                            " " +
                            userDetail?.lastname?.charAt(0)?.toUpperCase()}
                        </Avatar>
                        <span className="user_profile_green"></span>
                      </Grid>
                      <Grid className="d-flex flex-column mx-2 my-2 text-capitalize text-start">
                        <Grid
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          welcome
                        </Grid>
                        <Grid
                          sx={{
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                          className="my-1"
                        >
                          {userDetail?.firstname?.charAt(0)?.toUpperCase() +
                            userDetail?.firstname?.slice(1) +
                            " " +
                            userDetail?.lastname?.charAt(0)?.toUpperCase() +
                            userDetail?.lastname?.slice(1)}
                        </Grid>
                      </Grid>
                      <Grid className="my-3">
                        <KeyboardArrowDownIcon sx={{ color: "white" }} />
                      </Grid>
                    </Grid>
                  }
                  aria-owns={state.profileOpen ? `setting` : undefined}
                  aria-haspopup={"true"}
                  className="nav-link"
                  sx={tabStyle}
                />
                <Popper
                  key={state.value}
                  open={state.profileOpen}
                  anchorEl={state.profileAnchorEl}
                  id="menu-setting"
                  sx={{
                    zIndex: 9999,
                    width: "20%",
                    marginLeft: "20px !important",
                  }}
                >
                  <Paper
                    sx={{
                      backgroundColor: "#393939",
                      marginTop: "20px",
                    }}
                  >
                    <MenuList key={"setting"}>
                      {profileMenuItems.map((item: any, index: any) => {
                        return item?.profileMenuItems?.map(
                          (val: any, index: any) => {
                            // val?.title != "Designation" && isSSOLogin 
                            return (
                    !isSSOLogged &&  val?.title === "Designation" ? null :
                              <MenuItem
                                sx={{
                                  ...menuItemStyle,
                                  "&:hover": {
                                    background: "none",
                                    color: val?.path ? "#86BC25" : "none",
                                  },
                                }}
                                key={val.id}
                                onClick={() => {
                                  if (val?.path) {
                                    navigate(val?.path, { replace: true });
                                  } 
                                  else if (val?.id === "6" && isSSOLogged === true) {                                    
                                    ssoLogoutUser()
                                   
                                  }
                                  else if (val?.id === "6") {
                                    logoutUser();
                                  }
                                  window.location.reload(true)
                                }}
                                className="d-flex flex-row justify-content-between"
                              >
                              
                                <Grid container>
                              <Grid md={8}>{val.title}</Grid>
                                  {val.showProfileName ? (
                                    <Grid md={4} textAlign="end">
                                      {/* {userDetail?.emailid} */}
                                    </Grid>
                                  ) : val.showProfileDesignation  ? (
                                    <Grid md={0} textAlign="end">
                                     {jobTitle}
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </MenuItem>
                            );
                          }
                        );
                      })}
                    </MenuList>
                    <CustomModal
                      title="LogOut"
                      onOpen={successPopup}
                      content={"Logged Out Successfully"}
                      modalType={"success"}
                      width={"sm"}
                      OnClickCloseIcon={() => {
                        setSuccessPopup(false);
                      }}
                    />
                  </Paper>
                </Popper>
              </>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default CustomHeader;

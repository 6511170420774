const columns: any = () => {
  return [
    {
      id: "type",
      label: "Type",
      minWidth: 30,
      align: "left",
    },
    {
      id: "field_order",
      label: "Sr. no",
      minWidth: 30,
      align: "left",
    },
    {
      id: "Target_System_Field_Name",
      label: "Target system field name",
      minWidth: 30,
      align: "left",
    },
    {
      id: "Business_Description",
      label: "Business Description",
      minWidth: 30,
      align: "left",
    },
    {
      id: "Workday_Field_Name",
      label: "Workday Field Name",
      minWidth: 30,
      align: "left",
    },
    {
      id: "Required",
      label: "Required",
      minWidth: 30,
      align: "center",
    },
    {
      id: "Max_Length",
      label: "Max Length",
      minWidth: 30,
      align: "left",
    },
    {
      id: "ValuesorField_Logic",
      label: "Values/Field Logic",
      minWidth: 30,
      align: "left",
    },
    // {
    //   id: 'Mapping',
    //   label: 'Mapping',
    //   minWidth: 30,
    //   align:"center"

    // }
  ];
};

export default columns;

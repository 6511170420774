import { call, put, takeLeading } from "redux-saga/effects";
import {
  createTaskResourceAsync,
  searchUserAsync,
  taskDeleteResourceAsync,
  taskResourceAsync,
  taskResourceUpdateAsync,
  saveUnitTesting,
  getAllUnitTesting
} from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";

import { taskActions, setTaskResources } from "../../actions/Task";
import { actions } from "../../actions/Project";

function* SignupTaskResource(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType === "create"
        ? createTaskResourceAsync
        : taskResourceUpdateAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskResources({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* TaskResources(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(taskResourceAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setTaskResources({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* TaskResourceDelete(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(taskDeleteResourceAsync, payload);
    console.log("deleted data", res.data);

    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* SearchUsers(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(searchUserAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setUserDetails({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* UnitTesting(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(saveUnitTesting, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log("error", error)
    callback(false);
  }
}

function* UnitTestingFetch(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getAllUnitTesting, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    console.log("error", error)
    callback(false);
  }
}

export function* watchTaskResource() {
  yield takeLeading(actions.GET_USER_BY_NAME, SearchUsers);
  yield takeLeading(taskActions.CREATE_TASK_RESOURCES, SignupTaskResource);
  yield takeLeading(taskActions.GET_TASK_RESOURCES, TaskResources);
  yield takeLeading(taskActions.UPDATE_TASK_RESOURCES, SignupTaskResource);
  yield takeLeading(taskActions.DELETE_TASK_RESOURCES, TaskResourceDelete);
  yield takeLeading(taskActions.CREATE_UNIT_TESTING, UnitTesting);
  yield takeLeading(taskActions.GET_UNIT_TESTING, UnitTestingFetch);
}

import { textAlign } from "@mui/system";
import React, { useState, useRef, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../../components/CustomLoader/loaderState";
import CustomModal from "../../../../components/CustomModalPop";
import {
  errorMessages,
  REPLACE_REGEX,
  TASK_FILE_CATEGORY,
  TASK_FILE_VIEW_BY,
} from "../../../../constants/common";
import { useForm } from "../../../../helpers/validations/useForm";
import {
  downloadTaskFiles,
  generateSignedUrl,
  getTaskFiles,
  uploadTaskFiles,
} from "../../../../store/actions/Task";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { ProfileType } from "../../../../utility/commonTypes";

interface TaskFiles {
  id: any;
  file_name: any;
  location: any;
  task_uploaded_file: any;
  uploaded_by: any;
  task_id: any;
  timestamp: any;
  comments: any;
  category: any;
  view_by: any;
  actionType: any;
  task_technical: any;
  keyForPSUrl: any;
  url: any;
}
const TaskFilesForm: FC = ({
  row,
  openModalPop,
  setOpenModalPop,
  handleClose,
  onSuccess,
}: any) => {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("create");
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const location: any = useLocation();
  const params: any = useParams();
  const stateData = params;
  const [open, setOpen] = useState(false);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    handleSubmit,
    handleChange,
    handleFileUpload,
    data: taskFiles,
    setData,
    errors,
  } = useForm<TaskFiles>({
    validations: {
      category: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      view_by: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      comments:{
        pattern:{
            value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
            message:errorMessages?.pattern()
          },
          maxlength: {
            value: 100,
            message: errorMessages?.maxlength("Description",100)
          },
        },
      },
      onError: (errors: any) => {
        console.log({ errors }, Object.keys(errors).length);
        const isError = Object.keys(errors).length;
        if (isError) {
          setOpen(true);
        }
      },
    onSubmit: () => {
      console.log("details",taskFiles)
      setLoader(true);
      const formData = new FormData()
      formData.append("file",taskFiles?.task_uploaded_file)
      formData.append("filename",taskFiles?.task_uploaded_file?.name)
      formData.append("actionType",actionType)

      console.log("form details",formData)
      let jsonFormat = {
        id: taskFiles?.id,
        uploaded_by: taskFiles?.uploaded_by,
        comments: taskFiles?.comments,
        category: taskFiles?.category,
        view_by: taskFiles?.view_by,
        filename:taskFiles?.task_uploaded_file?.name,
        task_uploaded_file: taskFiles?.task_uploaded_file,
        location: taskFiles?.location,
        // keyPSUrl:taskFiles?.keyForPSUrl,
        task_technical: 0,
        actionType: actionType,
      };
      
      if (
        taskFiles?.task_uploaded_file?.size === undefined &&
        taskFiles?.task_uploaded_file?.name === undefined
      ) {
        delete jsonFormat.task_uploaded_file;

      }
      
      // dispatch(
      //   uploadTaskFiles(
      //     {
      //       jsonFormat,
      //     },
      //     stateData?.taskId,
      //     onCreateTaskFilesCallback
      //   )
      // );
      dispatch(generateSignedUrl(
       
        jsonFormat,
        stateData?.taskId,

      
      onCreateTaskFilesCallback

      ));
    },
  });

  const onCreateTaskFilesCallback = (data: any, error: any) => {
    setLoader(false);
    if (data == false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      onSuccess();
    }
    setOpenModalPop(false);
   setLoader(false)
  };
  useEffect(() => {
    if (row && row?.id) {
      console.log("details", row);
      row?.id && setActionType("update");
      setData({
        id: row?.id,
        uploaded_by: row?.uploaded_by,
        comments: row?.comments,
        category: row?.category,
        view_by: row?.view_by,
        file_name: row?.file_name,
        keyForPSUrl: row?.keyPSUrl,
        url: row?.url,
        location: row?.location,
      });
    } else {
      setActionType("create");
      setData({
        uploaded_by: "",
        comments: "",
        category: "",
        view_by: "",
        task_uploaded_file: "",
        location: "",
      });
    }
  }, [setData, row]);

  return (
    <>
      <CustomModal
        title="Upload File"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      onChange={handleChange("comments")}
                      name="type"
                      id="type"
                      value={taskFiles?.comments}
                      className="form-control"
                    />
                     {errors.comments && (
                      <p className="error-text">{errors.comments}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Category *</label>
                    <select
                      onChange={handleChange("category")}
                      className="form-control"
                      value={taskFiles?.category}
                    >
                      <option value="">--Select--</option>
                      {TASK_FILE_CATEGORY.map((category) => (
                        <option value={category.key}>{category.label}</option>
                      ))}
                    </select>
                    {errors.category && (
                      <p className="error-text">{errors.category}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">View By *</label>
                    <select
                      onChange={handleChange("view_by")}
                      className="form-control"
                      value={taskFiles?.view_by}
                    >
                      <option value="">--Select--</option>
                      {TASK_FILE_VIEW_BY.map((view) => (
                        <option value={view.key}>{view.label}</option>
                      ))}
                    </select>
                    {errors.view_by && (
                      <p className="error-text">{errors.view_by}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Upload File</label>
                    <input
                      type="file"
                      name="task_uploaded_file"
                      onChange={handleFileUpload}
                      className="form-control"
                    />
                    <a href="#">{taskFiles?.file_name}</a>
                  </div>
                </div>
                {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType  ? 

                <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                      onClick={() => {
                        setLoader(false);
                      }}
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>:null}
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title="Task Files"
        onOpen={errorPopup}
        content={message||"File Upload Failed"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Files"
        onOpen={successPopup}
        content={"Task Files Uploaded Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};
export default TaskFilesForm;

import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { projectDateAsync, projectDateUpdateAsync } from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import { actions } from "../../actions/Project";
import { APIResponse } from "../../../utility/commonTypes";

import { setProjectDates } from "../../actions/Project";

function* ProjectDates(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(projectDateAsync, payload);
    if (res?.status === 200) {
      //yield put(setProjectDates({ ...res.data }, function () {}));
      callback(res?.data);
      // callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* SignupProjectDates(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(projectDateUpdateAsync, payload);
    if (res?.status === 201) {
    } else if (res?.status === 200) {
      //yield put(setProjectDates({ ...res.data }, function () {}));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchProjectDates() {
  // yield takeLeading(actions.CREATE_PROJECT_DATES,SignupProjectDates)
  yield takeLeading(actions.UPDATE_PROJECT_DATES, SignupProjectDates);

  yield takeEvery(actions.GET_PROJECT_DATES, ProjectDates);
}

import { forwardRef, useImperativeHandle, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const CustomLoader = forwardRef((props, ref) => {
    const [isloaderEnabled, setLoaderEnabled] = useState(false);

    useImperativeHandle(ref, () => ({
        setLoader(value: boolean) {
            setLoaderEnabled(value);
        },
    }));

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isloaderEnabled}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
});
export default CustomLoader;

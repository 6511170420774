import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { getAllUsers, getNewTokenAsync, loginUserAsync, logoutUserAsync, ssoLoginAsync } from "../../../api/common/index";
import { setAuthToken, userLogoutSuccess } from "../../actions/auth";
import { actions } from "../../actions/User";

import { APIResponse } from "../../../utility/commonTypes";
import setLoader from "../../../components/CustomLoader/loaderState";
import { getStore } from "../../../api/apiClient";
import timeoutService, { clearTimeoutObject } from "../../../utility/timeoutService/timeoutService";
import { setIdleTimeFromStorage } from "../../../utility/idelTimer";

function* loginUser(action: any) {
  const { payload, callback } = action;
  try {
    setLoader(true);
    let res: APIResponse = payload?.payload?.isSSOLogin ? yield call(ssoLoginAsync, payload?.payload?.token) : yield call(loginUserAsync, payload)
    if (res?.status === 200) {
      yield put(
        setAuthToken({ ...res.data, refreshTime: new Date().getTime() / 1000 ,isSSOLogin:payload?.payload?.isSSOLogin})
      );
      setIdleTimeFromStorage(new Date().getTime().toString())
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
    setLoader(false);
  } catch (error) {
    setLoader(false);
    callback(false);
  }
}

function* logoutUser(action: any) {
  const { payload, callback } = action;
  try {
    setLoader(true);
    let res: APIResponse = yield call(logoutUserAsync, payload);
    if (res?.status === 200) {
      // yield put(userLogoutSuccess({ ...res?.data }));
      callback(true);
      
    } else {
    const store=getStore()
      store?.dispatch(
        userLogoutSuccess(() => {})
        
      );
      console.log("should redirect to login");
      window.location.href = "/Login";
    }
 clearTimeoutObject?.()
 sessionStorage.clear()

    setLoader(false);
  } catch (error) {
    setLoader(false);
    callback(false);
  }
}

function* getNewToken(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getNewTokenAsync, payload);
    if (res?.status === 200) {
      yield put(
        setAuthToken({ ...res.data, refreshTime: new Date().getTime() / 1000 })
      );
      callback(true);
    } else {
       callback(false);
    } 
  } catch (error) {
    alert("Your session was already expired, Please try after login again.")
    const store=getStore()
    store?.dispatch(
      userLogoutSuccess(() => {})
      
    );
    console.log("should redirect to login");
    window.location.href = "/Login";
  
    console.log("error is",error)
    callback(false);
  }
}
function* getAllUserList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getAllUsers, payload);
    if (res?.status === 200) {
      // yield put(
      //   setAuthToken({ ...res.data, refreshTime: new Date().getTime() / 1000 })
      // );
      callback(res?.data);
    } else {
       callback(false);
    } 
  } catch (error) {
    callback(false);
  }
}

export function* watchAuth() {
  yield takeLeading(actions.LOGIN_USER, loginUser);
  yield takeLatest(actions.LOGOUT_USER, logoutUser);
  yield takeLatest(actions.GET_NEW_TOKEN, getNewToken);
  yield takeLatest(actions.ALL_USER_LIST, getAllUserList);

}

import { call, put, takeLeading } from "redux-saga/effects";
import { deleteTaskFiles, downloadTaskFiles, GetTaskFilesAsync, taskFilesFunctionality, updateTaskFilesAsync } from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import {  taskActions } from "../../actions/Task";
import { APIResponse } from "../../../utility/commonTypes";

function* UploadTaskFiles(action: any) {
  const { payload, task_id, callback } = action;
  console.log("payload is", payload);
  console.log("id", task_id);
  try {
    let res: APIResponse = yield call(payload.task_uploaded_file!=undefined
    ?taskFilesFunctionality
    :updateTaskFilesAsync, payload, task_id);
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } 
   else if (res?.status === 200) {
      callback(res?.data);
    }else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* getTaskFiles(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(GetTaskFilesAsync, payload);
    console.log(res.data);

    if (res?.status === 200) {
      
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* deleteFileTask(action:any) {
  const { payload, callback } = action;

  try {

      let res:APIResponse = yield call(deleteTaskFiles,payload)
      console.log(res.data)
      if (res?.status === 200) {
          callback(res?.data)
         
      }

  }
  catch (error) {
      console.log(error)
  }
}
function* downloadFileTask(action:any) {
  const { payload, callback } = action;

  try {

      let res:APIResponse = yield call(downloadTaskFiles,payload)
      console.log(res.data)
      if (res?.status === 200) {
          callback(res?.data)
         
      }

  }
  catch (error) {
      console.log(error)
  }
}

export function* watchTaskFiles() {
  yield takeLeading(taskActions.GENERATE_SIGNED_URL, UploadTaskFiles);
  yield takeLeading(taskActions.UPLOAD_TASK_FILES, UploadTaskFiles);
  yield takeLeading(taskActions.UPDATE_TASK_FILES, UploadTaskFiles);
  yield takeLeading(taskActions.GET_TASK_FILES, getTaskFiles)
  yield takeLeading(taskActions.DELETE_TASK_FILES, deleteFileTask)
  yield takeLeading(taskActions.DOWNLOAD_TASK_FILES, downloadFileTask)

}

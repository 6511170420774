export const activeTabClass = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#000',
  border: "1px solid transparent",
  textTransform: 'capitalize',
  borderBottom: "1px solid #007CB0",
  '&:hover': {
    border: "1px solid #007CB0",
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    textTransform: 'capitalize',
  },
  '&.Mui-selected': {
    textTransform: 'capitalize',
    border: "1px solid #007CB0",
    borderBottom: "1px solid transparent",
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
  },

}
export const blankTabClass = {
  border: "1px solid transparent",
  borderBottom: "1px solid #007CB0",
}
import { actions } from '../../actions/loaderState';

const initialState = {
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.IS_LOADING:
            return { isLoading: action?.isLoading };
        default:
            return state;
    }
}

import { actions } from "../../actions/isConnectedStatus";

const initState = true;

const isConnected = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_CONNECTED_STATUS:
      return action?.isConnected;
    default:
      return state;
  }
};

export default isConnected;

import { call } from "redux-saga/effects";
import {
  getTaskByCustomerData,
  getTasksData,
  getMyTasksData,
  getTaskByCustomerProjectHealth,
  getTaskByCustomerProjectHealthRatio,
} from "../../../../api/Tasks";
import { APIResponse } from "../../../../utility/commonTypes";

export function* FetchMyTasksLists(action: any): any {
  const { payload, callback } = action;
  console.log("FetchMyTasksLists--->", payload);

  try {
    const isMyTask = payload?.isMyTask;
    const res: APIResponse = yield call(
      isMyTask === 1 ? getMyTasksData : getTasksData,
      payload
    );
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchProjectByCustomerLists(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getTaskByCustomerData, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchProjectByCustomerHealth(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(
      getTaskByCustomerProjectHealth,
      payload
    );
    console.log("FetchProjectByCustomerHealth res", res);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchProjectByCustomerHealthRatio(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(
      getTaskByCustomerProjectHealthRatio,
      payload
    );
    console.log("getTaskByCustomerProjectHealthRatio res", res);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {  TASK_STATUS, TEMPLATE_DROPDOWNS } from "../../../constants/common";
import { formatDate } from "../../../utility/idelTimer";
const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const Columns = (handleUserDetails:any) => {
  const location: any = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  console.log(">>>>>>>>>>>>>>>>>>>");
  console.log({ location, params });
  console.log(">>>>>>>>>>>>>>>>>>>");
  return [
    {
      field: "integration_id",
      headerName: "Id",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {
              <Tooltip title={params?.row?.integration_id} followCursor>
                <Link
                  to={`/create-update-task/${
                    stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null
                  }/${
                    stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null
                  }/${params?.row?.id}`}
                  state={{
                    subProject: stateData?.subProjectId ? true : false,
                    projectId: stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null,
                    customerId: stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null,
                    action: "create",
                    taskId: params?.row?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.integration_id}
                </Link>
              </Tooltip>
            }
          </>
        );
      },
    },
    {
      field: "task_name",
      headerName: "Task Name",
      // headerTooltip:'Task Name',
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {
              <Tooltip title={params?.row?.task_name} followCursor>
                <Link
                  to={`/create-update-task/${
                    stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null
                  }/${
                    stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null
                  }/${params?.row?.id}`}
                  state={{
                    subProject: stateData?.subProjectId ? true : false,
                    projectId: stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null,
                    customerId: stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null,
                    action: "create",
                    taskId: params?.row?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.task_name}
                </Link>
              </Tooltip>
            }
          </>
        );
      },
    },

    {
      field: "task_status",
      headerName: "Task Status",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = TASK_STATUS.find(
          (o) => o.key === params?.row?.task_status
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );

      },    },

    {
      field: "health",
      headerName: "Health",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.health || "NA",
    },
    {
      field: "estimated_hours",
      headerName: "Est . Hours",
      minWidth: 115,
      ...commonColumnsDef,
    },

    {
      field: "flow_of_integration_workday",
      headerName: "Direction",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) =>
        params?.row?.Requirement?.flow_of_integration_workday || "NA",
    },
    {
      field: "OwnedBy",
      headerName: "Owned By",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) =>{
        return (
          params?.row?.Resources[0]?.User?.OwnedBy ? 
              <a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.Resources[0]?.user_id);return false} }
              >
              {params?.row?.Resources[0]?.User?.OwnedBy} 
              </a> 
          : "NA"
      )
      // <Tooltip title={params?.row?.Resources[0]?.User?.OwnedBy} followCursor>
      //   <div>
      //   {params?.row?.Resources[0]?.User?.OwnedBy || "NA"}
      //   </div>
      //   </Tooltip>
      }
    },
    {
      field: "requirement_template",
      headerName: "Template",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return(
          <>
          {
 <Tooltip title={params?.row?.Requirement?.requirement_template?.replace(/_/g, ' ')} followCursor>
        <div>
        {params?.row?.Requirement?.requirement_template?.replace(/_/g, ' ')||"NA"}
        </div>
        </Tooltip>
          }
          </>
        );

       
       
      //   const functionalArea=params?.row?.Requirement?.functional_area
      //   const template_functional_area=TEMPLATE_DROPDOWNS?.find(o=>o.key === functionalArea)
      //   {console.log("template",template_functional_area)}

      //   const searchVal=template_functional_area?.subFields?.find(o=>o.key === params?.row?.Requirement?.requirement_template)
      //  {console.log("template",searchVal)}

      //   return searchVal?.value ? (
      //     <Tooltip title={searchVal?.value} followCursor>
      //       <div>{searchVal?.value}</div>
      //     </Tooltip>
      //   ) : (
      //     "NA"
      //   );
      }
      },
    {
      field: "task_completion_percentage",
      headerName: "Req % Complete",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) =>
        params?.row?.task_completion_percentage || "NA",
    },
    {
      field: "PendingItems",
      headerName: "Pending Items",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.PendingItems?.length || "NA",
    },
    {
      field: "go_live_date",
      headerName: "Go-Live Date",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) =>
    
      <Tooltip title={params?.row?.TaskProgresses[0]?.go_live_date ?formatDate(params?.row?.TaskProgresses[0]?.go_live_date):"NA"} followCursor>
       <div>
        {params?.row?.TaskProgresses[0]?.go_live_date ?formatDate(params?.row?.TaskProgresses[0]?.go_live_date):"NA"}
             </div>
         </Tooltip>
    },
  ];
};

export default Columns;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getRequirementTest,
  updateRequirementTest,
} from "../../../store/actions/Requirement";
import { ProfileType } from "../../../utility/commonTypes";

export interface RequirementTest {
  id: any;
  field_data: any;
  requirement_id: any;

  Is_a_test_environment_available_or_is_one_required: any;
  Are_there_any_Dependencies_or_timing_requirements_for_testing: any;
  Recommended_number_of_testing_iterations: any;
  Test_PlanorProtocol: any;
  Notes_and_Additional_requirements: any;
}
const Test = () => {
  const [successPopup, setSuccessPopup] = useState(false);
  const location = useLocation();
  const params: any = useParams();
  const stateData = location.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const { handleSubmit, handleChange, data: test,errors, setData } = useForm<
    RequirementTest
  >({
    validations: {
      Is_a_test_environment_available_or_is_one_required:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Industry Should be less than 50 character length",
        },
      },
      Are_there_any_Dependencies_or_timing_requirements_for_testing:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Industry Should be less than 50 character length",
        },
      },
      Recommended_number_of_testing_iterations:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Industry Should be less than 50 character length",
        },
      },
      Test_PlanorProtocol:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      Notes_and_Additional_requirements:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      }
    },
    onSubmit: () => {
      // setLoader(true);
      let res: any = {};
      console.log("test data is", test);
      for (const i in test) {
        const testValue = test[i];
        res[i] = {
          field_desc: testValue !== undefined ? testValue : "",
          test_cases: 23,
        };
      }
      dispatch(
        updateRequirementTest(
          {
            payload: {
              id: test?.id,
              requirement_id: stateData?.requirementId,
              field_data: res,
              test_cases: 23,
            },
          },

          onUpdateTest
        )
      );
    },
  });

  const [errorPopup, setErrorPopup] = useState(false);
  const onUpdateTest = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
    }
    setTimeout(() => {
     setSuccessPopup(false)
     window.scrollTo(0,0)
    }, 2000);
  };
  useEffect(() => {
    dispatch(
      getRequirementTest({ 
        id: stateData?.requirementId 
      }, function(result: any) {
        setData((prevData) => {
          return {
            ...prevData,
            Is_a_test_environment_available_or_is_one_required:
              result?.field_data
                ?.Is_a_test_environment_available_or_is_one_required?.field_desc,
            Are_there_any_Dependencies_or_timing_requirements_for_testing:
              result?.field_data
                ?.Are_there_any_Dependencies_or_timing_requirements_for_testing?.field_desc,
            Recommended_number_of_testing_iterations:
              result?.field_data?.Recommended_number_of_testing_iterations?.field_desc,
            Test_PlanorProtocol: result?.field_data?.Test_PlanorProtocol?.field_desc,
            Notes_and_Additional_requirements:
              result?.field_data?.Notes_and_Additional_requirements?.field_desc,
          };
        });
      })
    );
  }, [setData]); 


  return (
    <div className="container-fluid ">
      <div className="col-xl-10 col-xxl-7">
        <div className="frame_outer">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left mt-2 mb-3 vendor_contact">
                  Test Requirement
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="mt-2 pull-left vendor_contact">Test case</div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Is a test environment available or is one required ?
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    value={
                      test?.Is_a_test_environment_available_or_is_one_required
                    }
                    onChange={handleChange(
                      "Is_a_test_environment_available_or_is_one_required"
                    )}
                    placeholder=" "
                  />
                   {errors?.Is_a_test_environment_available_or_is_one_required && (
                      <p className="error-text">{errors?.Is_a_test_environment_available_or_is_one_required}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Are there any Dependencies or timing requirements for
                    testing?
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    value={
                      test?.Are_there_any_Dependencies_or_timing_requirements_for_testing
                    }
                    onChange={handleChange(
                      "Are_there_any_Dependencies_or_timing_requirements_for_testing"
                    )}
                    placeholder=" "
                  />
                   {errors?.Are_there_any_Dependencies_or_timing_requirements_for_testing && (
                      <p className="error-text">{errors?.Are_there_any_Dependencies_or_timing_requirements_for_testing}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Recommended number of testing iterations
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={test?.Recommended_number_of_testing_iterations}
                    className="form-control"
                    placeholder=" "
                    onChange={handleChange(
                      "Recommended_number_of_testing_iterations"
                    )}
                  />
                   {errors?.Recommended_number_of_testing_iterations && (
                      <p className="error-text">{errors?.Recommended_number_of_testing_iterations}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Test plan /protocol
                  </label>
                  <textarea
                    id="story"
                    name="story"
                    value={test?.Test_PlanorProtocol}
                    onChange={handleChange("Test_PlanorProtocol")}
                    className="form-textarea"

                  >
                    {" "}
                  </textarea>
                  {errors?.Test_PlanorProtocol && (
                      <p className="error-text">{errors?.Test_PlanorProtocol}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Notes and additional requirements
                  </label>
                  <textarea
                    id="story"
                    name="story"
                    value={test?.Notes_and_Additional_requirements}
                    onChange={handleChange("Notes_and_Additional_requirements")}
                    className="form-textarea"
                  >
                    {" "}
                  </textarea>
                  {errors?.Notes_and_Additional_requirements && (
                      <p className="error-text">{errors?.Notes_and_Additional_requirements}</p>
                    )}
                </div>
                <div className="btn-block">
                {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType  ? 

                  <button type="submit" className="thame-btn w-100">
                    Update
                  </button>:null}
                </div>
              </div>

              {/* <div className="col-md-2">
                <div className="testcase_list_wrapper">
                  <div className="test_case_list">
                    <div className="test_case_list pt-1">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselist"
                      >
                        test
                      </a>
                    </div>
                    <div className="test_case_list pt-5">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselistfill"
                      >
                        test 2
                      </a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">3</a>
                    </div>
                    <div className="test_case_list mt-4">
                      <a href="#">4</a>
                    </div>
                    <div className="test_case_list mt-5">
                      <a href="#">5</a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <CustomModal
        title="Requirement Test"
        onOpen={errorPopup}
        content={"Error!Please try again"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Requirement Test"
        onOpen={successPopup}
        content={"Requirement Test data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
    </div>
  );
};

export default Test;

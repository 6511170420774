import { useState, FC, useEffect } from "react";
import "./register.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../helpers/validations/useForm";
import { getUserDetails, signupUser, updateUser } from "../../store/actions/User";
import { REPLACE_REGEX, UserType } from "../../constants/common";
import { getAllCustomerList } from "../../store/actions/Customers";
import Box from "@mui/material/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import React from "react";

interface Register {
  active: any;
  allow_logon: any;
  allow_notifications: any;
  firstname: string;
  lastname: string;
  company_customer_id: string;
  usertype: string;
  work_phone: any;
  mobile_phone: any;
  emailid: string;
  notes: string;
  company_name: any;
  created_by:any;
  modified_by:any;
  createdAt:any;
  updatedAt:any;
  actionType:any
}

const ViewForm: FC = (props:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [checked, setChecked] = useState(false);
  const[actionType,setActionType]=useState("create")
  const[message,setMessage]=useState([])
  const loggedInId = useSelector((store: any) => {
    return store.auth.globalAuthData.id
  });
  const createdBy = useSelector((store: any) => {
    return store.auth.globalAuthData.created_by
  });
  const modifiedBy = useSelector((store: any) => {
    return store.auth.globalAuthData.modified_by
  });
  const[checkedAllowLogOn,setCheckedAllowLogOn]=useState(false)
  const[checkedAllowNotification,setCheckedAllowNotification]=useState(false)
const handleActiveCheckBox=(e:any)=>{
setChecked(e.target.checked)
}
const handleLogOnCheckBox=(e:any)=>{
setCheckedAllowLogOn(e.target.checked)
}
const handleNotificationCheckBox=(e:any)=>{
setCheckedAllowNotification(e.target.checked)
}
const params: any = useParams();
// const stateData: any = location?.state;
 const stateData: any = params;

const {
  userId="",
  setOpenUserDetailsPopUp=true,
}=props
  const { handleSubmit, handleChange, data: user, errors, setData } = useForm<
    Register
  >({
    validations: {
      firstname: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: "Please enter valid firstname",
        },
        minlength:{
          value:1,
          message:"First name should be atleast 1 character length"
        },
        maxlength:{
          value:50,
          message:"First name should be less than 50 character length"
        }
      },
      lastname: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: "Please enter valid lastname",
        },
        minlength:{
          value:1,
          message:"Last name should be atleast 1 character length"
        },
        maxlength:{
          value:50,
          message:"Last name should be less than 50 character length"
        }
      },
      company_customer_id: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      usertype: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      emailid: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: "^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+.)+[A-Za-z]{2,4}$",
          message: "Please provide correct Email ID",
        },
      },
      work_phone:{
        pattern:{
          value:REPLACE_REGEX?.PHONE_NUMBER_FORMAT,
          message:"Please enter valid phone number"
        },
        maxlength:{
          value:20,
          message:"Phone number should be less than 20 numbers"
        }
      },
      mobile_phone:{
        pattern:{
          value:REPLACE_REGEX?.PHONE_NUMBER_FORMAT,
          message:"Please enter valid phone number"
        },
        maxlength:{
          value:20,
          message:"Phone number should be less than 20 numbers"
        }
      },
      notes:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:"Please enter combination of Alphanumeric and special characters are allowed"
        },
        maxlength:{
          value:5000,
          message:"Notes should be less than 5000 character length"
        }
      }
    },
    onSubmit: () => {
      setLoader(true);
     
      dispatch(
        signupUser(
          {
            payload: {
                id:user?.id,
              active: checked,
              allow_logon: checkedAllowLogOn,
              allow_notifications: checkedAllowNotification,
              firstname: user?.firstname,
              lastname: user?.lastname,
              company_customer_id: user?.company_customer_id,
              usertype: user?.usertype,
              work_phone: user?.work_phone,
              mobile_phone: user?.mobile_phone,
              emailid: user?.emailid,
              notes:user?.notes, 
              created_by:createdBy,
              modified_by:user?.modified_by,
              actionType:actionType
            },
          },
          onSignUpCallback
        )
      );
    },
  });
  useEffect(() => {
    setLoader(true);
    dispatch(
      getAllCustomerList({}, function (data: any) {
        const rows = data?.map((row: any) => ({
          id: row?.id,
          company_name: row?.company_name,
        }));
        setRowData(rows);
      })
    );
    if(loggedInId){
      setLoader(true);
      console.log("user id is",stateData?.userId)

    dispatch(
        
      getUserDetails({id:userId||stateData?.userId||loggedInId},function(res:any){
        res?.id && setActionType("update");
        setData({
          ...res,
          id:res?.id,
          active: res?.active,
          allow_logon: res?.allow_logon,
          allow_notifications: res?.allow_notifications,
          firstname:res?.firstname,
          lastname:res?.lastname,
          company_customer_id: res?.company_customer_id,
          usertype: res?.usertype,
          work_phone: res?.work_phone,
          mobile_phone: res?.mobile_phone,
          notes:res?.notes,
          created_by:res?.created_by,
          updatedAt:res?.updatedAt,
          createdAt:res?.createdAt,
          modified_by:res?.modified_by
  
        })
        setLoader(false);
        setChecked(res?.active)
        setCheckedAllowLogOn(res?.allow_logon)
        setCheckedAllowNotification(res?.allow_notifications)
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
     

      )
    )
    }
  
  }, [setData]);
  const [readOnly,setReadonly]=useState(false)
  const handleReadOnly=(data:any)=>{
  if(data===true){
    setReadonly(true)
  }
  else{
    setReadonly(false)
  }
  }
  
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const onSignUpCallback = (data: any,error:any) => {
    setLoader(false);
    if (data == false) {
      setMessage(error)
      setErrorPopup(true);
    } else {
      setMessage(data)
      setSuccessPopup(true);

      setTimeout(() => {
        if(userId)
        {
        setOpenUserDetailsPopUp(false)
        }
        else
        {
        setSuccessPopup(false)
        window.location.reload()
        }
      

      }, 3000);


    }
  };
  const handleCheckBox = (e: any) => {
    if (e.target.checked === true) {
      setChecked(!checked);
    } else {
      setChecked(checked);
    }
  };
  const handleCancel=()=>{
    // navigate("/register")
    setOpenUserDetailsPopUp(false)
  }

  const userType = useSelector((store: any) => {
    return store.auth.globalAuthData.usertype;
  });
console.log("usertype is",userType)

  return (
    <div style={{ marginTop: "80px" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "95%",
            margin: "auto",
            marginTop: 5,
          }}
        >
          <div className="row justify-content-center">
            <form onSubmit={handleSubmit}>
              <div className="col-md-9">
                <h2>User</h2>

                <div className="d-flex">
                {userType === "Admin" ? 


                <div className="d-flex">
                   
                    
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          id="exampleCheck4"
                          name="checked"
                          onChange={handleActiveCheckBox}
                          value={user?.active}
                          checked={checked}

                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                          style={{ width: "80px" }}
                        >
                          Active
                        </label>
                      </div>:
                       <div className="d-flex">
                   
                       <input
                         type="checkbox"
                         className="form-check-input mx-2"
                         id="exampleCheck4"
                         name="checked"
                         onChange={handleActiveCheckBox}
                         value={user?.active}
                         checked={checked}
                         disabled
                       />
                       <label
                         // className="form-check-label mt-1"
                         className="formLabel"
                         htmlFor="checked"
                         style={{ width: "80px" }}
                       >
                         Active
                       </label>
                     </div>
                      }
                {userType === "Admin" ? 


                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          id="exampleCheck5"
                          name="checked"
                          onChange={handleLogOnCheckBox}
                          value={user?.allow_logon}
                          checked={checkedAllowLogOn}     


                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                          style={{ width: "100px" }}
                        >
                          Allow Logon
                        </label>
                      </div>:
                        <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          id="exampleCheck5"
                          name="checked"
                          onChange={handleLogOnCheckBox}
                          value={user?.allow_logon}
                          checked={checkedAllowLogOn} 
                          disabled    


                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                          style={{ width: "100px" }}
                        >
                          Allow Logon
                        </label>
                      </div>}
                      {userType === "Admin" ?
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          id="exampleCheck6"
                          name="checked"
                          onChange={handleNotificationCheckBox}
                          value={user?.allow_notifications}
                          checked={checkedAllowNotification}

                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                        >
                          Allow Notification
                        </label>
                      </div>:
                       <div className="d-flex">
                       <input
                         type="checkbox"
                         className="form-check-input mx-2"
                         id="exampleCheck6"
                         name="checked"
                         onChange={handleNotificationCheckBox}
                         value={user?.allow_notifications}
                         checked={checkedAllowNotification}
                         disabled

                       />
                       <label
                         className="form-check-label mt-1"
                         htmlFor="checked"
                       >
                         Allow Notification
                       </label>
                     </div>}
                    </div>
                    </div>
                  {userType === "Admin" ? 


                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="checked"className="form-label">First Name*</label>
                        <div>
                          <input
                            className="form-control"
                            name="firstname"
                            id="firstname"
                            type="text"
                            value={user?.firstname}
                            onChange={
                              handleChange("firstname")
                            
                            }
                          />
                          {errors.firstname && (
                            <p className="error-text">{errors.firstname}</p>
                          )}
                        </div>
                      </div>
                    </div>:
                       <div className="col-md-4">
                       <div className="form-group">
                         <label htmlFor="checked"className="form-label">First Name*</label>
                         <div>
                           <input
                             className="form-control"
                             name="firstname"
                             id="firstname"
                             type="text"
                             value={user?.firstname}
                            disabled
                           />
                         </div>
                       </div>
                     </div>}
                     { userType === "Admin" ?
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Last Name*</label>
                        <div>
                          <input
                            className="form-control"
                            name="lastname"
                            id="lastname"
                            value={user?.lastname}
                            onChange={
                              handleChange("lastname")
                            
                          }
                          />
                          {errors.lastname && (
                            <p className="error-text">{errors.lastname}</p>
                          )}
                        </div>
                      </div>
                    </div>:
                     <div className="col-md-4">
                     <div className="form-group">
                       <label className="form-label">Last Name*</label>
                       <div>
                         <input
                           className="form-control"
                           name="lastname"
                           id="lastname"
                           value={user?.lastname}
                          disabled
                         />
                         {errors.lastname && (
                           <p className="error-text">{errors.lastname}</p>
                         )}
                       </div>
                     </div>
                   </div>}
                   {userType === "Admin" ?


                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Company/Customer*</label>
                        <div>
                          <select
                            className="form-select"
                            name="about"
                            id="about"
                            value={user?.company_customer_id || ""}
                            onChange={handleChange("company_customer_id")}
                          >
                            <option value="">--Select--</option>
                            {rowData.map((company) => (
                              <option value={company.id}>
                                {company.company_name}
                              </option>
                            ))}
                          </select>
                          {errors.company_customer_id && (
                            <p className="error-text">
                              {errors.company_customer_id}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>:
                     <div className="col-md-4">
                     <div className="form-group">
                       <label className="form-label">Company/Customer*</label>
                       <div>
                         <select
                           className="form-select"
                           name="about"
                           id="about"
                           value={user?.company_customer_id || ""}
                           onChange={handleChange("company_customer_id")}
                           disabled
                         >
                           <option value="">--Select--</option>
                           {rowData.map((company) => (
                             <option value={company.id}>
                               {company.company_name}
                             </option>
                           ))}
                         </select>
                         {errors.company_customer_id && (
                           <p className="error-text">
                             {errors.company_customer_id}
                           </p>
                         )}
                       </div>
                     </div>
                   </div>}
                   {userType === "Admin" ?
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">User Type*</label>
                        <div>
                          <select
                            className="form-select"
                            name="usertype"
                            value={user?.usertype}
                            onChange={handleChange("usertype")}
                          >
                            <option value="">--Select--</option>
                            {UserType.map((user) => (
                              <option value={user.key}>{user.label}</option>
                            ))}
                          </select>
                          {errors.usertype && (
                            <p className="error-text">{errors.usertype}</p>
                          )}
                        </div>
                      </div>
                    </div>:
                     <div className="col-md-4">
                     <div className="form-group">
                      <div className="form-group">
                      <label className="form-label">User Type*</label>
                      <div>
                        <select
                          className="form-select"
                          name="usertype"
                          value={user?.usertype}
                          onChange={handleChange("usertype")}
                          disabled
                        >
                          <option value="">--Select--</option>
                          {UserType.map((user) => (
                            <option value={user.key}>{user.label}</option>
                          ))}
                        </select>
                       
                      </div>
                    </div>
                    </div>
                    </div>
}

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Work Phone</label>
                        <div>
                          <input
                            className="form-control"
                            name="work_phone"
                            type="text"
                            id="work_phone"
                            value={user?.work_phone}
                            placeholder="+91 XXXXXXXXXX"
                            onChange={handleChange("work_phone")}
                          />

                          {errors.work_phone && (
                            <p className="error-text">{errors.work_phone}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label"> Mobile Phone</label>
                        <div>
                          <input
                            className="form-control"
                            name="mobile_phone"
                            type="text"
                            value={user?.mobile_phone}
                            placeholder="+91 XXXXXXXXXX"
                            onChange={handleChange("mobile_phone")}
                          />
                          {errors.mobile_phone && (
                            <p className="error-text">{errors.mobile_phone}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Email ID*</label>
                        <div>
                          <input
                            className="form-control"
                            name="emailid"
                            type="text"
                            value={user?.emailid}
                            onChange={(data:any)=>{handleChange("emailid");
                          handleReadOnly(data)}
                          }
                          />
                          {errors.emailid && (
                            <p className="error-text">{errors.emailid}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Notes</label>
                        <div>
                          <textarea
                            name="notes"
                            value={user?.notes}
                            onChange={handleChange("notes")}
                            className="form-textarea"
                          ></textarea>&nbsp;
                         {errors.notes && (
                            <p className="error-text">{errors.notes}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {loggedInId && 
                    <div>
                      <div className="col-md-6">
                      <div className="form-group">

                          <label htmlFor="created-by" className="form-label">Created By</label>
                          <div>
                          <input type="text" className="form-control" id="created-by"                      
                           value={user?.created_by+" "+user?.createdAt?.split("T")[0]+" "+user?.createdAt?.split("T")[1]}

placeholder="USERID YYYY-MM-DD HH:MM:SS"
                        disabled 
/>                                        
                      </div>
                       </div>  
                         </div> 
                  { user?.modified_by!==null  && user?.modified_by!=="" &&
                    (<div className="col-md-6">
                                            <div className="form-group">

                      <label htmlFor="modified-by" className="form-label">Modified By </label>
                      <div>
                      <input type="text" className="form-control" value={user?.modified_by+" "+user?.updatedAt?.split("T")[0]+" "+user?.updatedAt?.split("T")[1]} id="modified-by" disabled/>                                        
                    </div>
                    </div>
                    </div>)}
                    </div>
                    }
                     <div className="col-md-12">
            {actionType === "create" ? (
              <button className="btn add_button btn-primary mx-0 w-auto" id="create" onClick={()=>{setLoader(false)}}>
                ADD
              </button>
            ) : (
              <button 
              className="btn add_button btn-primary mx-0 w-auto" id="create">
                UPDATE
              </button>
            )}
            &nbsp;
                 <button
                        type="button"
                        className="btn add_button btn-primary mx-0 w-auto"
                        id="cancelButton"
                        onClick={handleCancel}
                      >
                        CANCEL
                      </button>
                </div>
              
                 
            
            </form>
            <CustomModal
              title="User Registration"
              onOpen={errorPopup}
              content={message}
              modalType={"error"}
              width={"sm"}
              OnClickCloseIcon={() => {
                setErrorPopup(false);
              }}
            />
            <CustomModal
              title="User Registration"
              onOpen={successPopup}
              content={"User Created/Updated Successfully"}
              modalType={"success"}
              width={"sm"}
              OnClickCloseIcon={() => {
                setSuccessPopup(false);
              }}
            />
          </div>
        </Box>
      </Box>
    </div>
  );

};

export default ViewForm;

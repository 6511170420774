import { useEffect, useState, useMemo } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../components/CustomPagination";
import CustomModal from "../../../components/CustomModalPop";
import columns from "./columnsDef";
import { BoxStyle, HeaderStyle, ButtonStyle } from "./style";

import setLoader from "../../../components/CustomLoader/loaderState";
import { getProjectList } from "../../../store/actions/Customer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProfileType } from "../../../utility/commonTypes";

const CustomerProjects = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [successPopup, setSuccessPopup] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const location = useLocation();
  const params: any = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setLoader(true);
    setOpenModalPop(true);
    setLoader(false);
    setSelectedRowId("");
  };
  const fetchCustomerProjectsList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getProjectList(
          {
            id: stateData?.customerId,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const [deletePopUp, setDeletePopUp] = useState(false);

  useEffect(() => {
    fetchCustomerProjectsList(0, "ON_LOAD");
  }, []);
  const deleteCustomerProjects = (event: any, id: any) => {
    setDeletePopUp(true);
    setSelectedRowId(id);
  };

  const editCustomerProjects = (event: any, id: any) => {
    setOpenModalPop(true);
    setSelectedRowId(id);
  };

  const defaultColumns = useMemo(() => {
    return columns(editCustomerProjects, deleteCustomerProjects);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
    setSelectedRowId("");
  };
  const addProject = () => {
    stateData?.customerId ? 
      navigate("/project/" + stateData?.customerId, {
        state: {
          customerId: stateData?.customerId,
        }
      })
      :navigate("/project");
  };
  const onCusterContactsCreateSuccess = () => {
    setOpenModalPop(false);
    fetchCustomerProjectsList(0, "ON_LOAD");
    setSuccessPopup(true);
    setLoader(false);
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Customer Projects
        </Typography>
        <div className="my-4 mx-4">
    {ProfileType?.ADMIN === getProfileType &&      <Button
            variant="contained"
            onClick={addProject}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>}
        </div>
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchCustomerProjectsList(data, "NEXT_PAGE");
          }}
        />
      </Box>
      <CustomModal
        title="Customer Contact"
        onOpen={successPopup}
        content={"Customer Contact Created Successfully"}
        modalType={"success"}
        width={"lg"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
    </>
  );
};

export default CustomerProjects;

export const BoxStyle = {
  marginTop: '10px',
  // paddingRight: "20px",
  // paddingLeft: "20px",
  marginBottom: '40px',
  width: '100%',
  '& .MuiDataGrid-root': {
    border: "none",
    backgroundColor: "white",
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    padding: "10px",
    fontSize: '16px'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: "1px solid #212529"
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#212529',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '600',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: "1px solid #212529"
  }
}

export const HeaderStyle = {
  fontSize: '22px',
  fontWeight: '700',
  color: '#000',
  lineHeight: '30px',
}

export const ButtonStyle = {
  paddingRight: "2.5rem",
  paddingLeft: "2.5rem",
  backgroundColor: "#007cb0",
  "&:hover": {
    backgroundColor: "#007cb0"
  },
}
export const TableCellStyle = {
  '&.MuiTableCell-root': {
    padding: "2px !important",
  },
}

import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../actions/auth";
import { taskActions, setTaskDetails } from "../../actions/Task";
import { APIResponse } from "../../../utility/commonTypes";
import {
  createTaskAsync,
  getTaskDetailsAsync,
  getTaskProjectAsync,
  updateTaskDetailsAsync,
} from "../../../api/common";

function* TaskDetails(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType === "create"
        ? createTaskAsync
        : updateTaskDetailsAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskDetails({ ...res.data }, function () {}));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetTaskDetails(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getTaskDetailsAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetTaskProjects(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getTaskProjectAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchTaskDetails() {
  yield takeLeading(taskActions.CREATE_TASK, TaskDetails);
  yield takeLeading(taskActions.GET_TASK_DETAILS, GetTaskDetails);
  yield takeLeading(taskActions.UPDATE_TASK_DETAILS, TaskDetails);
  yield takeLeading(taskActions.GET_TASK_PROJECTS, GetTaskProjects);

  
}

import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Tooltip } from "@mui/material";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "firstname",
      headerName: "Users",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => 
    // <a href={`/user/${params?.row?.id}`}>{params?.row?.firstname+" "+params?.row?.lastname}</a>
    <Link
    to={`/user/${params?.row?.id}`}
    state={{ userId: params?.row?.id }}
    style={{ color: "#1976d2" }}
  >
    {params?.row?.firstname+" "+params?.row?.lastname}
    </Link>
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
      return (
        <Checkbox
          checked={params?.row?.active === true}
          inputProps={{ "aria-label": "controlled" }}
          disabled
        />
      );   
     },
    },
    {
      field: "allow_logon",
      headerName: "Allow Logon",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
      return (
        <Checkbox
          checked={params?.row?.active === true}
          inputProps={{ "aria-label": "controlled" }}
          disabled
        />
      );   
     },
    },
    {
      field: "allow_notifications",
      headerName: "Allow Notifications",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
      return (
        <Checkbox
          checked={params?.row?.active === true}
          inputProps={{ "aria-label": "controlled" }}
          disabled
        />
      );   
     },
    },
    {
      field: "usertype",
      headerName: "UserType",
      minWidth: 50,
      ...commonColumnsDef,
      renderCell: (params: any) => {
    
    return params?.row?.userType
      }
    },
    {
      field: "emailid",
      headerName: "EmailId",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
      <Tooltip title={params?.row?.emailid} followCursor>
      <div>{params?.row?.emailid}</div>
      </Tooltip>
        );
  },
  
    },
     ];
};

export default columns;

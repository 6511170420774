import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import React from "react";
import { PROJECT_STATUS, PROJECT_TYPE } from "../../../constants/common";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "project_name",
      headerName: "Project Name",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.id ? (
              <Tooltip title={params?.row?.project_name} followCursor>
                <Link
                  to={`/project/manage-sub-project/${params?.row?.master_project}/${params?.row?.company_customer_id}/${params?.row?.id}`}
                  state={{
                    masterProjectId: params?.row?.master_project,
                    companyCustomerId: params?.row?.company_customer_id,
                    subProjectId: params?.row?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.project_name}
                </Link>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "project_type",
      headerName: "Type",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PROJECT_TYPE.find(
          (o) => o.key === params?.row?.project_type
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },    },
    {
      field: "project_status",
      headerName: "Status",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PROJECT_STATUS.find(
          (o) => o.key === params?.row?.project_status
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },    },
    {
      field: "total_pool_hours",
      headerName: "SOW Hours",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.total_pool_hours || "NA",
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) =>
        params?.row?.projectCreator?.created_by || "NA",
    },
    {
      field: "createdAt",
      headerName: "Created On",
      minWidth: 70,
      ...commonColumnsDef,
      // renderCell: (params: any) => params?.row?.createdAt.split("T")[0] || "NA",
      valueFormatter: (params: any) => {
        return params?.value === undefined ||null ? 'NA' : formatDate(params?.value)

     },
    },
  ];
};

export default columns;

import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography, Button } from "@mui/material";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import CustomPagination from "../../../components/CustomPagination";
import columns from "./columnsDef";

import { BoxStyle, HeaderStyle, ButtonStyle } from "./style";
import {
  deleteProjectResources,
  getProjectResources,
} from "../../../store/actions/Project";
import ProjectResourcesForm from "./ProjectResourcesForm";
import { CustomConfirmPopup } from "../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import setLoader from "../../../components/CustomLoader/loaderState";
import { deleteTaskResources } from "../../../store/actions/Task";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../components/CustomModalPop";
import ViewForm from "../../Register/viewPage";
import { ProfileType } from "../../../utility/commonTypes";

const ProjectResources = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [loading, setLoading] = useState(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [searchedUser, setUser] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);
  const location = useLocation();
  const params: any = useParams();

  //const stateData=location.state
  const stateData = params;
  const [successPopup, setSuccessPopup] = useState(false);
   const [deleteSuccessPopup, setDeleteSuccessPopup] = useState(false);
   const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
   const[userId,setUserId]=useState("")
  
  const [selectedRowId, setSelectedRowId] = useState("");

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setLoader(true);
    setOpenModalPop(true);
    setLoader(false);
    setSelectedRowId("");
  };
  const fetchProjectResourcesList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getProjectResources(
          {
            id: stateData?.projectId || stateData?.subProjectId,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProjectResourcesList(0, "ON_LOAD");
  }, []);
  const onProjectResourceCreateSuccess = () => {
    fetchProjectResourcesList(0, "ON_LOAD");
    setSuccessPopup(true);
    setTimeout(()=>{
      setSuccessPopup(false);

    },5000)
  };

  const onClickYes = (rowId: any) => {
    console.log("selected id", rowId);
    setDeletePopUp(false);
    setLoader(true);

    dispatch(
      deleteProjectResources(
        {
          id: rowId,
          project_id: stateData?.projectId || stateData?.subProjectId,
        },
        function (res: any) {
          setLoader(false);
          setDeleteSuccessPopup(true);
          setTimeout(()=>{
            setDeleteSuccessPopup(false);
      
          },5000)
          fetchProjectResourcesList(0, "ON_LOAD");
        }
      )
    );
  };
  const deleteProjectResource = (event: any, id: any) => {
    setDeletePopUp(true);
    setSelectedRowId(id);
  };

  const editProjectResource = (event: any, id: any) => {
    setOpenModalPop(true);
    setSelectedRowId(id);
  };
  const handleUserDetails=(event:any,id:any)=>{
    setOpenUserDetailsPopUp(true)
    setUserId(id)
  }
  const defaultColumns = useMemo(() => {
    return columns(editProjectResource, deleteProjectResource,handleUserDetails);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
    setSelectedRowId("");
  };
  const onClickNo = () => {
    // navigate("/all-projects");
    setDeletePopUp(false);
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Projects Resources
        </Typography>
        <div className="my-4 mx-4">
      {ProfileType?.ADMIN === getProfileType &&    <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>}
        </div>
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchProjectResourcesList(data, "NEXT_PAGE");
          }}
        />
      </Box>
      {openModalPop && (
        <ProjectResourcesForm
          row={selectedRowId}
          handleClose={handleClose}
          openModalPop={openModalPop}
          onSuccess={onProjectResourceCreateSuccess}
          setOpenModalPop={setOpenModalPop}
        />
      )}
   {ProfileType?.ADMIN === getProfileType &&   <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        closeAndSubmit={() => {}}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
        rowId={selectedRowId}
        title={`Delete Project Resource`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected row`}
        // successIcon={true}
      />}
      <CustomModal
        title="Project Resource"
        onOpen={successPopup}
        content={"Project Resource Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <CustomModal
        title="Project Resource"
        onOpen={deleteSuccessPopup}
        content={"Project Resource Deleted Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setDeleteSuccessPopup(false);
        }}
      />
       <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
    </>
  );
};

export default ProjectResources;

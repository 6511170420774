import { actions } from "../../actions/configuration";

interface CONFIG {
  version: string;
  imageUploadSize: string | number;
  isAppMaintenance: boolean;
  enabledPrograms: object;
  appUrls: object;
  apiKeys: object;
  showSFInformationNote: boolean;
}
const initialState: CONFIG = {
  version: "",
  imageUploadSize: 1,
  isAppMaintenance: false,
  enabledPrograms: {},
  appUrls: {},
  apiKeys: {},
  showSFInformationNote: true,
};

export default function (
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case actions.SAVE_CONFIG_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SF_HIDE_INFO_NOTE:
      return {
        ...state,
        showSFInformationNote: false,
      };
    default:
      return state;
  }
}

import { call, put, select, takeLeading } from "redux-saga/effects";
import {
  forgotPasswordAsync,
  getUserDetailsAsync,
  signupUserAsync,
  updateUserDetailsAsync,
} from "../../../api/common/index";
import { actions } from "../../actions/User";
import { APIResponse } from "../../../utility/commonTypes";
import { setUserDetails } from "../../actions/Project";

function* signupUser(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType === "create"
        ? signupUserAsync
        : updateUserDetailsAsync,
      payload
    );
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      yield put(setUserDetails({ ...res.data }));

      callback(res?.data);
    } 
 else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* forgotPassword(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(forgotPasswordAsync, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetUserDetails(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getUserDetailsAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setUserDetails({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchSignUp() {
  yield takeLeading(actions.SIGNUP_USER, signupUser);
  yield takeLeading(actions.FORGOT_PASSWORD_HANDLER, forgotPassword);
  yield takeLeading(actions.UPDATE_USER, signupUser);
  yield takeLeading(actions.GET_USER_DETAILS, GetUserDetails);
}

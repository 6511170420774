import apiClient, {BASE_URL as baseURL} from "../apiClient";
import { END_POINTS } from "./endpoint";
import axios from "axios";

// export const getOAuthToken = async () => {
//     return await apiClient.postPublic(
//         END_POINTS.FETCH_OAUTH,
//         queryGenerator({
//             ...OAUTH_CONFIG,
//             client_secret: "aaaaa345643dfghjr",
//         })
//     );
// };

export const verifyCustomer = async (payload: Record<string, any>) =>
  await apiClient.postPublic(END_POINTS.VERIFY_USER, {
    ...payload,
    channel: "Web",
  });
export const loginUserAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.LOGIN_USER, payload);
};
export const logoutUserAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(END_POINTS.LOGOUT_USER, payload);
};
export const getNewTokenAsync = async (payload: Record<string, any>) => {
  return await axios.get(baseURL+END_POINTS.GET_NEW_TOKEN, {headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
  withCredentials: true
}
  );
};
export const signupUserAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.SIGNUP_USER, payload);
};
export const updateUserDetailsAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_USER_DETAILS.replace(":id", payload.payload.id),
    payload
  );
};
export const getUserDetailsAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_USER_DETAILS.replace(":id", payload.id),
    payload
  );
};
export const ssoLoginAsync = async (token:any) => {
  const client = axios.create({
     baseURL,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,

    },
    withCredentials: true

  });
  const postPublic = async (url: any, data: any, config = {}) => {
    try {
       const urlNew = baseURL + url;
      const result = await client.post(urlNew, data, config);      

      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await postPublic(END_POINTS.SSO_LOGIN,{});
};


export const signupCustomerAsync = async (payload: Record<string, any>) => {
  const client = axios.create({
    baseURL,
    headers: { "Content-Type": "multipart/form-data" },
    withCredentials: true,
  });
  const postPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      const result = await client.post(urlNew, data, config);
      
      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await postPublic(END_POINTS.SIGNUP_CUSTOMER, payload);
};
export const setUserPreferenceAsync = async (payload: Record<string, any>) =>
  await apiClient.post(END_POINTS.SET_USER_PREFERENCE, payload);

export const getFaqContent = async (payload: Record<string, any>) => {
  return await apiClient.post(END_POINTS.GET_FAQ_DATA, payload);
};

export const logoutAppUser = async () =>
  await apiClient.post(END_POINTS.LOGOUT, {});

export const CustomerProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.CUSTOMER_PROJECTS.replace(":id", payload.id) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const getTaskProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.TASK_PROJECTS.replace(":id", payload.id),
    payload
  );
};
export const CustomerAMSSearch = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(END_POINTS.SEARCH_FILTER, payload);
};
export const getAllUsers = async (payload: Record<string, any>) => {
  if (payload.size || payload.page) {

  return await apiClient.getPublic(END_POINTS.ALL_USERS+ `?size=${payload.size}` +
  `&page=${payload.page}`, payload);
  }
};
export const CustomerContactCreateAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.CUSTOMER_CONTACT_CREATE.replace(
      ":id",
      payload.payload.company_customer_id
    ),
    payload
  );
};
export const CustomerContactUpdateAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.CUSTOMER_CONTACT_UPDATE.replace(
      ":id",
      payload.payload.company_customer_id
    ).replace(":id", payload.payload.id),
    payload
  );
};
export const CustomerContactDeleteAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.delete(
    END_POINTS.CUSTOMER_CONTACT_DELETE.replace(
      ":id",
      payload.company_customer_id
    ).replace(":id", payload.id),
    payload
  );
};
export const CustomerDetailsAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_CUSTOMER_DETAILS.replace(":id", payload.id),
    payload
  );
};
export const CustomerDetailsUpdateAsync = async (
  payload: Record<string, any>
) => {
  const client = axios.create({
    baseURL,
    headers: { "Content-Type": "multipart/form-data" },
    withCredentials: true,
  });
  const postPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      const result = await client.post(urlNew, data, config);
      
      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await postPublic(
    END_POINTS.UPDATE_CUSTOMER_DETAILS.replace(":id", payload.id),
    payload
  );
};
export const CustomerModulesUpdateAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_CUSTOMER_MODULES.replace(":id", payload.payload.id),
    payload
  );
};
// export const CustomerAMSAsync = async (payload: Record<string, any>) => {
//   return await apiClient.postPublic(
//     END_POINTS.CUSTOMER_AMS_CREATE.replace(":id", payload.id),
//     payload
//   );
// };

export const GetModulesByCustomer = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_MODULES_BY_CUSTOMER.replace(":id", payload.id),
    payload
  );
};
export const CustomerModulesAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(END_POINTS.CUSTOMER_MODULES, payload);
};
export const CustomerFilesAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.CUSTOMER_FILES.replace(":id", payload.id),
    payload
  );
};
export const projectScopeAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.PROJECT_SCOPE.replace(":id", payload.project_id),
    payload
  );
};
export const projectScopeUpdateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.PROJECT_SCOPE_UPDATE.replace(":id", payload.payload.project_id),
    payload
  );
};

export const projectDateAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.PROJECT_DATES.replace(":id", payload.project_id),
    payload
  );
};
export const projectDateUpdateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.PROJECT_DATES_UPDATE.replace(":id", payload.payload.project_id),
    payload
  );
};
export const signupProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.SIGNUP_PROJECT, payload);
};

export const projectUpdateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_PROJECT.replace(":id", payload.payload.id),
    payload
  );
};
export const getProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_PROJECT_DETAILS.replace(":id", payload.id),
    payload
  );
};
export const signupProjectResourceAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.PROJECT_RESOURCE_CREATE.replace(
      ":id",
      payload.payload.project_id
    ),
    payload
  );
};
export const projectResourceUpdateAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.PROJECT_RESOURCE_UPDATE.replace(
      ":id",
      payload.payload.project_id
    ).replace(":id", payload.payload.id),
    payload
  );
};
export const projectResourceAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.PROJECT_RESOURCE.replace(":id", payload.id) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const subProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_SUB_PROJECT.replace(":id", payload.id),
    payload
  );
};
export const projectTaskAsync = async (payload: Record<string, any>) => {
  let epURL = "";
  epURL = END_POINTS.PROJECT_TASK.replace(":id", payload.id);
  epURL = epURL.replace(":page", payload.page);
  epURL = epURL.replace(":size", payload.size);
  return await apiClient.getPublic(epURL, payload);
};

export const getCustomerProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_CUSTOMER_PROJECTS.replace(
      ":id",
      payload.company_customer_id
    ) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const fetchMasterProjectAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.FETCH_MASTER_PROJECT_LIST,
    payload
  );
};
export const projectDeleteResourceAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.delete(
    END_POINTS.PROJECT_RESOURCE_DELETE.replace(
      ":id",
      payload.project_id
    ).replace(":id", payload.id),
    payload
  );
};
export const createPendingItemsAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.CREATE_PENDING_ITEM.replace(":id", payload.payload.task_id),
    payload
  );
};

export const createPendingItemsAsyncUnitTest = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.CREATE_PENDING_ITEM_UNIT_TEST.replace(":id", payload.payload.task_id),
    payload
  );
};

export const getAllItemsAsyncUnitTest = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.GETALL_ITEM_UNIT_TEST.replace(":id", payload.task_id),
    payload
  );
};

export const saveAsyncUnitTest = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.SAVE_ITEM_UNIT_TEST.replace(":id", payload.payload.task_id),
    payload
  );
};

export const saveAsyncTestCase = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.SAVE_ITEM_TEST_CASE.replace(":id", payload.payload.task_id),
    payload
  );
};

export const getPendingItemsAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_PENDING_ITEMS.replace(":id", payload.id) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const updatePendingItemsAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_PENDING_ITEMS.replace(
      ":id",
      payload.payload.task_id
    ).replace(":id", payload.payload.id),
    payload
  );
};
export const deletePendingItemsAsync = async (payload: Record<string, any>) => {
  return await apiClient.delete(
    END_POINTS.DELETE_PENDING_ITEMS.replace(":id", payload.task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
};
export const searchUserAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.USER_SEARCH, payload);
};
export const taskFilesFunctionality = async (payload: Record<string, any>,task_id:any) => {
  let fileName = "";
  let signedUrl = "";
  //step 1
  const getPreSignedUrl = await apiClient.postPublic(
    END_POINTS.GENERATE_SIGNED_URL,
    payload
  );
  fileName  = getPreSignedUrl?.data?.keyForPSUrl
  signedUrl = getPreSignedUrl?.data?.url
  let allowCreate=false
  //step 2
 let file={file:payload?.task_uploaded_file}
 let config={ headers: { "Content-Type": "multipart/form-data" }}
 try{
  const putToS3=await apiClient.putS3(signedUrl,file?.file,config)
  allowCreate=true
  
 }
 catch(ex){
  alert("File not uploaded successfully.Please try again")
  
  }
//  step3
 if(allowCreate){
 payload["keyForPSUrl"]=fileName
  // payload["keyForPSUrl"]="html.zip"

 let uploadBody = payload
 if(payload?.actionType==="create"){
 const uploadtoDB = await apiClient.postPublic(
      END_POINTS.UPLOAD_TASK_FILES.replace(":id", task_id),
      uploadBody
    );
return uploadtoDB
 }
 else{
  const updatetoDB = await apiClient.postPublic(
    END_POINTS.UPDATE_TASK_FILES.replace(":id", task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
  return updatetoDB

 }
   }



 
};

export const GetTaskFilesAsync = async (payload: Record<string, any>) => {
  const client = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)

    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    );
  const query = `${queryString}`.replaceAll(",", "&");
  const urlParams = new URLSearchParams(query);

  const params = Object.fromEntries(urlParams.entries());
  const stringQuery = "task_technical=" + params?.task_technical;

  const getPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      const result = await client.get(urlNew, data, config);
      

      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await getPublic(
    END_POINTS.GET_TASK_FILES.replace(":id", payload.id) +
      `?${stringQuery}` +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const updateTaskFilesAsync = async (
  payload: Record<string, any>,
  task_id: any
) => {
  // const client = axios.create({
  //   baseURL:"https://x9mlgos92a.execute-api.ap-south-1.amazonaws.com/uat",
  //   // timeout: 30,
  //   headers: { "Content-Type": "multipart/form-data" },
  //   withCredentials: true,
  // });

  // const postPublic = async (url: any, data: any, config = {}) => {
  //   try {
  //     const urlNew = "https://x9mlgos92a.execute-api.ap-south-1.amazonaws.com/uat" + url;
  //     //  console.log(data)
  //     //  return await apiClient.post(urlNew, data, config);
  //     const result = await client.post(urlNew, data, config);
  //     console.log(`bbbbbbbbb ${result}`);

  //     return result;
  //   } catch (ex) {
  //     return ex.response;
  //   }
  // };
   console.log("paylaod is", payload);

  return await apiClient.postPublic(
    END_POINTS.UPDATE_TASK_FILES.replace(":id", task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
};
export const getRequirementDetailsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.getPublic(
    END_POINTS.GET_REQUIREMENT_DETAILS.replace(":id", payload.id),
    payload
  );
};
export const updateRequirementDetailsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_REQUIREMENT_DETAILS.replace(":id", payload.payload.id),
    payload
  );
};
export const createTaskAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.CREATE_TASK, payload);
};
export const updateTaskDetailsAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_TASK_DETAILS.replace(":id", payload.payload.id),
    payload
  );
};
export const getTaskDetailsAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_TASK_DETAILS.replace(":id", payload.id),
    payload
  );
};

export const createRequirementPendingItemsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.CREATE_REQUIREMENT_PENDING_ITEMS.replace(
      ":id",
      payload.payload.requirement_id
    ),
    payload
  );
};
export const getRequirementPendingDetailsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.getPublic(
    END_POINTS.GET_REQUIREMENT_PENDING_ITEMS.replace(":id", payload.id) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const updateRequirementPendingItemsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_REQUIREMENT_PENDING_ITEMS.replace(
      ":id",
      payload.payload.requirement_id
    ).replace(":id", payload.payload.id),
    payload
  );
};
export const deleteRequirementPendingItemsAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.delete(
    END_POINTS.DELETE_REQUIREMENT_PENDING_ITEMS.replace(
      ":id",
      payload.requirement_id
    ).replace(":id", payload.id),
    payload
  );
};

export const getRequirementTechnicalAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.getPublic(
    END_POINTS.GET_TECHNICAL.replace(":id", payload.id),
    payload
  );
};
export const updateRequirementTechnicalAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_TECHNICAL.replace(":id", payload.payload.requirement_id),
    payload
  );
};

export const getRequirementDataAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_REQUIREMENT_DATA.replace(":id", payload.id),
    payload
  );
};
export const updateRequirementDataAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_REQUIREMENT_DATA.replace(
      ":id",
      payload.payload.requirement_id
    ),
    payload
  );
};
export const getRequirementTransportsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.getPublic(
    END_POINTS.GET_REQUIREMENT_TRANSPORT.replace(":id", payload.id),
    payload
  );
};
export const updateRequirementTransportAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_REQUIREMENT_TRANSPORT.replace(
      ":id",
      payload.payload.requirement_id
    ),
    payload
  );
};
export const getRequirementTestAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_REQUIREMENT_TEST.replace(":id", payload.id),
    payload
  );
};
export const updateRequirementTestAsync = async (
  payload: Record<string, any>
) => {
  return await apiClient.postPublic(
    END_POINTS.UPDATE_REQUIREMENT_TEST.replace(
      ":id",
      payload.payload.requirement_id
    ),
    payload
  );
};

export const forgotPasswordAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(END_POINTS.FORGOT_PASSWORD, payload);
};
export const createTaskResourceAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.TASK_RESOURCE_CREATE.replace(":id", payload.payload.task_id),
    payload
  );
};
export const taskResourceUpdateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.TASK_RESOURCE_UPDATE.replace(
      ":id",
      payload.payload.task_id
    ).replace(":id", payload.payload.id),
    payload,
    payload
  );
};
export const taskResourceAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.TASK_RESOURCE.replace(":id", payload.id) +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const taskDeleteResourceAsync = async (payload: Record<string, any>) => {
  return await apiClient.delete(
    END_POINTS.TASK_RESOURCE_DELETE.replace(":id", payload.task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
};

export const saveUnitTesting = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.TASK_UNIT_TEST_CREATE.replace(":id", payload.task_id),
    payload
  );
};


export const getAllUnitTesting = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.TASK_UNIT_TEST_GETALL.replace(":id", payload.task_id),
    payload
  );
};

export const getAllTestCase = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.TASK_TEST_CASE_GETALL.replace(":id", payload.task_id),
    payload
  );
};

export const integrationRepositoryTableAsync = async (
  payload: Record<string, any>
) => {
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)

    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    )

    .join("&");

  return await apiClient.get(
    END_POINTS.INTEGRATION_REPOSITORY_TABLE + `?${queryString}`
  );
};

export const integrationRepositoryCatalogueAsync = async (
  payload: Record<string, any>
) => {
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)

    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    )

    .join("&");

  return await apiClient.get(
    END_POINTS.INTEGRATION_REPOSITORY_CATALOGUE + `?${queryString}`
  );
};

export const searchTaskNameAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.SEARCH_BY_TASK_NAME + "?name=" + payload.task_name,
    payload
  );
};
export const sourceTargetSystemAsync = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(END_POINTS.SOURCE_TARGET_SYSTEM, payload);
};
export const searchAllUsersAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.ALL_USER_SEARCH.replace(":id", payload.id),
    payload
  );
};
export const CustomerAMSCreateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.CUSTOMER_AMS_CREATE.replace(
      ":id",
      payload.payload.company_customer_id
    ),
    payload
  );
};
export const CustomerAMSUpdateAsync = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.CUSTOMER_AMS_UPDATE.replace(
      ":id",
      payload.payload.company_customer_id
    ).replace(":id", payload.payload.id),
    payload
  );
};
export const getMasterAMS = async (payload: Record<string, any>) => {
  return await apiClient.getPublic(
    END_POINTS.GET_AMS_MASTER.replace(":id", payload.id),
    payload
  );
};
export const CustomerAMSDetails = async (payload: Record<string, any>) => {
  return await apiClient.postPublic(
    END_POINTS.GET_AMS_CUSTOMER.replace(":id", payload.company_customer_id),
    payload
  );
};
// export const uploadTaskTechnicalFilesAsync = async (
//   payload: Record<string, any>,
//   task_id: any
// ) => {
//   console.log("task id", task_id);
//   const client = axios.create({
//     baseURL: "http://localhost:8000",
//     // timeout: 30,
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     // headers: { "Content-Type": "application/json"

//     withCredentials: true,
//   });
//   const postPublic = async (url: any, data: any, config = {}) => {
//     try {
//       const urlNew = "http://localhost:8000" + url;
//       //  console.log(data)
//       //  return await apiClient.post(urlNew, data, config);
//       const result = await client.post(urlNew, data, config);
//       console.log(`bbbbbbbbb ${result}`);

//       return result;
//     } catch (ex) {
//       return ex.response;
//     }
//   };
//   return await postPublic(
//     END_POINTS.UPLOAD_TASK_TECHNICAL_FILES.replace(":id", task_id),
//     payload.jsonFormat
//   );
// };
export const taskTechnicalFilesFunctionality = async (payload: Record<string, any>,task_id:any) => {
  let fileName = "";
  let signedUrl = "";
  //step 1
  const getPreSignedUrl = await apiClient.postPublic(
    END_POINTS.GENERATE_SIGNED_URL_TECHNICAL,
    payload
  );
  fileName  = getPreSignedUrl?.data?.keyForPSUrl
  signedUrl = getPreSignedUrl?.data?.url
  let allowCreate=false
  //step 2
 let file={file:payload?.task_uploaded_file}
 let config={ headers: { "Content-Type": "multipart/form-data" }}
 try{
  const putToS3=await apiClient.putS3(signedUrl,file?.file,config)
  allowCreate=true
  
 }
 catch(ex){
  alert("File not uploaded successfully.Please try again")
  
  }
 //step3
 if(allowCreate){
 payload["keyForPSUrl"]=fileName
 let uploadBody = payload
 if(payload?.actionType==="create"){
 const uploadtoDB = await apiClient.postPublic(
      END_POINTS.UPLOAD_TASK_TECHNICAL_FILES.replace(":id", task_id),
      uploadBody
    );
return uploadtoDB
 }
 else{
  const updatetoDB = await apiClient.postPublic(
    END_POINTS.UPDATE_TASK_TECHNICAL_FILES.replace(":id", task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
  return updatetoDB

 }
 }



 
};
export const GetTaskTechnicalFilesAsync = async (
  payload: Record<string, any>
) => {
  const client = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)

    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    );
  const query = `${queryString}`.replaceAll(",", "&");
  const urlParams = new URLSearchParams(query);

  const params = Object.fromEntries(urlParams.entries());
  const stringQuery = "task_technical=" + params?.task_technical;

  const getPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      const result = await client.get(urlNew, data, config);    

      return result;
    } catch (ex) {
      return ex.response;
    }
  };
  return await getPublic(
    END_POINTS.GET_TASK_TECHNICAL_FILES.replace(":id", payload.id) +
      `?${stringQuery}` +
      `?size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};
export const deleteTaskFiles = async (payload: Record<string, any>) => {
  return await apiClient.delete(
    END_POINTS.DELETE_TASK_FILE.replace(":id", payload.id).replace(
      ":file_name",
      payload.file_name
    ),
    payload
  );
};

export const deleteTechnicalTaskFiles = async (
  payload: Record<string, any>
) => {
  return await apiClient.delete(
    END_POINTS.DELETE_TASK_TECHNICAL_FILE.replace(":id", payload.id).replace(
      ":file_name",
      payload.file_name
    ),
    payload
  );
};
export const downloadTaskFiles = async (
  payload: Record<string, any>
) => {
  const client = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
      withCredentials: true,
  });

  const getPublic = async (url: any, data: any, config = {}) => {
    try {
      const urlNew = baseURL + url;
      const result = await client.get(urlNew, data, config);      

      return result;
    } catch (ex) {
      return ex.response;
    }
  }
  return await getPublic(
    END_POINTS.DOWNLOAD_TASK_FILE.replace(
      ":id",
      payload.id
    ).replace(":file_name", payload.file_name),
    payload
  );
};
export const downloadTaskTechnicalFiles = async (
  payload: Record<string, any>
) => {
  return await apiClient.getPublic(
    END_POINTS.DOWNLOAD_TASK_FILE.replace(":id", payload.id).replace(
      ":file_name",
      payload.file_name
    ),
    payload
  );
};
export const updateTaskTechnicalFilesAsync = async (
  payload: Record<string, any>,
  task_id: any
) => {
  // const client = axios.create({
  //   baseURL: "http://localhost:8000",
  //   // timeout: 30,
  //   headers: { "Content-Type": "multipart/form-data" },
  //   withCredentials: true,
  // });
  // const postPublic = async (url: any, data: any, config = {}) => {
  //   try {
  //     const urlNew = "http://localhost:8000" + url;
  //     //  console.log(data)
  //     //  return await apiClient.post(urlNew, data, config);
  //     const result = await client.post(urlNew, data, config);
  //     console.log(`bbbbbbbbb ${result}`);

  //     return result;
  //   } catch (ex) {
  //     return ex.response;
  //   }
  // };
  return await apiClient.postPublic(
    END_POINTS.UPDATE_TASK_TECHNICAL_FILES.replace(":id", task_id).replace(
      ":id",
      payload.id
    ),
    payload
  );
};
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import CustomModal from "../../../components/CustomModalPop";
import { createUnitTesting, getAllUnitTests, saveItemsUnitTest } from "../../../store/actions/Task";
import moment from "moment";
import setLoader from "../../../components/CustomLoader/loaderState";
import { BoxStyle, HeaderStyle } from "../style";
import { useParams } from "react-router-dom";


const UnitTests = () => {

  const params: any = useParams();
  const stateData: any = params;
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  // console.log("task id", stateData?.taskId)

  const unit_test_cases = [
    {
      "order_id": 1,
      "task_id": stateData?.taskId,
      "task":"Connectivity Test",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 2,
      "task_id": stateData?.taskId,
      "task":"File matches specs",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 3,
      "task_id": stateData?.taskId,
      "task":"Required fields",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 4,
      "task_id": stateData?.taskId,
      "task":"Field length",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },{
      "order_id": 5,
      "task_id": stateData?.taskId,
      "task":"Data for terminations",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 6,
      "task_id": stateData?.taskId,
      "task":"File naming - Seq Gen",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },{
      "order_id": 7,
      "task_id": stateData?.taskId,
      "task":"Date formats",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 8,
      "task_id": stateData?.taskId,
      "task":"Selection criteria",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },{
      "order_id": 9,
      "task_id": stateData?.taskId,
      "task":"Security - Integration",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 10,
      "task_id": stateData?.taskId,
      "task":"Error Handling - Launch parameters",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },{
      "order_id": 11,
      "task_id": stateData?.taskId,
      "task":"Error Handling - Studio",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    },
    {
      "order_id": 12,
      "task_id": stateData?.taskId,
      "task":"Volume Test",
      "completed": false,
      "na": false,
      "completedBy": "",
      "completion_date": null,
      "comments": ""
    }
  ]

  const [testCaseValues , setTestCaseValues] = useState<any>(unit_test_cases)

  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData;
  });

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [user_details, setUserDetails] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const fetchMyUnitTestsList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoader(true);
      dispatch(
        getAllUnitTests(
          {
            task_id: stateData?.taskId
          },
          (data: any) => {
            if (data?.length > 0) {
              const sortedValues = data?.sort(function (a, b) {
                return new Date(a.order_id).valueOf() - new Date(b.order_id).valueOf()
              })
              setTestCaseValues(data);
            } else {
              setTestCaseValues(unit_test_cases)
            }
            setLoader(false);
          }
        )
      );
    } catch (e) {
      setLoader(false);
      console.log(e, "error");
    }
  };

  const handleSave = () => {
    setLoader(true);
    dispatch(
      saveItemsUnitTest(
        {
          payload: {
            task_id: stateData?.taskId,
            unitTest: testCaseValues
          },
        },

        onCreateUnitTestingCallback
      )
    );
    // dispatch(
    //   createUnitTesting(
    //     {
    //       task_id: stateData?.taskId,
    //       unitTest: testCaseValues
    //     },

    //     onCreateUnitTestingCallback
    //   )
    // );
  }

  const onCreateUnitTestingCallback = (data: any) => {
    console.log('on saving records', data)
    if (data == 'success') {
      console.log('1')
      setSuccessPopup(true);
      setLoader(false);
    } else {
      console.log('2')
      setLoader(false);
      setErrorPopup(true)
    }
  };

  const handleChangeCompleted = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].completed = e.target.checked;

    if (e.target.checked) {
      let user = userDetail.firstname + " " + userDetail.lastname
      setUserDetails(user)
      newFeatures[index].na = false
      newFeatures[index].completedBy = user
      newFeatures[index].completion_date = moment(new Date()).format("DD-MMM-YYYY");
    } else {
      setUserDetails("")
      newFeatures[index].completedBy = ""
      newFeatures[index].completion_date = null

    }
    setTestCaseValues(newFeatures)
  };

  const handleNACompleted = (e, index) => {
    const newFeatures = [...testCaseValues]

    if (e.target.checked) {
      let user = userDetail.firstname + " " + userDetail.lastname
      setUserDetails(user)
      newFeatures[index].na = false
      newFeatures[index].completedBy = user
      newFeatures[index].completion_date = moment(new Date()).format("DD-MMM-YYYY");
    } else {
      setUserDetails("")
      newFeatures[index].completedBy = ""
      newFeatures[index].completion_date = null

    }

    newFeatures[index].na = e.target.checked
    newFeatures[index].completed = false
    setTestCaseValues(newFeatures)
  };

  const setCommentsState = (e, index) => {
    const newFeatures = [...testCaseValues]

    newFeatures[index].comments = e
    setTestCaseValues(newFeatures)
  }

  const formatDate = (data:Date) => {
    return moment(new Date(data)).format("DD-MMM-YYYY");
  }

  useEffect(() => {
    fetchMyUnitTestsList(0, "ON_LOAD");
  }, []);

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        {/* Unit Testing */}
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <div className="row border border-1" style={{ backgroundColor: "rgb(245, 246, 247)", paddingTop: "10px", paddingBottom: "10px" }}>
          <div className="col-sm-1 fw-bold">Completed</div>
          <div className="col-sm-1 fw-bold">N/A</div>
          <div className="col-sm-2 fw-bold">Task</div>
          <div className="col-sm-2 fw-bold">Completed By</div>
          <div className="col-sm-2 fw-bold">Date</div>
          <div className="col-sm-4 fw-bold">Comments</div>
        </div>
        {testCaseValues?.map((row: any, index:number) => (
          <div className="row mt-3">
            <div className="col-sm-1 mt-2">
              <input
                type="checkbox"
                className="form-check-input progressCheckbox"
                name={`completed`+index}
                id={`completed`+index}
                onChange={(e) => {
                  handleChangeCompleted(e, index);
                }}
                value={
                  row?.completed
                }
                checked={
                  row?.completed
                }
              />
            </div>
            <div className="col-sm-1 mt-2">
              <input
                type="checkbox"
                className="form-check-input progressCheckbox"
                name={`ua` + index}
                id={`ua` + index}
                onChange={(e) => {
                  handleNACompleted(e, index);
                }}
                value={
                  row?.na
                }
                checked={row?.na}
              />
            </div>
            <div className="col-sm-2 mt-2">{ row?.task  }</div>
            <div className="col-sm-2 mt-2">{ row?.completedBy }</div>
            <div className="col-sm-2 mt-2">{
              (row?.completion_date)
                ? <div>{ formatDate(row?.completion_date) } </div>
                : <div> </div>
            }</div>
            <div className="col-sm-4 mt-2">
              <textarea rows="3" style={{height:"70%"}} value={row?.comments} name={`inputComments` + index} id={`inputComments` + index} onChange={e => setCommentsState(e.target.value, index)}></textarea>
            </div>
          </div>
        ))}
        <div className="form-group">
          <div className="btn-block">
            <button className="thame-btn w-auto" onClick={() => handleSave()}>Save</button>
          </div>
        </div>
      </Box>
        <CustomModal
          title="Unit Testing"
          onOpen={successPopup}
          content={"Data Saved Successfully!"}
          modalType={"success"}
          width={"xs"}
          OnClickCloseIcon={() => {
            setSuccessPopup(false);
          }}
        />
        <CustomModal
          title="Unit Testing"
          onOpen={errorPopup}
          content={"Error Saving Record"}
          modalType={"error"}
          width={"xs"}
          OnClickCloseIcon={() => {
            setErrorPopup(false);
          }}
        />
    </>
  );
};

export default UnitTests;

import React, { useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import columns from "./columnsDef";
import { TableCellStyle } from "./style";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useParams } from "react-router-dom";
import { ProfileType } from "../../../../utility/commonTypes";

const FieldRequirementList = ({
  fielwRquirementList,
  handleChange,
  onAddRow,
  onDeleteRow,
  onMappingPopUp,
  onFirstAddRow,
  indexNo,
  addClick,
}: any) => {
  const [rowID, setRowID] = React.useState("");

  const defaultColumns: any = useMemo(() => {
    return columns();
  }, []);

  // console.log("fielwRquirementListv", fielwRquirementList);
  const sortedValues = fielwRquirementList?.sort(function (a, b) {
    return a.field_order - b.field_order
  })
  const IsHeader = fielwRquirementList.some(
    (rowData: any) => rowData.type === "header"
  );
  const IsFooter = fielwRquirementList.some(
    (rowData: any) => rowData.type === "footer"
  );
  const IsDetailsRecord = fielwRquirementList.some(
    (rowData: any) => rowData.type === "DetailsRecord"
  );

  const headerFieldOrder = fielwRquirementList.filter((fieldData: any) => {
    return fieldData.type === "header";
  });
  const footerFieldOrder = fielwRquirementList.filter((fieldData: any) => {
    return fieldData.type === "footer";
  });
  const DetailsRecordFieldOrder = fielwRquirementList.filter(
    (fieldData: any) => {
      return fieldData.type === "DetailsRecord";
    }
  );
  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
  const params: any = useParams();
  const stateData: any = params;

  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });
  const isLocked =  selector?.locked=== true       

  return (
    <>
      <div className="row">
        <div className="d-flex col-md-12">
          <div className="col-md-6 my-2">
            <div className="btn-block text-start d-flex">
              <div className="vendor_contact mx-3">
                {" "}
                Field requirements - File {indexNo}{" "}
              </div>
              {fielwRquirementList?.length === 0 && (
                     ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ? 
                      <Button variant="contained" onClick={() => onFirstAddRow()} disabled={isLocked}>
                  ADD
                </Button>:null
              )}
            </div>
          </div>
          <div className="col-md-6 my-2">
            <div className="btn-block text-end mx-2">
              {/* Download */}
              <a className="mx-2" href="#0">
                {/* <img src="assets/images/PDF_file_icon.svg.png" /> */}
              </a>
              <a className="#0" href="#0">
                {/* <img src="assets/images/download.png" /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <Paper sx={{ width: "100%" }} key={rowID}>
        <TableContainer sx={{ maxHeight: 1000 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {defaultColumns?.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fielwRquirementList?.length === 0 && (
                <TableRow
                  hover
                  className="text-center"
                  sx={{
                    background: "#f6f6f6",
                  }}
                >
                  <TableCell
                    align="center"
                    colSpan={20}
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    No Rows
                  </TableCell>
                </TableRow>
              )}
              {IsHeader && (
                <TableRow
                  hover
                  className="text-center"
                  sx={{
                    background: "#f6f6f6",
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={20}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Header
                  </TableCell>
                </TableRow>
              )}
              {fielwRquirementList
                ?.filter(v => v.type == 'header')
                ?.sort((fieldA: any, fieldB: any) =>
                  fieldA?.field_order > fieldB?.field_order ? 1 : 0
                )
                ?.map((row: any, ind: any) => {
                  const headerCount = fielwRquirementList.filter(v => v.type == 'header').length - 1;
                  return (
                    row?.type === "header" && (
                      <>
                        <TableRow hover tabIndex={-1} key={row?.field_order}>
                          {defaultColumns.map((column: any) => {
                            const valueData =
                              row?.[column?.id] ||
                              row?.field_attributes?.[column?.id];
                            const isTrue = Object.keys(row)?.includes(
                              column?.id
                            );
                            return (
                              <>
                                {column?.InputType === "SELECT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {addClick == 'header' && headerCount == ind ? (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "header",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={""}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option
                                          value={"header"}
                                        >
                                          Header
                                        </option>
                                        <option
                                          value={"DetailsRecord"}
                                        >
                                          Details Record
                                        </option>
                                        <option
                                          value={"footer"}
                                        >
                                          Footer
                                        </option>
                                      </select>
                                    ) : (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "header",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={"header"}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option
                                          value={"header"}
                                        >
                                          Header
                                        </option>
                                      </select>
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "TEXT_AREA" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <textarea
                                      id={column?.id}
                                      name={column?.id}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "header",
                                        row
                                      )}
                                      value={valueData}
                                      placeholder=""
                                      disabled={isLocked}
                                      className="form-textarea"
                                    >
                                      {" "}
                                    </textarea>
                                  </TableCell>
                                ) : column?.InputType === "ACTION" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <div className="d-inline-flex">
                                      <IconButton
                                        onClick={() => onAddRow(row)}
                                        className="p-0"
                                        disabled={isLocked}
                                      >
                                        <AddCircleOutlineOutlinedIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => onDeleteRow(row)}
                                        className="p-0"
                                        disabled={isLocked}
                                      >
                                        <RemoveCircleOutlineOutlinedIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                ) : column?.InputType === "MAPPING" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <IconButton
                                      onClick={() => onMappingPopUp(row)}
                                      className="p-0"
                                      disabled={isLocked}
                                    >
                                      <TextSnippetOutlinedIcon
                                        sx={{ color: "#007cb0" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ) : column?.InputType === "INPUT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {column?.id === "Max_Length" ? (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "header",
                                          row
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        pattern="^[0-9]*$"
                                        title="Max Length should be of numeric value"
                                        className="form-control mb-0"
                                        disabled={isLocked}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "header",
                                          row
                                        )}
                                        disabled={isLocked}
                                        value={valueData}
                                        placeholder=""
                                        className="form-control mb-0"
                                      />
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "CHECKBOX" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <input
                                      style={{ width: "100%", height: "100%" }}
                                      type="checkbox"
                                      id={column?.id + ind}
                                      name={column?.id + ind}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "header",
                                        row
                                      )}
                                      disabled={isLocked}
                                      checked={valueData === "on" ? true : false}
                                      defaultChecked={valueData === "on" ? true : false }
                                      className="mb-0"
                                    />
                                  </TableCell>
                                ) : null}
                              </>
                            );
                          })}
                        </TableRow>
                      </>
                    )
                  );
                })}
              {IsDetailsRecord && (
                <TableRow
                  hover
                  className="text-center"
                  sx={{
                    background: "#f6f6f6",
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={20}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Detail Record
                  </TableCell>
                </TableRow>
              )}
              {fielwRquirementList
                ?.filter(v => v.type == 'DetailsRecord')
                ?.sort((fieldA: any, fieldB: any) =>
                  fieldA?.field_order > fieldB?.field_order ? 1 : 0
                )
                ?.map((row: any, ind: any) => {
                  const DetailsRecordCount = fielwRquirementList.filter(v => v.type == 'DetailsRecord').length - 1;
                  return (
                    row?.type === "DetailsRecord" && (
                      <>
                        <TableRow hover tabIndex={-1} key={row?.field_order}>
                          {defaultColumns.map((column: any) => {
                            const valueData =
                              row?.[column?.id] ||
                              row?.field_attributes?.[column?.id];
                            const isTrue = Object.keys(row)?.includes(
                              column?.id
                            );
                            return (
                              <>
                                {column?.InputType === "SELECT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {addClick == 'DetailsRecord' && DetailsRecordCount == ind? (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "DetailsRecord",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={""}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option
                                          value={"header"}
                                        >
                                          Header
                                        </option>
                                        <option
                                          value={"DetailsRecord"}
                                        >
                                          Details Record
                                        </option>
                                        <option
                                          value={"footer"}
                                        >
                                          Footer
                                        </option>
                                      </select>
                                    ) : (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "DetailsRecord",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={"DetailsRecord"}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option
                                          value={"DetailsRecord"}
                                        >
                                          Details Record
                                        </option>
                                      </select>
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "TEXT_AREA" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <textarea
                                      id={column?.id}
                                      name={column?.id}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "DetailsRecord",
                                        row
                                      )}
                                      value={valueData}
                                      placeholder=""
                                      disabled={isLocked}
                                      className="form-textarea"
                                    >
                                      {" "}
                                    </textarea>
                                  </TableCell>
                                ) : column?.InputType === "ACTION" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <div className="d-inline-flex">
                                      <IconButton
                                        onClick={() => onAddRow(row)}
                                        className="p-0"
                                      >
                                        <AddCircleOutlineOutlinedIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => onDeleteRow(row)}
                                        className="p-0"
                                      >
                                        <RemoveCircleOutlineOutlinedIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                ) : column?.InputType === "MAPPING" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <IconButton
                                      onClick={() => onMappingPopUp(row)}
                                      className="p-0"
                                    >
                                      <TextSnippetOutlinedIcon
                                        sx={{ color: "#007cb0" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ) : column?.InputType === "INPUT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {column?.id === "Max_Length" ? (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "DetailsRecord",
                                          row
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        pattern="^[0-9]*$"
                                        title="Max Length should be of numeric value"
                                        className="form-control mb-0"
                                        disabled={isLocked}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "DetailsRecord",
                                          row
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        className="form-control mb-0"
                                        disabled={isLocked}
                                      />
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "CHECKBOX" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <input
                                      style={{ width: "100%", height: "100%" }}
                                      type="checkbox"
                                      id={column?.id + ind}
                                      name={column?.id + ind}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "DetailsRecord",
                                        row
                                      )}
                                      // value={valueData}
                                      checked={valueData === "on" ? true : false}
                                      defaultChecked={valueData === "on" ? true : false }
                                      className="mb-0"
                                      disabled={isLocked}
                                    />
                                  </TableCell>
                                ) : null}
                              </>
                            );
                          })}
                        </TableRow>
                      </>
                    )
                  );
                })}
              {IsFooter && (
                <TableRow
                  hover
                  className="text-center"
                  sx={{
                    background: "#f6f6f6",
                  }}
                >
                  <TableCell
                    align="left"
                    colSpan={20}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Footer
                  </TableCell>
                </TableRow>
              )}
              {fielwRquirementList
                ?.filter(v => v.type == 'footer')
                ?.sort((fieldA: any, fieldB: any) =>
                  fieldA?.field_order > fieldB?.field_order ? 1 : 0
                )
                ?.map((row: any, ind: any) => {
                  const footerCount = fielwRquirementList.filter(v => v.type == 'footer').length - 1;
                  return (
                    row?.type === "footer" && (
                      <>
                        <TableRow hover tabIndex={-1} key={row?.field_order}>
                          {defaultColumns.map((column: any) => {
                            const valueData =
                              row?.[column?.id] ||
                              row?.field_attributes?.[column?.id];
                            const isTrue = Object.keys(row)?.includes(
                              column?.id
                            );
                            return (
                              <>
                                {column?.InputType === "SELECT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {addClick == 'footer' && footerCount == ind ? (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "footer",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={""}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option value={"header"}>
                                          Header
                                        </option>
                                        <option value={"DetailsRecord"}>
                                          Details Record
                                        </option>
                                        <option value={"footer"}>
                                          Footer
                                        </option>
                                      </select>
                                    ) : (
                                      <select
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "footer",
                                          row
                                        )}
                                        className="form-select"
                                        aria-label="Default select example"
                                        disabled={isLocked}
                                        defaultValue={"footer"}
                                        value={valueData}
                                      >
                                        <option value={""}>Select</option>
                                        <option
                                          value={"footer"}
                                        >
                                          Footer
                                        </option>
                                      </select>
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "TEXT_AREA" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <textarea
                                      id={column?.id}
                                      name={column?.id}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "footer",
                                        row
                                      )}
                                      className="form-textarea"
                                      value={valueData}
                                      placeholder=""
                                      disabled={isLocked}
                                    >
                                      {" "}
                                    </textarea>
                                  </TableCell>
                                ) : column?.InputType === "ACTION" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <div className="d-inline-flex">
                                      <IconButton
                                        onClick={() => onAddRow(row)}
                                        className="p-0"
                                      >
                                        <AddCircleOutlineOutlinedIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => onDeleteRow(row)}
                                        className="p-0"
                                      >
                                        <RemoveCircleOutlineOutlinedIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                ) : column?.InputType === "MAPPING" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <IconButton
                                      onClick={() => onMappingPopUp(row)}
                                      className="p-0"
                                    >
                                      <TextSnippetOutlinedIcon
                                        sx={{ color: "#007cb0" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ) : column?.InputType === "INPUT" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    {column?.id === "Max_Length" ? (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "footer",
                                          row
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        pattern="^[0-9]*$"
                                        title="Max Length should be of numeric value"
                                        className="form-control mb-0"
                                        disabled={isLocked}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          row?.field_order,
                                          isTrue,
                                          "field_attributes",
                                          headerFieldOrder.length,
                                          footerFieldOrder?.length,
                                          DetailsRecordFieldOrder?.length,
                                          "footer",
                                          row
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        className="form-control mb-0"
                                        disabled={isLocked}
                                      />
                                    )}
                                  </TableCell>
                                ) : column?.InputType === "CHECKBOX" ? (
                                  <TableCell
                                    key={column.id}
                                    sx={TableCellStyle}
                                    align={column.align}
                                  >
                                    <input
                                      style={{ width: "100%", height: "100%" }}
                                      type="checkbox"
                                      id={column?.id + ind}
                                      name={column?.id + ind}
                                      onChange={handleChange(
                                        column?.id,
                                        "",
                                        "RequirementFields",
                                        "",
                                        "",
                                        row?.field_order,
                                        isTrue,
                                        "field_attributes",
                                        headerFieldOrder.length,
                                        footerFieldOrder?.length,
                                        DetailsRecordFieldOrder?.length,
                                        "footer",
                                        row
                                      )}
                                      // value={valueData}
                                      className="mb-0"
                                      checked={valueData === "on" ? true : false}
                                      defaultChecked={valueData === "on" ? true : false }
                                      disabled={isLocked}
                                    />
                                  </TableCell>
                                ) : null}
                              </>
                            );
                          })}
                        </TableRow>
                      </>
                    )
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
export default FieldRequirementList;

import React from "react"
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { errorMessages, REPLACE_REGEX, TASK_RESOURCES_ROLE } from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import { getCustomerUserByName } from "../../../store/actions/Customer";
import { createTaskResources } from "../../../store/actions/Task";
import RegisterForm from "../../Register/register";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { ProfileType } from "../../../utility/commonTypes";

interface TaskResources {
  task_id: any;
  ResourceName: any;
  user_id: any;
  role: any;
  start_date: any;
  end_date: any;
  notes: any;
  created_by: any;
  totals: any;
  hours:any
}
const ResourcesForm = ({
  row,
  messages,
  openModalPop,
  setOpenModalPop,
  handleClose,
  onSuccess,
}: any) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const [resourceUserId, setresourceUserId] = useState<string>("");
  const [resourceStartDate, setResourceStartDate] = useState<string>("");
  const [resourceEndtDate, setResourceEndDate] = useState<string>("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actionType, setActionType] = useState("create");
  const [checked, setChecked] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const location: any = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const[openResourceRegisterPopUp,setOpenResourceRegisterPopUp]=useState(false)
  const [open, setOpen] = useState(false);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    handleSubmit,
    handleChange,
    data: resources,
    setData,
    errors,
  } = useForm<TaskResources>({
    validations: {
      ResourceName: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
        custom: {
          isValid: (value) => {
            console.log("Assigned to Validation", value);
            if (
              value !== undefined &&
              value != null &&
              value !== "" &&
              resourceUserId === ""
            ) {
              console.log("Invalid Assigned To");
              return false;
            }
            return true;
          },
          message: "Please select valid Resource Name",
        },
      },
      start_date: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
      },
    totals: {
        pattern:{
          value:REPLACE_REGEX?.NUMBERS_WITH_DECIMALS,
          message:errorMessages?.patternWithOnlyDecimalNumbers()
        },
        maxlength: {
          value: 6,
          message: errorMessages?.allowedRange("Hours"),
        },
    },
      end_date: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
        custom: {
          isValid: (value) => {
            if (actionType === "create") {
              const selected = new Date(resourceEndtDate);
              const maxDate = new Date(resourceStartDate);
              maxDate.setHours(0, 0, 0, 0);
              maxDate.setDate(maxDate.getDate() + 1);
              console.log(maxDate);
              if (selected < maxDate) {
                return false;
              }
              return true;
            }
            return true;
          },
          message: "Resource end date should not be less then start date",
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        createTaskResources(
          {
            payload: {
              id: resources?.id,
              task_id: stateData?.taskId,
              ResourceName: resources?.ResourceName,
              user_id: resources?.user_id,
              role: resources?.role,
              start_date: resources?.start_date,
              end_date: resources?.end_date,
              notes: resources?.notes,
              totals: resources?.totals?Number(resources?.totals).toFixed(2):0,
              actionType: actionType,
            },
          },

          onCreateTaskResourcesCallback
        )
      );
    },
  });

  const [errorPopup, setErrorPopup] = useState(false);
  const onCreateTaskResourcesCallback = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      onSuccess();
    }
    //on Success redirect to parent page and reload the data grid
    setOpenModalPop(false);
    setLoader(false);
    
  };

  useEffect(() => {
    if (row && row?.id) {
      row?.id && setActionType("update");
      setresourceUserId(row?.user_id);
      setData({
        id: row?.id,
        ResourceName: row?.ResourceName,
        role: row?.role,
        user_id: row?.user_id,
        start_date: row?.start_date.split("T")[0],
        end_date: row?.end_date.split("T")[0],
        notes: row?.notes,
        created_by: row?.created_by,
        totals: row?.totals,
      });
    } else {
      setActionType("create");
      setData({
        id: "",
        ResourceName: "",
        role: "",
        user_id: "",
        start_date: "",
        end_date: "",
        notes: "",
        created_by: "",
        totals: "",
      });
    }
  }, [setData, row]);

  const handleKeyUpEvent = (event: any) => {
    dispatch(
      getCustomerUserByName(
        { searchWord: event.target.value, customerUser: 0 },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setSuggestions({
            ...res,
          });
        }
      )
    );
  };

  useEffect(() => {
    openModalPop && setOpenModalPop(true);
  }, [openModalPop]);
  const handleClickOpen = () => {
    setOpenResourceRegisterPopUp(true);
  };
  let suggestRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  return (
    <>
      <CustomModal
        title="Select Task Resources"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Name* <AddCircleOutlineIcon onClick={handleClickOpen}/></label>
                    <input
                      name="ResourceName"
                      id="ResourceName"
                      value={resources?.ResourceName}
                      onChange={handleChange("ResourceName", (val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpEvent}
                      className="form-control"
                    />
                    {errors.ResourceName && (
                      <p className="error-text">{errors.ResourceName}</p>
                    )}
                    {showSuggestion && (
                      <ul
                        className="list-group"
                        ref={suggestRef}
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }}                      >
                        {" "}
                        {Object.entries(suggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setShowSuggestion(false);
                                   setresourceUserId(i?.id);
                                  setData({
                                    ...resources,
                                    user_id: i?.id,
                                    ResourceName: i?.ResourceName,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Role</label>
                    <select
                      name="role"
                      id="role"
                      value={resources?.role}
                      onChange={handleChange("role")}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {TASK_RESOURCES_ROLE.map((role) => (
                        <option value={role.key}>{role.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Start Date*</label>
                    <input
                      type="date"
                      value={resources?.start_date}
                      onChange={handleChange("start_date", (val) => {
                        setResourceStartDate(val);
                        return val;
                      })}
                      data-date={resources?.start_date ? moment(resources?.start_date)?.format("DD-MMM-YYYY"):"DD-MM-YYYY"}
                      className="form-control"
                      placeholder="DD-MMM-YYYY"
                    />
                    {errors.start_date && (
                      <p className="error-text">{errors.start_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">End Date*</label>
                    <input
                      type="date"
                      value={resources?.end_date}
                      onChange={handleChange("end_date", (val) => {
                        setResourceEndDate(val);
                        return val;
                      })}
                      data-date={resources?.end_date ?moment(resources?.end_date)?.format("DD-MMM-YYYY"):"DD-MM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                    />
                    {errors.end_date && (
                      <p className="error-text">{errors.end_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Hours</label>
                    <input
                      type="text"
                      value={resources?.totals}
                      onChange={handleChange("totals")}
                      className="form-control"
                    />
                    {errors.totals && (
                      <p className="error-text">{errors.totals}</p>
                    )}
                  </div>
                </div>
                {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) ||  ProfileType?.ADMIN === getProfileType  ? 
     <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>:null}
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />

      <CustomModal
        title="Task Resources"
        onOpen={errorPopup}
        content={messages}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Resources"
        onOpen={successPopup}
        content={"Task Resources Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
        <CustomModal
        title="Create Resource"
        height="80%"
        width="md"
        onOpen={openResourceRegisterPopUp}
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={<RegisterForm companyId={stateData?.customerId} setOpenResourceRegisterPopUp={setOpenResourceRegisterPopUp}/>}
        OnClickCloseIcon={() => {
          setOpenResourceRegisterPopUp(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};
export default ResourcesForm;

import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Tooltip } from "@mui/material";
import moment from "moment";
import { PROJECT_LEVEL, PROJECT_STATUS, PROJECT_TYPE } from "../../../constants/common";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "company_customer_id",
      headerName: "Customer",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.company_customer_id ? (
              <Tooltip title={params?.row?.Customer?.company_name} followCursor>
                <Link
                  to={`/customer/${params?.row?.company_customer_id}`}
                  state={{ customerId: params?.row?.company_customer_id }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Customer?.company_name}
                </Link>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "project_name",
      headerName: "Project Name",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.project_level === "Sub_Project" ? (
              <Tooltip title={params?.row?.project_name} followCursor>
                <Link
                  to={`/project/manage-sub-project/${params?.row?.master_project}/${params?.row?.company_customer_id}/${params?.row?.id}`}
                  state={{
                    masterProjectId: params?.row?.master_project,
                    companyCustomerId: params?.row?.company_customer_id,
                    subProjectId: params?.row?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.project_name}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title={params?.row?.project_name} followCursor>
                <Link
                  to={`/project/${params?.row?.id}/${params?.row?.company_customer_id}`}
                  state={{
                    projectId: params?.row?.id,
                    customerId: params?.row?.company_customer_id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.project_name}
                </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "project_level",
      headerName: "Level",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
          
        let obj = PROJECT_LEVEL.find(
          (o) => o.key === params?.row?.project_level
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "project_status",
      headerName: "Status",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
          
        let obj = PROJECT_STATUS.find(
          (o) => o.key === params?.row?.project_status
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },    },
    {
      field: "phase",
      headerName: "Phase",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.phase || "NA",
    },
    {
      field: "wave",
      headerName: "Wave",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.wave || "NA",
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Checkbox
            checked={params?.row?.active === true}
            inputProps={{ "aria-label": "controlled" }}
            disabled
          />
        );
      },
    },
    {
      field: "project_type",
      headerName: "Type",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
          
        let obj = PROJECT_TYPE.find(
          (o) => o.key === params?.row?.project_type
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },    },
    {
      field: "engagement_id",
      headerName: "Engagement Id",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => 
          <Tooltip title={params?.row?.engagement_id} followCursor>
            <div>{params?.row?.engagement_id || "NA"}</div>
          </Tooltip>  
    },
    {
      field: "opportunity_id",
      headerName: "Opportunity Id",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => 
        <Tooltip title={params?.row?.opportunity_id} followCursor>
          <div>{params?.row?.opportunity_id || "NA"}</div>
        </Tooltip>
   
    },      
    {
      field: "plan_phase_start_date",
      headerName: "Plan Start Date",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => 
        <Tooltip title={params?.row?.ProjectDate?.plan_phase_start_date?formatDate(params?.row?.ProjectDate?.plan_phase_start_date?.split("T")[0]):"NA"} followCursor>
        <div>
         {params?.row?.ProjectDate?.plan_phase_start_date ?formatDate(params?.row?.ProjectDate?.plan_phase_start_date?.split("T")[0]):"NA"}
              </div>
          </Tooltip>
      },
    {
      field: "go_live_date",
      headerName: "Go Live Date",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => 
        <Tooltip title={params?.row?.ProjectDate?.go_live_date?formatDate(params?.row?.ProjectDate?.go_live_date?.split("T")[0]):"NA"} followCursor>
        <div>
         {params?.row?.ProjectDate?.go_live_date ?formatDate(params?.row?.ProjectDate?.go_live_date?.split("T")[0]):"NA"}
              </div>
          </Tooltip>
    },
    /*{
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/customer/${params?.row?.company_customer_id}`}
            state={{ customerId: params?.row?.company_customer_id }}
            style={{ color: "#1976d2" }}
          >
            <EditIcon sx={{ color: "#B4B2B2" }} />
          </Link>
        );
      },
    },*/
  ];
};

export default columns;

import React, { useEffect, useState } from "react";
import ProgressTask from "./ProgressTask";
import { useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import setLoader from "../../CustomLoader/loaderState";
import CustomModal from "../../CustomModalPop";
import { useLocation, useParams } from "react-router-dom";
import { CustomConfirmPopup } from "../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";

import {
  getTaskProgressData,
  updateTaskProgressData,
} from "../../../store/actions/Task";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { ProfileType } from "../../../utility/commonTypes";

const Progress: React.FC = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params: any = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  const[open,setOpen]=useState(false)
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const validationSchema = Yup.object().shape({
    tickets: Yup.array().of(
      Yup.object().shape({
        initiaterequirements: Yup.string().required("Name is required"),
        comments: Yup.string()
          .max(200, "Must be less then 200 chars")
          .matches(/^[a-zA-Z0-9\s,'\-/_#@&:;]*$/, "Only special characters all are a-z,A-Z,0-9#_-")
          .nullable()
          .notRequired(),
        // assigned_hours: Yup.number()
        // .when("completed", {
        //   is: true,
        //   then: Yup
        //     .number()
        //     .required("Must be more than 0")
        //     .positive("Should be greater than 0")
        //     .integer("input integer value")
        // }),
        target_end_date: Yup.string()
          .strict(true)
          .test(
            "test-case",
            "Invalid Target Date",
            (values: any, targetVal) => {
              const { options, parent } = targetVal;
              let targetValLength =
                targetVal["from"][1]["value"]["tickets"].length;
              if (options.index > 1) {
                let prevDate =
                  targetVal["from"][1]["value"]["tickets"][options.index - 1][
                    "target_end_date"
                  ];
                if (prevDate === "") {
                  for (let i = options.index - 1; i >= 1; i--) {
                    prevDate =
                      targetVal["from"][1]["value"]["tickets"][i][
                        "target_end_date"
                      ];
                    if (prevDate !== "") {
                      break;
                    }
                  }
                }

                prevDate = new Date(prevDate);
                let currentDate = new Date(values);
                if (prevDate !== "Invalid Date" && values !== "") {
                  if (currentDate < prevDate) {
                    setOpen(true)
                    return false;
                  }
                }
              } else if (options?.index === 1) {
                let nextDate =
                  targetVal["from"][1]["value"]["tickets"][options?.index + 1][
                    "target_end_date"
                  ];

                if (nextDate === "") {
                  for (let i = options?.index + 1; i <= targetValLength-1; i++) {
                    nextDate =
                      targetVal["from"][1]["value"]["tickets"][i][
                        "target_end_date"
                      ];
                    if (nextDate !== "") {
                      break;
                    }
                  }
                }
                
                if(nextDate !== ''){
                  nextDate = new Date(nextDate);
                  //console.log("Inside 1111", nextDate);
                  let currentDate = new Date(values);
                  if (
                    nextDate !== "Invalid Date" &&
                    currentDate !== "Invalid Date" &&
                    nextDate < currentDate
                  ) {
                    setOpen(true)
                    return false;
                  }
                }
                return true;
              }

              return true;
            }
          ),
      })
      /*.test(
          "is-some-test",
          "Name needs te be unique",
          validateAgainstPrevious
        )*/
    ),
  });
  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [taskProgressData, setTaskProgressData] = useState([]);
  const [isTaskData, setIsTaskData] = useState(true);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const transformProgressData = (data: any) => {
    //console.log({ data });
    return data;
  };

  const getTaskProgressDetailData = () => {
    dispatch(
      getTaskProgressData({}, stateData?.taskId, (res: any) => {
        if (res !== false) {
          setTaskProgressData(transformProgressData(res));
        } else {
          setTimeout(() => {
            setIsTaskData(false);
          }, 1000);
        }
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      })
    );
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  useEffect(() => {
    setLoader(true);
    getTaskProgressDetailData();
  }, []);

  //useEffect(() => {}, [])

  const { errors } = formState;
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const prepareFormData = (data: any) => {
    let filteredDataVal = data.filter(
      (value: any) => Object.keys(value).length !== 0
    );

    let newDataItems = filteredDataVal.map((value: any) => {
      return {
        assigned_hours: parseInt(value.assigned_hours),
        not_applicable: value.not_applicable,
        completed: value.completed,
        completed_by: value.completed ? value.completed_by : null,
        target_end_date: value.target_end_date || null,
        actual_end_date: value.completed ? value.actual_end_date : null,
        comments: value.comments || null,
        task_id: stateData?.taskId,
        id: value.id,
        progress_phase_item_id: parseInt(value.progress_phase_item_id),
        project_role: value.project_role || null,
        initiaterequirements: value.initiaterequirements || null,
      };
    });
    return newDataItems;
  };

  function onFormSubmit(data: any) {
    setLoader(true);
    // display form data on success
    let filteredDataVal = prepareFormData(data?.tickets);

    //console.log("---->", JSON.stringify(filteredDataVal));
    dispatch(
      updateTaskProgressData(
        { task_progress: filteredDataVal },
        stateData.taskId,
        (res: any) => {
          if (res !== false) {
            setSuccessPopup(true);
            getTaskProgressDetailData();
          } else {
            setErrorPopup(true);
          }
          setTimeout(()=>{
            setSuccessPopup(false)
            window.scrollTo(0,0)        
          },2000)
          setLoader(false);
        }
      )
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)} onReset={reset}>
        {taskProgressData &&
          taskProgressData.length > 0 &&
          taskProgressData.map((dataSet, i) => (
            <div key={i} className="col-md-12 mt-4">
              <div key={`subcontainer-${i}`} className="progress-box">
                <div key={`subcontainer1-${i}`} className="form-row">
                  <ProgressTask
                    key={i + 1}
                    Cnt={i}
                    dataSet={dataSet}
                    register={register}
                    errors={errors}
                    task_id={stateData?.taskId}
                    setValue={setValue}
                    watch={watch}
                  />
                </div>
              </div>
            </div>
          ))}
        {!isTaskData && (
          <section className="main-contant inner_page">
            <div className="main-wrap-inner">
              <div className="text-center mt-3">
                <img
                  src="/assets/images/empty-graph.svg"
                  width="220px"
                  height=""
                />
              </div>
              <div className="text-center mt-3">
                <h4>No data found</h4>
              </div>
            </div>
          </section>
        )}
        {taskProgressData.length > 0 &&  (
     ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable )|| ProfileType?.ADMIN  === getProfileType ?  <div className="col-md-2">
            <button
              type="submit"
              className="thame-btn w-100"
            >
              Update
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <button
              className="btn add_button btn-primary mx-0 w-auto"
              type="reset"
            >
              Reset
            </button> */}
          </div>:null
        )}
      </form>
      {/* <BasicForm /> */}
      <CustomModal
        title="Task Progress"
        onOpen={errorPopup}
        content={"Error!Please try again"}
        modalType={"error"}
        width={"md"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Progress"
        onOpen={successPopup}
        content={"Task Progress data saved!"}
        modalType={"success"}
        width={"md"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />

      <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        closeAndSubmit={() => {}}
        onClickYes={() => {}}
        onClickNo={() => {}}
        rowId={""}
        title={`Delete Project Resource`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected row`}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </div>
  );
};

export default Progress;

import React from "react"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/actions/User";
import { useForm } from "../../helpers/validations/useForm";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import "./login.css";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ForgotPassword from "../PasswordReset/forgot";
import { Button, Grid } from "@mui/material";
import { ButtonStyle } from "../Projects/ProjectTask/style";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
interface Login {
  email: any;
  password: any;
}
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © Copyright "}
      {/* <Link color="inherit" href="#"> */}
      Deloitte India LLP{" "}
      {/* </Link> */} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
const logoImg = "/assets/images/logo.png";
const signInImg = "/assets/images/signin.png";
export default function NormalLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorPopup, setErrorPopup] = useState(false);
  const {
    handleSubmit,
    handleChange,
    data: customer,
    errors,
    setData,
  } = useForm<Login>({
    validations: {
      email: {
        required: {
          value: true,
          message: "Email field should not be empty ",
        },
      },
      password: {
        required: {
          value: true,
          message: "Password field should not be empty",
        },
      },
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        loginUser(
          {
            payload: {
              email: customer.email,
              password: customer.password,
            },
          },

          onLoginCallback
        )
      );
    },
  });
  const handleClose = () => {
    setOpenModalPop(false);
  };
  const onLoginCallback = (data) => {
    //redirection logic
    setLoader(false);
    //in response we can get error or success
    if (data === false) {
      setErrorPopup(true);
    } else {
      navigate("/home");
    }
  };
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const handleModalPopup = () => {
    setOpenModalPop(true);    
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="table-wrap">
        <div className="align-wrap">
          <div className="container-fluid p-0 ">
            <div className="row m-0" style={{ float: "left", width: "50%" }}>
              <div
                className="col-md-6 p-0 height-vh bg-dark"
                style={{ width: "100%" }}
              >
                <div className="login-wrap">
                  <div className="logo">
                    <img src={logoImg} alt="Logo" />
                  </div>
                  <div className="text-center inner-img">
                    <img src={signInImg} alt="signing_img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="cantrol_div">
              <div className="form-wrap">
                <h2>
                  Unified Cloud <br /> Integrator
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      id="email"
                      value={customer?.email}
                      onChange={handleChange("email")}
                    />
                    {errors.email && (
                      <p className="error-text">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      id="password"
                      value={customer?.password}
                      onChange={handleChange("password")}
                    />
                    <span className="pass">
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    </span>
                    {errors.password && (
                      <p className="error-text">{errors.password}</p>
                    )}
                  </div>
                  <div className="forgotpassword">
                    <Button
                      onClick={handleModalPopup}
                      size="medium"
                      className="link"
                    >
                      Forgot Password?
                    </Button>
                  </div>
                  <div className="btn-block">
                    <button type="submit" className="thame-btn w-100">
                      Login
                    </button>
                  </div>
                </form>
              </div>

              <div className="footer_section">
                <div className="copyright_text">
                {" © Copyright "}
      Deloitte  LLP
       {new Date().getFullYear()}                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ForgotPassword
        openModalPopUp={openModalPop}
        setOpenModalPopup={setOpenModalPop}
      />

      <CustomModal
        title="Login"
        onOpen={errorPopup}
        content={"Invalid Email/Password, Please try again!"}
        modalType={"error"}
        width={"md"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
    </ThemeProvider>
  );
}

import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../actions/auth";
import { APIResponse } from "../../../utility/commonTypes";
import { integrationRepositoryCatalogueAsync, integrationRepositoryTableAsync, searchTaskNameAsync, sourceTargetSystemAsync } from "../../../api/common";
import { actions, setIntegrationRepositoryCatalogue, setIntegrationRepositoryTable, setSourceTargetSystem } from "../../actions/Repository";
import { taskActions } from "../../actions/Task";


function* GetIntegrationRepositoryTable(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(integrationRepositoryTableAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setIntegrationRepositoryTable({ ...res.data },function(){}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetIntegrationRepositoryCatalogue(action: any) {
    const { payload, callback } = action;
    try {
      let res: APIResponse = yield call(integrationRepositoryCatalogueAsync, payload);
      console.log(res.data);
      if (res?.status === 200) {
        // yield put(setIntegrationRepositoryCatalogue({ ...res.data },function(){}));
        callback(res?.data);
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
    }
  }
  function* SearchByTaskName(action: any) {
    const { payload, callback } = action;
    try {
      let res: APIResponse = yield call(searchTaskNameAsync, payload);
      console.log(res.data);
      if (res?.status === 200) {
        callback(res?.data);
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
    }
  }
  function* SourceTargetSystem(action: any) {
    const { payload, callback } = action;
    try {
      let res: APIResponse = yield call(sourceTargetSystemAsync, payload);
      console.log(res.data);
      if (res?.status === 200) {
        yield put(setSourceTargetSystem({ ...res.data },function(){}));
        callback(res?.data);
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
    }
  }

  export function* watchIntegrationRepository() {
  yield takeLeading(actions.GET_INTEGRATION_REPOSITORY_CATALOGUE, GetIntegrationRepositoryCatalogue);
  yield takeLeading(actions.GET_INTEGRATION_REPOSITORY_TABLE, GetIntegrationRepositoryTable);
  yield takeLeading(taskActions.SEARCH_TASK_NAME, SearchByTaskName);
  yield takeLeading(actions.GET_SOURCE_TARGET_SYSTEM, SourceTargetSystem);


}

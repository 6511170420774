import { Link } from "react-router-dom";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "integration_id",
      headerName: "ID",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.task_name ? (
              <Link
                to={`/create-update-task/${params?.row?.Project?.id}/${params?.row?.Project?.company_customer_id}/${params?.row?.id}`}
                state={{
                  subProject:
                    params?.row?.Project?.project_level === "Sub_Project"
                      ? true
                      : false,
                  projectId: params?.row?.Project?.id,
                  customerId: params?.row?.Project?.Customer?.id,
                  action: "create",
                  taskId: params?.row?.id,
                }}
                style={{ color: "#1976d2" }}
              >
                {params?.row?.integration_id}
              </Link>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },

    {
      field: "task_name",
      headerName: "Task Name",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.task_name ? (
              <Link
                to={`/create-update-task/${params?.row?.Project?.id}/${params?.row?.Project?.company_customer_id}/${params?.row?.id}`}
                state={{
                  subProject:
                    params?.row?.Project?.project_level === "Sub_Project"
                      ? true
                      : false,
                  projectId: params?.row?.Project?.id,
                  customerId: params?.row?.Project?.Customer?.id,
                  action: "create",
                  taskId: params?.row?.id,
                }}
                style={{ color: "#1976d2" }}
              >
                {params?.row?.task_name}
              </Link>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "task_status",
      headerName: "Status",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.task_status || "NA",
    },
    {
      field: "health",
      headerName: "Health",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.health || "NA",
    },
    {
      field: "estimated_hours",
      headerName: "Est. Hours",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.estimated_hours || "NA",
    },
    {
      field: "task_status_summary",
      headerName: "Status Summary",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.Messages || "NA",
    },
    {
      field: "task_owned_by",
      headerName: "Owned by",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.task_owned_by || "NA",
    },
    {
      field: "earned_value",
      headerName: "EV",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.earned_value || "NA",
    },
    {
      field: "planned_value",
      headerName: "PV",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.planned_value || "NA",
    },
    {
      field: "PendingItems",
      headerName: "Pending",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.PendingItems || "NA",
    },
  ];
};

export default columns;

import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PDF from "./images/pdf.svg";
import WORD from "./images/microsoft-word-icon.svg";

const PrintTab = () => {
  const params = useParams();

  return (
    <>
      <div className="container-fluid ">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left mt-2 mb-3 vendor_contact">Print</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="print_box">
                  <a href="#" className="">
                    <div>
                      <Link
                        to={`/pdf/${params?.projectId}/${params?.customerId}/${params?.taskId}/${params?.requirementId}`}
                        state={{
                          projectId: params?.projectId,
                          customerId: params?.customerId,
                          taskId: params?.taskId,
                          requirementId: params?.requirementId,
                        }}
                        style={{ color: "#1976d2" }}
                        target={"_blank"}
                      >
                        <img src={PDF} />
                      </Link>
                    </div>
                    <p>
                      <Link
                        to={`/pdf/${params?.projectId}/${params?.customerId}/${params?.taskId}/${params?.requirementId}`}
                        state={{
                          projectId: params?.projectId,
                          customerId: params?.customerId,
                          taskId: params?.taskId,
                          requirementId: params?.requirementId,
                        }}
                        style={{ color: "#1976d2" }}
                        target={"_blank"}
                      >
                        Click to preview the requirements
                      </Link>
                    </p>
                  </a>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="print_box">
                  <a href="#" className="">
                    <div><img src={WORD} /></div>
                    <p> Click to preview the requirements</p>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTab;

import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NotAuthorized from "../NotAuthorized";
import CustomModal from "../CustomModalPop";
import { getNewToken, LogoutUser } from "../../store/actions/auth";
import IdleTimer, { getIdleTimeFromStorage } from "../../utility/idelTimer";
import {
  clearTimeoutObject,
  createTimeoutObject,
} from "../../utility/timeoutService/timeoutService";

const PrivateRoute = ({ component: Component, profiletype, ...rest }) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((store: any) => {
    return store.auth.isLoggedIn;
  });
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const getSessionTimeout = useSelector((store: any) => {
    return store.auth.globalAuthData?.sessionExp;
  });
  const { allowedIdleTime } = useSelector((store: any) => {
    return { allowedIdleTime: store.auth.globalAuthData.allowedIdleTime };
  });

  const generateNewToken = () => {
    dispatch(
      getNewToken({}, () => {
        setSuccessPopup(false);
      })
    );
  };
  const LogoutNavigation = () => {
    dispatch(
      LogoutUser({}, function (res: any) {
        if (res === true) {
          setSuccessPopup(false);
          navigate("/");
        }
      })
    );
  };

  const checkIfIdle = (timeout: number) => {
    const expDate = new Date(timeout);

    const currentDate = new Date();
    const diff: any = (expDate.getTime() - currentDate.getTime()) / 1000 / 60;

    const timeinMilliseconds = (Math.abs(Math.ceil(diff)) - 1) * 60 * 1000;

    clearTimeoutObject?.();

    createTimeoutObject?.(() => {
      const idleDate = new Date(getIdleTimeFromStorage());
      const currentDate1 = new Date();
      const diffIdle: any =
        (currentDate1.getTime() - idleDate.getTime()) / 1000 / 60;
      if (diffIdle > allowedIdleTime) {
        setSuccessPopup(true);
      } else {
        generateNewToken();
        // clearTimeout(timer);
        // checkIfIdle(getSessionTimeout)
      }
    }, timeinMilliseconds);
  };
  useEffect(() => {
    if (isLoggedIn) {
      checkIfIdle(getSessionTimeout);
    } else {
     clearTimeoutObject?.();
    }
  }, [successPopup, isLoggedIn, getSessionTimeout]);
  if (isLoggedIn && profiletype?.includes(getProfileType)) {
    return (
      <React.Fragment>
        <Component {...rest} />
        <CustomModal
          title="Session Confirmation"
          onOpen={successPopup}
          content={
            "You are about to be logged out! Press Continue if you want to remain logged in."
          }
          modalType={"success"}
          firstBtnName={"continue"}
          secondBtnName={"logout"}
          OnClickFirstBtn={() => generateNewToken()}
          OnClickSecondBtn={() => LogoutNavigation()}
        />
      </React.Fragment>
    );
  } else if (isLoggedIn && !profiletype?.includes(getProfileType)) {
    return <NotAuthorized />;
  } else {
    return <Navigate to="/Login" />;
  }
};
export default PrivateRoute;

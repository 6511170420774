import { ACTION_TYPES } from "../../actionTypes";

const {
  GET_REQUIREMENT_QUESTION,
  UPDATE_REQUIREMENT_QUESTIONS,
  UPDATE_REQUIREMENT_NUMBER_OF_FIELDS,
  CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  GET_REQUIREMENT_NUMBER_OF_FIELDS,
  GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  GET_PDF_REQUIREMENT,
  SET_PROCESS_FLOW,
  GET_PROCESS_FLOW,
} = ACTION_TYPES.REQUIREMENT;

export const requirementActions = {
  CREATE_REQUIREMENT_PENDING_ITEMS: "CREATE_REQUIREMENT_PENDING_ITEMS",
  UPDATE_REQUIREMENT_PENDING_ITEMS: "UPDATE_REQUIREMENT_PENDING_ITEMS",
  DELETE_REQUIREMENT_PENDING_ITEMS: "DELETE_REQUIREMENT_PENDING_ITEMS",
  GET_REQUIREMENT_PENDING_ITEMS: "GET_REQUIREMENT_PENDING_ITEMS",
  SET_REQUIREMENT_PENDING_ITEMS: "SET_REQUIREMENT_PENDING_ITEMS",
  UPDATE_REQUIREMENT_DETAILS: " UPDATE_REQUIREMENT_DETAILS",
  SET_REQUIREMENT_DETAILS: "SET_REQUIREMENT_DETAILS",
  GET_REQUIREMENT_DETAILS: " GET_REQUIREMENT_DETAILS",
  UPDATE_REQUIREMENT_TECHNICAL: "UPDATE_REQUIREMENT_TECHNICAL",
  SET_REQUIREMENT_TECHNICAL: "SET_REQUIREMENT_TECHNICAL",
  GET_REQUIREMENT_TECHNICAL: "GET_REQUIREMENT_TECHNICAL",
  UPDATE_REQUIREMENT_DATA: "UPDATE_REQUIREMENT_DATA",
  GET_REQUIREMENT_DATA: "GET_REQUIREMENT_DATA",
  SET_REQUIREMENT_DATA: "SET_REQUIREMENT_DATA",
  SET_REQUIREMENT_TRANSPORT: " SET_REQUIREMENT_TRANSPORT",
  GET_REQUIREMENT_TRANSPORT: " GET_REQUIREMENT_TRANSPORT",
  UPDATE_REQUIREMENT_TRANSPORT: "UPDATE_REQUIREMENT_TRANSPORT",
  UPDATE_REQUIREMENT_TEST: "UPDATE_REQUIREMENT_TEST",
  SET_REQUIREMENT_TEST: "SET_REQUIREMENT_TEST",
  GET_REQUIREMENT_TEST: "GET_REQUIREMENT_TEST",
  SET_TOGGLE_REQUIREMENT:"SET_TOGGLE_REQUIREMENT"
}
export interface Requirement {
  id: any;
  company_customer_id: any;
  project_id: any;
  task_id: any;
  purpose: any;
  integration_description: any;
  assumptions: any,
  source_system: any,
  target_system: any,
  flow_of_integration_workday: any,
  existing_integration: any,
  initial_proposed_integration: any,
  actual_integration_template: any,
  functional_area: any,
  requirement_template: any,
  integration_type: any,
  existing_requirements_specification_document: any,
  actionType: any
}
export interface PendingItems {
  id: any;
  requirement_id: any;
  type: any;
  date: any;
  due_date: any;
  issue: any;
  priority: any;
  status: any;
  assigned_to: any;
  internal: any;
  allow_notifications: any;
  actionType: any;
  created_by: any;
}
export interface Technical {
  id: any;
  field_data: any,
  requirement_id: any;
  Integration_Run_Frequency: any;
  Anticipated_First_Run_Date: any;
  Scheduling_Specifics: any;
  Trigger: any;
  Dependent_or_required_for_Workday_payroll_Activities: any;
  Launch_Parameters: any;
  Web_Services_Used: any;
  Annual_growth_anticipated_volume: any;
  Performance_Testing_Required: any;
  Audit_File_Requirement: any;
  Exception_Handling_Requirement: any;
  Notification_Requirement: any;
  Notes_and_Additional_Comments: any;
}
export interface RequirementData {
  id: any;
  field_data: any;
  Selection_Criteria: any;
  Mode_or_Change_Detection: any;
  For_changes_only_files_should_records_be_sent_when_entered_or_effective: any;
  Will_data_processed_by_this_integration_be_treated_as_sensitive: any;
  How_will_Terminations_be_handled: any;
  How_are_new_hires_records_handled: any;
  How_are_rehire_records_handled: any;
  Send_Active_Employees: any;
  Send_Contingent_Workers: any;
  Send_Terminations: any;
  How_are_transactions_grouped_or_sorted: any;
  Does_the_integration_always_output_current_data_Any_future_transactions_Explain: any;
  Can_the_record_become_inactive: any;
  what_happen_to_inactive_records: any;
  what_type_of_data_will_be_sent: any;
  Notes_and_Additional_Comments: any
}
interface RequirementTransport {
  id: any;
  field_data: any,
  requirement_id: any;
  Transport_Details_available_with_the_required_infrastructure: any,
  Use_Workday_Temp_File_Functionality: any,
  Delivery_Transport_Production: any,
  AddressorURL_Production: any,
  Directory_Production: any,
  Username_Production: any,
  Authentication_Production: any,
  Delivery_Transport_Test: any,
  AddressorURL_Test: any,
  Directory_Test: any,
  Username_Test: any,
  Authentication_Test: any,
  Document_Retention_Period: any,
  Encryption_Required: any,
  Delete_file_after_retrieval_applicable_for_inbound_integration: any,
  Notes: any
}
export interface RequirementTest {
  id: any;
  field_data: any,
  requirement_id: any;
  Is_a_test_environment_available_or_is_one_required: any,
  Are_there_any_Dependencies_or_timing_requirements_for_testing: any,
  Recommended_number_of_testing_iterations: any,
  Test_Plan_Protocol: any,
  Notes_and_Additional_requirements: any
}

export const updateRequirement = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.UPDATE_REQUIREMENT_DETAILS,
    payload,
    callback,
  };
};
export const setRequirementDetails = (
  payload: Requirement,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.SET_REQUIREMENT_DETAILS,
    payload,
    callback,
  };
};
export const getRequirementDetails = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.GET_REQUIREMENT_DETAILS,
    payload,
    callback,
  };
};
export const createRequirementPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.CREATE_REQUIREMENT_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const updateRequirementPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.UPDATE_REQUIREMENT_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const getRequirementPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.GET_REQUIREMENT_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const setRequirementPendingItems = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.SET_REQUIREMENT_PENDING_ITEMS,
    payload,
    callback,
  };
};

export const deleteRequirementPendingItemsDetails = (
  payload: PendingItems,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.DELETE_REQUIREMENT_PENDING_ITEMS,
    payload,
    callback,
  };
};
export const updateRequirementTechnical = (
  payload: Technical,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.UPDATE_REQUIREMENT_TECHNICAL,
    payload,
    callback,
  };
};
export const getRequirementTechnical = (
  payload: Technical,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.GET_REQUIREMENT_TECHNICAL,
    payload,
    callback,
  };
};
export const setRequirementTechnical = (
  payload: Technical,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.SET_REQUIREMENT_TECHNICAL,
    payload,
    callback,
  };
};
export const updateRequirementData = (
  payload: RequirementData,
  callback: CallableFunction
) => {
  return {
    type: requirementActions.UPDATE_REQUIREMENT_DATA,
    payload,
    callback,
  };
};
export const getRequirementData = (
  payload: RequirementData,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.GET_REQUIREMENT_DATA,
    payload,
    callback,
  };
};
export const setRequirementData = (
  payload: RequirementData,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.SET_REQUIREMENT_DATA,
    payload,
    callback,
  };
};
export const updateRequirementTransport = (
  payload: RequirementTransport,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.UPDATE_REQUIREMENT_TRANSPORT,
    payload,
    callback,
  };
};
export const getRequirementTransport = (
  payload: RequirementTransport,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.GET_REQUIREMENT_TRANSPORT,
    payload,
    callback,
  };
};
export const setRequirementTransport = (
  payload: RequirementTransport,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.SET_REQUIREMENT_TRANSPORT,
    payload,
    callback,
  };
};
export const updateRequirementTest = (
  payload: RequirementTest,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.UPDATE_REQUIREMENT_TEST,
    payload,
    callback,
  };
};
export const getRequirementTest = (
  payload: RequirementTest,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.GET_REQUIREMENT_TEST,
    payload,
    callback,
  };
};
export const setRequirementTest = (
  payload: RequirementTest,
  callback: CallableFunction
) => {

  return {
    type: requirementActions.SET_REQUIREMENT_TEST,
    payload,
    callback,
  };
};



export const getRequirementTemplateQuestionList = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: GET_REQUIREMENT_QUESTION,
    payload,
    callback,
  };
};

export const updateRequirementQuestions = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: UPDATE_REQUIREMENT_QUESTIONS,
    payload,
    callback,
  };
};

export const updateNoOfField = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: UPDATE_REQUIREMENT_NUMBER_OF_FIELDS,
    payload,
    callback,
  };
};
export const createNoFieldsForm = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    payload,
    callback,
  };
};

export const getNoOfField = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: GET_REQUIREMENT_NUMBER_OF_FIELDS,
    payload,
    callback,
  };
};
export const getNoFieldsForm = (
  payload: any,
  callback: CallableFunction
) => {

  return {
    type: GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    payload,
    callback,
  };
};

export const updateNoFieldsForm = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    payload,
    callback,
  };
};

export const deleteNoFieldsForm = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    payload,
    callback,
  };
};

export const getPdfRequirementData = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: GET_PDF_REQUIREMENT,
    payload,
    callback,
  };
};

export const setProcessFlow = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: SET_PROCESS_FLOW,
    payload,
    callback,
  };
};
export const getProcessFlow = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: GET_PROCESS_FLOW,
    payload,
    callback,
  };
};
export const setRequirementToggleValue=(

  payload: any,
  callback: CallableFunction
) => {
  console.log("payload is",payload)
  return {
    type: requirementActions.SET_TOGGLE_REQUIREMENT,
    payload,
     callback,
  };
}

import { PERSONA } from "../../../constants/common";
import { actions } from "../../actions/auth";

const initialState = {
  customerId: "",
  isLoggedIn: false,
  accountId: "",
  globalAuthData: {},
  authData: {},
  mpinWrongAttempts: {
    count: 0,
    lastTry: 0,
    blockScreen: false,
  },
  currentPersona: PERSONA.CUSTOMER,
  userType: "",
  isSSOLogin:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_USER:
      return {
        ...initialState,
      };
    case actions.USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggedIn: false,
        token: "",
        refreshToken: "",
        serverToken: "",
        isSSOLogin:false
      };
    case actions.SET_CURRENT_ACCOUNT_ID:
      return { ...state, accountId: action?.accountId };
    case actions.SET_AUTH_TOKEN:
     { 
      const {isSSOLogin,...payload}=action?.payload||{}
      return {
        ...state,
        isLoggedIn: true,
        globalAuthData: {profile:state?.globalAuthData?.profile,...payload},
        isSSOLogin:state?.isSSOLogin||isSSOLogin
      };}
    case actions.SET_LOGIN_DATA:
      return {
        ...state,
        ...action?.payload,
      };

    case actions.SET_CURRENT_PERSONA:
      return {
        ...state,
        currentPersona: action?.payload,
      };

    case actions.SET_CUSTOMER_TYPE:
      return {
        ...state,
        userType: action?.payload,
      };
      // case actions.SET_IDLE_TIME:
      //   return {
      //     ...state,
      //     globalAuthData: {...state?.globalAuthData,idleTime:action?.payload},
      //   };
      
    default:
      return state;
  }
}

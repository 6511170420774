import React, { useState,useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { getProcessFlow, setProcessFlow } from "../../../store/actions/Requirement";
import { ProfileType } from "../../../utility/commonTypes";
import './index.css';
const ProcessFlow = () => {
  const [prflow, setprflow] = useState<any>({})
const[processFlowData,setProcessFlowData]=useState({location:"",file_name:""})
const [file, setFile] = useState<any>({})
const [errorPopup, setErrorPopup] = useState(false);
const [successPopup, setSuccessPopup] = useState(false);
const [message, setMessage] = useState("");
const [errors, setErrors] = useState({})
const [uploaded,setUploaded]=useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const stateData=location.state
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const handleFlow = (e: any) => {
    // setprflow(URL.createObjectURL(e.target.files[0]))
        setprflow(URL.createObjectURL(e.target.files[0]))
        setUploaded(true)
        setFile(e.target.files[0])
        setErrors({})

  }
  const currentTabData=()=>{
    dispatch(
      getProcessFlow({ id: location?.state?.requirementId }, function (res: any) {
        console.log("res is",res)
        setProcessFlowData({location:res,file_name:''})
        // setProcessFlowData({
        //   ...res,
        //   id:res?.id,
        //   requirement_uploaded_file:res?.requirement_uploaded_file,
        //   file_name:res?.requirement_uploaded_file?.name,
        //  location:res?.location
        // });
      
      })
    )
    
  }
  const pfSubmit = async (e: any) => {
    e.preventDefault()
    const formData: any = new FormData()
    formData.append('id', location?.state?.requirementId)
    formData.append('requirement_uploaded_file', file)
    formData.append('file_name', file[0]?.name)
    console.log("formdata is",file,file?.size)
    let fields: any = {}

    if(file?.size === undefined)
    {
   
      setErrors({prflow:'Required Field'})
    }
    else{
        dispatch(
      setProcessFlow(formData, (data: any,error:any) => {
        console.log("data is",data)
        if (data === false) {
          setMessage(error);
          setErrorPopup(true);
        } else {
          setMessage("Uploaded Image Successfully");
          setSuccessPopup(true);
          setTimeout(()=>{
            setSuccessPopup(false);
      
          },5000)
      
        }
        setTimeout(() => {
          setSuccessPopup(false)
          currentTabData()
          window.scrollTo(0,0)     
          currentTabData() 
        }, 2000);
        setLoader(false);
      
      
      })
    )
    }
  }
  console.log("name is",file[0]?.name)
  console.log("id is",location?.state?.requirementId)
  useEffect(() => {
   
    currentTabData()
      
      
  
  }, [setProcessFlowData]);
console.log("file name",processFlowData?.file_name?.substring(processFlowData.file_name.lastIndexOf("-")+1))
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="col-md-12 pull-left  mb-4 vendor_contact">Process Flow</div>

            <div>
              <div>
                <input type="file" name="requirement_uploaded_file" id="requirement_uploaded_file" value={prflow?.name} className="form-control" onChange={(e: any) => handleFlow(e)} required/>
                {errors?.prflow && (
                        <p className="error-text">
                          {errors?.prflow}
                        </p>
                      )}                
                      
                    
                     {/* <img src="assets/images/upload_image.jpg" alt="process flow"/>} */}
                   {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType?  

                <button className="upload-btn" onClick={(e: any) => {pfSubmit(e)}}>Upload image</button>:null}
              </div>
              <div className="thumbnail_frame">
               {uploaded  && (
                <img
                src={prflow}
                 alt="preview"
                 height="500" width="500"
                  className="img-fluid"
                />)}
{file[0]?.name}
               {!uploaded && processFlowData?.location!=="" &&
               <img
                src={`data:image/png;base64,${processFlowData.location}`}
                height="500" width="500"
                 alt="upload preview"
                  className="img-fluid"
                />
               } 
               {processFlowData?.file_name?.substring(processFlowData.file_name.lastIndexOf("-")+1)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title=" Requirement Process Flow"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title=" Requirement Process Flow"
        onOpen={successPopup}
        content={message}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
    </div>
  );
};
export default ProcessFlow;

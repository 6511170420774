import React from "react"
import "./TransitionDocument.css"
const TransitionDocument=()=>{
    return(
        <div style={{textAlign:"center"}}>
               <h1>Transition Document Generator</h1><br/><br/>
               <h3 style={{textAlign:"center", 
               fontSize:"45px",
    color: "red",
    }} className="blinktext">Coming Soon</h3>
        </div>
    )
}
export default TransitionDocument
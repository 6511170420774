import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { CustomerProjectAsync } from "../../../api/common";
import { customerActions, setProjectList } from "../../actions/Customer";
import { APIResponse } from "../../../utility/commonTypes";

function* CustomerProjectList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(CustomerProjectAsync, payload);
    console.log("res is", res);
    if (res?.status === 200) {
      //  yield put(setAuthToken({ ...res.data, refreshTime: new Date().getTime() / 1000 }))
      //  yield put(setProjectList({ ...res.data}))

      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchCustomerProjects() {
  yield takeLeading(
    customerActions.GET_CUSTOMER_PROJECTS_LIST,
    CustomerProjectList
  );
}

import { call } from "redux-saga/effects";
import {
  getProjectData,
  getProjectByCustomerData,
  getMyProjectDashboardData,
  getCustomerProject,
} from "../../../../api/Projects";
import { APIResponse } from "../../../../utility/commonTypes";

export function* FetchAllProjectLists(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getProjectData, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
/**
 * Dashboard API
 * @param action
 */
export function* FetchMyProjectListsDashboard(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getMyProjectDashboardData, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchMyProjectLists(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getProjectData, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchProjectByCustomerLists(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getProjectByCustomerData, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* FetchCustomerProject(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getCustomerProject, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

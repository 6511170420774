import { Checkbox } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import { Link } from "react-router-dom";
import { PROJECT_LEVEL } from "../../../constants/common";
import Tooltip from "@mui/material/Tooltip";
import { PROJECT_TYPE,PROJECT_STATUS } from "../../../constants/common";
import React from "react";
const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "project_name",
      headerName: "Project Name",
      minWidth: 150,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.project_level === "Sub_Project" ? (
              <Tooltip title={params?.row?.project_name} followCursor>
              <Link
                to={`/project/manage-sub-project/${params?.row?.master_project}/${params?.row?.company_customer_id}/${params?.row?.id}`}
                state={{
                  masterProjectId: params?.row?.master_project,
                  companyCustomerId: params?.row?.company_customer_id,
                  subProjectId: params?.row?.id,
                }}
                style={{ color: "#1976d2" }}
              >
                {params?.row?.project_name}
              </Link>
              </Tooltip>
            ) : (
              <Tooltip title={params?.row?.project_name} followCursor>

              <Link
                to={`/project/${params?.row?.id}/${params?.row?.company_customer_id}`}
                state={{
                  projectId: params?.row?.id,
                  customerId: params?.row?.company_customer_id,
                }}
                style={{ color: "#1976d2" }}
              >
                {params?.row?.project_name}
              </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "project_level",
      headerName: "Level",
      minWidth: 130,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PROJECT_LEVEL.find(
          (o) => o.key === params?.row?.project_level
        );
        return obj?.label || "NA";
      },
    },
    {
      field: "project_status",
      headerName: "Status",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PROJECT_STATUS.find(
          (o) => o.key === params?.row?.project_status
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );

      },
    },
    {
      field: "phase",
      headerName: "Phase",
      minWidth: 70,
      ...commonColumnsDef,
    },
    {
      field: "wave",
      headerName: "Wave",
      minWidth: 70,
      ...commonColumnsDef,
    },

    {
      field: "active",
      headerName: "Active",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Checkbox
            checked={params?.row?.active === true}
            inputProps={{ "aria-label": "controlled" }}
            disabled
          />
        );
      },
    },

    {
      field: "project_type",
      headerName: "Type",
      minWidth: 110,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = PROJECT_TYPE.find(
          (o) => o.key === params?.row?.project_type
        );
        return obj?.label ? (
          <Tooltip title={obj?.label} followCursor>
            <div>{obj?.label}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      field: "engagement_id",
      headerName: "Engagement ID",
      minWidth: 110,
      ...commonColumnsDef,
      renderCell: (params: any) =>
      <Tooltip title={params?.row?.engagement_id} followCursor>
        <div>
      {params?.row?.engagement_id || "NA"}
      </div>
      </Tooltip>
    },
    {
      field: "opportunity_id",
      headerName: "Opportunity ID",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) =>
      <Tooltip title={params?.row?.opportunity_id} followCursor>
        <div>
      {params?.row?.opportunity_id || "NA"}
      </div>
      </Tooltip>
    },
    {
      field: "plan_phase_start_date",
      headerName: "Plan Start Date",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) =>
      <Tooltip title={params?.row?.ProjectDate?.plan_phase_start_date.split("T")[0]} followCursor>
        <div>
      {params?.row?.ProjectDate?.plan_phase_start_date.split("T")[0] || "NA"}
      </div>
      </Tooltip>
    },
    {
      field: "go_live_date",
      headerName: "Go Live Date",
      minWidth: 100,
      ...commonColumnsDef,
      renderCell: (params: any) =>
      <Tooltip title={params?.row?.ProjectDate?.go_live_date.split("T")[0]} followCursor>
      <div>
    {params?.row?.ProjectDate?.go_live_date.split("T")[0] || "NA"}

    </div>
    </Tooltip>
    },
  ];
};

export default columns;

//constants
export const Field_TYPE: any = {
  "DROP_DOWN": {
    "Do_you_have_any_employees_paid_with_multiple_different_rates_multiple_jobs": "Do you have any employees paid with multiple different rates (multiple jobs)?",
    "Will_we_send_defined_amounts_or_coverage_levels_eg_2*Salary": "Will we send defined amounts or coverage levels (eg. 2*Salary) ?",
    "Will_this_integration_require_actionorevent_reason_code_mapping": "Will this integration require action/event reason code mapping ?",
    "Retirement_Saving_Integrations_Will_the_file_contain_Loans_and_Deferrals_together": "Retirement Saving Integrations - Will the file contain Loans and Deferrals together?",
    "Is_a_full_file_required_during_open_enrollment_or_other_high_change_periods": "Is a full file required during open enrollment or other high change periods?",
    "Retirement_Saving_Integrations_Will_loan_numbers_be_recycled": "Retirement Saving Integrations - Will loan numbers be recycled?",
    "Will_adjustment_be_received_through_this_integration": "Will adjustment be received through this integration?",
    "Is_Employee_ID_available_in_the_input_file": "Is Employee ID available in the input file?",
    "Will_pay_groups_be_combined_as_one_settlement_run": "Will pay groups be combined as one settlement run ?",
    "Does_the_3rd_party_system_use_Workday_Employee_ID_as_the_unique_identifier": "Does the 3rd party system use Workday Employee ID as the unique identifier ?",
    "Do_you_need_any_custom_Year_To_Date_data_Included": "Do you need any custom Year To Date data Included ?",
    "Should_these_elections_be_set_up_as_checking_accounts": "Should these elections be set up as checking accounts ?",
    "Will_Contingent_Worker_pay_elections_be_updated": "Will Contingent Worker pay elections be updated ?",
    "Do_we_need_to_included_on_and_off_cycle_results": "Do we need to included on and off cycle results?",
    "Do_we_need_to_send_pay_groups_separately": "Do we need to send pay groups separately?",
    "Do_adjustment_needs_to_be_included": "Do adjustment needs to be included?",
    "Can_negative_amount_be_sent_on_the_file": "Can negative amount be sent on the file?",
    "Do_we_need_to_include_employees_that_are_not_in_the_prompted_period": "Do we need to include employees that are not in the prompted period?",
    "Can_we_send_multiple_pay_dates_by_employees": "Can we send multiple pay dates by employees?",
    "Can_we_send_multiple_pay_dates_for_a_single_file": "Can we send multiple pay dates for a single file?",
    "Do_we_need_to_pass_goal_amounts": "Do we need to pass goal amounts?",
    "Will_plans_need_to_be_prorated": "Will plans need to be prorated?",
    "Are_rescinds_andoror_corrections_required_to_be_sent_via_this_integration_This_increases_the_complexity_of_the_integration": "Are rescinds and/or corrections required to be sent via this integration? (This increases the complexity of the integration)",
    "Will_employee_on_International_Assignments_IA_need_to_be_included_in_the_integration": "Will employee on International Assignments (IA) need to be included in the integration?",
    "Can_a_worker_have_more_than_one_record_in_the_file": "Can a worker have more than one record in the file?",
    "Will_the_integration_require_employee_status_code_mapping": "Will the integration require employee status code mapping?",
    "Is_employee_id_available_in_the_file": "Is employee id available in the file ?",
    "Is_the_integration_expected_to_be_used_to_load_conversion_data": "Is the integration expected to be used to load conversion data?",
    "Will_employees_need_to_be_included_on_this_integration_without_being_enrolled_in_a_plan": "Will employees need to be included on this integration without being enrolled in a plan?",
    "Does_the_file_contain_both_indicativeordemographic_data_and_payroll_related_data": "Does the file contain both indicative/demographic data and payroll related data?",
    "If_using_benefit_elections_do_elections_need_to_be_updated_for_terminated_workers": "If using benefit elections, do elections need to be updated for terminated workers ?",
    "Will_data_for_expatriates_be_sent": "Will data for expatriates be sent?",
    "Do_you_need_an_additional_integration_for_bonuses_and_one_time_payments": "Do you need an additional integration for bonuses and one-time payments?",
    "Do_you_need_an_additional_integration_for_time_off_data": "Do you need an additional integration for time off data?",
    "Do_you_need_an_additional_integration_for_time_tracking_data": "Do you need an additional integration for time tracking data?",
    "Does_time_tracking_correction_data_need_to_be_included": "Does time tracking correction data need to be included?",
    "Do_you_need_leave_of_absence_data_included": "Do you need leave of absence data included?",
    // "Earliest_Open_Pay_Period_or_Pay_Period_for_Current_Date": "Earliest Open Pay Period or Pay Period for Current Date?",
    "Do_you_have_any_employees_paid_in_multiple_currencies": "Do you have any employees paid in multiple currencies?",
    "Do_you_have_any_employees_paid_with_different_pay_rates_multiple_jobs": "Do you have any employees paid with different pay rates (multiple jobs)?",
    "Will_Earnings_be_included_in_this_interface": "Will Earnings be included in this interface?",
    "Will_Deductions_be_included_in_this_interface": "Will Deductions be included in this interface?",
    "Will_Hours_be_included_in_this_interface": "Will Hours be included in this interface?",
    "Will_we_use_the_vendor_earning_codes": "Will we use the vendor earning codes?",
    "Will_we_use_the_vendor_deduction_codes": "Will we use the vendor deduction codes?",
    "Is_a_full_file_required_at_any_point": "Is a full file required at any point?",
    "Are_you_using_3rd_Party_check_print": "Are you using 3rd Party check print?",
    "Do_you_rtequire_employeeorpayee_name_verification": "Do you require employee/payee name verification?",
    "Does_the_file_contain_a_unique_payment_or_transaction_ID_that_would_need_to_be_tracked_in_Workday": "Does the file contain a unique payment or transaction ID that would need to be tracked in Workday?",
    "Is_Employee_ID_available_on_the_file": "Is Employee ID available on the file?",
    "Does_this_require_an_offsetting_earningordeduction": "Does this require an offsetting earning/deduction?",
    "Does_Deloitte_needs_to_calculate_the_taxes_for_this_file": "Does Deloitte needs to calculate the taxes for this file?",
    "Will_the_vendor_have_all_Workday_tax_jurisdiction_codes": "Will the vendor have all Workday tax jurisdiction codes?",
    // "Will_an_adhoc_integration_be_required": "Will an adhoc integration be required?",
    "Is_the_target_payroll_system_already_live": "Is the target payroll system already live?",
    "Is_there_an_existing_file_and_can_a_sample_be_provided": "Is there an existing file and can a sample be provided?",
    "Does_job_history_data_need_to_be_included": "Does job history data need to be included?",
    "Is_there_a_unique_ID_on_the_payroll_system_that_is_different_than_the_emplid": "Is there a unique ID on the payroll system that is different than the emplid?",
    "Does_the_payroll_vendor_have_a_testing_environment": "Does the payroll vendor have a testing environment?",
    "Is_there_an_additional_cost_associated_with_the_testing_environment": "Is there an additional cost associated with the testing environment?",
    "Will_earning_codes_be_sent_on_the_file": "Will earning codes be sent on the file?",
    "Will_deduction_codes_be_sent_on_the_file": "Will deduction codes be sent on the file?",
    "Will_employer_deduction_codes_be_sent_on_the_file": "Will employer deduction codes be sent on the file?",
    "Will_sending_duplicate_records_from_primary_PI_and_adhoc_PI_cause_a_failure_for_the_vendor": "Will sending duplicate records from primary PI and adhoc PI cause a failure for the vendor?",
    "Is_related_persons_information_required_in_your_payroll_system": "Is related persons information required in your payroll system?",
    "Does_related_persons_ID_information_need_to_be_included": "Does related persons ID information need to be included?",
    "Do_you_need_to_include_workers_on_International_Assignment_IA": "Do you need to include workers on International Assignment (IA)?",
    "Should_the_interface_include_any_employee_banking_information": "Should the interface include any employee banking information?",
    "Does_educational_data_need_to_be_included": "Does educational data need to be included?",
    "Is_garnishment_information_currently_processed": "Is garnishment information currently processed?",
    "Will_you_be_processing_data_for_periods_other_than_the_period_of_the_current_date": "Will you be processing data for periods other than the period of the current date?",
    "Do_we_need_to_send_a_status_field_for_terminated_workers": "Do we need to send a status field for terminated workers?",
    "Do_you_pay_workers_after_termination": "Do you pay workers after termination?",
    "Do_you_need_employee_contract_information_for_employees_on_contract_included_different_from_contingent_workers": "Do you need employee contract information for employees on contract included? (different from contingent workers)",
    "Will_one_time_payment_plans_be_included_in_the_integration": "Will one-time payment plans be included in the integration?",
    "Does_this_integration_require_time_off_data": "Does this integration require time off data?",
    "Do_you_need_an_additional_integration_for_time_tracking_data not_included": "Do you need an additional integration for time tracking data? (not included)",
    "Does_time_tracking_correction_data_need_to_be_included_not_included": "Does time tracking correction data need to be included? (not included)",
    "Do_you_need_leave_of_absence_data_included_different_from_time_off": "Do you need leave of absence data included?(different from time off)",
    "Do_you_have_paid_leave_of_absence_and_unpaid_leave_of_absence_being_sent_on_the_output_file": "Do you have paid leave of absence and unpaid leave of absence being sent on the output file?",
    "Will_this_integration_be_generating_an_ACH_to_a_bank": "Will this integration be generating an ACH to a bank?",
    "If_generating_an_ACH_have_all_of_the_deduction_recipients_been_identified_with_bank_accounts_and_electronic_payment_types": "If generating an ACH have all of the deduction recipients been identified with bank accounts and electronic payment types?",
    "Are_you_also_printing_advices": "Are you also printing advices?",
    "Will_this_interface_require_additional_cost_by_the_payroll_vendor": "Will this interface require additional cost by the payroll vendor?",
    "Do_you_allow_One_Time_Payments_for_terminated_workers": "Do you allow One Time Payments for terminated workers?",
    "Will_you_be_using_external_inputs_as_One_Time_Payments": "Will you be using external inputs as One Time Payments?",
    "Will_earning_codes_need_to_be_sent_on_the_file": "Will earning codes need to be sent on the file?",
    "Will_the_input_file_contain_the_Workday_employee_ID": "Will the input file contain the Workday employee ID?",
    "Is_Workday_the_system_of_record_for_accruals": "Is Workday the system of record for accruals?",
    "Will_delete_records_be_sent_for_changes_andoror_deletions": "Will delete records be sent for changes and/or deletions?",
    "Can_the_worker_be_on_concurrent_leaves": "Can the worker be on concurrent leaves?",
    "Do_you_require_employee_or_payee_name_verification":"Do you require employee/payee name verification?",
    "Do_you_require_any_mapping_from_WorkdayTime_Off_Codes_to_vendor_codes": "Do you require any mapping from WorkdayTime Off Codes to vendor codes?",
    "Are_Balances_included": "Are Balances included?",
    "Are_rescinds_andoror_corrections_required_to_be_sent_via_this_integration_Note_: this_increases_the_complexity_of_integration": "Are rescinds and/or corrections required to be sent via this integration? (Note : This increases the complexity of integration)",
    "Can_user_names_be_recycled": "Can user names be recycled?",
    "Should_positions_be_sent_over_when_entered_or_effective_availability_date": "Should positions be sent over when entered or effective (availability date)?",
    "Can_job_requistions_have_multiple_positions_opened_against_them": "Can job requistions have multiple positions opened against them?",
    "Will_job_requisitions_be_used_in_Workday": "Will job requisitions be used in Workday?",
    "Is_there_a_scenario_where_the_position_would_be_moved": "Is there a scenario where the position would be moved?",
    "Will_default_compensation_be_used_in_Workday": "Will default compensation be used in Workday?",
    "Is_position_overlap_turned_on": "Is position overlap turned on?",
    "Are_additional_jobs_in_scope_for_this_integration": "Are additional jobs in scope for this integration?",
    "Are_International_Assignments_in_scope_for_this_integration": "Are International Assignments in scope for this integration?",
    "Does_the_Integration_need_to_update_the_worker_personal_data_for_rehires": "Does the Integration need to update the worker personal data for rehires?",
    "Does_the_integration_need_to_update_the_worker_personal_data_for_Convert_to_Employee": "Does the integration need to update the worker personal data for Convert to Employee?",
    "Does_the_integration_need_to_update_the_worker_personal_data_for_internal_transfers": "Does the integration need to update the worker personal data for internal transfers?",
    "Are_contingent_workers_in_scope": "Are contingent workers in scope?",
    "Will_Contingent_Worker_ID_be_the_same_as_Employee_ID": "Will Contingent Worker ID be the same as Employee ID?",
    "Will_Pre_Hire_ID_be_the_same_as_Employee_ID": "Will Pre-Hire ID be the same as Employee ID?",
    "Will_the_tenant_be_configured_to_'Allow_Override_of_Restrictions_on_Staffing_Events'": 'Will the tenant be configured to "Allow Override of Restrictions on Staffing Events"?',
    "Will_the_Candidate_ID_from_recruiting_system_be_stored_in_Workday": "Will the Candidate ID from recruiting system be stored in Workday?",
    "Does_ATS_handle_internal_advancement_promoteordemoteortransfer": "Does ATS handle internal advancement (promote/demote/transfer)?",
    "Will_changes_be_made_to_the_positionorrequistion_in_the_3rd_party_ATS_system": "Will changes be made to the position/requistion in the 3rd party ATS system?",
    "Will_job_requsitions_be_used_in_this_implementation": "Will job requsitions be used in this implementation?",
    "Doe_sthe_Vendor_support_Scrapping": "Does the Vendor support Scrapping?",
    "Will_personal_data_such_as_DOB_national_ID_home_address_etc_be_collected_in_Workday_prior_to_the_background_check": "Will personal data such as DOB, national ID, home address, etc be collected in Workday prior to the background check?",
    "Does_the_vendor_require_personal_data_such_as_DOB_national_ID_home_address_etc_to_be_sent": "Does the vendor require personal data such as DOB, national ID, home address, etc to be sent?",
    "Is_any_salary_data_being_passed": "Is any salary data being passed?",
    "Will_FMLA_coverage_need_to_be_sent_on_this_integration": "Will FMLA coverage need to be sent on this integration?",
    "Can_a_worker_have_more_than_one_row_in_the_file": "Can a worker have more than one row in the file?",
    "Are_Workers_on_International_Assignment included": "Are Workers on International Assignment  included?",

    "Has_the_client_provided_the_list_of_compensation_elements": "Has the client provided the list of compensation elements?",
    "Have_you_already_received_the_CCB_Kickoff_Kit": "Have you already received the CCB Kickoff Kit?",
    "If_so_have_you_already_sent_out_to_the_provider_Authorization_email_with_the_Provider_Authorization_Requirement_document_attached": "If so have you already sent out to the provider Authorization email with the Provider Authorization Requirement document attached?",
    "Will_you_be_using_Workday_Benefits": "Will you be using Workday Benefits?",
    "Will_you_be_using_Workday_Payroll": "Will you be using Workday Payroll?",
    "Has_the_client_provided_any_required_group_structure": "Has the client provided any required group structure?",
    "Will_any_plans_for_this_integration_be_bundled": "Will any plan(s) for this integration be bundled?",
    "Are_dependents_required_on_this_integration": "Are dependents required on this integration?",
    "If_this_is_a_changes_only_integration_will_a_full_file_be_required_at_any_point": "If this is a changes only integration, will a full file be required at any point?",
    "Are_rescinds_andoror_corrections_required_to_be_sent_via_this_integration": "Are rescinds and/or corrections required to be sent via this integration?",
    "Will_employess_need_to_be_included_on_this_integration_without_being_enrolled_in_a_plan": "Will employees need to be included on this integration without being enrolled in a plan?",
    "Will_this_integration_require_employee_status_code_mapping": "Will this integration require employee status code mapping?",
    "Will_the_integration_require_actionorevent_reason_code_mapping": "Will this integration require action/event reason code mapping?",
    "Is_pay_period_payroll_information_required": "Is pay period payroll information required?",
    "Disability_Integrations_Will_FMLA_coverage_need_to_be_sent_on_this_integration": "Disability Integrations - Will FMLA coverage need to be sent on this integration?",
    "FSA_Integrations: will_plans_needs_to_be_prorated": "FSA Integrations : will plans needs to be prorated?",
    "Insurance_Integrations_Will_we_send_defined_amounts_or_coverage_levels_eg_2*Salary": "Insurance Integrations - Will we send defined amounts or coverage levels (eg. 2*Salary)?",
    "Has_the_vendor_provided_the_834_configuration_attributes": "Has the vendor provided the 834 configuration attributes?",
    "Does_a_separate_file_run_need_to_be_passed_at_year_end_to_end_date_benefits_for_the_current_plan_year": "Does a separate file run need to be passed at year end to end date benefits for the current plan year?",
    "Does_the_Vendor_support_Scrapping": "Does the vendor support scrapping ?",
    "Does_the_vendor_support_scraping": "Does the vendor support Scrapping ?",
    // "Is_YTDorMTDorQTD_payroll_data_required": "Is YTD/MTD/QTD payroll data required?",
    //credit card
    // "What_type_of_card_program_is_this":"What type of card program is this?",
    // "What_is_the_payment_responsibility_for_this_program": "What is the payment responsibility for this program?",
    "Will_this_be_using_the_PCI_Environment":"Will this be using the PCI Environment?",
    "If_using_PCI_has_the_purge_integration_been_added_to_scope":"If using PCI has the purge integration been added to scope?",
    "Will_the_employee_ids_be_included_on_the_file":"Will the employee ids be included on the file?",
    "Will_the_employee_ids_have_leading_zeros":"Will the employee ids have leading zeros?",
    //Currency rates
    "Will_the_source_system_provide_Effective_Date_and_Time_stamps_for_each_currency_conversion_rate":"Will the source system provide Effective Date and Time stamps for each currency conversion rate?",
    //financials
    "Will_the_integration_be_processing_multiple_currencies":"Will the integration be processing multiple currencies?",
    "Does_this_integration_require_HR_data":"Does this integration require HR data?",
    "If_so_has_HR_been_notified_of_the_requirements":"If so has HR been notified of the requirements?",
    "Is_Worktag_Balancing_being_used":"Is Worktag Balancing being used?",
    //Expenses
    "Do_you_require_PCI":"Do you require PCI?",
    "If_yes_has_the_purge_integration_been_added":"If yes, has the purge integration been added?",
    "Will_the_credit_card_files_contain_the_full_credit_card_number":"Will the credit card files contain the full credit card number?",
    "Do_the_employee_ids_have_leading_zeros":"Do the employee ids have leading zeros?",
    //GL Inbound
    "Is_the_FDM_Complete":"Is the FDM Complete?",
    "Will_a_new_Journal_Source_be_required":"Will a new Journal Source be required?",
    "Are_there_any_custom_validations_on_manual_journal_entries":"Are there any custom validations on manual journal entries?",
    "Do_the_Workday_cost_centers_exist_in_the_source_system_Is_there_an_integration_required_to_keep_them_in_sync":"Do the Workday cost centers exist in the source system? Is there an integration required to keep them in sync?",
    "Is_Workday_balancing_being_used":"Is Workday balancing being used?",
    //general ledgers
    "Do_you_need_to_send_forwarding_accruals_to_the_financial_system":"Do you need to send forwarding accruals to the financial system?",
    "Do_you_need_any_pay_component_mapped_to_a_financial_system_dimension":"Do you need any pay component mapped to a financial system dimension?",
    "Do_you_need_pay_components_identified_by_journal_categories":"Do you need pay components identified by journal categories?",
    //procurement
    "Will_this_integration_be_loading_taxes":"Will this integration be loading taxes?",
    //tax
    "Do_you_need_monthly_tax_filing":"Do you need monthly tax filing?",
    //tax rates outbound
    "Does_WD_need_to_pass_federal_tax_rates_on_this_file":"Does WD need to pass federal tax rates on this file?",
    "Does_WD_need_to_pass_state_tax_rates_on_this_file":"Does WD need to pass state tax rates on this file?",
    "Does_WD_need_to_pass_local_tax_rates_on_this_file":"Does WD need to pass local tax rates on this file?",
    "Does_WD_need_to_pass_non-US_tax_rates_on_this_file":"Does WD need to pass non-US tax rates on this file?",
    "Can_the_vendor_take_the_WD_tax_jurisdiction_codes":"Can the vendor take the WD tax jurisdiction codes?",
    "If_a_changes_file,_do_we_need_to_pass_changes_to_rates_as_well":"If a changes file, do we need to pass changes to rates as well? (i.e. the rate being the driver of the change)",
    "If_this_is_a_changes_only_integration,will_a_full_file_be_required_at_any_point":"If this is a changes only integration,will a full file be required at any point?",
    "Can_a_worker_have_more_than_one_row_on_the_file":"Can a worker have more than one row on the file?",
    "Will_this_integration_require_action/event_reason_code_mapping":"Will this integration require action/event reason code mapping?",
    "Are_rescinds_and/or_corrections_required_to_be_sent_via_this_integration":"Are rescinds and/or corrections required to be sent via this integration (NOTE: this increases the complexity of the integration)?",
    // payroll check print updation from text field to drop down
    "Do_you_currently_have_positive_pay": "Do you currently have positive pay?",
    "Will_the_vendor_be_supplying_deduction_recipients": "Will the vendor be supplying deduction recipients?",
    "Will_this_interface_go_live_with_the_primary_payroll_interface": "Will this interface go live with the primary payroll interface?",
    "Will_employees_on_leave_lose_access_to_downstream_systems": "Will employees on leave lose access to downstream systems?",
    "Will_Workday_User_IDs_need_to_be_synchronized_with_the_Active_Directory": "Will Workday User IDs need to be synchronized with the Active Directory?",
    "Will_pre_hires_need_to_access_Workday_prior_to_receiving_AD_credentials": "Will pre-hires need to access Workday prior to receiving AD credentials?",
    "Will_terminated_employees_need_to_access_Workday": "Will terminated employees need to access Workday?"

  },
  "TEXT_FIELD": {
    // "Is_YTDorMTDorQTD_payroll_data_required": "Is YTD/MTD/QTD payroll data required?",
    "If_so_which_plans_are_bundled_together": "If so, which plans are bundled together?",
    "Does_cost_allocation_data_need_to_be_included": "Does cost allocation data need to be included?",
    "If_this_is_a_changes_only_reintegration_does_the_vendor_require_all_transactions_or_top_of_stack": "If this is a changes only reintegration does the vendor require all transactions or top of stack?",
    "What_pay_information_needs_to_be_included_annual_amount_weekly_amount_pay_period_amount_frequency_hours": "What pay information needs to be included ? (annual amount, weekly amount, pay period amount, frequency, hours)",
    "Do_you_use_preprinted_check_stock_or_blank_check_stock": "Do you use preprinted check stock or blank check stock?",
    "Are_you_going_to_print_your_checks_via_Workday_or_would_you_like_to_create_a_check_file_for_a_3rd_party_vendor": "Are you going to print your checks via Workday or would you like to create a check file for a 3rd party vendor?",
    "List_all_the_countriesorregions_that_are_in_scope_for_this_integration": "List all the countries/regions that are in scope for this integration?",
    "How_will_updates_to_existing_leaves_be_sent": "How will updates to existing leaves be sent?",
    "Note_and_Additional_Comments": "Note and Additional Comments.",
    "Retirement_Saving_Integrations_How_many_concurrent_loans_can_an_employee_have": "Retirement Saving Integrations - How many concurrent loans can an employee have?",
    "Which_benefit_plans_will_be_used_or_referenced_in_this_integration": "Which benefit plan(s) will be used or referenced in this integration?",
    "Will_the_integration_be_using_the_payroll_Inputs_or_benefit_plans": "Will the integration be using the payroll Inputs or benefit plans?",
    "When_will_new_electionsorinputs_begin": "When will new elections/inputs begin?",
    "When_will_existing_electionsorinputs_end": "When will existing elections/inputs end?",
    "How_will_ended_electionsorinputs_be_communicated_on_the_file": "How will ended elections/inputs be communicated on the file?",
    "Which_pay_frequencies_will_be_used_on_this_integration": "Which pay frequencies will be used on this integration?",
    "How_many_pay_groups_will_be_configured_in_Workday": "How many pay groups will be configured in Workday ?",
    "Frequency_of_settlements": "Frequency of settlements",
    "What_bank_institution_do_you_currently_use": "What bank institution do you currently use ?",
    "Will_the_employee_have_one_or_multiple_rows_in_the_the_file": "Will the employee have one or multiple rows in the the file?",
    "If_so_which_status_codes_are_required_to_be_included": "If so, which status codes are required to be included?",
    "What_fields_if_missing_data_should_exclude_the_employee_from_the_file": "What fields if missing data should exclude the employee from the file?",
    "Are_there_any_data_elements_that_cant_be_sent_in_exception_circumstances": "Are there any data elements that cant be sent in exception circumstances?",
    "If_IA_records_are_to_be_included_from_which_position_should_data_be_sent": "If IA records are to be included, from which position should data be sent?",
    "If_the_file_has_multiple_record_type_describe_when_records_are_required_to_be_sent": "If the file has multiple record type, describe when records are required to be sent?",
    "What_paygroups_will_be_used_in_this_integration": "What paygroups will be used in this integration?",
    "Are_you_converting_payroll_balances_that_would_be_required_in_any_YTDorMTDorQTD_accumulations_on_this_integration": "Are you converting payroll balances that would be required in any YTD/MTD/QTD accumulations on this integration?",
    "Which_employee_type_will_be_included": "Which employee type will be included?",
    "Will_an_adhoc_integration_be_required": "Will an adhoc integration be required?",
    "When_do_the_rates_become_effective": "When do the rates become effective?",
    "Which_benefit_plan_s_will_be_used_or_referenced_in_this_integration": "Which benefit plan (s) will be used or referenced in this integration ?",
    "If_not_using_Workday_Benefits_describe_any_plan_eligibility_rules": "If not using Workday Benefits, describe any plan eligibility rules",
    "Can_a_Cloud_Connect_for_Benefits_Tempalte_be_used_If_so_which_templates": "Can a Cloud Connect for Benefits Template be used? If so, which templates?",
    "Which_pay_components_will_be_used_or_referenced_in_this_integration": "Which pay component(s) will be used or referenced in this integration?",
    "How_many_groups_will_be_processed": "How many groups will be processed?",
    "What_national_IDs_needs_to_be_included_ex_Social_Security_Govt_ID_Citizenship_ID_National_ID": "What national IDs needs to be included (ex. Social Security, Govt ID, Citizenship ID, National ID)?",
    "Where_will_data_be_loaded_into_Workday": "Where will data be loaded into Workday?",
    "What_value_will_be_used_to_map_the_pay_groups_on_the_input_file": "What value will be used to map the pay groups on the input file?",
    "What_is_the_file_format_of_the_inbound_file": "What is the file format of the inbound file?",
    "Are_you_sending_voids_or_canceled_checks": "Are you sending voids or cancelled checks?",
    "How_many_source_bank_accounts_will_be_used": "How many source bank accounts will be used?",
    "If_the_payments_are_loaded_as_manual_payments_what_bank_accounts_will_be_used": "If the payments are loaded as manual payments what bank account(s) will be used?",
    "Where_will_taxes_be_calculated": "Where will taxes be calculated?",
    "What_taxes_will_need_to_be_loaded_and_will_they_be_available_on_the_file": "What taxes will need to be loaded and will they be available on the file?",
    "If_there_are_multiple_files_can_they_be_processed_together_or_does_it_require_separate_runs": "If there are multiple files, can they be processed together or does it require separate runs?",
    "What_payment_date_should_the_integration_use": "What payment date should the integration use?",
    "Where_will_Data_be_loaded_in_Workday": "Where will Data be loaded in Workday?",
    "How_many_pay_groups_will_be_processed": "How many pay groups will be processed?",
    "What_are_the_pay_period_frequencies": "What are the pay period frequencies?",
    "Which_pay_component_s_will_be_used_or_referenced_in_this_integration_and_how_are_they_mapped_to_vendors_code": "Which pay component (s) will be used or referenced in this integration and how are they mapped to vendor's code ?",
    "What_countries_is_this_payroll_integration_being_created_for": "What countries is this payroll integration being created for?",
    "Will_Inbound_Payroll_results_3rd_Party_Payroll_be_needed": "Will Inbound Payroll results - 3rd Party Payroll be needed?",
    "what_pay_information_needs_to_be_included annual_amount_weekly_amount_pay_period_amount_frequency_hours": "What pay information needs to be included? (annual amount, weekly amount, pay period amount, frequency, hours)",
    "what_additional_pay_information_needs_to_be_included Allowances_reimbursements": "What additional pay information needs to be included? (Allowances, reimbursements)",
    "Are_all_employee_types_included_Do_employees_need_to_be_grouped_in_any_way_Hourly_Salary_part_time_etc": "Are all employee types included? Do employees need to be grouped in any way? ( Hourly, Salary, part-time,etc.)",
    "Do_any_employee_position_salary_organization_status_changes_require_any_reasons_such_as_promoted_transferred_deceased": "Do any (employee, position, salary, organization, status) changes require any 'reasons' such as 'promoted', 'transferred', 'deceased'?",
    "Does_government_tax_information_need_to_be_sent_to_your_payroll_system_state_and_local_tax_info_not_available": "Does government tax information need to be sent to your payroll system (state and local tax info not available)?",
    "what_national_ID's_need_to_be_included_ex_Social_security_Govt_ID,Citizenship_ID,National_ID": "What national ID's need to be included (ex. Social security, Govt ID,Citizenship ID,National ID)?",
    "Who_is_your_garnishment_service_provider": "Who is your garnishment service provider?",
    "If_no_how_will_deduction_recipients_be_loaded": "If no how will deduction recipients be loaded?",
    "Will_the_employee_id_be_provided_on_the_file_or_is_another_form_of_ID_being_used": "Will the employee id be provided on the file or is another form of ID being used?",
    "Is_there_an_existing_integration_so_that_we_can_verify_format_and_structure": "Is there an existing integration so that we can verify format and structure?",
    "Do_you_print_your_checks_for_on_cycle_or_off_cycle": "Do you print your checks for on cycle or off cycle?",
    "How_many_bank_accounts_do_you_have": "How many bank accounts do you have?",
    "Is_there_a_unique_ID_on_the_payroll_system_that_is_different_than_the_employee_ID_and_if_so_what": "Is there a unique ID on the payroll system that is different than the employee ID and if so what?",
    "How_many_pay_groups_exist_for_this_interface": "How many pay groups exist for this interface?",
    "Notes_&_Additional_Comments": "Notes & Additional Comments",
    "Will_the_interface_need_to_perform_any_plan_mapping": "Will the interface need to perform any plan mapping?",
    "Which_benefit_plans_are_included_on_this_interface": "Which benefit plan(s) are included on this interface?",
    "What_leave_types_will_be_sent_from_the_source_system": "What leave types will be sent from the source system?",
    "Which_Time_Off_types_are_included": "Which Time Off types are included?",
    "Please_provide_an_overview_of_the_current_identity_management_approach_Please_include_details_such_as_the_current_directory_system_used_federated_system_if_any_and_any_process_flows_demonstrating_the_current_user_provisioning_process": "Please provide an overview of the current identity management approach. Please include details such as the current directory system used, federated system if any and any process flows demonstrating the current user-provisioning process",
    "Should_users_be_allowed_to_log_in_outside_the_corporate_network_If_yes_are_there_any_restrictions_on_any_group_of_users_that_log_in_outside_of_the_corporate_network_such_as_administartive_users": "Should users be allowed to log in outside the corporate network? If yes, are there any restrictions on any group of users that log in outside of the corporate network (such as administartive users)?",
    "With_the_exception_of_administrative_users_are_there_any_users_that_need_to_access_the_Workday_page_using_native_authentication_non_SSO_logins": "With the exception of administrative users, are there any users that need to access the Workday page using native authentication (non-SSO logins)?",
    "If_the_federated_service_is_available_what_is_the_identity_provider_ID When_can_the_x509_public_key_be_sent": "If the federated service is available, what is the identity provider ID? When can the x509 public key be sent?",
    "Where_should_users_be_redirected_to_if_attempting_to_reach_Workday_directly_Will_this_be_the_same_for_the_test_environment": "Where should users be redirected to if attempting to reach Workday directly? Will this be the same for the test environment?",
    "Where_should_users_land_upon_logging_out_Will_this_be_the_same_for_the_test_environment": "Where should users land upon logging out? Will this be the same for the test environment?",
    "Where_should_users_land_upon_timing_out_Will_this_be_the_same_for_the_test_environment": "Where should users land upon timing out ? Will this be the same for the test environment?",
    "Will_Workday_initiate_logouts_Or_will_identity_provider_initiate_only_If_Workday_can_initiate_logout_will_single_logout_SLO_be_required": "Will Workday initiate logouts? Or will identity-provider initiate only? If Workday can initiate logout, will single-logout (SLO) be required?",
    "How_do_we_handle_the_update_if_one_attribute_fails_to_load_Fail_the_record_or_move_to_the_bnext_field": "How do we handle the update if one attribute fails to load? Fail the record or move to the next field?",
    "Is_an_error_file_required_containing_all_failed_rows_or_is_eventy_log_reporting_acceptable": "Is an error file required Containing all failed rows or is eventy log reporting acceptable?",
    "Do_notifications_need_to_be_sent_for_the_integration_status_Complete_Complete_with_Warnings_Failed": "Do notifications need to be sent for the integration status (Complete, Complete with Warnings, Failed)?",
    "What_time_of_the_day_are_we_running_the_interface": "What time of the day are we running the interface?",
    "How_many_times_a_day_are_we_running_the_interface": "How many times a day are we running the interface?",
    "How_should_adds_hiresorrehires_be_indicated": "How should adds (hires/rehires) be indicated?",
    "How_should_remove_terminations_be_indicated": "How should remove (terminations) be indicated?",
    "What_data_will_be_sourced_from_active_directory_phone_email_custom_ID": "What data will be sourced from active directory (phone, email, custom ID)?",
    "Additional_Information": "Additional Information.",
    "What_compensation_information_should_be_sent_along_with_the_position": "What compensation information should be sent along with the position?",
    "What_values_that_will_be_maintained_on_Workday_need_to_be_passed_back_on_the_hire_record": "What values that will be maintained on Workday need to be passed back on the hire record?",
    "What_types_of_transactions_will_be_sent_to_the_recruiting_system": "What types of transactions will be sent to the recruiting system?",
    "What_recruiting_system_is_being_used": "What recruiting system is being used?",
    "Identify_compensation_terms_that_will_need_to_be_loaded_by_the_integration": "Identify compensation terms that will need to be loaded by the integration",
    "List_all_the_fields_that_will_not_be_provided_on_the_data_feed_as_valid_Workday_values_and_which_will_require_mapping_from_external_system_values_to_Workday_values": "List all the fields that will not be provided on the data feed as valid Workday values and which will require mapping from external system values to Workday values?",
    "Describe_the_approach_for_business_process_reasons_to_be_associated_with_Workday_transactions_being_triggered_by_integration_Eg_Hire_Change_Job_Contract_Contingent_Worker_End_Contract_etc": "Describe the approach for business process reasons to be associated with Workday transactions being triggered by integration. E.g. Hire, Change Job, Contract Contingent Worker, End Contract, etc",
    "Describe_error_and_validation_approach_Errors_that_should_cause_the_integration_to_error_versus_warnings_eg_missing_ceratin_personal_fields": "Describe error and validation approach. Errors that should cause the integration to error versus warnings, e.g. missing certain personal fields",
    "For_transfers_where_the_supervisory_org_is_changing_what_should_be_done_with_the_old_position": "For transfers, where the supervisory org is changing, what should be done with the old position?",
    "Which_steps_of_the_HireorContractorChange_Job_BPs_will_the_integration_need_to_complete": "Which steps of the Hire/Contract/Change Job BPs will the integration need to complete?",
    "For_transfers_how_will_the_integration_identify_which_position_to_transfer_from": "For transfers, how will the integration identify which position to transfer from?",
    "Describe_how_international_assignment_will_be_used_in_Workday": "Describe how international assignment will be used in Workday",
    "How_will_address_data_validation_be_performed": "How will address data validation be performed?",
    "How_will_phone_data_validations_be_performed": "How will phone data validations be performed?",
    "How_will_rehires_be_identified": "How will rehires be identified?",
    "Will_Contingent_Worker_Convert_to_Employee_process_be_handled": "Will Contingent Worker Convert to Employee process be handled?",
    "How_will_internal_candidates_be_indentified": "How will internal candidates be identified?",
    "List_all_fields_that_can_be_modified_in_the_3rd_party_ATS_system": "List all fields that can be modified in the 3rd party ATS system",
    // "Where_will_requistionorposition_originate": "Where will requistion/position originate?",
    "What_fields_if_any_may_have_more_than_one_record_being_sent": "What fields if any may have more than one record being sent?",
    "What_fields_uniquely_identify_a_job_posting_record": "What field(s) uniquely identify a job posting record?",
    "Does_the_file_need_to_pass_any_Evidence_of_Insurability_information": "Does the file need to pass any Evidence of Insurability information?",
    "If_so_how_is_this_calculated": "If so, how is this calculated?",
    "If_so_is_there_a_defined_FMLA_plan_or_is_it_assumed_from_another_plan": "If so, is there a defined FMLA plan or is it assumed from another plan?",
    "Are_there_any_data_elements_that_can't_be_sent_in_exception_circumstances": "Are there any data elements that can't be sent in exception circumstances?",
    "What_fields_if_missing_data_should_exclude_from_file": "What fields if missing data should exclude from file?",
    "If_IA_records_are_to_be_included_from_which_positions_should_the_data_be_sent": "If IA records are to be included, from which positions should the data be sent?",
    "When_does_the_original_benefit_coverage_end": "When does the original benefit coverage end?",
    "Does_this_integration_need_to_be_tied_into_a_business_process_or_run_on_an_ad_hoc_or_scheduled": "Does this integration need to be tied into a business process or run on an ad-hoc or scheduled?",
    "Describe_the_calculation_logic": "Describe the calculation logic",
    "Which_pay_frequencies_will_be_used_in_this_integration": "Which pay frequencies will be used in this integration?",
    "If_an_Open_Enrollment_is_in_scope_for_this_go_live_what_is_the_target_date_for_this_event": "If an Open Enrollment is in scope for this go live, what is the target date for this event?",
    "Which_Benefits_would_the_employee_need_to_enroll_on_the_vendor's_website_as_opposed_to_in_Workday_eg_401K_403B": "Which Benefits would the employee need to enroll on the vendor's website as opposed to in Workday? (eg. 401K, 403B)",
    "What_would_be_the_target_go_live_date_to_send_the_integration_files_out_after_Open_Enrollment": "What would be the target go live date to send the integration files out after Open Enrollment?",
    "Which_CCB_Template_will_be_used": "Which CCB Template will be used?",
    "Which_employee_types_will_be_included_in_the_integration": "Which employee types will be included in the integration?",
    "What_coverage_levels_are_required_in_this_integration": "What coverage levels are required in this integration?",
    "What_pay_groups_will_be_used_in_this_integration": "What pay groups will be used in this integration?",
    "COBRA_Integrations: When_does_coverage_end": "COBRA Integrations : When does coverage end?",
    "Disability_Integrations_If_so_is_there_a_defined_FMLA_plan_or_is_it_assumed_from_another_plan": "Disability Integrations - If so, is there a defined FMLA plan or is it assumed from another plan?",
    "Retirement_Saving_Integrations_What_types_of_plans_will_be_included_eg_Roth_Catch_up": "Retirement Saving Integrations - What types of plans will be included (eg. Roth, Catch-up)?",
    "Notes_and_Additional_Comments": "Notes and Additional Comments",
    "Which_Cloud_Connect_for_Benefits_Template_will_be_used": "Which Cloud Connect for Benefits Template will be used?",
    "If_so_which_employee_status_codes_are_required_to_be_included": "If so, which employee status codes are required to be included?",
    // HCM
    "What_fields_if_any_may_have_more_than_one_value_which_may_result_in_more_than_one_record_being_sent": "What fields if any may have more than one value which may result in more than one record being sent?",
    "What_companies_will_need_to_be_enrolled_with_E_verify_for_employment_verification": "What companies will need to be enrolled with E-verify for employment verification ?",
    "Will_the_Amend_Form_I_9_business_process_be_required": "Will the Amend Form I-9 business process be required?",
    "Will_the_contingent_worker_photos_need_to_be_loaded": "Will the contingent worker photos need to be loaded?",
    "Are_employee_photos_in_JPG_PNG_or_GIF_format Otherwise_photos_will_need_to_be_converted": "Are employee photos in JPG, PNG or GIF format? Otherwise, photos will need to be converted.",
    "Are_the_photos_1_MB_or_less_in_size Otherwise_photos_will_need_to_be_compressed": "Are the photos 1 MB or less in size? Otherwise, photos will need to be compressed.",
    "which_benefit_plans_will_be_used_or_referenced_in_this_integration": "Which benefit plan(s) will be used or referenced in this integration?",
    "Can_a_Cloud_connect_for_Benefits_template_be_used If_so_which_template": "Can a Cloud connect for Benefits template be used? If so, which template?",
    "Will_the_EOI_be_related_to_coverage_levels_or_$_amounts": "Will the EOI be related to coverage levels or $ amounts?",
    "Has_the_Client_provided_the_EOI_logic_around_default_coverage_levelsoramounts": "Has the Client provided the EOI logic around default coverage levels/amounts?",
    "What_Equifax_services_will_be_used": "What Equifax services will be used?",
    "what_record_types_will_be_sent_in_the_file": "What record types will be sent in the file?",
    "what_termination_data_should_be_included": "What termination data should be included?",
    "Are_there_any_termination_statuses_that_are_treated_as_active_or_any_exceptions": "Are there any termination statuses that are treated as active or any exceptions?",
    "Will_this_integration_use_term_reason mapping": "Will this integration use term reason  mapping ?",
    "If_the_integration_requires_term_mapping_has_the_client_provided_the_required_maps": "If the integration requires term mapping, has the client provided the required maps?",
    "Are_corrected_or_rescinded_terminations_expected_to_be_sent If_so_please_describe_what_information_needs_to_be_sent": "Are corrected or rescinded terminations expected to be sent? If so, please describe what information needs to be sent.",
    "What_type_of_organizations_should_be_included_in_the_output": "What type of organizations should be included in the output?",
    "What_Organization_Types_will_be_included": "What Organization Type(s) will be included?",
    "How_should_inactivations_be_processed_when_the_organization_has_active_subordinates": "How should inactivations be processed when the organization has active subordinates?",
    "Will_the_file_pass_changes_to_legal_name": "Will the file pass changes to legal name?",
    "Will_the_file_pass_changes_to_address_information": "Will the file pass changes to address information?",
    "Will_the_file_pass_changes_to_personal_information": "Will the file pass changes to personal information?",
    "Which_employee_types_will_be_included": "Which employee types will be included?",
    "Will_this_integration_require_employee_status_code_mapping If_so_which_employee_status_codes_are_required_to_be_included": "Will this integration require employee status code mapping? If so, which employee status codes are required to be included?",
    "If_this_is_a_changes_only_integration_will_a_full_file_be_required_at_any_time If_so_please_elaborate_in_Notes_&_Additional_Comments": "If this is a changes only integration, will a full file be required at any time? If so, please elaborate in Notes & Additional Comments?",
    "Will_virtualorremote_workers_be_included_in_this_integration If_so_what_address_should_be_sent_as_their_work_address": "Will virtual/remote workers be included in this integration? If so, what address should be sent as work address?",
    "Will_International_Assignment_be_used If_so_should_the_worker's_home_or_host_record_be_sent": "Will international Assignment be used? If so, should the worker's home or host record be sent?",
    "How_far_in_the_future_should_future_dated_terminations_be_included": "How far in the future should future dated terminations be included?",
    "How_far_in_the_future_should_future_dated_hires_be_included": "How far in the future should future dated hires be included?",
     "Notes_and_Additional_requirements": "Notes and Additional requirements",
     "Notes_&_Additional_Requirements":"Notes & Additional Requirements",
    "Does_the_actual_population_include_workers_on_leave_If_so_should_they_be_included_or_excluded_from_output": "Does the actual population include workers on leave? If so, should they be included or excluded from output?",
    "If_using_payroll_inputs_will_it_need_to_handle_deductions_earnings_or_both": "If using payroll inputs, will it need to handle deductions, earnings or both?",

    
    // HCM
    // credit card
    "What_bank_is_issuing_the_cards":"What bank is issuing the cards?",
    // currency 
    "List_the_currency_rate_types_that_are_in_scope_for_this_interface":"List the currency rate types that are in scope for this interface?",
    "Describe_the_cross_rates_or_triangulation_needed":"Describe the cross rates or triangulation needed",
    //financials
    "What_worktags_are_required_or_optional":"What worktags are required or optional?",
    "How_is_Company_id_being_determined":"How is Company id being determined?",
    //expenses
    "Will_expenses_be_directly_entered_into_Workday_or_will_credit_card_transactions_be_entered":"Will expenses be directly entered into Workday or will credit card transactions be entered?",
    "Who_is_your_credit_card_vendor":"Who is your credit card vendor? (ex. AMEX, BOA, Citibank)",
    "If_yes_how_will_leading_zeros_on_the_cardholders_be_handled":"If yes, how will leading zeros on the cardholders be handled?",
    //gl inbound
    "What_are_the_Required_and_Optional_Worktags":"What are the Required and Optional Worktags?",
    "What_is_the_maximum_number_of_Journal_Lines_to_expect_on_this_integration":"What is the maximum number of Journal Lines to expect on this integration? (Workday Limit is 50,000)",
    //general ledger
    "What_is_your_existing_financials_accounting_system":"What is your existing financials accounting system?",
    "Does_your_accounting_system_require_data_to_be_summarized_can_detail_lines_be_sent":"Does your accounting system require data to be summarized can detail lines be sent?",
    "Will_the_GL_be_out_of_payroll_or_expenses":"Will the GL be out of payroll or expenses?",
    "How_should_reversals_be_transmitted":"How should reversals be transmitted?",
    "Will_any_ledger_account_types_need_specific_mapping_or_override":"Will any ledger account types need specific mapping or override?",
    "How_many_account_sets_will_be_configured":"How many account sets will be configured?",
    "What_is_the_limit_on_the_number_of_lines_sent_on_the_output_file":"What is the limit on the number of lines sent on the output file?",
    "How_are_retro_calculation_Payment_dates_determined":"How are retro calculation Payment dates determined?",
    "How_are_prior_period_adjustments_being_handled":"How are prior period adjustments being handled?",
    "Do_the_payroll_companies_correspond_to_the_GL_companies":"Do the payroll companies correspond to the GL companies?",
    "How_should_cross_period_off-cycle_payments_be_handled":"How should cross period off-cycle payments be handled?",
    //procurement
    "How_is_the_Company_Being_Determined":"How is the Company Being Determined?",
    "Will_there_be_any_prepaid_invoices":"Will there be any prepaid invoices?",
    //receivables
    "How_are_tax_amounts_being_determined":"How are tax amounts being determined?",
    "For_inbound_customer_invoices_what_worktags_are_required":"For inbound customer invoices,what worktags are required?",
    "How_is_tax_filing_being_handled":"How is tax filing being handled?",
    //tax
    "Who_is_the_3rd_party_tax_vendor":"Who is the 3rd party tax vendor?",
    "Is_there_an_existing_tax_integration_or_will_there_be_a_new_vendor":"Is there an existing tax integration or will there be a new vendor?",
    //tax rates outbound
    "What_reciprocity_rules_should_be_used":"What reciprocity rules should be used?",
    "If_so,_which_employee_status_codes_are_required_to_be_included":"If so, which employee status codes are required to be included?",
    //updations- payroll payelection from drop down to free text
    "Should_all_elections_be_updated_or_just_one": "Should all elections be updated or just one ?",
    "How_will_a_no_show_be_handled_in_Workday": "How will a no show be handled in Workday?",
    "What_withholding_order_types_should_be_included_in_this_file": "What withholding order types should be included in this file?",
    "Will_the_input_file_explicitly_specify_both_the_Source_Currency_and_the_Target_Currency_for_each_record_If_No_how_should_the_implied_Source_or_Target_Currency_be_derived":"Will the input file explicitly specify both the Source Currency and the Target Currency for each record? If No, how should the implied Source or Target Currency be derived?"
  },
  "DROP_DOWN1": {
    "If_this_is_a_changes_only_integration_does_the_vendor_require_all_transactions_or_top_of_stack": "If this is a changes only integration, does the vendor require all transactions or top of stack?",
  },
  
  "SUBSET_DROP_DOWN": {
    "Will_all_pay_groups_be_included_or_a_subset": "Will all pay groups be included or a subset?",
  },
  "PAYROLL_DROP_DOWN": {
    "If_using_payroll_inputs_will_they_be_one_time_or_ongoing": "If using payroll inputs, will they be one-time or ongoing?",
    "If_using_payroll_inputs_will_there_be_one_time_or_ongoing_payment": "If using payroll inputs, will there be one-time or ongoing payment ?",
  },
  "PAYMENT_DROP_DOWN": {
    "Will_these_payments_be_created_as_On_Demand_or_Manual_Payments": "Will these payments be created as On Demand or Manual Payments?",
  },

  "PAYROLL_INPUT_DROP_DOWN": {
  },
  "DROPDOWN_PI_CONNECTOR":{
    "Earliest_Open_Pay_Period_or_Pay_Period_for_Current_Date": "Earliest Open Pay Period or Pay Period for Current Date",
  
  },

  "HIRE_PROCESS_DROP_DOWN": {
    "Will_the_background_check_occur_during_the_job_application_or_hire_process": "Will the background check occur during the job application or hire process?",
  },
  "SYSTEM_RECORD_DROP_DOWN": {
    "What_is_the_system_of_record_for_positionsorrequistions_ATS_or_Workday": "What is the system of record for positions/requistions? ATS or Workday?",
  },
  "STAFFING_MODAL_DROP_DOWN": {
    "What_staffing_model_is_being_used": "What staffing model is being used?",
  },
  "RECRUITING_HIRE_INBOUND_DROP_DOWN": {
    "Where_will_requistionorposition_originate": "Where will requistion/position originate?",

  },
  "CREDIT_CARD_DROP_DOWN": {
    "What_type_of_card_program_is_this": "What type of card program is this?",
  },
  "CREDIT_CARD_DROP_DOWN_PR": {
    "What_is_the_payment_responsibility_for_this_program": "What is the payment responsibility for this program?",
  },
  "CURRENCY_RATE_DROP_DOWN": {
    "Will_cross_rates_or_rate_triangulation_need_to_be_calculated_by_the_integration": "Will cross rates or rate triangulation need to be calculated by the integration?",
  },
  "GL_INBOUND_DROP_DOWN":{
    "Will_the_journals_go_through_the_Submit_Journal_business_process_or_be_auto-completed":"Will the journals go through the Submit Journal business process or be auto-completed?"
  },
  "GENERAL_LEDGERS_DROP_DOWN_1":{
    "How_will_the_GL_data_be_reconciled_with_the_financial_system":"How will the GL data be reconciled with the financial system?"
  },
  "GENERAL_LEDGERS_DROP_DOWN_2":{
    "Should_actuals_and_accruals_be_sent_in_the_same_file_or_separate_files":"Should actuals and accruals be sent in the same file or separate files?"
  },
  "GENERAL_LEDGERS_DROP_DOWN_3":{
    "Will_the_GL_file_be_automatically_uploaded_or_manually_uploaded":"Will the GL file be automatically uploaded or manually uploaded?"
  },
  "GENERAL_LEDGERS_DROP_DOWN_4":{
    "Are_off_cycle_payments_sent_separately_or_with_the_on_cycle_payments":"Are off cycle payments sent separately or with the on cycle payments?"
  },
  "GENERAL_LEDGERS_DROP_DOWN_5":{
    "What_posting_date_will_be_used_for_actuals":"What posting date will be used for actuals?"
  },
  "RECEIVABLES_DROP_DOWN":{
    "Is_Workday_the_system_of_record_for_Customer_Data":"Is Workday the system of record for Customer Data?"
  },
  "TAX_DROP_DOWN": {
    "Frequency_of_Tax_Filing":"Frequency of Tax Filing"
  },
  "TAX_RATES_OUTBOUND_DROP_DOWN_1": {
    "If_IA_records_are_to_be_included,_from_which_position_should_data_be_sent":"If IA records are to be included, from which position should data be sent?"
  },
  "TAX_RATES_OUTBOUND_DROP_DOWN_2": {
    "Is_YTD/MTD/QTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  },
  "PAYROLL_INTERFACE_DROPDOWN": {
    "Are_we_sending_only_active_payment_elections_or_deleted_accounts_on_the_file": "Are we sending only active payment elections or deleted accounts on the file?"
  },
  "PAYROLL_INTERFACE_DROPDOWN_1": {
    "If_workers_on_IA_are_included_will_they_be_paid_out_of_the_home_system_or_the_international_assignment_system": "If workers on IA are included, will they be paid out of the home system or the international assignment system?"
  },
  "PAYROLL_INTERFACE_DROPDOWN_2": {
    "Will_you_be_printing_the_check_in_actual_size_or_shrinking_to_fit_the_page": "Will you be printing the check in actual size or shrinking to fit the page?"
  },
  "PAYROLL_INTERFACE_DROPDOWN_3": {
    "Are_you_willing_to_use_Workday's_check_layout_or_continue_using_your_bank's_custom_layout": "Are you willing to use Workday's check layout or continue using your bank's custom layout?"
  },
  "PAYROLL_INTERFACE_DROPDOWN_4": {
    "If_you_are_printing_advices,are_you_willing_to_use_Workday's_advice_layout_Or_continue_using_your_bank's_custom_layout": "If you are printing advices,are you willing to use Workday's advice layout Or continue using your bank's custom layout?"
  },
  "ABSENT_MANAGEMENT_TIMEOFF": {
    "What_units_of_Time_Off_will_be_tracked": "What units of Time Off will be tracked?"
  },
  "BENEFITS_OUTBOUND_DROP_DOWN_1": {
    "Is_YTDorMTDorQTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  },
  "BAR_BI_DIRECTIONAL_DROP_DOWN_1": {
    "Is_YTDorMTDorQTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  },
  "LIFE_DISABILITY_DROP_DOWN_1": {
    "Is_YTDorMTDorQTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  },
  "HSAFSA_DROP_DOWN_1": {
    "Is_YTDorMTDorQTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  },
  "FourzeroOne_DROP_DOWN_1": {
    "Is_YTDorMTDorQTD_payroll_data_required":"Is YTD/MTD/QTD payroll data required?"
  }
  
}

import apiClient from "../apiClient";
import { END_POINTS } from "../common/endpoint";

export const getMyProjectDashboardData = async (payload: object) => {
  let epURL =
    END_POINTS.GET_MY_PROJECT_DASHBOARD + `?project_status=In_Progress&active=1`;  
  return await apiClient.post(epURL, payload);
};

export const getProjectData = async (payload: object) => {    
  let epURL = END_POINTS.GET_MY_PROJECT.replace(":page", payload.page);
  epURL = epURL.replace(":size", payload.size);  
  return await apiClient.post(epURL, payload);
};

export const getProjectByCustomerData = async (payload: any) => {
  return await apiClient.get(
    END_POINTS.GET_PROJECT_BY_CUSTOMER +
      `?projectStatus=${payload.projectStatus}` +
      `&companyName=${payload.companyName}` +
      `&size=${payload.size}` +
      `&page=${payload.page}`,
    payload
  );
};

export const getCustomerProject = async (payload: any) => {
  let endPointUrl =
    END_POINTS.GET_CUSTOMER_PROJECT.replace(
      ":id",
      payload.company_customer_id
    ) + `?page=0&size=100`;
  return await apiClient.get(endPointUrl, payload);
};

const footerMenu: any = () => {
  return [

    {
      id: "1",
      title: "Home",
      path: "/Home",
      hide: false,
    },
    {
      id: "2",
      title: "Product",
      path: "/project",
      hide: false,
    },
    {
      id: "3",
      title: "User Profile",
      path: "/create-update-task",
      hide: false,
    },
    {
      id: "4",
      title: "Company",
      path: "/register",
      hide: false,
    },
    {
      id: "5",
      title: "Blog",
      path: "/register",
      hide: true,
    }

  ];
};

export default footerMenu;
import { all, fork } from "redux-saga/effects";
import { watchAuth } from "./auth";
import { watchSignUpCustomer } from "./Customer/createCustomer";
import { watchCustomerAMS } from "./Customer/CustomerAMS";
import { watchCustomerContact } from "./Customer/customerContact";
import { watchCustomerFiles } from "./Customer/customerFiles";
import { watchCustomerModulesList } from "./Customer/customerModules";
import { watchCustomerProjects } from "./Customer/customerProjects";
import { watchSignUpProject } from "./Project/createProject";
import { watchProjectDates } from "./Project/projectDates";
import { watchSignUpProjectResource } from "./Project/projectResource";
import { watchProjectScope } from "./Project/projectScope";
import { watchProjectTask } from "./Project/projectTask";
import { watchSubProject } from "./Project/subProject";
import { watchSignUp } from "./User/createUser";

import { watchCustomers } from "./Customers";
import { watchProjects } from "./Projects";
import { watchTasks } from "./Tasks";
import { watchPendingItems } from "./Task/PendingItems";
import { watchTaskFiles } from "./Task/TaskFiles";
import { watchRequirementDetails } from "./Requirement";
import { watchTaskDetails } from "./Task";
import { watchRequirementTechnical } from "./Requirement/Technical";
import { watchRequirementData } from "./Requirement/data";
import { watchRequirementTransport } from "./Requirement/transport";
import { watchRequirementTest } from "./Requirement/test";
import {  watchTaskResource } from "./Task/resources";
import { watchIntegrationRepository } from "./Repository";
import { watchTaskTechnicalFiles } from "./Task/TechnicalFiles";
export function* rootSaga() {
  yield all([
    fork(watchAuth),
    fork(watchSignUp),
    fork(watchSignUpCustomer),
    fork(watchSignUpProject),
    fork(watchCustomerProjects),
    fork(watchCustomerFiles),
    fork(watchCustomerContact),
    fork(watchCustomerModulesList),
    fork(watchCustomerAMS),
    fork(watchProjectScope),
    fork(watchProjectDates),
    fork(watchSignUpProjectResource),
    fork(watchSubProject),
    fork(watchProjectTask),
    fork(watchCustomers),
    fork(watchProjects),
    fork(watchTasks),
    fork(watchPendingItems),
    fork(watchTaskFiles),
    fork(watchTaskTechnicalFiles),
    fork(watchRequirementDetails),
    fork(watchTaskDetails),
    fork(watchRequirementTechnical),
    fork(watchRequirementData),
    fork(watchRequirementTransport),
    fork(watchRequirementTest),
    fork(watchTaskResource),
    fork(watchIntegrationRepository)
  ]);
}

import { ACTION_TYPES } from "../../../actionTypes";

const {
  GET_MY_TASKS_LIST,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO,
} = ACTION_TYPES.TASKS;

export const getMyTasksList = (payload: object, callback: CallableFunction) => {
  return {
    type: GET_MY_TASKS_LIST,
    payload,
    callback,
  };
};

export const getTasksByCustomerAndProjectList = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST,
    payload,
    callback,
  };
};

export const getTasksByCustomerProjectHealthAction = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH,
    payload,
    callback,
  };
};

export const getTasksByCustomerProjectHealthRatioAction = (
  payload: object,
  callback: CallableFunction
) => {
  return {
    type: GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO,
    payload,
    callback,
  };
};

import { call, put, takeLeading } from "redux-saga/effects";
import { customerActions, setCustomerDetails } from "../../actions/Customer";
import {
  CustomerDetailsAsync,
  CustomerDetailsUpdateAsync,
  signupCustomerAsync,
} from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";

function* SignupCustomer(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.actionType == "create"
        ? signupCustomerAsync
        : CustomerDetailsUpdateAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      callback?.(res?.data);
    } else if (res?.status === 200) {
      callback?.(res?.data);
    } else {
      callback(false, res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

function* getCustomerDetails(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(CustomerDetailsAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setCustomerDetails({ ...res.data }));
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchSignUpCustomer() {
  yield takeLeading(customerActions.SIGNUP_CUSTOMER, SignupCustomer);
  yield takeLeading(customerActions.GET_CUSTOMER_DETAILS, getCustomerDetails);
  yield takeLeading(customerActions.UPDATE_CUSTOMER_DETAILS, SignupCustomer);
}

import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../actions/auth";
import {
  requirementActions, setRequirementData, setRequirementTechnical
} from "../../actions/Requirement";
import { APIResponse } from "../../../utility/commonTypes";
import { getRequirementDataAsync, getRequirementTechnicalAsync, updateRequirementDataAsync, updateRequirementTechnicalAsync } from "../../../api/common";

function* RequirementData(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
     
        updateRequirementDataAsync,
      payload
    );
    console.log(res.data);
   
 if (res?.status === 200) {
      yield put(setRequirementData({ ...res.data },function(){}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* GetRequirementData(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getRequirementDataAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      yield put(setRequirementData({ ...res.data },function(){}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchRequirementData() {
  yield takeLeading(requirementActions.GET_REQUIREMENT_DATA, GetRequirementData);
  yield takeLeading(requirementActions.UPDATE_REQUIREMENT_DATA, RequirementData);
}

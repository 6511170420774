import React, { useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import columns from "./columnsDef";
import { TableCellStyle } from "./style";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const FieldRequirementPopUp = ({
  fielwRquirementList,
  mappingRow,
  handleChange,
  onAddMappingRow,
  onMappingDeleteRow,
}: any) => {
  const [rowID, setRowID] = React.useState("");
  const [editStop, setEditStop] = React.useState(false);
  const defaultColumns: any = useMemo(() => {
    return columns();
  }, []);
  //console.log("---->", mappingRow);
  const OnClickEdit = (RowVal: any, editShow: any) => {
    setEditStop(editShow);
    setRowID(RowVal);
  };
  let listNo = fielwRquirementList?.filter((arrayList: any) => {
    //console.log(arrayList, "arrayList");
    return (
      arrayList.type === mappingRow?.type &&
      arrayList.field_order === mappingRow?.field_order
    );
  });

  const onAddClick = () => {
    setEditStop(true);
    setRowID(listNo[0]?.field_attributes?.Mapping?.length+1);
    onAddMappingRow(
        listNo[0],
        listNo[0]?.field_attributes?.Mapping?.length
    )
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Button
          variant="contained"
          onClick={onAddClick}>
          ADD
        </Button>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {defaultColumns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listNo[0]?.field_attributes?.Mapping?.length === 0 && (
              <TableRow
                hover
                className="text-center"
                sx={{
                  background: "#f6f6f6",
                }}
              >
                <TableCell
                  align="center"
                  colSpan={12}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  No Rows
                </TableCell>
              </TableRow>
            )}
            {fielwRquirementList?.map((row: any, index: any) => {
              return row.type === mappingRow?.type &&
                row.field_order === mappingRow?.field_order &&
                row?.field_attributes?.Mapping?.length > 0
                ? row?.field_attributes?.Mapping?.map((mappingData: any, mIndex: number) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={mappingData?.field_order}
                      >
                        {defaultColumns.map((column: any) => {
                          const valueData = mappingData?.[column?.id];
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                sx={TableCellStyle}
                                align={column.align}
                              >
                                {column?.id === "Action" ? (
                                  <div className="d-inline-flex">
                                    {editStop && mIndex == rowID - 1 ? (
                                      <IconButton
                                        onClick={() => {
                                          OnClickEdit(
                                            mappingData?.field_order,
                                            false
                                          );
                                        }}
                                        className="p-0"
                                      >
                                        <CheckOutlinedIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => {
                                          OnClickEdit(
                                            mappingData?.field_order,
                                            true
                                          );
                                        }}
                                        className="p-0"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}

                                    <IconButton
                                      onClick={() =>
                                        onMappingDeleteRow(
                                          row,
                                          mappingData?.field_order
                                        )
                                      }
                                      className="p-0"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <>
                                    {editStop &&
                                    rowID === mIndex + 1  &&
                                    column?.id !== "field_order" ? (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={handleChange(
                                          column?.id,
                                          "",
                                          "RequirementFields",
                                          "",
                                          "",
                                          mappingRow.id,
                                          true,
                                          "field_attributes",
                                          "",
                                          "",
                                          "",
                                          "",
                                          "",
                                          true,
                                          "Mapping",
                                          mappingData?.field_order,
                                          "field_order"
                                        )}
                                        value={valueData}
                                        placeholder=""
                                        style={{
                                          width: "80px",
                                          height: "45px",
                                          textAlign: "center",
                                          border: "1px solid blue",
                                          borderColor: "#86b7fe",
                                          outline: 0,
                                          boxShadow:
                                            "0 0 0 0.25rem rgb(13 110 253 / 25%)",
                                        }}
                                      />
                                    ) : (
                                      <div className="text-capitalize">
                                        {valueData}
                                      </div>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default FieldRequirementPopUp;

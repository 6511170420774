import { requirementActions } from "../../actions/Requirement";

const initialState = {
  data: [],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
  case requirementActions.SET_TOGGLE_REQUIREMENT: {
    console.log("action payload",action?.payload?.payload)
    return {
      ...state,
      data: action?.payload?.payload,
      
    };
  }
    case requirementActions.SET_REQUIREMENT_DETAILS: {
      console.log("state is",state)
      console.log("action is",action?.payload)

      return {
        ...state,
        data: action?.payload,
        
      };
    }
    // case requirementActions.SET_TOGGLE_REQUIREMENT: {
    //   return {
    //     ...state,
    //     data: {
    //       locked: action?.payload?.locked,
    //       // data: {
    //       //     companyId:action?.payload.company_customer_id,
    //       //     intId:action?.payload.integration_id,
    //       //     taskName:action?.payload.task_name,
    //       //     projectId:action?.payload.project_id,
    //       //     customer_id:action?.payload.Project.Customer.id
    //     },
    //   };
    // }
    default:
      return state;
  }
  

}

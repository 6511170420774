import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "company_name",
      headerName: "Company Name",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.company_name ? (
              <Tooltip title={params?.row?.company_name} followCursor>
                <Link
                  to={`/customer/${params?.row?.id}/${params?.row?.company_name}`}
                  state={{
                    customerId: params?.row?.id,
                    companyId: params?.row?.company_name,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.company_name}
                </Link>
              </Tooltip>
            ) : (
              <Link
                to={`/customer/${params?.row?.id}`}
                state={{ customerId: params?.row?.id }}
                style={{ color: "#1976d2" }}
              >
                {params?.row?.company_name}
              </Link>
            )}
          </>
        );
      },
    },
    {
      field: "industry",
      headerName: "Industry",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.industry || "NA",
    },
    {
      field: "headquater",
      headerName: "Headquater",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.headquarter || "NA",
    },
    {
      field: "region",
      headerName: "Region",
      minWidth: 70,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.region || "NA",
    },
    {
      field: "legacyHRSystem",
      headerName: "Legacy HR System",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.legacy_hr_system || "NA",
    },
    /*{
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            minWidth: 122,
            ...commonColumnsDef,
            renderCell: (params: any) => {
                return (
                    <Link to={`/customer/${params?.row?.id}`} state={{ customerId: params?.row?.id }}>
                        <EditIcon sx={{ color: "#B4B2B2" }} />
                    </Link>

                );
            }
        },*/
  ];
};

export default columns;

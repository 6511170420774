import React from "react";
import { useState, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../../components/CustomLoader/loaderState";
import CustomModal from "../../../../components/CustomModalPop";
import {
  errorMessages,
  REPLACE_REGEX,
  TASK_FILE_CATEGORY,
  TASK_FILE_VIEW_BY,
} from "../../../../constants/common";
import { useForm } from "../../../../helpers/validations/useForm";
import { generateSignedUrl, generateSignedUrlTechnical, uploadTechnicalTaskFiles } from "../../../../store/actions/Task";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

interface TechnicalTaskFiles {
  id: any;
  file_name: any;
  uploaded_by: any;
  timestamp: any;
  task_uploaded_file: any;
  comments: any;
  category: any;
  view_by: any;
  file_type: any;
  task_technical: any;
  keyForPSUrl: any;

  actionType: any;
}
const TechnicalTaskFilesForm: FC = ({
  row,
  openModalPop,
  setOpenModalPop,
  handleClose,
  onSuccess,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actionType, setActionType] = useState("create");
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const location: any = useLocation();
  const params: any = useParams();
  const stateData = params;
  const [open, setOpen] = useState(false);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [message, setMessage] = useState("");
  const {
    handleSubmit,
    handleChange,
    data: technicalFiles,
    handleFileUpload,
    setData,
    errors,
  } = useForm<TechnicalTaskFiles>({
    validations: {
      category: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      view_by: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
      },
      comments:{
        pattern:{
            value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
            message:errorMessages?.pattern()
          },
          maxlength: {
            value: 100,
            message: errorMessages?.maxlength("Description",100)
          },
        },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      let jsonFormat = {
        id: technicalFiles?.id,
        uploaded_by: technicalFiles?.uploaded_by,
        comments: technicalFiles?.comments,
        category: technicalFiles?.category,
        view_by: technicalFiles?.view_by,
        filename:technicalFiles?.task_uploaded_file?.name,
        task_uploaded_file: technicalFiles?.task_uploaded_file,
        task_technical: "1",
        actionType: actionType,
      };
      if (
        technicalFiles?.task_uploaded_file?.size === undefined &&
        technicalFiles?.task_uploaded_file?.name === undefined
      ) {
        delete jsonFormat.task_uploaded_file;
      }
      // dispatch(
      //   uploadTechnicalTaskFiles(
      //     {
      //       jsonFormat,
      //     },
      //     stateData?.taskId,
      //     onCreateTaskFilesCallback
      //   )
      // );
      dispatch(generateSignedUrlTechnical(
       
        jsonFormat,
        stateData?.taskId,

      
      onCreateTaskFilesCallback

      ));
    },
  });

  const onCreateTaskFilesCallback = (data: any, error: any) => {
    setLoader(false);
    if (data == false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      onSuccess();
    }
    setOpenModalPop(false);

    setLoader(false);
  };
  useEffect(() => {
    if (row && row?.id) {
      console.log("details", row);
      row?.id && setActionType("update");
      setData({
        id: row?.id,
        uploaded_by: row?.uploaded_by,
        comments: row?.comments,
        category: row?.category,
        view_by: row?.view_by,
        file_name: row?.file_name,
        keyForPSUrl: row?.keyPSUrl,
        url: row?.url,
        location: row?.location,
      });
    } else {
      setActionType("create");
      setData({
        uploaded_by: "",
        comments: "",
        category: "",
        view_by: "",
        task_uploaded_file: "",
      });
    }
  }, [setData, row]);

  return (
    <>
      <CustomModal
        title="Upload File"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      onChange={handleChange("comments")}
                      name="type"
                      id="type"
                      value={technicalFiles?.comments}
                      className="form-control"
                    />
                     {errors.comments && (
                      <p className="error-text">{errors.comments}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Category *</label>
                    <select
                      onChange={handleChange("category")}
                      className="form-control"
                      value={technicalFiles?.category}
                    >
                      <option value="">--Select--</option>
                      {TASK_FILE_CATEGORY.map((category) => (
                        <option value={category.key}>{category.label}</option>
                      ))}
                    </select>
                    {errors.category && (
                      <p className="error-text">{errors.category}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>View By *</label>
                    <select
                      value={technicalFiles?.view_by}
                      onChange={handleChange("view_by")}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {TASK_FILE_VIEW_BY.map((view) => (
                        <option value={view.key}>{view.label}</option>
                      ))}
                    </select>
                    {errors.view_by && (
                      <p className="error-text">{errors.view_by}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload File</label>
                    <input
                      type="file"
                      name="task_uploaded_file"
                      onChange={handleFileUpload}
                      className="form-control"
                    />
                    <a href="#">{technicalFiles?.file_name}</a>
                  </div>
                </div>
                <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                      onClick={() => {
                        setLoader(false);
                      }}
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title="Task Files"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Files"
        onOpen={successPopup}
        content={"Task Files Uploaded Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};
export default TechnicalTaskFilesForm;

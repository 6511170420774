import Header from "../Header/LoginPageHeader/header";
import LoginFooter from "../Footer/LoginFooter";
import withNavigation from "../../components/Nav/withNavigation";
import "./passwordSuccess.css";
import Link from "@material-ui/core/Link";

const PasswordSuccess = () => {
  const HeaderComponentWithNavigation = withNavigation(Header);
  return (
    <div>
      <HeaderComponentWithNavigation />
      <div className="success">
        <fieldset className="fieldBox">
          <h3>
            Password Reset done Successfully
            <br />
            <br />
          </h3>
          <br />

          <Link className="anchor" href="/login">
            Return to Login
          </Link>
        </fieldset>
      </div>
      <LoginFooter />
    </div>
  );
};

export default PasswordSuccess;

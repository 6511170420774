import { useEffect, useState, useMemo } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../components/CustomPagination";
import CustomModal from "../../../components/CustomModalPop";
import Columns from "./columnsDef";
import { BoxStyle, HeaderStyle, ButtonStyle } from "./style";
import setLoader from "../../../components/CustomLoader/loaderState";
import { getProjectTask } from "../../../store/actions/Project";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewForm from "../../Register/viewPage";
import { ProfileType } from "../../../utility/commonTypes";

const ProjectTasks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<");
  console.log({ location, params, stateData });
  console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<");
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
  const[userId,setUserId]=useState("")
 
  const handleClickOpen = () => {
    navigate(
      "/create-update-task/" +
        (stateData?.projectId
          ? stateData?.projectId
          : stateData?.subProjectId
          ? stateData?.subProjectId
          : null) +
        "/" +
        (stateData?.customerId
          ? stateData?.customerId
          : stateData?.companyCustomerId
          ? stateData?.companyCustomerId
          : null),
      {
        state: {
          subProject: stateData?.subProjectId ? true : false,
          projectId: stateData?.projectId
            ? stateData?.projectId
            : stateData?.subProjectId
            ? stateData?.subProjectId
            : null,
          customerId: stateData?.customerId
            ? stateData?.customerId
            : stateData?.companyCustomerId
            ? stateData?.companyCustomerId
            : null,
          action: "create",
          taskId: "",
        },
      }
    );
  };
  console.log({ location });
  const fetchProjectTaskList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getProjectTask(
          {
            id: stateData?.projectId
              ? stateData?.projectId
              : stateData?.subProjectId
              ? stateData?.subProjectId
              : null,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            console.log({ data });
            let taskList = data?.rowsReturned;
            let taskListData: any = [];
            if (taskList?.length > 0) {
              console.log({ taskListData });
              setRowData(data.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const handleUserDetails=(event:any,id:any)=>{
    setOpenUserDetailsPopUp(true)
    setUserId(id)
  }
  useEffect(() => {
    fetchProjectTaskList(0, "ON_LOAD");
  }, []);

  const defaultColumns = useMemo(() => {
    return Columns(handleUserDetails);
  }, []);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Project Task
        </Typography>
        <div className="my-4 mx-4">
  {ProfileType?.ADMIN === getProfileType &&        <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>}
        </div>
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchProjectTaskList(data, "NEXT_PAGE");
          }}
        />
      </Box>

      <CustomModal
        title="Project Task"
        onOpen={successPopup}
        content={"Project Task Created Successfully"}
        modalType={"success"}
        width={"lg"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
    </>
  );
};

export default ProjectTasks;

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
// import columns from "../columnsDef"
// import { BoxStyle } from '../style'
import CustomPagination from "../../../../components/CustomPagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TaskFilesForm from "./TaskFilesForm";
import { BoxStyle, ButtonStyle, HeaderStyle } from "../../style";
import Columns from "./columnsDef";
import { deleteTaskFiles, getTaskFiles } from "../../../../store/actions/Task";
import { CustomConfirmPopup } from "../../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import CustomModal from "../../../../components/CustomModalPop";
import setLoader from "../../../../components/CustomLoader/loaderState";
import ViewForm from "../../../Register/viewPage";
import { ProfileType } from "../../../../utility/commonTypes";

const TaskFiles = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteSuccessPopup, setDeleteSuccessPopup] = useState(false);
  const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
  const[userId,setUserId]=useState("")

  const [loading, setLoading] = useState(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [selectedRowId, setRowId] = useState("");
  const params: any = useParams();
  const stateData = params;
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });


  const handleClickOpen = () => {
    setOpenModalPop(true);
    setRowId("");
  };
  const location: any = useLocation();
  const [successPopup, setSuccessPopup] = useState(false);
  const navigate = useNavigate();

  const fetchTaskFilesList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getTaskFiles(
          {
            id: stateData?.taskId,
            task_technical: 0,
            size: 5,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const onPendingItemSuccess = () => {
    fetchTaskFilesList(0, "ON_LOAD");
    setSuccessPopup(true);
    setTimeout(()=>{
      setSuccessPopup(false);

    },5000)
  };
  useEffect(() => {
    fetchTaskFilesList(0, "ON_LOAD");
  }, []);
  const deleteTaskFile = (event: any, id: any) => {
    setDeletePopUp(true);
    setRowId(id);
  };

  const editTaskFile = (event: any, id: any) => {
    setOpenModalPop(true);
    setRowId(id);
  };
  const handleUserDetails=(event:any,id:any)=>{
    setOpenUserDetailsPopUp(true)
    setUserId(id)
  }
  const onClickYes = (fileName: any, taskId: any) => {
    setDeletePopUp(false);
    setLoader(true);
    dispatch(
      deleteTaskFiles({ file_name: fileName, id: taskId }, function (res: any) {
        setLoader(false);
        setDeleteSuccessPopup(true);
        setTimeout(()=>{
          setDeleteSuccessPopup(false);
    
        },5000)
        fetchTaskFilesList(0, "ON_LOAD");
      })
    );
  };
  const onClickNo = () => {
    setDeletePopUp(false);
  };

  const defaultColumns = useMemo(() => {
    return Columns(editTaskFile, deleteTaskFile,handleUserDetails);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={{ ...HeaderStyle }} className="my-4 mx-4">
          Files
        </Typography>
        {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ?  

        <div className="my-4 mx-4">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            {/* <FileUploadIcon className="add_button_table_top pull-right"/> */}
            Upload
          </Button>
        </div>:null}
      </Grid>
      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchTaskFilesList(data, "NEXT_PAGE");
          }}
        />
      </Box>

      {openModalPop && (
        <TaskFilesForm
          row={selectedRowId}
          onSuccess={onPendingItemSuccess}
          handleClose={handleClose}
          openModalPop={openModalPop}
          setOpenModalPop={setOpenModalPop}
        />
      )}
              {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ?  

      <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        closeAndSubmit={() => {}}
        rowId={selectedRowId}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
        taskId={stateData?.taskId}
        title={`Delete Task Files`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected row`}
        //  successIcon={true}
      />:null}
      <CustomModal
        title="Task Files"
        onOpen={successPopup}
        content={"Task Files Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
      <CustomModal
        title="Task Files"
        onOpen={deleteSuccessPopup}
        content={"Task Files Deleted Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setDeleteSuccessPopup(false);
        }}
      />
    </>
  );
};
export default TaskFiles;

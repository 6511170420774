import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { projectTaskAsync, getCustomerProjectAsync } from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";
import { actions } from "../../actions/Project";

function* ProjectTaskList(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(projectTaskAsync, payload);
    console.log("res is", res);
    if (res?.status === 200) {
     callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* GetCustomerProjects(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(getCustomerProjectAsync, payload);
    console.log("res is", res);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchProjectTask() {
  yield takeLeading(actions.GET_PROJECT_TASK, ProjectTaskList);
  yield takeLeading(actions.GET_CUSTOMER_PROJECTS, GetCustomerProjects);
}

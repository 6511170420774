import { call, put, takeLatest } from "redux-saga/effects";
import { subProjectAsync } from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";
import { actions, setSubProjects } from "../../actions/Project";
function* GetSubProjects(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(subProjectAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setSubProjects({ ...res.data }));
      callback?.(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchSubProject() {
  yield takeLatest(actions.GET_SUB_PROJECT_LIST, GetSubProjects);
}

import { createStore, compose, applyMiddleware } from "redux";

import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import Cookies from 'js-cookie';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { setStore } from "../api/apiClient";
import { APP_PREFIX } from "../constants/common";

import reducers from "./reducers";
import { rootSaga } from "./sagas";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: new CookieStorage(Cookies,{
    setCookieOptions: {
      path: '/',
      sameSite: 'Lax',
      domain: '.deloitte.com',
      secure: true
    }
  }),
  blacklist: [
    // 'auth',
  ],
  whitelist: ["auth", "apiDebugger"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
function getMiddleware() {
  //if (__DEV__) {
  return applyMiddleware(
    sagaMiddleware,
    createLogger({
      titleFormatter: (action: any) => {
        //, time?: String, took?: Number
        return `${APP_PREFIX.GLOBAL.toUpperCase()} (${action?.type})`;
      },
    })
  );
  //}
  //return applyMiddleware(sagaMiddleware);
}

const sagaMiddleware = createSagaMiddleware();

/* To be used during debugging only */
// const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, createLogger())));
const globalStore = createStore(
  persistedReducer,
  composeEnhancer(getMiddleware())
);
const persister = persistStore(globalStore);
// setting store for api client.
setStore(globalStore);

sagaMiddleware.run(rootSaga);

export { globalStore, persister };

import './LoginFooter.css'
import Link from '@material-ui/core/Link';

const LoginFooter=()=>{
    return(
        <div id="footer">
        <footer>
          {/* <div className="footer-text-left"> */}
              {/* <ul className="list-inline"> */}
                <div className="loginFooter"><Link href="/login"  underline='always'>Home |</Link>
                <Link href="" underline='always'>Products |</Link>
               <Link className="effect" href="" id="left-li" underline='always'>Company |</Link>
                <Link className="effect" href="" id="left-li" underline='always'>Blog</Link></div>

            {/* </ul> */}
          {/* </div> */}
          </footer>
      </div>
          )
          }
export default LoginFooter
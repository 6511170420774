import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import CustomPagination from "../../../components/CustomPagination";

import {
  getAllProjectList,
  getCustomerProject,
} from "../../../store/actions/Projects";
import { getAllCustomerList } from "../../../store/actions/Customers";
import { getTasksByCustomerAndProjectList } from "../../../store/actions/Tasks";

import columns from "./columnsDef";

import {
  TASK_STATUS,
  FUNCTIONAL_AREA,
  INTEGRATION_TYPE,
  REQUIREMENT_TEMPLATE,
  TEMPLATE_DROPDOWNS,
} from "../../../constants/common";

import { BoxStyle, HeaderStyle } from "./style";

import rows from "./taskByCustomer.json";

const TaskByCustomerList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [customerPorjectData, setCustomerProjectData] = useState<object>([]);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState<string>("first");

  const [filterKeys, setFilterKeys] = useState<any>({
    customerName: "",
    projectName: "",
    taskStatus: "",
    functionalArea: "",
    integrationType: "",
    requirementTemplate: "",
  });

  const fetchAllCustomerList = () => {
    try {
      dispatch(
        getAllCustomerList({}, (data: any) => {
          if (data?.length > 0) {
            setCustomerList(data);
          } else {
            setCustomerList([]);
          }
        })
      );
    } catch (e) {
      console.log(e, "error");
    }
  };
  const fetchAllProjectList = () => {
    try {
      dispatch(
        getAllProjectList(
          {
            role: "admin",
            size: 10,
            page: 0,
          },
          (data: any) => {
            console.log(data, "data");
            if (data?.rowsReturned?.length > 0) {
              setProjectList(data?.rowsReturned);
            } else {
              setProjectList([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    //fetchAllProjectList();
    setPageLoaded("first");
    fetchAllCustomerList();
    fetchProjectByCustomerList(0, "ON_LOAD");
  }, []);

  const getCustomerProjectData = (customer_id: any) => {
    try {
      if (customer_id) {
        setLoading(true);
        dispatch(
          getCustomerProject(
            { company_customer_id: customer_id },
            (res: any) => {
              if (res && res?.totalItems) {
                setCustomerProjectData(res?.rowsReturned);
              }
              setLoading(false);
            }
          )
        );
      } else {
        setCustomerProjectData([]);
      }
    } catch (e) {}
  };

  const requirementDropownList = TEMPLATE_DROPDOWNS.filter((val: any) => {
    return val.value === filterKeys?.functionalArea;
  });

  const fetchProjectByCustomerList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getTasksByCustomerAndProjectList(
          {
            taskStatus: filterKeys.taskStatus ? filterKeys.taskStatus : "",
            companyName: filterKeys.customerName ? filterKeys.customerName : "",
            projectName: filterKeys.projectName ? filterKeys.projectName : "",
            functionalArea: filterKeys.functionalArea
              ? filterKeys.functionalArea
              : "",
            integrationType: filterKeys.integrationType
              ? filterKeys.integrationType
              : "",
            requirementTemplate: filterKeys.requirementTemplate
              ? filterKeys.requirementTemplate
              : "",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageLoaded !== "first") {
      fetchProjectByCustomerList(0, "ON_LOAD");
    }
  }, [filterKeys, pageLoaded]);

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        Task By Customer / Project
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <Grid container direction="row" sm={12} alignItems="center">
          <Grid container direction="row" sm={6} alignItems="center">
            <Grid item sm={3}>
              <InputLabel htmlFor="customer">Customer</InputLabel>
            </Grid>
            <Grid item sm={6}>
              <Select
                name="customer"
                fullWidth
                variant="outlined"
                value={filterKeys?.customerName}
                displayEmpty
                onChange={(e: any) => {
                  setFilterKeys({
                    ...filterKeys,
                    customerName: e?.target?.value,
                  });
                  setPageLoaded("second");
                  getCustomerProjectData(e?.target?.value);
                }}
                sx={{ height: "40px" }}
              >
                <MenuItem value="" selected>
                  Select
                </MenuItem>
                {customerList.map((customerItems: any) => {
                  return (
                    <MenuItem value={customerItems.id}>
                      {customerItems.company_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid container direction="row" sm={6} alignItems="center">
            <Grid item sm={3}>
              <InputLabel htmlFor="taskStatus">Task Status</InputLabel>
            </Grid>
            <Grid item sm={6}>
              <Select
                name="taskStatus"
                fullWidth
                variant="outlined"
                displayEmpty
                value={filterKeys?.taskStatus}
                onChange={(e: any) => {
                  setPageLoaded("second");
                  setFilterKeys({
                    ...filterKeys,
                    taskStatus: e?.target?.value,
                  });
                }}
                sx={{ height: "40px" }}
              >
                <MenuItem value="" selected>
                  Select
                </MenuItem>
                {TASK_STATUS.map((taskStatusItem: any) => {
                  return (
                    <MenuItem value={taskStatusItem.label}>
                      {taskStatusItem.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="project">Project</InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="project"
              fullWidth
              variant="outlined"
              value={filterKeys?.projectName}
              displayEmpty
              onChange={(e: any) => {
                setPageLoaded("second");
                setFilterKeys({
                  ...filterKeys,
                  projectName: e?.target?.value,
                });
              }}
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {customerPorjectData.map((projectItems: any) => {
                return (
                  <MenuItem value={projectItems.id}>
                    {projectItems.project_name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="functionalArea">Functional Area</InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="functionalArea"
              fullWidth
              variant="outlined"
              value={filterKeys?.functionalArea}
              displayEmpty
              onChange={(e: any) => {
                setPageLoaded("second");
                setFilterKeys({
                  ...filterKeys,
                  functionalArea: e?.target?.value,
                });
              }}
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {FUNCTIONAL_AREA.map((functionalAreaItems: any) => {
                return (
                  <MenuItem value={functionalAreaItems.label}>
                    {functionalAreaItems.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="integrationType">Integration Type</InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="integrationType"
              fullWidth
              variant="outlined"
              value={filterKeys?.integrationType}
              displayEmpty
              onChange={(e: any) => {
                setPageLoaded("second");
                setFilterKeys({
                  ...filterKeys,
                  integrationType: e?.target?.value,
                });
              }}
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {INTEGRATION_TYPE.map((integrationTypeItems: any) => {
                return (
                  <MenuItem value={integrationTypeItems.label}>
                    {integrationTypeItems.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="requirementTemplate">
              Requirement Template
            </InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="requirementTemplate"
              fullWidth
              variant="outlined"
              value={filterKeys?.requirementTemplate}
              displayEmpty
              onChange={(e: any) => {
                setPageLoaded("second");
                setFilterKeys({
                  ...filterKeys,
                  requirementTemplate: e?.target?.value,
                });
              }}
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {requirementDropownList.map((template: any) => {
                return template.subFields.map((opt: any) => {
                  return <MenuItem value={opt.key}>{opt.value}</MenuItem>;
                });
              })}
            </Select>
          </Grid>
        </Grid>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchProjectByCustomerList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default TaskByCustomerList;

export const BoxStyle = {
  marginTop: '10px',
  paddingRight: "25px",
  paddingLeft: "25px",
  marginBottom: '40px',
  width: '100%',
  '& .MuiDataGrid-root': {
    border: "1px solid transparent",
    backgroundColor: "white",
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    padding: "10px",
    fontSize: '16px'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: "1px solid #212529"
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#212529',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '600',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: "1px solid #212529"
  }
}

export const HeaderStyle = {
  fontSize: '22px',
  fontWeight: '700',
  color: '#000',
  lineHeight: '30px',
}

export const ButtonStyle = {
  paddingRight: "2.5rem",
  paddingLeft: "2.5rem",
  backgroundColor: "#007cb0",
  "&:hover": {
    backgroundColor: "#007cb0"
  },
}

export const activeTabClass = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#000',
  border: "1px solid transparent",
  textTransform: 'capitalize',
  borderBottom: "1px solid #007CB0",
  '&:hover': {
    border: "1px solid #007CB0",
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    textTransform: 'capitalize',
  },
  '&.Mui-selected': {
    textTransform: 'capitalize',
    border: "1px solid #007CB0",
    borderBottom: "1px solid transparent",
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
  },

}
export const blankTabClass = {
  border: "1px solid transparent",
  borderBottom: "1px solid #007CB0",
}
export const customStyleOnTab: any = {
  fontSize: '18px',
  color: '#000 !important',
  textTransform: 'capitalize',
  fontWeight: '500',

}

export const tabStyle = {
  color: "#fff",
  opacity: 1,
  marginRight: '-10px',
  '&:hover': {
    color: '#86BC25',
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  }
}

export const menuItemStyle = {
  padding: "19px 45px 18px 2px",
  color: "#BBBCBC",
  borderBottom: "solid 1px #6E6E6E",
  marginLeft: "10px",
  width: "90%",
  '&:hover': {
    background: 'none',
    color: '#86BC25',
  },
  '&:last-child': {
    borderBottom: 'none',
  }
}
import { call } from "redux-saga/effects";
import { getAllCustomer } from "../../../../api/Customer";

export function* FetchAllCustomerLists(action: any): any {
  const { payload, callback } = action;

  try {
    const res = yield call(getAllCustomer, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, Button } from "@mui/material";
import { BoxStyle, ButtonStyle, HeaderStyle } from "../style";
import CustomPagination from "../../../components/CustomPagination";
import PendingItemForm from "./pendingItemForm";
import { CustomConfirmPopup } from "../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import {
  deleteTaskPendingItems,
  getTaskPendingItems,
} from "../../../store/actions/Task";
import Columns from "./columnsDef";
import ViewForm from "../../Register/viewPage";
import { ProfileType } from "../../../utility/commonTypes";

const PendingItems = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [selectedRowId, setRowId] = useState("");
  const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
  const[userId,setUserId]=useState("")
  const params: any = useParams();
  const stateData = params;
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const handleClickOpen = () => {
    setLoader(true);
    setOpenModalPop(true);
    setLoader(false);
    setRowId("");
  };
  //const location: any = useLocation();
  const fetchTaskPendingItemsList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getTaskPendingItems(
          {
            id: stateData?.taskId,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              let newRowReturned: any = data?.rowsReturned.map(
                (item: any, i: number) => {
                  item["fieldId"] = i;
                  return item;
                }
              );
              setRowData(newRowReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const [successPopup, setSuccessPopup] = useState(false);
  const [deleteSuccessPopup, setDeleteSuccessPopup] = useState(false);

  const onClickYes = (rowId: any, taskId: any) => {
    setDeletePopUp(false);
    setLoader(true);

    dispatch(
      deleteTaskPendingItems({ id: rowId, task_id: taskId }, function (
        res: any
      ) {
        setLoader(false);
        setDeleteSuccessPopup(true);
        setTimeout(()=>{
          setDeleteSuccessPopup(false);
    
        },5000)
        fetchTaskPendingItemsList(0, "ON_LOAD");
      })
    );
  };
  const onClickNo = () => {
    setDeletePopUp(false);
  };

  useEffect(() => {
    fetchTaskPendingItemsList(0, "ON_LOAD");
  }, []);

  const [deletePopUp, setDeletePopUp] = useState(false);

  const deleteTaskPendingItem = (event: any, id: any) => {
    setDeletePopUp(true);
   
    setRowId(id);
  };

  const editTaskPendingItem = (event: any, id: any) => {
    setOpenModalPop(true);
    setRowId(id);
  };
  const handleUserDetails=(event:any,id:any)=>{
    setOpenUserDetailsPopUp(true)
    setUserId(id)
  }
  const defaultColumns = useMemo(() => {
    return Columns(editTaskPendingItem, deleteTaskPendingItem,handleUserDetails);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
    setRowId("");
  };
  const onPendingItemSuccess = () => {
    fetchTaskPendingItemsList(0, "ON_LOAD");
    setSuccessPopup(true);
    setTimeout(()=>{
      setSuccessPopup(false);

    },5000)
  };
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Pending Items
        </Typography>
        {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ? 

        <div className="my-4 mx-4">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>
        </div>:null}
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchTaskPendingItemsList(data, "NEXT_PAGE");
          }}
        />
      </Box>

      {openModalPop && (
        <PendingItemForm
          row={selectedRowId}
          onSuccess={onPendingItemSuccess}
          handleClose={handleClose}
          openModalPop={openModalPop}
          setOpenModalPop={setOpenModalPop}
        />
      )}
{((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ?

      <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        closeAndSubmit={() => {}}
        rowId={selectedRowId}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
        taskId={stateData?.taskId}
        title={`Delete Pending Item`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected pending item`}
        //  successIcon={true}
      />:null}
      <CustomModal
        title="Pending Items"
        onOpen={successPopup}
        content={"Pending Items Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <CustomModal
        title="Pending Items"
        onOpen={deleteSuccessPopup}
        content={"Pending Items Deleted Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setDeleteSuccessPopup(false);
        }}
      />
      <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
    </>
  );
};
export default PendingItems;

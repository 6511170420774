import * as React from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Button,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { BtnClass, ModalTypeStyles, titleCSS } from './style';

export interface CustomModalProps {
  title: string;
  titleIcon?: React.ReactNode;
  modalType?: any  //"error", "success", "warning", "default"
  content: React.ReactNode;
  onOpen: boolean
  OnClickCloseIcon?: () => void;
  OnClickFirstBtn?: () => void;
  OnClickSecondBtn?: () => void;
  firstBtnName?: string;
  secondBtnName?: string;
  width?: any // 'xs','sm','md','lg','xl'
  height?: any
  customWidth?: any
  backgroundColor?: any
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  titleIcon,
  modalType,
  content,
  onOpen,
  OnClickFirstBtn,
  OnClickSecondBtn,
  firstBtnName,
  secondBtnName,
  OnClickCloseIcon,
  width = 'sm',
  height = "auto",
  customWidth,
  backgroundColor = "transparent"
}) => {

  return (
    <div>
      <Dialog
        open={onOpen}
        maxWidth={width}
        fullWidth
        PaperProps={{
          style: {
            borderTop: ModalTypeStyles(modalType)?.border,
            minHeight: '30vh',
            height: height,
            maxHeight: '100vh',
            width: customWidth ? customWidth : undefined
          }
        }}
      >
        <DialogTitle style={{ background: backgroundColor }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid item container direction="row" alignItems="center" className="mt-1">
                {titleIcon ? titleIcon : null}
                <Typography variant="h6" sx={{
                  color: ModalTypeStyles(modalType)?.color,
                  marginLeft: titleIcon ? "10px" : "20px",
                  ...titleCSS
                }}> {title}</Typography>
              </Grid>
            </Grid>
            {OnClickCloseIcon ?
              <IconButton aria-label="close" onClick={OnClickCloseIcon}>
                <CancelOutlinedIcon fontSize='large' />
              </IconButton> : null}
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ marginLeft: "20px" }}>
          {content}
        </DialogContent>
        <DialogActions>
          {OnClickFirstBtn ? <Button onClick={OnClickFirstBtn} sx={BtnClass}>
            {firstBtnName}
          </Button> : null}
          {OnClickSecondBtn ? <Button onClick={OnClickSecondBtn} sx={BtnClass}>
            {secondBtnName}
          </Button> : null}
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default CustomModal
export const initialPayload: any = {
  "reqFiles": {
    "fieldData": {
      "Number_of_fields": {
        "field_desc": "",
        "test_cases": 0
      },
      "Field_Requirements_Type": {
        "field_desc": "",
        "test_cases": 0
      },
      "File_Description": {
        "field_desc": "",
        "test_cases": 0
      },
      "A_header_row_will_be_included_in_the_data_file": {
        "field_desc": "",
        "test_cases": 0
      },
      "A_trailer_row_will_be_included_in_the_data_file": {
        "field_desc": "",
        "test_cases": 0
      },
      "The_file_being_processed_is_to_be_delimited_by_a": {
        "field_desc": "",
        "test_cases": 0
      },
      "The_data_transfer_will_be_formatted_as": {
        "field_desc": "",
        "test_cases": 0
      },
      "Filtering_Requirements": {
        "field_desc": "",
        "test_cases": 0
      },
      "The_expected_volume_of_records_to_be_processed_by_this_integration_is": {
        "field_desc": "",
        "test_cases": 0
      },
      "The_expected_maximum_number_of_records_that_the_integration_will_handle_is": {
        "field_desc": "",
        "test_cases": 0
      },
      "File_Naming_Standard_Prod": {
        "field_desc": "",
        "test_cases": 0
      },
      "File_Naming_Standard_Test": {
        "field_desc": "",
        "test_cases": 0
      },
      "Will_input_or_output_contains_special_characters": {
        "field_desc": "",
        "test_cases": 0
      },
      "Encoding": {
        "field_desc": "",
        "test_cases": 0
      },
      "what_type_of_data_will_be_sent": {
        "field_desc": "",
        "test_cases": 0
      },
      "Sample_File": {
        "field_desc": "",
        "test_cases": 0
      },
      "Notes_and_Additional_requirements": {
        "field_desc": "",
        "test_cases": 0
      }
    }
  },
  "reqFields": []
}

export const ACTION_TYPES = {
  CUSTOMER: {
    GET_ALL_CUSTOMER_LIST: "GET_ALL_CUSTOMER_LIST",
    GET_CUSTOMER_CONTACT_LIST: "GET_CUSTOMER_CONTACT_LIST",
    GET_CUSTOMER_CONTACT: "GET_CUSTOMER_CONTACT",
    CUSTOMER_CONTACT_CREATE: "CUSTOMER_CONTACT_CREATE",
  },
  PROJECT: {
    GET_MY_PROJECT_LIST: "GET_MY_PROJECT_LIST",
    GET_ALL_PROJECT_LIST: "GET_ALL_PROJECT_LIST",
    GET_PROJECT_BY_CUSTOMER: "GET_PROJECT_BY_CUSTOMER",
    GET_CUSTOMER_PROJECT: "GET_CUSTOMER_PROJECT",
    GET_ALL_PROJECT_LIST_DASHBOARD: "GET_ALL_PROJECT_LIST_DASHBOARD",
  },
  TASKS: {
    GET_MY_TASKS_LIST: "GET_MY_TASKS_LIST",
    GET_TASK_PROGRESS: "GET_TASK_PROGRESS",
    UPDATE_TASK_PROGRESS: "UPDATE_TASK_PROGRESS",
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST:
      "GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST",
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH:
      "GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH",
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO:
      "GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO",
  },
  REQUIREMENT: {
    GET_REQUIREMENT_QUESTION: "GET_REQUIREMENT_QUESTION",
    UPDATE_REQUIREMENT_QUESTIONS: "UPDATE_REQUIREMENT_QUESTIONS",
    UPDATE_REQUIREMENT_NUMBER_OF_FIELDS: "UPDATE_REQUIREMENT_NUMBER_OF_FIELDS",
    CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM:
      "CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM",
    GET_REQUIREMENT_NUMBER_OF_FIELDS: "GET_REQUIREMENT_NUMBER_OF_FIELDS",
    GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM:
      "GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM",
    UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM:
      "UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM",
    DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM:
      "DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM",
    GET_PDF_REQUIREMENT: "GET_PDF_REQUIREMENT",
    SET_PROCESS_FLOW: "SET_PROCESS_FLOW",
    GET_PROCESS_FLOW: "GET_PROCESS_FLOW",
  },
};

export const activeCustomTabClass = {
  fontSize: '16px',
  fontWeight: 500,
  color: '#000',
  border: "none",
  boxShadow: 'none',
  borderRadius: 'none',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 500,
    color: '#fff',
    backgroundColor: '#007cb0',
    boxShadow: '1px 1px 10px  rgb(0 0 0 / 10%)',
    borderRadius: '25px',
  },

}

import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Grid, Button, Typography } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import CustomPagination from "../../../components/CustomPagination";

import columns from "./columnsDef";

import { BoxStyle, ButtonStyle, HeaderStyle } from "./style";

import { getSubProjects } from "../../../store/actions/Project";
import React from "react";
import { ProfileType } from "../../../utility/commonTypes";

const SubProjectList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params: any = useParams();
  console.log({ location, params });
  const stateData: any = params;

  const [filterKeys, setFilterKeys] = useState<any>({
    customerName: "",
    ProjectStatus: "",
  });
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const fetchSubProjectList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getSubProjects(
          {
            id: stateData?.projectId,
            // role: "admin",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubProjectList(0, "ON_LOAD");
  }, []);

  const handleClick = () => {
    let subProjURL =
      "/project/manage-sub-project" +
      (stateData?.projectId
        ? "/" + stateData?.projectId + "/" + stateData?.customerId
        : "");
    navigate(subProjURL, {
      state: {
        subProject: true,
        masterProjectId: stateData?.projectId,
        companyCustomerId: stateData?.customerId,
      },
    });
    //window.location.reload(false);
  };
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Sub Projects
        </Typography>
        <div className="my-4 mx-4">
    {ProfileType?.ADMIN === getProfileType &&      <Button
            variant="contained"
            onClick={handleClick}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>}
        </div>
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchSubProjectList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default SubProjectList;

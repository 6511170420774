export const END_POINTS = {
  VERIFY_USER: "/verifyCustomer",
  SSO_LOGIN:"/customer/user/ssoauth",
  LOGIN_USER: "/customer/user/login",
  LOGOUT_USER: "/customer/user/logout",
  GET_NEW_TOKEN: "/newtokenpair",
  SIGNUP_USER: "/user/create",
  GET_USER_DETAILS: "/user/:id",
  UPDATE_USER_DETAILS: "/user/:id/update",
  SIGNUP_CUSTOMER: "/customer/company/create",
  GET_CUSTOMER_DETAILS: "/customer/company/:id",
  UPDATE_CUSTOMER_DETAILS: "/customer/company/:id/update",
  SIGNUP_PROJECT: "/project/create",
  GET_PROJECT: "/project/all",
  GET_PROJECT_DETAILS: "/project/:id",
  UPDATE_PROJECT: "/project/:id/update",
  CUSTOMER_PROJECTS: "/customer/company/:id/project",
  CUSTOMER_FILES: "/customer/company/:id/file/upload",
  CUSTOMER_CONTACT_CREATE: "/customer/company/:id/contact/create",
  CUSTOMER_CONTACT_UPDATE: "/customer/company/:id/contact/:id/update",
  CUSTOMER_CONTACT_DELETE: "/customer/company/:id/contact/:id/delete",
  GET_CUSTOMER_CONTACT: "/customer/company/:id/contact",
  SET_USER_PREFERENCE: "/userPreferences",
  CUSTOMER_CONTACT_LIST: "/customer/company/",
  CUSTOMER_MODULES: "/module/all/",
  UPDATE_CUSTOMER_MODULES: "/customer/company/:id/module/update/",
  GET_MODULES_BY_CUSTOMER: "/customer/company/:id/module/",
  SEARCH_FILTER: "/user/search",
  PROJECT_SCOPE: "/project/:id/scope",
  PROJECT_SCOPE_UPDATE: "/project/:id/scope/update",
  PROJECT_DATES: "/project/:id/date/",
  PROJECT_DATES_UPDATE: "/project/:id/date/update",
  PROJECT_RESOURCE: "/project/:id/resource",
  PROJECT_RESOURCE_CREATE: "/project/:id/resource/create",
  PROJECT_RESOURCE_UPDATE: "/project/:id/resource/:id/update",
  PROJECT_RESOURCE_DELETE: "/project/:id/resource/:id/delete",
  GET_SUB_PROJECT: "/project/:id/subProjects",
  FETCH_MASTER_PROJECT_LIST: "/project/allMasterProject",
  PROJECT_TASK: "/project/:id/task?page=:page&size=:size",
  GET_CUSTOMER_PROJECTS: "project/:id/customerProject",
  LOGOUT: "/logout",
  GET_ALL_CUSTOMER: "/customer/company/all/name",
  GET_FAQ_DATA: "GET_FAQ_DATA",
  GET_MY_PROJECT: "/project/all?page=:page&size=:size",
  GET_MY_PROJECT_DASHBOARD: "/project/all",
  GET_PROJECT_BY_CUSTOMER: "/projectfilter",
  CREATE_PENDING_ITEM: "/task/:id/pending/create",
  CREATE_PENDING_ITEM_UNIT_TEST: "/task/:id/pending/createunittest",
  GETALL_ITEM_UNIT_TEST: "/task/:id/pending/getunitall",
  SAVE_ITEM_UNIT_TEST: "/task/:id/pending/saveunittest",
  GET_PENDING_ITEMS: "/task/:id/pending",
  UPDATE_PENDING_ITEMS: "/task/:id/pending/:id/update",
  DELETE_PENDING_ITEMS: "/task/:id/pending/:id/delete",
  GET_MY_TASKS: "/task/all",
  USER_SEARCH: "/user/search",
  ALL_USERS:"/user/all",
  GET_TASK_FILES: "/task/:id/file",
  UPLOAD_TASK_FILES: "/task/:id/file/create",
  UPDATE_TASK_FILES: "/task/:id/file/:id/update",
  TASK_PROJECTS:"/customer/company/:id/project?active=1",
  GENERATE_SIGNED_URL:"/psurl",
  GENERATE_SIGNED_URL_TECHNICAL:"/psurl",
  DELETE_TASK_FILE: "/task/:id/file/delete/:file_name",
  DOWNLOAD_TASK_FILE: "/task/:id/file/download/:file_name",
  GET_TASK_TECHNICAL_FILES: "/task/:id/file",
  UPLOAD_TASK_TECHNICAL_FILES: "/task/:id/file/create",
  UPDATE_TASK_TECHNICAL_FILES: "/task/:id/file/:id/update",
  DELETE_TASK_TECHNICAL_FILE: "/task/:id/file/delete/:file_name",
  DOWNLOAD_TASK_TECHNICAL_FILE: "/task/:id/file/download/:file_name",
  GET_TASK_BY_CUSTOMER_PROJECT: "/taskfilter",
  GET_TASK_BY_CUSTOMER_PROJECT_HEALTH: "/projecthealth/table",
  GET_TASK_BY_CUSTOMER_PROJECT_HEALTH_RATIO: "/projecthealth/ratio",
  GET_TASK_PROGRESS: "/task/:id/progress",
  UPDATE_TASK_PROGRESS: "/task/:id/progress/update",
  UPDATE_REQUIREMENT_DETAILS: "/requirement/:id/update",
  GET_REQUIREMENT_DETAILS: "/requirement/:id",
  CREATE_TASK: "/task/create",
  GET_TASK_DETAILS: "/task/:id",
  UPDATE_TASK_DETAILS: "/task/:id/update",
  GET_REQUIREMENT_PENDING_ITEMS: "/requirement/:id/pending",
  CREATE_REQUIREMENT_PENDING_ITEMS: "/requirement/:id/pending/create",
  UPDATE_REQUIREMENT_PENDING_ITEMS: "/requirement/:id/pending/:id/update",
  DELETE_REQUIREMENT_PENDING_ITEMS: "/requirement/:id/pending/:id/delete",
  UPDATE_TECHNICAL: "/requirement/:id/technical/update",
  GET_TECHNICAL: "/requirement/:id/technical",
  REQUIREMENT: "/requirement/",
  UPDATE_REQUIREMENT_DATA: "/requirement/:id/data/update",
  GET_REQUIREMENT_DATA: "/requirement/:id/data",
  UPDATE_REQUIREMENT_TRANSPORT: "/requirement/:id/transport/update",
  GET_REQUIREMENT_TRANSPORT: "/requirement/:id/transport",
  UPDATE_REQUIREMENT_TEST: "/requirement/:id/test/update",
  GET_REQUIREMENT_TEST: "/requirement/:id/test",
  FORGOT_PASSWORD: "/customer/user/forgotPassword",
  TASK_RESOURCE: "/task/:id/resource",
  TASK_RESOURCE_CREATE: "/task/:id/resource/create",
  TASK_RESOURCE_UPDATE: "/task/:id/resource/:id/update",
  TASK_RESOURCE_DELETE: "/task/:id/resource/:id/delete",
  INTEGRATION_REPOSITORY_TABLE: "/integrationrepo/table",
  INTEGRATION_REPOSITORY_CATALOGUE: "/integrationrepo/catalogue",
  SEARCH_BY_TASK_NAME: "/task/search",
  SOURCE_TARGET_SYSTEM: "/sourcetarget",
  ALL_USER_SEARCH: "/task/:id/search/pih",
  GET_CUSTOMER_PROJECT: "/customer/company/:id/project",
  CUSTOMER_AMS_CREATE: "/customer/company/:id/amsmaster/create",
  CUSTOMER_AMS_UPDATE: "/customer/company/:id/amsmaster/update",
  GET_AMS_MASTER: "/customer/company/:id/amsmaster",
  GET_AMS_CUSTOMER: "/customer/company/:id/amsmaster",
  TASK_UNIT_TEST_CREATE: "/project/unittest",
  TASK_UNIT_TEST_GETALL: "/project/unittest/all/:id",
  SAVE_ITEM_TEST_CASE: "/project/testcase",
  TASK_TEST_CASE_GETALL: "/project/testcase/all/:id"
};

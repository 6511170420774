import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {  useForm } from "../../../helpers/validations/useForm";
import { useDispatch, useSelector } from "react-redux";
import { createTask, getTaskDetails, getTaskProjects, saveEditableTask } from "../../../store/actions/Task";
import setLoader from "../../CustomLoader/loaderState";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllCustomerList } from "../../../store/actions/Customers";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import {
  getCustomerDetails,
  // getProjectList,
} from "../../../store/actions/Customer";

import {
  COMPLEXITY,
  errorMessages,
  FUNCTIONAL_AREA,
  PHASE,
  REPLACE_REGEX,
  SUB_TYPE_MAPPING,
  TASK_PRIORITY,
  TASK_STATUS,
  TASK_TYPE_MAPPING,
} from "../../../constants/common";
import CustomModal from "../../CustomModalPop";
import { getProjectDetails } from "../../../store/actions/Project";
import { ProfileType } from "../../../utility/commonTypes";

interface TaskForm {
  id: any;
  company_customer_id: any;
  requirement_id: any;
  project_id: any;
  integration_id: any;
  task_name: any;
  task_type: any;
  task_sub_type: any;
  functional_area: any;
  health: any;
  task_status: any;
  task_phase: any;
  description: any;
  estimated_hours: any;
  priority: any;
  complexity: any;
  crno_relatedrequest: any;
  engagement_id: any;
  engagement_number: any;
  actionType: any;
}
interface CreateUpdateProps {
  onSubmitAction?: Function;
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
const CreateUpdate: React.FC<CreateUpdateProps> = (props) => {
  const params: any = useParams();
  const stateData: any = params;
  const { taskDetail } = props;
  const [customerData, setCustomerData] = useState<any>({});
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("create");
  const [masterProjectList, setMasterProjectList] = useState([]);
  const [projectRowData, setProjectRowData] = useState([]);
  const [taskRequirementId, setTaskRequirementId] = useState<any>("");
  const selector = useSelector((store: any) => {
    return store?.taskDetails?.data;
  });
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return stateData?.taskId?store?.taskDetails?.taskEditable?.[stateData?.taskId]:1
  });
  const location = useLocation();
  //const stateData: any = location.state;
  
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const { handleSubmit, handleChange, data: task, setData, errors } = useForm<
    TaskForm
  >({
    validations: {
      company_customer_id: {
        required: {
          value: !stateData?.customerId && !stateData?.projectId,
          // value: true,
          message: errorMessages?.required(),
        },
      },
      project_id: {
        required: {
          value: !stateData?.customerId && !stateData?.projectId,
          // value: true,
          message: errorMessages?.required(),
        },
      },
      integration_id: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern: {
          value: REPLACE_REGEX?.COMPANY_NAME_OR_TASK_ID,
          message: errorMessages?.pattern()
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Task id",1)
        },
        maxlength: {
          value: 20,
          message: errorMessages?.maxlength("Task id",20)
        },
      },
      task_name: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Task name",1)
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Task name",100)
        },
      },
      // engagement_id: {
      //   pattern: {
      //     value: "^[0-9]*$",
      //     message: "Engagement Number is not Valid.Please provide only numbers",
      //   },
      // },
      engagement_number: {
        pattern: {
          value: REPLACE_REGEX?.START_WITH_ALPHABETS_NUMBERS,
          message: errorMessages?.pattern()
        },
        maxlength: {
          value: 20,
          message: errorMessages?.maxlength("Engagement Task id",20)
        },
      },
      description: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Description",5000)
        },
      },
      crno_relatedrequest: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: errorMessages?.pattern()
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Cr No.",100)
        },
      },
      estimated_hours: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern:{
          value:REPLACE_REGEX?.NUMBERS_WITH_DECIMALS,
          message:errorMessages?.patternWithOnlyDecimalNumbers()
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Estimated hours",1)
        },
        maxlength: {
          value: 6,
          message: errorMessages?.allowedRange("Estimated hours"),
        },
    },
  },

    onError: (errors: any) => {      
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        createTask(
          {
            payload: {
              id: task?.id,
              //  requirement_id: taskRequirementId,
              integration_id: task?.integration_id,
              company_customer_id: stateData?.customerId
                ? stateData?.customerId
                : task?.company_customer_id,
              project_id: stateData?.projectId
                ? stateData?.projectId
                : task?.project_id,
              task_name: task?.task_name,
              task_type: task?.task_type,
              task_status: task?.task_status,
              functional_area: task?.functional_area,
              health: task?.health,
              description: task?.description,
              priority: task?.priority,
              crno_relatedrequest: task?.crno_relatedrequest,
              task_sub_type: task?.task_sub_type,
              task_phase: task?.task_phase,
              complexity: task?.complexity,
              engagement_id:
                task?.engagement_id ||
                taskProject?.engagement_id ||
                projectDetails?.engagement_id,
              engagement_number: task?.engagement_number,
              estimated_hours: task?.estimated_hours,
              actionType: actionType,
            },
          },

          onCreateTask
        )
      );
    },
  });

  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [message, setMessage] = useState("");

  const [taskProject, setTaskProject] = useState({
    projectId: "",
    projectName: "",
    projectLevel: "",
    masterProject: "",
    companyCustomerId: "",
    engagement_id: "",
  });
  const onCreateTask = (data: any, isError: any) => {
    setLoader(false);    
    if (data === false) {
      setErrorPopup(true);
      setMessage(isError)
    } else {      
      setSuccessPopup(true);
      setTimeout(() => {
        navigate(
          "/create-update-task/" +
            (stateData?.projectId ? stateData?.projectId : task?.project_id) +
            "/" +
            (stateData?.customerId
              ? stateData?.customerId
              : task?.company_customer_id) +
            "/" +
            data?.id,
          {
            state: {
              projectId: stateData?.projectId
                ? stateData?.projectId
                : task?.project_id,
              customerId: stateData?.customerId
                ? stateData?.customerId
                : task?.company_customer_id,
              taskId: data?.id,
            },
            replace: true,
          }
        );
        window.location.reload();
      }, 1000);
    }
    setLoader(false);
  };
  useEffect(() => {
    // if (stateData?.customerId) {
    setLoader(true);

    stateData?.customerId &&
      dispatch(
        getCustomerDetails({ id: stateData?.customerId }, function (res: any) {
          setCustomerData(res);
          stateData?.projectId &&
          dispatch(
            getProjectDetails({ id: stateData?.projectId }, function (res: any) {
              setTaskProject((taskProject) => ({
                ...taskProject,
                ...{
                  projectId: res?.id,
                  projectName: res?.project_name,
                  projectLevel: res?.project_level,
                  masterProject: res?.master_project,
                  engagement_id:res?.engagement_id,
                  companyCustomerId: res?.company_customer_id,
                },
              }));
               stateData?.taskId &&
      dispatch(
        getTaskDetails({ id: stateData?.taskId }, function (res: any) {
          res?.id && setActionType("update");
          taskDetail(res);
          dispatch(
            saveEditableTask(stateData?.taskId,res?.isEditable)
          )          
          setTaskRequirementId(res?.Requirement?.id);
          setData({
            ...res,
            id: res?.id,
            requirement_id: res?.Requirement?.id,
            integration_id: res?.integration_id,
            company_customer_id: stateData?.customerId,
            project_id: stateData?.projectId,
            task_name: res?.task_name,
            task_type: res?.task_type,
            functional_area: res?.functional_area,
            health: res?.health,
            description: res?.description,
            priority: res?.priority,
            crno_relatedrequest: res?.crno_relatedrequest,
            task_sub_type: res?.task_sub_type,
            task_phase: res?.task_phase,
            complexity: res?.complexity,
            engagement_id: res?.engagement_id,
            engagement_number: res?.engagement_number,
            estimated_hours: res?.estimated_hours,
          });
     

                      setTimeout(() => {
                        setLoader(false);
                      }, 1000);
                    })
                  );
              })
            );
        })
      );

    !stateData?.projectId && getAllCustomer();
    // stateData?.projectId && getAllCustomer();

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [setData]);

  const getCustomerProjects = (company_customer_id: any) => {
    dispatch(
      getTaskProjects(
        {
          id: company_customer_id,
          // id:company_customer_id,
          size: 100,
          page: 0,
        },
        (data: any) => {
          if (data?.rowsReturned?.length > 0) {
            setProjectRowData(data?.rowsReturned);
          } else {
            setProjectRowData([]);
          }
        }
      )
    );
  };
  const projectDetails: any = projectRowData.find(
    (o: any) => o?.id === task?.project_id
  );

  const handleCustomerChange = (val: any) => {    
    if (!val) return;
    getCustomerProjects(val);
  };
  const getAllCustomer = () => {
    dispatch(
      getAllCustomerList({}, function (data: any) {        
        const rows = data?.map((row: any) => ({
          id: row?.id,
          company_name: row?.company_name,
        }));
        setMasterProjectList(rows);
      })
    );
  };
 
  
  const [companyId, setCompanyId] = useState("");
 

  return (
    <div className="container-fluid inner_page">
      <div className="row">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {selector?.requirementId && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Requirement ID
                      </label>
                      <div>
                        <Link
                          to={`/requirement/${taskRequirementId}`}
                          state={{ reqID: taskRequirementId }}
                          style={{ color: "#1976d2" }}
                        >
                          {taskRequirementId && "Requirement Id"}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="requirement" className="form-label">
                      ID*
                    </label>

                    <input
                      name="integration_id"
                      id="integration_id"
                      type="text"
                      value={task?.integration_id}
                      //defaultValue={"mmmm"}
                      onChange={handleChange("integration_id")}
                      placeholder="Int XXX"
                      className="form-control"
                    />
                    {errors.integration_id && (
                      <p className="error-text">{errors.integration_id}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Task Name*
                    </label>
                    <input
                      name="task_name"
                      id="task_name"
                      type="text"
                      value={task.task_name}
                      //defaultValue={"mmmm"}
                      onChange={handleChange("task_name")}
                      className="form-control"
                    />
                    {errors.task_name && (
                      <p className="error-text">{errors.task_name}</p>
                    )}
                  </div>
                </div>
                {!stateData?.customerId && !stateData?.projectId && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="customer" className="form-label">
                        Customer Name*
                      </label>

                      <select
                        name="company_customer_id"
                        id="company_customer_id"
                        onChange={handleChange("company_customer_id", (val) => {
                          handleCustomerChange(val);
                          return val;
                        })}
                        className="form-select"
                      >
                        <option value="">Customer Name</option>
                        {masterProjectList.map((company: any) => {                          
                          let companySel =
                            task?.company_customer_id === company.id
                              ? true
                              : false;
                          return (
                            <option value={company.id} selected={companySel}>
                              {company.company_name}
                            </option>
                          );
                        })}
                      </select>
                        &nbsp;
                      {errors.company_customer_id && (
                        <p className="error-text">
                          {errors.company_customer_id}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {stateData?.customerId && stateData?.projectId && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Customer Name*
                      </label>

                      {/* <select
                        name="company_customer_id"
                        id="company_customer_id"
                        onChange={handleChange("company_customer_id", (val) => {
                          handleCustomerChange(val);
                          return val;
                        })}
                        className="form-select"
                      >
                        <option value="">--Select Customer--</option>
                        {masterProjectList.map((company:any) => {
                          console.log(
                            task?.company_customer_id + "===" + company.id
                          );
                          let companySel =
                            task?.company_customer_id === company.id
                              ? true
                              : false;
                          return (
                            <option value={company.id} selected={companySel}>
                              {company.company_name}
                            </option>
                          );
                        })}
                      </select> */}
                      <div>
                        <Link
                          to={`/customer/${customerData?.id}/${customerData?.company_name}`}
                          state={{
                            customerId: customerData?.id,
                            companyId: customerData?.company_name,
                          }}
                          style={{ color: "#1976d2", cursor: "pointer" }}
                        >
                          {customerData?.company_name}

                          {/* <input
                            type="text"
                            id="name"
                            readOnly={true}
                            className="form-control"
                            placeholder={customerData?.company_name}
                            defaultValue={task?.company_customer_id}
                            style={{ cursor: "pointer" }}
                          /> */}
                        </Link>
                      </div>

                      {/* {errors.company_customer_id && (
                        <p className="error-text">
                          {errors.company_customer_id}
                        </p>
                      )} */}
                    </div>
                  </div>
                )}

                {!stateData?.customerId && !stateData?.projectId && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Project Name*
                      </label>

                      <select
                        name="project_id"
                        id="project_id"
                        value={task?.project_id || ""}
                        onChange={handleChange("project_id")}
                        className="form-select"
                      >
                        <option value="">--Select Project--</option>
                        {projectRowData.map((project) => {                          
                          let companySel =
                            task?.project_id === project.id ? true : false;
                          return (
                            <option value={project.id} selected={companySel}>
                              {project.project_name}
                            </option>
                          );
                        })}
                      </select>
&nbsp;
                      {errors.project_id && (
                        <p className="error-text">{errors.project_id}</p>
                      )}
                    </div>
                  </div>
                )}
                {stateData?.customerId && stateData?.projectId && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Project Name*
                      </label>
                      {taskProject?.projectLevel === "Sub_Project" ? (
                        <Link
                          to={`/project/manage-sub-project/${taskProject?.masterProject}/${taskProject?.companyCustomerId}/${taskProject?.projectId}`}
                          state={{
                            masterProjectId: taskProject?.masterProject,
                            companyCustomerId: taskProject?.companyCustomerId,
                            subProjectId: taskProject?.projectId,
                            subProject: true,
                          }}
                          style={{ color: "#1976d2" }}
                        >
                          {taskProject?.projectName}
                        </Link>
                      ) : (
                        <Link
                          to={`/project/${taskProject?.projectId}/${taskProject?.companyCustomerId}`}
                          state={{
                            projectId: taskProject?.projectId,
                            customerId: taskProject?.companyCustomerId,
                            subProject: false,
                          }}
                          style={{ color: "#1976d2" }}
                        >
                          {taskProject?.projectName}
                        </Link>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Customer Name</label>

                    <input
                      name="company_customer_id"
                      id="company_customer_id"
                      type="text"
                      value={taskProject?.companyCustomerId}
                      onChange={handleChange("company_customer_id")}
                      placeholder="Customer Name"
                      className="form-control"
                    />
                    {errors.company_customer_id && (
                      <p className="error-text">{errors.company_customer_id}</p>
                    )}
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Engagement ID
                    </label>
                    <input
                      name="engagement_id"
                      id="engagement_id"
                      type="text"
                      // defaultValue={task?.engagement_id}
                      defaultValue={
                        task?.engagement_id ||
                        taskProject?.engagement_id ||
                        projectDetails?.engagement_id
                      }
                      // value={task?.engagement_number}
                      // pattern="^[0-9]+$"
                      onChange={handleChange("engagement_id")}
                      placeholder="Only Numbers are accepted"
                      className="form-control"
                    />
                    {errors.engagement_id && (
                      <p className="error-text">{errors.engagement_id}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Task Type
                    </label>
                    <select
                      name="task_type"
                      id="task_type"
                      value={task?.task_type}
                      onChange={handleChange("task_type")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {TASK_TYPE_MAPPING.map((task) => (
                        <option value={task.key}>{task.label}</option>
                      ))}
                    </select>
                    {errors.task_type && (
                      <p className="error-text">{errors.task_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Sub Type
                    </label>
                    <select
                      name="task_sub_type"
                      id="task_sub_type"
                      value={task?.task_sub_type}
                      onChange={handleChange("task_sub_type")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {SUB_TYPE_MAPPING.map((subType) => (
                        <option value={subType.key}>{subType.label}</option>
                      ))}
                    </select>
                    {errors.task_sub_type && (
                      <p className="error-text">{errors.task_sub_type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Functional Area
                    </label>
                    <select
                      name="functional_area"
                      id="functional_area"
                      value={task?.functional_area}
                      onChange={handleChange("functional_area")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select Functional Area
                      </option>
                      {FUNCTIONAL_AREA.map((area) => (
                        <option value={area.key}>{area.label}</option>
                      ))}
                    </select>
                    {errors.functional_area && (
                      <p className="error-text">{errors.functional_area}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Description / Purpose
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      value={task?.description}
                      onChange={handleChange("description")}
                      className="form-textarea"
                    ></textarea>
                    {errors.description && (
                      <p className="error-text">{errors.description}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Priority</label>
                    <select
                      name="priority"
                      id="priority"
                      value={task?.priority}
                      onChange={handleChange("priority")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select Priority
                      </option>
                      {TASK_PRIORITY.map((priority) => (
                        <option value={priority.key}>{priority.label}</option>
                      ))}
                    </select>
                    {errors.priority && (
                      <p className="error-text">{errors.priority}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Complexity
                    </label>
                    <select
                      name="complexity"
                      id="complexity"
                      value={task?.complexity}
                      onChange={handleChange("complexity")}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {COMPLEXITY.map((complexity) => (
                        <option value={complexity.key}>
                          {complexity.label}
                        </option>
                      ))}
                    </select>
                    {errors.complexity && (
                      <p className="error-text">{errors.complexity}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      CR No/Related Request
                    </label>

                    <input
                      name="crno_relatedrequest"
                      id="crno_relatedrequest"
                      type="text"
                      value={task?.crno_relatedrequest}
                      //defaultValue={"mmmm"}
                      onChange={handleChange("crno_relatedrequest")}
                      placeholder="CR XXX"
                      className="form-control"
                    />
                    {errors.crno_relatedrequest && (
                      <p className="error-text">{errors.crno_relatedrequest}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Health
                    </label>
                    <select
                      name="health"
                      id="health"
                      value={task?.health}
                      onChange={handleChange("health")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select Health
                      </option>
                      <option value="Green">Green</option>
                      <option value="Amber">Amber</option>
                      <option value="Red">Red</option>
                    </select>
                    {errors.health && (
                      <p className="error-text">{errors.health}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Task Status
                    </label>
                    <select
                      name="task_status"
                      id="task_status"
                      value={task?.task_status}
                      onChange={handleChange("task_status")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {TASK_STATUS.map((status) => (
                        <option value={status.key}>{status.label}</option>
                      ))}
                    </select>
                    {errors.task_status && (
                      <p className="error-text">{errors.task_status}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Phase
                    </label>
                    <select
                      name="task_phase"
                      id="task_phase"
                      value={task?.task_phase}
                      onChange={handleChange("task_phase")}
                      className="form-select"
                    >
                      <option value="" selected>
                        Select Phase
                      </option>
                      {PHASE.map((phase) => (
                        <option value={phase.value}>{phase.label}</option>
                      ))}
                    </select>
                    {errors.task_phase && (
                      <p className="error-text">{errors.task_phase}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Engagement Task ID
                    </label>

                    <input
                      name="engagement_number"
                      id="engagement_number"
                      type="text"
                      value={task?.engagement_number}
                      onChange={handleChange("engagement_number")}
                      className="form-control"
                    />
                    {errors.engagement_number && (
                      <p className="error-text">{errors.engagement_number}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Estimated Hours*
                    </label>

                    <input
                      name="estimated_hours"
                      id="estimated_hours"
                      type="text"
                      value={task?.estimated_hours}
                      onChange={handleChange("estimated_hours")}
                      className="form-control"
                    />
                    {errors.estimated_hours && (
                      <p className="error-text">{errors.estimated_hours}</p>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="btn-block">
                  {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN  === getProfileType ?  <button type="submit" className="thame-btn w-100">
                          {actionType === "create" ? "Submit" : "Update"}
                        </button>:null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomModal
        title="Task Main"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Task Main"
        onOpen={successPopup}
        content={"Task data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Error on page, please validate!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default CreateUpdate;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import ProgressData from "./ProgressData";

const useStyles = makeStyles((theme) => ({
  bgColrGray: {
    backgroundColor: "#f5f7fa",
  },
  bgColrWhite: {
    backgroundColor: "#ffffff",
  },
}));

const ProgressTask: React.FC = (props) => {
  const { Cnt, register, errors, dataSet, task_id, setValue, watch } = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [completedDate, setCompletedDate] = useState(new Date());
  return (
    <div key={`b` + Cnt} id={`a` + Cnt}>
      <Grid key={5} className="progress-header">
        <div className="row">
          <div className="col-md-9 playwrapper">
            <div className="playicon">
              <img src="/assets/images/play.png" />
            </div>
            <p>{dataSet?.ProgressPhase?.name}</p>
          </div>
          <div className="col-md-3">
            <div className="perctange_bloc">
              <p>Phase Percent Complete</p>
              <div className="progress">
                <div
                  className="progress-bar progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${dataSet?.completion_percentage}%` }}
                  aria-valuenow={dataSet?.completion_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {dataSet?.completion_percentage}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <Grid>
          <div className="progress-main mb-4">
            <div className="table-responsive table_wrapper_main">
              <table className="table table-sm">
                <ProgressHeader />
                <tbody>
                  {dataSet?.ProgressPhase?.ProgressPhaseItems?.length &&
                    dataSet?.ProgressPhase?.ProgressPhaseItems.map(
                      (subProgressData: any, Cntkey: any) => {
                        subProgressData.TaskProgressesNew =
                          subProgressData.TaskProgresses[0];
                        //delete subProgressData.TaskProgresses;
                        return (
                          <ProgressData
                            classes={classes}
                            Cnt={Cnt}
                            CntK={Cntkey}
                            errors={errors}
                            register={register}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            completedDate={completedDate}
                            setCompletedDate={setCompletedDate}
                            data={subProgressData}
                            task_id={task_id}
                            setValue={setValue}
                            watch={watch}
                          />
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const ProgressHeader = () => {
  return (
    <thead>
      <tr>
        <th>Completed</th>
        <th>N/A</th>
        <th>Task</th>
        <th>Target Date</th>
        {/* <th>Est Hours</th> */}
        <th>To be completed by</th>
        <th>Completed by</th>
        <th>Date</th>
        <th>Comments</th>
      </tr>
    </thead>
  );
};

export default ProgressTask;

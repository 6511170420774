const columns: any = () => {
  return [
    {
      id: 'fieldId',
      label: '#',
      minWidth: 30,
      align: "left",
    },
    {
      id: 'date',
      label: 'Date',
      minWidth: 30,
      align: "left"
    },
    {
      id: 'issue',
      label: 'Issue',
      minWidth: 30,
      align: "left"
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 30,
      align: "left"
    },
    {
      id: 'priority',
      label: 'Priority',
      minWidth: 50,
      align: "left"
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 30,
      align: "left",

    },
    {
      id: 'assigned_to',
      label: 'Assigned to',
      minWidth: 50,
      align: "left"
    },
    {
      id: 'due_date',
      label: 'Due Date',
      minWidth: 30,
      align: "left"
    },
    {
      id: 'created_by',
      label: 'Created by',
      minWidth: 50,
      align: "left"
    }
  ]
};

export default columns;
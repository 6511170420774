import React from "react";
import { useEffect, useState } from "react";
import { Grid, Switch, Tab, Tabs } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { activeCustomTabClass } from "./style";
import { setRequirementToggleValue } from "../../store/actions/Requirement";
const CustomTab = ({
  indexNumber,
  locationPath,
  title,
  requirementId,
  moduleType,
  onToggleChange
}: any) => {
  const [value, setValue] = useState(indexNumber);
  const navigate = useNavigate();
  const location: any = useLocation();
  const params: any = useParams();
  //const stateData: any = location?.state;
  const stateData: any = params;
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const dispatch=useDispatch()
  let[toggleValue,setToggleValue]=useState(false)
  const selector= useSelector((state: any) => (
    {requirementdetail:state?.requirementDetails?.data}
  ));
  useEffect(() => {
    setIsDisabled(true);
    if (stateData?.taskId) {
      setIsDisabled(false);
    }
  }, [stateData]);
  return (
    <>
      <h2 className="px-4 py-2 my-2">
        {indexNumber === 0 ? "Task" : indexNumber === 1 ? "Requirements" : ""}{" "}
        Overview
      </h2>
      <Grid
        container
        direction="row"
        justifyContent="center"
        justifyItems="center"
        className="px-4 py-2 my-4"
        sx={{
          backgroundColor: "#EFEFEF",
          marginBottom: "-20px",
        }}
      >
        <Grid
          item
          md={10}
          direction="row"
          spacing={2}
          alignItems="center"
          className="d-inline-flex my-2"
        >
          {title !== "undefined-undefined" && (
            <>
              {" "}
              Task:
              <div className="mx-2">
                <a
                  href={`/create-update-task/${
                    (stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null) +
                    "/" +
                    (stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null) +
                    "/" +
                    stateData?.taskId
                  }`}
                  state={{
                    subProject: stateData?.subProjectId ? true : false,
                    projectId: stateData?.projectId
                      ? stateData?.projectId
                      : stateData?.subProjectId
                      ? stateData?.subProjectId
                      : null,
                    customerId: stateData?.customerId
                      ? stateData?.customerId
                      : stateData?.companyCustomerId
                      ? stateData?.companyCustomerId
                      : null,
                    action: "update",
                    taskId: stateData?.taskId ? stateData?.taskId : null,
                  }}
                >
                  {title ? title : ""}
                </a>
              </div>
            </>
          )}
          <Tabs
            className="ml-4"
            value={value}
            aria-label="project-list"
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          >
            <Tab
              sx={activeCustomTabClass}
              label="Task"
              onClick={(e) => {
                if (indexNumber === 0) {
                  navigate(locationPath);
                } else {
                  //navigate(`/create-update-task`);
                  stateData?.taskId
                    ? navigate(
                        "/create-update-task/" +
                          (stateData?.projectId
                            ? stateData?.projectId
                            : stateData?.subProjectId
                            ? stateData?.subProjectId
                            : null) +
                          "/" +
                          (stateData?.customerId
                            ? stateData?.customerId
                            : stateData?.companyCustomerId
                            ? stateData?.companyCustomerId
                            : null) +
                          "/" +
                          stateData?.taskId,
                        {
                          state: {
                            subProject: stateData?.subProjectId ? true : false,
                            projectId: stateData?.projectId
                              ? stateData?.projectId
                              : stateData?.subProjectId
                              ? stateData?.subProjectId
                              : null,
                            customerId: stateData?.customerId
                              ? stateData?.customerId
                              : stateData?.companyCustomerId
                              ? stateData?.companyCustomerId
                              : null,
                            action: "update",
                            taskId: stateData?.taskId
                              ? stateData?.taskId
                              : null,
                          },
                        }
                      )
                    : navigate("/create-task");
                }
              }}
            />

            <Tab
              sx={activeCustomTabClass}
              label="Requirements"
              disabled={isDisabled}
              onClick={(e) => {
                if (indexNumber === 1) {
                  navigate(locationPath);
                } else {
                  //navigate("/requirement");
                  stateData?.taskId
                    ? navigate(
                        "/requirement/" +
                          (stateData?.projectId
                            ? stateData?.projectId
                            : stateData?.subProjectId
                            ? stateData?.subProjectId
                            : null) +
                          "/" +
                          (stateData?.customerId
                            ? stateData?.customerId
                            : stateData?.companyCustomerId
                            ? stateData?.companyCustomerId
                            : null) +
                          "/" +
                          stateData?.taskId +
                          "/" +
                          requirementId,
                        {
                          state: {
                            subProject: stateData?.subProjectId ? true : false,
                            projectId: stateData?.projectId
                              ? stateData?.projectId
                              : stateData?.subProjectId
                              ? stateData?.subProjectId
                              : null,
                            customerId: stateData?.customerId
                              ? stateData?.customerId
                              : stateData?.companyCustomerId
                              ? stateData?.companyCustomerId
                              : null,
                            action: "create",
                            taskId: stateData?.taskId
                              ? stateData?.taskId
                              : null,
                            requirementId: requirementId,
                          },
                        }
                      )
                    : navigate("/requirement");
                }
              }}
            />
          </Tabs>
        </Grid>

        <Grid item md={2} alignItems="right" textAlign={"end"} className="py-2">
          {moduleType === "requirement" && (
            <div>
            Lock Requirements <Switch onClick={(e:any)=>{              
              // setToggleValue(e.target.checked)
              // selector?.toggle?.locked=e.target.checked
           let detail= selector?.requirementdetail
           detail['locked']=e.target.checked           
           onToggleChange(e.target.checked)
          //  window.location.reload(false)
              // dispatch(
              //   setRequirementToggleValue({payload:detail},function(res:any){
              //     console.log(res)
              //   })
                
              // )

            }} checked={selector?.requirementdetail?.locked} />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomTab;

import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  createPendingItemsAsync,
  deletePendingItemsAsync,
  getPendingItemsAsync,
  searchAllUsersAsync,
  updatePendingItemsAsync,
  createPendingItemsAsyncUnitTest,
  getAllItemsAsyncUnitTest,
  saveAsyncUnitTest,
  saveAsyncTestCase,
  getAllTestCase
} from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import { taskActions, setTaskPendingItems } from "../../actions/Task";
import { APIResponse } from "../../../utility/commonTypes";

function* PendingItems(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      payload.payload.actionType == "create"
        ? createPendingItemsAsync
        : updatePendingItemsAsync,
      payload
    );
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* PendingItemsUnitTest(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(createPendingItemsAsyncUnitTest,payload);
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

function* SaveItemsUnitTest(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(saveAsyncUnitTest,payload);
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

function* SaveItemsTestCase(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(saveAsyncTestCase,payload);
    console.log(res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}


function* GetAllItemsUT(action: any) {
  const { payload, callback } = action;
  console.log("into the saga")
  try {
    let res: APIResponse = yield call(getAllItemsAsyncUnitTest, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* GetAllItemsTC(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getAllTestCase, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* GetPendingItems(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getPendingItemsAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setTaskPendingItems({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* deletePendingItems(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(deletePendingItemsAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* SearchAllUsers(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(searchAllUsersAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setUserDetails({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchPendingItems() {
  yield takeLeading(taskActions.CREATE_TASK_PENDING_ITEMS, PendingItems);
  yield takeLeading(taskActions.CREATE_TASK_PENDING_ITEMS_UNIT_TEST, PendingItemsUnitTest);
  yield takeLeading(taskActions.GETALL_UNIT_TESTING, GetAllItemsUT);
  yield takeLeading(taskActions.GETALL_TEST_CASE, GetAllItemsTC);
  yield takeLeading(taskActions.SAVE_ITEMS_UNIT_TEST, SaveItemsUnitTest);
  yield takeLeading(taskActions.SAVE_ITEMS_TEST_CASE, SaveItemsTestCase);
  yield takeLeading(taskActions.GET_TASK_PENDING_ITEMS, GetPendingItems);
  yield takeLeading(taskActions.UPDATE_TASK_PENDING_ITEMS, PendingItems);
  yield takeLeading(taskActions.DELETE_TASK_PENDING_ITEMS, deletePendingItems);
  yield takeLeading(taskActions.SEARCH_ALL_USERS, SearchAllUsers);
}

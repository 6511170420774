import { ProfileType } from "../../utility/commonTypes";

const MenuItemList: any = () => {
  return [
    {
      name: "Home",
      path: "/Home",
      menuItems: [],
      access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER]
    },
    {
      name: "Create",
      access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER],
      menuItems: [
        {
          id: "1",
          title: "Customer",
          path: "/customer-create",
          access: [ProfileType?.ADMIN]
        },
        {
          id: "2",
          title: "Project",
          path: "/project-create",
          access: [ProfileType?.ADMIN]
        },
        {
          id: "3",
          title: "Task",
          path: "/create-task",
          access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER]
        },
        {
          id: "4",
          title: "User",
          path: "/register",
          access: [ProfileType?.ADMIN]
        },
      ],
    },
    {
      name: "View",
      access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER],
      menuItems: [
        {
          id: "1",
          title: "My Projects",
          path: "/my-projects",
          access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER]
        },
        {
          id: "2",
          title: "My Tasks",
          path: "/my-tasks",
          access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER]
        },
        {
          id: "3",
          title: "All Customers",
          path: "/all-customers",
          access: [ProfileType?.ADMIN,ProfileType?.USER]
        },
        {
          id: "4",
          title: "All Projects",
          path: "/all-projects",
          access: [ProfileType?.ADMIN]
        },
        {
          id: "5",
          title: "Projects By Customer",
          path: "/projects-by-customer",
          access: [ProfileType?.ADMIN]
        },
        {
          id: "6",
          title: "Tasks By Customer / Project",
          path: "/tasks-by-customer",
          access: [ProfileType?.ADMIN]
        },
        // {
        //   id: "7",
        //   title: "Certification Details",
        //   path: "/all-users",
        //   access: [ProfileType?.ADMIN]
        // },
      ],
    },
    // {
    //   name: "Dashboard",
    //   access: [ProfileType?.ADMIN, ProfileType?.CUSTOMER, ProfileType?.USER]
      // menuItems: [
      //   {
      //     id: "1",
      //     title: "Project Health Dashboard",
      //     path: "/resource-utilization-dashboard",
      //   },
      // ],
    // },
     {
      name: "Admin",
      access: [ProfileType?.ADMIN],
      menuItems: [
        {
          id: "1",
          title: "User Security Management",
          path: "/all-users",
          access: [ProfileType?.ADMIN]
        },
      //   {
      //     id: "2",
      //     title: "Manage Announcement",
      //     path: "/manage-announcement",
      //     access: [ProfileType?.ADMIN]
      //   },
      //   {
      //     id: "3",
      //     title: "Imports",
      //     path: "/imports-dashboard",
      //     access: [ProfileType?.ADMIN]
      //   },
       ],
     },
    {
      name: "Useful Links",
      access: [ProfileType?.ADMIN,ProfileType?.USER],
      menuItems: [
        // {
        //   id: "1",
        //   title: "My DPN Profile",
        //   path: "/marketing",
        //   access: [ProfileType?.ADMIN]
        // },
        // {
        //   id: "2",
        //   title: "StaffIT",
        //   path: "/consulting",
        //   access: [ProfileType?.ADMIN]
        // },
        // {
        //   id: "3",
        //   title: "Deloitte India SAP",
        //   path: "/design",
        //   access: [ProfileType?.ADMIN]
        // },
        // {
        //   id: "4",
        //   title: "SalesForce",
        //   path: "/development",
        //   access: [ProfileType?.ADMIN]
        // },
        {
          id: "1",
          title: "Deloitte Momentum Methodology",
          path: "https://hrtech.deloitte.com/momentum#/subpage/home",
          access: [ProfileType?.ADMIN,ProfileType?.USER],
          externalLink:true
        },
        {
          id: "2",
          title: "Transition Document Generator",
          path: "/transition",
          access: [ProfileType?.ADMIN,ProfileType?.USER]
        },
        // {
        //   id: "7",
        //   title: "Integration Repository",
        //   path: "/development",
        //   access: [ProfileType?.ADMIN]
        // },
      ],
    },
    {
      name:"Global Integration Repository",
      path:"/repository",
      access: [ProfileType?.ADMIN, ProfileType?.USER]
    }
  ];
};

export default MenuItemList;

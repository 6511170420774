import './header.css'
const Header=()=>{
    return(
<div className="header">
    
<h1> WorkDay Integration Accelerator</h1>

</div>
    )
}
export default Header
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../helpers/validations/useForm";

import {
  getProjectResources,
  getUserByName,
  signupProjectResources,
} from "../../../store/actions/Project";
import CustomModal from "../../../components/CustomModalPop";
import setLoader from "../../../components/CustomLoader/loaderState";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RegisterForm from "../../Register/register";
import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { errorMessages, REPLACE_REGEX } from "../../../constants/common";
import { ProfileType } from "../../../utility/commonTypes";
import moment from "moment";

interface Resource {
  ResourceName: any,
  id:any;
  project_id:any;
  user_id: any;
  emailid: string;
  company_name: any;
  start_date: any;
  end_date: any;
  role: string;
  team: string;
  notes: any;
}

const ProjectResourcesForm: FC = ({ openModalPop, setOpenModalPop, row, handleClose,onSuccess }: any) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState<any>(false);
  const[actionType,setActionType]=useState("create")
  const [showSuggestion, setShowSuggestion] = useState(false);
  const[resourceName,setResourceName]=useState("")
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
const[openResourceRegisterPopUp,setOpenResourceRegisterPopUp]=useState(false)
const location=useLocation()
const stateData=location.state
const[message,setMessage]=useState([])
const [projectDetail, setProjectDetail] = useState({
  id:"",
  companyName:""
}
)
const [open, setOpen] = useState(false);
const handleCloseAlert = (
  event?: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }

  setOpen(false);
};
  const {
    handleSubmit,
    handleChange,
    data: resource,
    setData,
    errors,
  } = useForm<Resource>({
    validations: {
     
      start_date: {
        required: {
          value: true,
          message: errorMessages?.required()
        }
        },
        end_date: {
          required: {
            value: true,
            message: errorMessages?.required()
          },
          custom: {
            isValid: (value) => {
              if (actionType === "create") {
                const selected = new Date(endDate);
                const maxDate = new Date(startDate);
                maxDate.setHours(0, 0, 0, 0);
                maxDate.setDate(maxDate.getDate() + 1);
                console.log(maxDate);
                if (selected < maxDate) {
                  return false;
                }
                return true;
              }
              return true;
            },
            message: "End date should not be less then start date",
          },
        },
        ResourceName: {
          required: {
            value: true,
            message: errorMessages?.required()
          },
        custom: {
          isValid: (value) => {
            console.log("value is",value,resourceName)
            if (
              value !== undefined &&
              value != null &&
              value !== "" &&
              resourceName === ""
            ) {
              return false;
            }
            return true;
          },
          message: "Please select valid Resource Name",
        },
      },
      role:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Role", 100),
        },
      },
      team:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Team",100)
        },
      },
      notes:{
        pattern:{
        value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
        message:errorMessages?.pattern(),
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Notes",5000),
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      dispatch(
        signupProjectResources(
          {
            payload: {
              id:resource?.id,
              project_id:stateData?.subProjectId||stateData?.projectId,
              ResourceName: resource?.ResourceName,
              user_id: resource?.user_id,
              emailid: resource?.emailid,
              company_name: resource?.company_name,
              start_date: resource?.start_date,
              end_date: resource?.end_date,
              role: resource?.role,
              team: resource?.team,
              notes: resource?.notes,
              actionType:actionType
            },
          },
          onSignUpProjectResourceCallback
        )
      );
    },
  });

  const onSignUpProjectResourceCallback = (data: any,error:any) => {
    if (data == false) {
      setMessage(error)
      setErrorPopup(true);
    } else {
      onSuccess();
     setOpenModalPop(false)
    }

    setLoader(false)
  };
  
  

    const handleKeyUpEvent=(event:any)=>{
    dispatch(
      getUserByName({ searchWord: event?.target?.value, customerUser: 0 }, function(res: any) {
        if(res==="Match not found")
        {
          res=""
        }
        setSuggestions({
          ...res
        })
      }))
  }
  const getProjecResourceDetails = (projectId: any) => {
    if (stateData?.projectId) {
      setLoader(true);
      dispatch(
        getProjectResources({ id: subProjectId||projectId }, function(res: any) {
          setProjectDetail({
            id: stateData?.subProjectId||stateData?.projectId,
            companyName: res?.company_name,
          });
          setLoader(false);
        })
      );
    }
  };
  const handleClickOpen = () => {
    setOpenResourceRegisterPopUp(true);
  };
  useEffect(() => {
    stateData?.projectId||stateData?.subProjectId && getProjecResourceDetails(stateData?.subProjectId||stateData?.projectId);
    setResourceName(row?.User?.ResourceName)
    console.log("row",row?.start_date,typeof(row?.end_date))
    if(row && row?.id)
    {
          row?.id && setActionType("update")
            setData({
              id:row?.id,
              project_id:row?.project_id,
              ResourceName:row?.User?.ResourceName,
              user_id:row?.user_id,
              start_date: row?.start_date.split("T")[0],
              end_date: row?.end_date.split("T")[0],
              emailid: row?.User?.emailid,
              company_name: row?.company_name,
              role:row?.role,
              team: row?.team,
              notes: row?.notes,
        });
      }
      else{
        setActionType("create")
        setData({
          id:"",
          project_id:"",
          ResourceName:"",
          user_id:"",
          start_date: "",
          end_date: "",
          emailid: "",
          company_name:"",
          role:"",
          team: "",
          notes: ""
    });
      }
  }, [setData,row]);

  useEffect(() => {
    openModalPop && setOpenModalPop(true)
  }, [openModalPop]);
let suggestRef=useRef()
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <CustomModal
        title="Select Project Resources"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth='750px'
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Resource Name* <AddCircleOutlineIcon onClick={handleClickOpen}/></label>
                    <input
                      name="ResourceName"
                      id="ResourceName"
                      type="text"
                      value={resource?.ResourceName}
                      onChange={handleChange("ResourceName",(val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpEvent}
                      className="form-control"
                    />
                    {errors.ResourceName && (
                      <p className="error-text">{errors.ResourceName}</p>
                    )}
                           {showSuggestion && (
             <ul className="list-group"
             ref={suggestRef}
             style={{ position: "absolute", zIndex: 1 , overflow:"auto",
             right: 0,
             left: 0,
             width: "100%",
             height: 130,
             top:92
           }}             >
              {""} 
              {Object.entries(suggestions)?.map(([suggest, i]: any) => {
                      return <li className="list-group-item" onClick={(e) => {
                        setShowSuggestion(false);
                        setResourceName(i?.id)
                        setData({
                          ...resource,
                          emailid: i?.emailid,
                          user_id: i?.id,
                          ResourceName: i?.ResourceName

                        })
                      }}>{i?.ResourceName}</li>
                    })}
                    </ul>
                           )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input
                      type="text"
                      name="emailid"
                      id="emailid"
                      value={resource?.emailid}
                      className="form-control"
                    />
                    {errors.emailid && <p className="error-text">{errors.emailid}</p>}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Start Date*</label>
                    <input
                      name="start_date"
                      type="date"
                      value={resource?.start_date}
                      onChange={handleChange("start_date")}
                      data-date={resource?.start_date ? moment(resource?.start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                    />
                    {errors.start_date && (
                      <p className="error-text">{errors.start_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">End Date*</label>
                    <input
                      name="end_date"
                      type="date"
                      value={resource?.end_date}
                      onChange={handleChange("end_date")}
                      data-date={resource?.end_date? moment(resource?.end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                    />
                    {errors.end_date && <p className="error-text">{errors.end_date}</p>}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Role</label>
                    <input
                      name="role"
                      type="text"
                      value={resource?.role}
                      onChange={handleChange("role")}
                      className="form-control"
                    />
                    {errors.role && <p className="error-text">{errors.role}</p>}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Team </label>
                    <input
                      name="team"
                      value={resource?.team}
                      onChange={handleChange("team")}
                      className="form-control"
                    />
                    {errors.team && <p className="error-text">{errors.team}</p>}
                  </div>
                </div>
                <div className="col-md-12 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Notes</label>
                    <textarea
                      name="notes"
                      id="notes"
                      value={resource?.notes}
                      onChange={handleChange("notes")}
                      className="form-textarea"
                      placeholder="Notes"
                    ></textarea>
                    {errors.notes && <p className="error-text">{errors.notes}</p>}
                  </div>
                </div>
       {ProfileType?.ADMIN === getProfileType &&         <div className="col-md-12">
           {actionType === "create" ? (
              <button className="btn add_button btn-primary mx-0 w-auto" id="create" onClick={()=>{setLoader(false)}}>
                ADD
              </button>
            ) : (
              <button 
              className="btn add_button btn-primary mx-0 w-auto" id="create">
                UPDATE
              </button>
            )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls">
                    CANCEL
                  </button>
                </div>}
              </div>
            </form >
          </div >
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title="Project Resource"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Project Resource"
        onOpen={successPopup}
        content={"Project Resource Added Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
        <CustomModal
        title="Create Resource"
        height="80%"
        width="md"
        onOpen={openResourceRegisterPopUp}
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={<RegisterForm companyId={stateData?.customerId||stateData?.companyCustomerId} setOpenResourceRegisterPopUp={setOpenResourceRegisterPopUp}/>}
        OnClickCloseIcon={() => {
          setOpenResourceRegisterPopUp(false);
        }}
      />
      <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};

export default ProjectResourcesForm;

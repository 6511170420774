export const BoxStyle = {
  marginTop: '10px',
  paddingRight: "20px",
  paddingLeft: "20px",
  marginBottom: '40px',
  width: '100%',
  '& .MuiDataGrid-root': {
    border: "none",
    backgroundColor: "white",
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    padding: "10px",
    fontSize: '16px'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: "1px solid #212529"
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#212529',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '600',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: "1px solid #212529"
  }
}

export const HeaderStyle = {
  fontSize: '22px',
  fontWeight: '700',
  color: '#000',
  lineHeight: '30px',
}

export const ButtonStyle = {
  paddingRight: "2.5rem",
  paddingLeft: "2.5rem",
  backgroundColor: "#007cb0",
  "&:hover": {
    backgroundColor: "#007cb0"
  },
}
export const selectBoxStyle = {
  border: 'none',
  borderBottom: '1px solid #fff',
  background: 'transparent',
  outline: 'none',
  height: '40px',
  color: 'rgb(189, 178, 178)',
  fontSize: ' 14px',
  width: '100%',
  "& .MuiSelect-icon": {
      color: 'white',
  },
}

export const selectBoxSearchStyle = {
  border: 'none',
  background: 'darkGrey',
  outline: 'none',
  height: '40px',
  color: 'rgb(189, 178, 178)',
  fontSize: ' 14px',
  marginTop: '-10px',
  // width: '200%',
  // position: "relative",
}

export const MenuPropsStyle = {
  backgroundColor: 'Grey',
  maxHeight: "31%",
  minHeight: "5%",
  top: '100%',
  fontWeight: '600',
  fontSize: '15px',
  //zIndex: '99',
  border: 'none',
  "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#3399FF",
      color: 'white',
      marginBottom: "1.8px",
  },
  "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#3399FF",
      color: 'white',
  },
  '.MuiMenuItem-root.Mui-disabled': {
      color: 'rgb(189, 178, 178)',
      opacity: 1,
      fontSize: '15px',
      fontWeight: 'bold',
      marginTop: "-.9rem",
      textAlign: "left",
  },

}

export const MenuItemStyle = {
  color: 'rgb(189, 178, 178)',
  fontSize: '15px',
  fontWeight: 'bold',
  paddingTop: "-7px"
}


export const TextFieldStyle = {
  padding: "3px",
  color: 'white',
  fontSize: '15px',
  fontWeight: 'bold',
  outline: 'none',
  border: 'none',
}
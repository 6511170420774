import { call, put, takeLeading } from "redux-saga/effects";
import { deleteTechnicalTaskFiles, downloadTaskTechnicalFiles, GetTaskFilesAsync, GetTaskTechnicalFilesAsync, taskTechnicalFilesFunctionality, updateTaskTechnicalFilesAsync, uploadTaskFilesAsync, uploadTaskTechnicalFilesAsync } from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import { taskActions } from "../../actions/Task";
import { APIResponse } from "../../../utility/commonTypes";

function* UploadTaskTechnicalFiles(action: any) {
  const { payload, task_id, callback } = action;
  console.log("payload is", payload);
  console.log("id", task_id);

  try {
    let res: APIResponse = yield call(payload.task_uploaded_file!=undefined
    ?taskTechnicalFilesFunctionality
    :updateTaskTechnicalFilesAsync, payload, task_id);
    console.log(res.data);

    if (res?.status === 201) {
      
      callback(res?.data);
    } 
    else if (res?.status === 200) {
      callback(res?.data);
    }
    else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* getTaskTechnicalFiles(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(GetTaskTechnicalFilesAsync, payload);
    console.log(res.data);

    if (res?.status === 200) {
      
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* deleteTechnicalFile(action:any) {
  const { payload, callback } = action;

  try {

      let res:APIResponse = yield call(deleteTechnicalTaskFiles,payload)
      console.log(res.data)
      if (res?.status === 200) {
          callback(res?.data)
         
      }

  }
  catch (error) {
      console.log(error)
  }
}
function* downloadFileTaskTechnical(action:any) {
  const { payload, callback } = action;

  try {

      let res:APIResponse = yield call(downloadTaskTechnicalFiles,payload)
      console.log(res.data)
      if (res?.status === 200) {
          callback(res?.data)
         
      }

  }
  catch (error) {
      console.log(error)
  }
}
export function* watchTaskTechnicalFiles() {
  yield takeLeading(taskActions.GENERATE_SIGNED_URL_TECHNICAL, UploadTaskTechnicalFiles);
   yield takeLeading(taskActions.UPLOAD_TASK_TECHNICAL_FILES, UploadTaskTechnicalFiles);
  yield takeLeading(taskActions.UPDATE_TASK_TECHNICAL_FILES, UploadTaskTechnicalFiles);
  yield takeLeading(taskActions.GET_TASK_TECHNICAL_FILES, getTaskTechnicalFiles)
  yield takeLeading(taskActions.DELETE_TECHNICAL_TASK_FILES, deleteTechnicalFile)
  yield takeLeading(taskActions.DOWNLOAD_TASK_TECHNICAL_FILES, downloadFileTaskTechnical)

}

import { takeLatest, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../actionTypes";
import {
  FetchMyTasksLists,
  FetchProjectByCustomerLists,
  FetchProjectByCustomerHealth,
  FetchProjectByCustomerHealthRatio,
} from "./TasksLists";
import {
  FetchTaskProgressAsync,
  UpdateTaskProgressAsync,
} from "./TaskProgress";

const {
  GET_MY_TASKS_LIST,
  GET_TASK_PROGRESS,
  UPDATE_TASK_PROGRESS,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH,
  GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO,
} = ACTION_TYPES.TASKS;

export function* watchTasks() {
  yield takeLatest(GET_MY_TASKS_LIST, FetchMyTasksLists);
  yield takeLatest(GET_TASK_PROGRESS, FetchTaskProgressAsync);
  yield takeLatest(UPDATE_TASK_PROGRESS, UpdateTaskProgressAsync);
  yield takeLatest(
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_LIST,
    FetchProjectByCustomerLists
  );
  yield takeLatest(
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH,
    FetchProjectByCustomerHealth
  );
  yield takeLatest(
    GET_TASKS_BY_CUSTOMER_AND_PROJECT_HEALTH_RATIO,
    FetchProjectByCustomerHealthRatio
  );
}

import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import RequirementMain from "./main";
import RequirementPendingItems from "./PendingItems/RequirementPendingItems";
import Technical from "./Technical/technical";
import DataTab from "../Tasks/dataTab";
import Transport from "./Transport";
import Test from "./Test";
import DynamicTab from "./DynamicTab";
import CustomTab from "../../components/CustomTab";

import { CONVERTED_KEYS } from "../../constants/common";
import { getTaskDetails } from "../../store/actions/Task";
import { activeTabClass, blankTabClass } from "./style";
import FieldRequirement from "./FieldRequirement";
import ProcessFlow from "./ProcessFlow";
import setLoader from "../../components/CustomLoader/loaderState";
import { useDispatch } from "react-redux";
import PrintTab from "./Print";
import { setRequirementToggleValue } from "../../store/actions/Requirement";

const Requirement = () => {
  const location = useLocation();
  const params: any = useParams();
  const [value, setValue] = useState(0);
  const stateData: any = params;
  const dispatch = useDispatch();
  console.log({ params, location });
  const [taskData, setTaskData] = useState({});
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
const [toggleValue,setNewToggleValue]=useState(false)
  console.log("selector",selector);
  useEffect(() => {
    stateData?.taskId &&
      dispatch(
        getTaskDetails({ id: stateData?.taskId }, function (res: any) {
          setTaskData(res);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })
      );
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);
  console.log("--------");
  console.log({ selector });
  const onToggleChange=(isChecked:any)=>{
    console.log("toggle checked",isChecked)
    let detail= selector
    detail['locked']=isChecked
    console.log("details",detail)
      dispatch(
                setRequirementToggleValue({payload:detail},function(res:any){
                  console.log(res)
                  setNewToggleValue(res?.locked)
                })
                
              )
  }
  return (
    <>
      <CustomTab
        indexNumber={1}
        title={taskData?.integration_id + "-" + taskData?.task_name}
        requirementId={taskData?.Requirement?.id}
        locationPath={location.pathname}
        moduleType="requirement"
        onToggleChange={onToggleChange}
      />
      <Box
        className="my-3"
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: "40px",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          aria-label="project-list"
          variant="scrollable"
          scrollButtons={false}
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab sx={activeTabClass} label="Main" />
          {/* <Tab sx={activeTabClass} label="Contacts" /> */}
          <Tab sx={activeTabClass} label="Technical" />
          <Tab sx={activeTabClass} label="Process Flow" />
          <Tab sx={activeTabClass} label="Data" />
          <Tab sx={activeTabClass} label="Field Requirements" />
          <Tab sx={activeTabClass} label="Transport" />
          <Tab sx={activeTabClass} label="Test" />
          {/* <Tab sx={activeTabClass} label="Changes Audit" /> */}
          <Tab sx={activeTabClass} label="Pending Items" />
          {selector?.requirement_template ? (
            <Tab
              sx={activeTabClass}
              label={CONVERTED_KEYS[selector?.requirement_template]}
            />
          ) : (
            <Tab sx={activeTabClass} label="Print" />
          )}

          {selector?.requirement_template ? (
            <Tab sx={activeTabClass} label="Print" />
          ) : (
            <Tab sx={blankTabClass} disabled />
          )}

          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
         
        </Tabs>
        <div
          style={{
            border: "1px solid #007CB0",
            borderTop: "1px solid transparent",
            padding: "2px",
          }}
        >   
          {value === 0 && <RequirementMain toggleValue={toggleValue}/>}
          {/* {value === 1 && <div>Contacts Page</div>} */}
          {value === 1 && <Technical toggleValue={toggleValue}/>}
          {value === 2 && <ProcessFlow />}
          {value === 3 && <DataTab toggleValue={toggleValue}/>}
          {value === 4 && <FieldRequirement toggleValue={toggleValue}/>}
          {value === 5 && <Transport />}
          {value === 6 && <Test />}
          {/* {value === 8 && <div></div>} */}
          {value === 7 && <RequirementPendingItems />}
          {selector?.requirement_template 
            ? value === 8 && <DynamicTab toggleValue={toggleValue}/>
            : value === 8 && <PrintTab />}
          {selector?.requirement_template ? value === 9 && <PrintTab /> : null}
        </div>
      </Box>
    </>
  );
};

export default Requirement;

import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import columns from "./columnsDef";

import { BoxStyle } from "./style";
import { getIntegrationRepositoryTable } from "../../store/actions/Repository";
import CustomPagination from "../../components/CustomPagination";

const RepositoryGrid = ({
  name,
  requirement,
  area,
  SourceTargetSystem,
}: any) => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [taskName, setTaskName] = useState("");
  const [requirementTemplate, setTemplate] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");
  const [sourceOrTargetSystem, setSourecTargetSystem] = useState("");
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);

  const load = useRef(true);
  
  const fetchAllTableList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);

      dispatch(
        getIntegrationRepositoryTable(
          {
            taskName: name,
            requirementTemplate: requirement,
            functionalArea: area,
            sourceOrTargetSystem: SourceTargetSystem,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);

            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTableList(0, "ON_LOAD");
  }, [name, requirement, SourceTargetSystem, area]);

  return (
    <>
      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          sortModel={[{ field: "name", sort: "asc" }]}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchAllTableList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default RepositoryGrid;

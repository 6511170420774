import React, { useState } from "react";

import Box from "@mui/material/Box";
import { Tab, Tabs } from "@mui/material";
import { activeTabClass,blankTabClass } from "../Customer/style";
import RegisterForm from "./register";

const User = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <Box
      className="my-3"
      sx={{
        paddingRight: "20px",
        paddingLeft: "20px",
        marginBottom: "40px",
        width: "100%",
      }}
    >
      <Tabs
        value={value}
        aria-label="project-list"
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Tab sx={activeTabClass} label="User Profile" />
        <Tab sx={activeTabClass} label="Certifications" />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
        <Tab sx={blankTabClass} disabled />
      </Tabs>
      <div
        style={{
          border: "1px solid #007CB0",
          borderTop: "1px solid transparent",
          padding: "2px",
        }}
      >
        {value === 0 && <RegisterForm />}
        {value === 1 && <div>Certifications</div>}
       
      </div>
    </Box>
  );
};

export default User;

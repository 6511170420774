import { taskActions } from "../../actions/Task";

const initialState = {
  isPendingTaskCreated: false,
 isEditable:0,
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case taskActions.GET_TASK_DETAILS:{
     return{
      ...state,
      
    }
  }
  case taskActions.SAVE_EDITABLE_TASK:
    return {
      ...state,
      taskEditable:{
        [action?.id]:action?.isEditable
      }
    };
  
    case taskActions.CREATE_TASK_PENDING_ITEMS:
      return {
        ...initialState,
        isPendingTaskCreated: true,
      };

    case taskActions.CREATE_TASK_PENDING_ITEMS_UNIT_TEST:
      return {
        ...initialState,
        isPendingTaskCreated: true,
      };  
    case taskActions.SAVE_ITEMS_UNIT_TEST:
      return {
        ...initialState,
        isPendingTaskCreated: true,
      };  
    case taskActions.SET_TASK_PENDING_ITEMS:
      return {
        ...initialState,
        // isCustomerProjectCreated:true,
        data: state.data,
      };
    // case taskActions.GET_TASK_PENDING_ITEMS:
    //   return {
    //     ...state,
    //     data: state.data,
    //   };
    case taskActions.SET_TASK_DETAILS: {
      return {
        ...state,
        data: {
          requirementId: action?.payload.Requirement.id,
          // data: {
          //     companyId:action?.payload.company_customer_id,
          //     intId:action?.payload.integration_id,
          //     taskName:action?.payload.task_name,
          //     projectId:action?.payload.project_id,
          //     customer_id:action?.payload.Project.Customer.id
        },
      };
    }
    case taskActions.UPDATE_TASK_PENDING_ITEMS:
      return {
        ...initialState,
        date: state.data,
      };
    default:
      return state;
  }
}

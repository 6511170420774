import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "task_name",
      headerName: "Tasks",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.task_name ? (
              <Tooltip title={params?.row?.integration_id+"-"+params?.row?.task_name} followCursor>
                <Link
                  to={`/create-update-task/${params?.row?.project_id}/${params?.row?.customer_id}/${params?.row?.id}`}
                  state={{
                    subProject:
                      params?.row?.project_level === "Sub_Project"
                        ? true
                        : false,
                    projectId: params?.row?.project_id,
                    customerId: params?.row?.customer_id,
                    action: "update",
                    taskId: params?.row?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.integration_id+"-"+params?.row?.task_name}
                </Link>
              </Tooltip>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      field: "project_name",
      headerName: "Project",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.project_level === "Sub_Project" ? (
              <Tooltip title={params?.row?.project_name} followCursor>
                <Link
                  to={`/project/manage-sub-project/${params?.row?.master_project}/${params?.row?.customer_id}/${params?.row?.project_id}`}
                  state={{
                    masterProjectId: params?.row?.master_project,
                    companyCustomerId: params?.row?.customer_id,
                    subProjectId: params?.row?.project_id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.project_name}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title={params?.row?.project_name} followCursor>
                <Link
                  to={`/project/${params?.row?.project_id}/${params?.row?.customer_id}`}
                  state={{
                    projectId: params?.row?.project_id,
                    customerId: params?.row?.customer_id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.project_name}
                </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "task_completion_percentage",
      headerName: "Completion Percentage",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) =>
        params?.row?.task_completion_percentage + ' %',
    },
    {
      field: "golive_date",
      headerName: "Go Live Date",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => (
        <Tooltip title={params?.row?.golive_date ?formatDate(params?.row?.golive_date):"NA"} followCursor>
          <div>{params?.row?.golive_date ?formatDate(params?.row?.golive_date):"NA"}</div>
        </Tooltip>
      ),
    },
    {
      field: "stage",
      headerName: "Stage",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => 
      <Tooltip title={params?.row?.stage === "NA"?"Not Started": params?.row?.stage} followCursor>
        <div>{params?.row?.stage === "NA"?"Not Started": params?.row?.stage}</div>
      </Tooltip>
    },
    /*{
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      minWidth: 110,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Link to={`/create-update-task/${params?.row?.Task?.id}`} state={{ taskId: params?.row?.Task?.id }} style={{ color: "#1976d2" }}>
            <EditIcon sx={{ color: "#B4B2B2" }} />
          </Link>

        );
      }
    },*/
  ];
};

export default columns;

import React, {useEffect} from "react"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import { useForm } from "../../helpers/validations/useForm";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import "./login.css";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ForgotPassword from "../PasswordReset/forgot";
import { Button, Grid } from "@mui/material";
import { ButtonStyle } from "../Projects/ProjectTask/style";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../components/Authentication/authProvider";
import { ssoLogin } from "../../store/actions/auth";
import { loginUser } from "../../store/actions/User";

interface Login {
  email: any;
  password: any;
  token:any
}
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © Copyright "}
      {/* <Link color="inherit" href="#"> */}
      Deloitte India LLP
      {/* </Link> */} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
const logoImg = "/assets/images/logo.png";
const signInImg = "/assets/images/signin.png";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorPopup, setErrorPopup] = useState(false);
  const [isTriedLogin, setIsTriedLogin] = useState(() => {return sessionStorage.getItem('istriedlogin') == 'true'});
  const[message,setMessage]=useState("User authorization failed")
  // const {
  //   handleSubmit,
  //   handleChange,
  //   data: customer,
  //   errors,
  //   setData,
  // } = useForm<Login>({
  //   validations: {
  //     email: {
  //       required: {
  //         value: true,
  //         message: "Email field should not be empty ",
  //       },
  //     },
  //     password: {
  //       required: {
  //         value: true,
  //         message: "Password field should not be empty",
  //       },
  //     },
  //   },
  //   onSubmit: () => {
  //     setLoader(true);
  //     dispatch(
  //       loginUser(
  //         {
  //           payload: {
  //             email: customer.email,
  //             password: customer.password,
  //           },
  //         },

  //         onLoginCallback
  //       )
  //     );
  //   },
  // });
  // const handleClose = () => {
  //   setOpenModalPop(false);
  // };
  // const onLoginCallback = (data) => {
  //   //redirection logic
  //   setLoader(false);
  //   //in response we can get error or success
  //   if (data === false) {
  //     setErrorPopup(true);
  //   } else {
  //     navigate("/home");
  //   }
  // };
  // const [openModalPop, setOpenModalPop] = useState<any>(false);
  // const handleModalPopup = () => {
  //   console.log("login open", openModalPop);

  //   setOpenModalPop(true);
  //   console.log("login open", openModalPop);
  // };
 
const handleLogin=()=>{
  navigate("/login")
}
const { instance } = useMsal();

// useEffect(() => {
    const accounts = instance.getActiveAccount();
    if (accounts && !isTriedLogin) {
        instance.acquireTokenSilent({...loginRequest, account: accounts}).then(response=>{
            dispatch(
                loginUser(
                    {
                        payload:{
                            isSSOLogin:true,
                            token:response?.accessToken
                        }
                    },
                    onSSOLoginCallback
                )
            );
        })
    }
// }, [isTriedLogin,instance]);

const onSSOLoginCallback = (data:any,error:any) => {
  //redirection logic
  setLoader(false);
  //in response we can get error or success
  if (data === false) {
        sessionStorage.setItem('istriedlogin', 'true');
        setIsTriedLogin(true);
        setMessage(error)
        setErrorPopup(true);
  } else {
    navigate("/home");
  }
};


const handleSSO=()=>{
    instance.setActiveAccount(null);
    sessionStorage.setItem('istriedlogin', 'false');
    setIsTriedLogin(false);
    instance.acquireTokenRedirect(loginRequest);
}
  return (
    <ThemeProvider theme={theme}>
      <div className="table-wrap">
        <div className="align-wrap">
          <div className="container-fluid p-0 ">
            <div className="row m-0" style={{ float: "left", width: "50%" }}>
              <div
                className="col-md-6 p-0 height-vh bg-dark"
                style={{ width: "100%" }}
              >
                <div className="login-wrap">
                  <div className="logo">
                    <img src={logoImg} alt="Logo" />
                  </div>
                  <div className="text-center inner-img">
                    <img src={signInImg} alt="signing_img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="cantrol_div">
              <div className="form-wrap">
               
                  <div className="btn-block">
                    <button style={{textAlign:"center"}}type="submit" className="thame-btn w-100" onClick={handleSSO}>
                      SSO Login
                    </button>&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="btn-block">
                    <button type="submit" id="createButton" className="thame-btn w-100" onClick={handleLogin}
                    >
                      Login
                    </button>

                  </div>
                  

              <div className="footer_section">
                <div className="copyright_text">
                  {" © Copyright "}
      Deloitte  LLP{" "}
       {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      
     </div>
     <CustomModal
        title="SSO Login"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"md"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
          setMessage("User authorization failed")
        }}
      />
    </ThemeProvider>
  );
}

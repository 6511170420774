import TaskFiles from "./TaskFiles/taskFiles";
import TechnicalFiles from "./TechnicalFiles/technicalFiles";

const Files = () => {
  return (
    <>
      <TaskFiles />
      <TechnicalFiles />
    </>
  );
}

export default Files;
import React from 'react';

import { Button, withStyles, ButtonProps } from '@material-ui/core';

export const CustomButton = withStyles({
    root: {
        minHeight: '40px',
        minWidth: '140px',
        '@media (max-width:576px)': {
            minWidth: '96px',
        },
        fontSize: 12,
        letterSpacing: 1.6,
        borderWidth: 1.5,
        fontWeight: 'bold',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            boxShadow: 'none',
        },
        '&.MuiButton-containedSizeLarge': {
            height: '44px',
        },
        '&.MuiButton-outlinedPrimary': {
            // border: "1.5px solid #0369dd"
        },
        '&$disabled': {
            backgroundColor: '#eff1f6',
        },
        '&.MuiButton-text.Mui-disabled': {
            backgroundColor: 'transparent',
        },
        '@media (min-width: 1681px)': {
            fontSize: '0.9rem !important',
        },
    },
    disabled: {},
})((props: ButtonProps) => <Button component="span" {...props} />);

import { useEffect, useState, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import { getCustomerContactList } from "../../../store/actions/Customers";
import CustomerContactsForm from "./CustomerContactsForm";
import CustomPagination from "../../../components/CustomPagination";
import CustomModal from "../../../components/CustomModalPop";
import Columns from "./columnsDef";
import { BoxStyle, ButtonStyle, HeaderStyle } from "./style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import { CustomConfirmPopup } from "../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import { deleteCustomerContact } from "../../../store/actions/Customer";
import ViewForm from "../../Register/viewPage";
import React from "react";
import { ProfileType } from "../../../utility/commonTypes";

const CustomerContacts = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const[userId,setUserId]=useState("")
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [successPopup, setSuccessPopup] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const location = useLocation();
  const params: any = useParams();
  const [deleteSuccessPopup, setDeleteSuccessPopup] = useState(false);

  const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
  //const stateData: any = location.state;
  const stateData: any = params;
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setLoader(true);
    setOpenModalPop(true);
    setLoader(false);
    setSelectedRowId("");
  };
  const fetchCustomerContactsList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getCustomerContactList(
          {
            id: stateData?.customerId,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e?.error, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const [deletePopUp, setDeletePopUp] = useState(false);

  const onClickYes = (rowId: any) => {
    setDeletePopUp(false);
    setLoader(true);

    dispatch(
      deleteCustomerContact(
        { id: rowId, company_customer_id: stateData?.customerId },
        function (res: any) {
          setLoader(false);
          setDeleteSuccessPopup(true);
          setTimeout(()=>{
            setDeleteSuccessPopup(false);
      
          },5000)     
          fetchCustomerContactsList(0, "ON_LOAD");
        }
      )
    );
  };

  const onClickNo = () => {
    setDeletePopUp(false);
  };
  useEffect(() => {
    fetchCustomerContactsList(0, "ON_LOAD");
  }, []);

  const deleteCustomerContacts = (event: any, id: any) => {
    setDeletePopUp(true);
    setSelectedRowId(id);
  };

  const editCustomerContacts = (event: any, id: any) => {
    setOpenModalPop(true);
    setSelectedRowId(id);
  };
const handleUserDetails=(event:any,id:any)=>{
  setOpenUserDetailsPopUp(true)
  setUserId(id)
}
  const defaultColumns = useMemo(() => {
    return Columns(editCustomerContacts, deleteCustomerContacts,handleUserDetails);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
    setSelectedRowId("");
  };

  const onCusterContactsCreateSuccess = () => {
    fetchCustomerContactsList(0, "ON_LOAD");
    setSuccessPopup(true);
    setTimeout(()=>{
      setSuccessPopup(false);

    },5000)
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Customer Contacts
        </Typography>
        <div className="my-4 mx-4">
{ProfileType?.ADMIN === getProfileType &&          <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>}
        </div>
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchCustomerContactsList(data, "NEXT_PAGE");
          }}
        />
      </Box>

      {openModalPop && (
        <CustomerContactsForm
          row={selectedRowId}
          messages={messages}
          onSuccess={onCusterContactsCreateSuccess}
          handleClose={handleClose}
          openModalPop={openModalPop}
          setOpenModalPop={setOpenModalPop}
        />
      )}
  {ProfileType?.ADMIN === getProfileType &&          
      <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        closeAndSubmit={() => {}}
        onClickYes={onClickYes}
        rowId={selectedRowId}
        onClickNo={onClickNo}
        title={`Delete Customer Contacts`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected row`}
        // successIcon={true}
      />}
      <CustomModal
        title="Customer Contact"
        onOpen={successPopup}
        content={"Customer Contact Added/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
        <CustomModal
        title="Customer Contact"
        onOpen={deleteSuccessPopup}
        content={"Customer Contact Deleted Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setDeleteSuccessPopup(false);
        }}
      />
        <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
    </>
  );
};

export default CustomerContacts;

import { call, put, takeLeading } from "redux-saga/effects";
import { setAuthToken } from "../../actions/auth";
import { APIResponse } from "../../../utility/commonTypes";
import {
  requirementActions,
  setRequirementDetails,
} from "../../actions/Requirement";
import {
  getRequirementDetailsAsync,
  updateRequirementDetailsAsync,
} from "../../../api/common";
import {
  getRequirementTemplateQuestions,
  updateRequirementTemplateQuestions,
  updateNumberOfFields,
  createFormFieldRequirement,
  getNumberOfFields,
  getFormFieldRequirement,
  updateFormFieldRequirement,
  deleteFormFieldRequirement,
  getPdfRequirement,
  uploadProcessFlow,
  getProcessFlow,
} from "../../../api/Requirement";
import { ACTION_TYPES } from "../../actionTypes";
const {
  GET_REQUIREMENT_QUESTION,
  UPDATE_REQUIREMENT_QUESTIONS,
  UPDATE_REQUIREMENT_NUMBER_OF_FIELDS,
  CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  GET_REQUIREMENT_NUMBER_OF_FIELDS,
  GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
  GET_PDF_REQUIREMENT,
  SET_PROCESS_FLOW,
  GET_PROCESS_FLOW,
} = ACTION_TYPES.REQUIREMENT;

function* getRequirement(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getRequirementDetailsAsync, payload);
    if (res?.status === 200) {
      yield put(setRequirementDetails({ ...res.data }, function () {}));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* Requirement(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(updateRequirementDetailsAsync, payload);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
function* getTemplateQuestion(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(getRequirementTemplateQuestions, payload);
    if (res?.status === 201) {
      yield put(
        setAuthToken({ ...res.data, refreshTime: new Date().getTime() / 1000 })
      );
      callback(res?.data);
    } else if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* updateTemplateQuestion(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(
      updateRequirementTemplateQuestions,
      payload
    );
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* updateRequirementNumberOfField(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(updateNumberOfFields, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* createRequirementNumberOfFieldForm(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(createFormFieldRequirement, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* getRequirementNumberOfField(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getNumberOfFields, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* getRequirementNumberOfFieldForm(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getFormFieldRequirement, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* updateRequirementNumberOfFieldForm(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(updateFormFieldRequirement, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* deleteRequirementNumberOfFieldForm(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(deleteFormFieldRequirement, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getPdfRequirementSaga(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getPdfRequirement, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* setProcessUploadSaga(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(uploadProcessFlow, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getProcessUpload(action: any): any {
  const { payload, callback } = action;
  try {
    const res: APIResponse = yield call(getProcessFlow, payload);
    if (res?.status === 200) {
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}


export function* watchRequirementDetails() {
  yield takeLeading(requirementActions.UPDATE_REQUIREMENT_DETAILS, Requirement);
  yield takeLeading(requirementActions.SET_TOGGLE_REQUIREMENT, Requirement);

  yield takeLeading(requirementActions.GET_REQUIREMENT_DETAILS, getRequirement);
  yield takeLeading(GET_REQUIREMENT_QUESTION, getTemplateQuestion);
  yield takeLeading(UPDATE_REQUIREMENT_QUESTIONS, updateTemplateQuestion);
  yield takeLeading(
    UPDATE_REQUIREMENT_NUMBER_OF_FIELDS,
    updateRequirementNumberOfField
  );
  yield takeLeading(
    CREATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    createRequirementNumberOfFieldForm
  );
  yield takeLeading(
    GET_REQUIREMENT_NUMBER_OF_FIELDS,
    getRequirementNumberOfField
  );
  yield takeLeading(
    GET_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    getRequirementNumberOfFieldForm
  );
  yield takeLeading(
    UPDATE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    updateRequirementNumberOfFieldForm
  );
  yield takeLeading(
    DELETE_REQUIREMENT_NUMBER_OF_FIELDS_FORM,
    deleteRequirementNumberOfFieldForm
  );
  yield takeLeading(GET_PDF_REQUIREMENT, getPdfRequirementSaga);
  yield takeLeading(SET_PROCESS_FLOW, setProcessUploadSaga);
  yield takeLeading(GET_PROCESS_FLOW, getProcessUpload);

}

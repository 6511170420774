import {
    ACTION_TYPES
} from "../../../actionTypes";

const {
    GET_ALL_CUSTOMER_LIST
} = ACTION_TYPES.CUSTOMER;

export const getAllCustomerList = (payload: any, callback: CallableFunction) => {
    return {
        type: GET_ALL_CUSTOMER_LIST,
        payload,
        callback
    }
}
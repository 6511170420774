export const actions = {
    GET_CONFIG_DATA: 'GET_CONFIG_DATA',
    SAVE_CONFIG_RESPONSE: 'SAVE_CONFIG_RESPONSE',
    SF_HIDE_INFO_NOTE: 'SF_HIDE_INFO_NOTE',
};

export const getConfigData = (payload: object) => ({
    type: actions.GET_CONFIG_DATA,
    payload: payload,
});
export const saveConfigData = (payload: object) => ({
    type: actions.SAVE_CONFIG_RESPONSE,
    payload,
});

export const hideSFInformationNote = () => ({
    type: actions.SF_HIDE_INFO_NOTE,
});

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, Button } from "@mui/material";
import { BoxStyle, ButtonStyle, HeaderStyle } from "../style";
import CustomPagination from "../../../components/CustomPagination";
import { CustomConfirmPopup } from "../../../components/CustomPopups/ConfirmPopup/CustomConfirmPopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import Columns from "./columnsDef";
import ResourcesForm from "./resourcesForm";
import {
  deleteTaskResources,
  getTaskResources,
} from "../../../store/actions/Task";
import React from "react";
import ViewForm from "../../Register/viewPage";
import { ProfileType } from "../../../utility/commonTypes";

const Resources = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModalPop, setOpenModalPop] = useState<any>(false);
  const [selectedRowId, setRowId] = useState("");
  const [selectedTaskId, setTaskId] = useState("");
  const [deleteSuccessPopup, setDeleteSuccessPopup] = useState(false);
  const[openUserDetailsPopUp,setOpenUserDetailsPopUp]=useState(false)
  const params: any = useParams();
  const stateData = params;
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

 const[userId,setUserId]=useState("")
  const handleClickOpen = () => {
    setLoader(true);
    setOpenModalPop(true);
    setLoader(false);
    setRowId("");
  };

  const fetchTaskResourcesList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getTaskResources(
          {
            id: stateData?.taskId,
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
      setMessages(e.Error);
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  const [successPopup, setSuccessPopup] = useState(false);
  const location = useLocation();
  const onClickYes = (rowId: any) => {
    console.log("selected id", rowId);
    setDeletePopUp(false);
    setLoader(true);

    dispatch(
      deleteTaskResources({ id: rowId, task_id: stateData?.taskId }, function (
        res: any
      ) {
        setLoader(false);
        setDeleteSuccessPopup(true);
        setTimeout(()=>{
          setDeleteSuccessPopup(false);
    
        },5000)
        fetchTaskResourcesList(0, "ON_LOAD");
      })
    );
  };
  const onClickNo = () => {
    setDeletePopUp(false);
  };

  useEffect(() => {
    fetchTaskResourcesList(0, "ON_LOAD");
  }, []);

  const [deletePopUp, setDeletePopUp] = useState(false);

  const deleteResource = (event: any, id: any) => {
    setDeletePopUp(true);
    setRowId(id);
  };

  const editResource = (event: any, id: any) => {
    setOpenModalPop(true);
    setRowId(id);
  };
  const handleUserDetails=(event:any,id:any)=>{
    setOpenUserDetailsPopUp(true)
    setUserId(id)
  }
  const defaultColumns = useMemo(() => {
    return Columns(editResource, deleteResource,handleUserDetails);
  }, []);

  const handleClose = () => {
    setOpenModalPop(false);
    setRowId("");
  };
  const onTaskResourceSuccess = () => {
    setOpenModalPop(false);
    fetchTaskResourcesList(0, "ON_LOAD");
    setSuccessPopup(true);
    setTimeout(()=>{
      setSuccessPopup(false);

    },5000)

  };

  return (
    <>
      <Grid container direction="row" justifyContent={"space-between"}>
        <Typography sx={HeaderStyle} className="my-4 mx-4">
          Assign Resources
        </Typography>
        {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) ||  ProfileType?.ADMIN === getProfileType ?  

        <div className="my-4 mx-4">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            size="medium"
            className="py-2"
            sx={ButtonStyle}
          >
            Add
          </Button>
        </div>:null}
      </Grid>
      <Box sx={BoxStyle}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchTaskResourcesList(data, "NEXT_PAGE");
          }}
        />
      </Box>

      {openModalPop && (
        <ResourcesForm
          row={selectedRowId}
          handleClose={handleClose}
          openModalPop={openModalPop}
          setOpenModalPop={setOpenModalPop}
          onSuccess={onTaskResourceSuccess}
        />
      )}
{((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) ||  ProfileType?.ADMIN === getProfileType  ? 

      <CustomConfirmPopup
        open={deletePopUp}
        close={() => {
          setDeletePopUp(false);
        }}
        taskId={selectedTaskId}
        closeAndSubmit={() => {}}
        onClickYes={onClickYes}
        rowId={selectedRowId}
        onClickNo={onClickNo}
        title={`Delete Resources`}
        btnText={`${"Yes"}`}
        btnText1={`${"No"}`}
        description={`Are you sure you want to delete the selected resource`}
      />:null}
      <CustomModal
        title="Task Resources"
        onOpen={successPopup}
        content={"Task Resources Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
      <CustomModal
        title="Task Resources"
        onOpen={deleteSuccessPopup}
        content={"Resources Deleted Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setDeleteSuccessPopup(false);
        }}
      />
       <CustomModal
            title="Details"
            height="80%"
            width="md"
             onOpen={openUserDetailsPopUp}
            customWidth="750px"
            backgroundColor="#E1E1E1"
            content={<ViewForm userId={userId} setOpenUserDetailsPopUp={setOpenUserDetailsPopUp}/>}
            OnClickCloseIcon={() => {
              setOpenUserDetailsPopUp(false);
            }}
          />
    </>
  );
};
export default Resources;

import { takeLatest, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../actionTypes";
import {
  FetchAllProjectLists,
  FetchMyProjectLists,
  FetchProjectByCustomerLists,
  FetchCustomerProject,
  FetchMyProjectListsDashboard,
} from "./ProjectLists";

const {
  GET_MY_PROJECT_LIST,
  GET_ALL_PROJECT_LIST,
  GET_PROJECT_BY_CUSTOMER,
  GET_CUSTOMER_PROJECT,
  GET_ALL_PROJECT_LIST_DASHBOARD,
} = ACTION_TYPES.PROJECT;

export function* watchProjects() {
  yield takeLatest(GET_MY_PROJECT_LIST, FetchMyProjectLists);
  yield takeLatest(GET_ALL_PROJECT_LIST, FetchAllProjectLists);
  yield takeLatest(
    GET_ALL_PROJECT_LIST_DASHBOARD,
    FetchMyProjectListsDashboard
  );
  yield takeLatest(GET_PROJECT_BY_CUSTOMER, FetchProjectByCustomerLists);
  yield takeLatest(GET_CUSTOMER_PROJECT, FetchCustomerProject);
}

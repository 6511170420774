import { call, put, takeLatest } from "redux-saga/effects";
// import { GetModulesByCustomer,CustomerModulesUpdateAsync} from "../../../api/common/index";
import {
  CustomerModulesAsync,
  CustomerModulesUpdateAsync,
  GetModulesByCustomer,
} from "../../../api/common";
import { setAuthToken } from "../../actions/auth";
import { customerActions } from "../../actions/Customer";
import { setCustomerModuleList, setModuleList } from "../../actions/Customer";
import { APIResponse } from "../../../utility/commonTypes";

function* CustomerModulesList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(CustomerModulesAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setModuleList({ ...res.data }, function () {}));
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* ModulesByCustomer(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(GetModulesByCustomer, payload);
    console.log(res.data);
    if (res?.status === 200) {
      //yield put(setCustomerModuleList({ ...res.data }, function () {}));
      // console.log("modules by data",res.data)
      callback?.(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
function* UpdateCustomerModulesList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(
      CustomerModulesUpdateAsync, payload);
    console.log("updated data", res.data);
    if (res?.status === 201) {
      callback(res?.data);
    } else if (res?.status === 200) {
      //yield put(setCustomerModuleList({ ...res.data }, function () {}));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchCustomerModulesList() {
  yield takeLatest(
    customerActions.GET_ALL_CUSTOMER_MODULES_LIST,
    CustomerModulesList
  );
  yield takeLatest(
    customerActions.GET_CUSTOMER_MODULES_LIST,
    ModulesByCustomer
  );
  yield takeLatest(
    customerActions.UPDATE_CUTOMER_MODULES_LIST,
    UpdateCustomerModulesList
  );
}

import { ACTION_TYPES } from "../../actionTypes";

export const customerActions = {
  SIGNUP_CUSTOMER: "SIGNUP_CUSTOMER",
  GET_CUSTOMER_DETAILS: "GET_CUSTOMER_DETAILS",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",
  UPDATE_CUSTOMER_DETAILS: "UPDATE_CUSTOMER_DETAILS",
  SET_CUSTOMER_PROJECTS_LIST: "SET_CUSTOMER_PROJECTS_LIST",
  GET_CUSTOMER_PROJECTS_LIST: "GET_CUSTOMER_PROJECTS_LIST",
  SET_CUSTOMER_FILES_LIST: "SET_CUSTOMER_FILES_LIST",
  GET_CUSTOMER_FILES_LIST: "GET_CUSTOMER_FILES_LIST",
  GET_CUSTOMER_CONTACT_LIST: "GET_CUSTOMER_CONTACT_LIST",
  UPDATE_CUSTOMER_CONTACT: "UPDATE_CUSTOMER_CONTACT",
  DELETE_CUSTOMER_CONTACT: "DELETE_CUSTOMER_CONTACT",
  SET_CUSTOMER_CONTACT_LIST: "SET_CUSTOMER_CONTACT_LIST",
  GET_CUSTOMER_USER_BY_NAME: "GET_CUSTOMER_USER_BY_NAME",
  CUSTOMER_CONTACT_CREATE: "CUSTOMER_CONTACT_CREATE",
  GET_CUSTOMER_MODULES_LIST: "GET_CUSTOMER_MODULES_LIST",
  GET_ALL_CUSTOMER_MODULES_LIST: "GET_ALL_CUSTOMER_MODULES_LIST",
  SET_MODULES_LIST: "SET_MODULES_LIST",
  SET_CUSTOMER_MODULES_LIST: "SET_CUSTOMER_MODULES_LIST",
  CUSTOMER_MODULE: "CUSTOMER_MODULES",
  UPDATE_CUTOMER_MODULES_LIST: "UPDATE_CUSTOMER_MODULES_LIST",
  CREATE_CUSTOMER_AMS: "CREATE_CUSTOMER_AMS",
  UPDATE_CUSTOMER_AMS: "UPDATE_CUSTOMER_AMS",
  SET_EMAILID_SEARCH_AMS: "SET_EMAILID_SEARCH_AMS",
  GET_EMAILID_SEARCH_AMS: "GET_EMAILID_SEARCH_AMS",
  GET_AMS_MASTER: "GET_AMS_MASTER",
  GET_CUSTOMER_AMS_DETAILS: "GET_CUSTOMER_AMS_DETAILS",
};
export interface createCustomerPayload {
  id: any;
  customer_id: any;
  actionType: any;
  active: any;
  company_name: any;
  industry: any;
  about: any;
  headquarter: any;
  region: any;
  legacy_hr_system: any;
  total_workers: any;
  notes: any;
  website: any;
  managed_by_deloitte_entity: any;
  company_logo: any;
}
export interface CustomerProjectPayload {
  project_name: string;
  project_level: string;
  project_status: string;
  phase: String;
  wave: string;
  active: boolean;
  project_type: string;
  engagement_id: int;
  opportunity_id: int;
  plan_start_date: Date;
  go_live_date: date;
}
export interface CustomerFilesPayload {
  file_name: string;
  category: string;
  comments: string;
  uploaded_by: String;
  view_by: string;
}
// export interface CustomerContactPayload {

//     resource_name: string,
//     email_id: string,
//     Active: boolean,
//     Allow_logon: boolean,
//     role: string,
//     team: string,
//     start_date: date,
//     end_date: date
// }
export interface customerContact {
  id: any;
  firstname: any;
  lastname: any;
  emailid: any;
  user_id: any;
  company_customer_id: any;
  start_date: any;
  end_date: any;
  role: string;
  team: string;
  notes: string;
  active: any;
  allow_logon: any;
  allow_notifications: any;
  actionType: any;
}

export interface customerAMS {
  id: any;
  company_customer_id: any;
  active: any;
  project_id: any;
  production_url: any;
  customer_account_man: any;
  customer_POC: any;
  customer_leadership: any;
  notes: any;
}
export interface searchFilter {
  searchWord: any;
  customerUser: any;
  companyId: any;
}
export interface customerModules {
  module_ids: Array<number>;
}
//serach user in AMS module
export const getUserEmailId = (
  payload: searchFilter,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_EMAILID_SEARCH_AMS,
    payload,
    callback,
  };
};
export const setUserEmailId = (
  payload: searchFilter,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_EMAILID_SEARCH_AMS,
    payload,
    callback,
  };
};
export const signupCustomerModules = (
  payload: customerModules,
  callback: CallableFunction
) => {
  return {
    type: customerActions.UPDATE_CUTOMER_MODULES_LIST,
    payload,
    callback,
  };
};
export const getCustomerDetails = (
  payload: createCustomerPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_CUSTOMER_DETAILS,
    payload,
    callback,
  };
};
export const setCustomerDetails = (
  payload: createCustomerPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_CUSTOMER_DETAILS,
    payload,
    callback,
  };
};
export const updateCustomerDetails = (
  payload: createCustomerPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.UPDATE_CUSTOMER_DETAILS,
    payload,
    callback,
  };
};
export const updateCustomerModules = (
  payload: customerModules,
  callback: CallableFunction
) => {
  return {
    type: customerActions.UPDATE_CUTOMER_MODULES_LIST,
    payload,
    callback,
  };
};
export const customerCreateContact = (
  payload: customerContact,
  callback: CallableFunction
) => {
  return {
    type: customerActions.CUSTOMER_CONTACT_CREATE,
    payload,
    callback,
  };
};
export const setCustomerContactList = (
  payload: customerContact,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_CUSTOMER_CONTACT_LIST,
    payload,
    callback,
  };
};

export const setCustomerModuleList = (
  payload: customerModules,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_CUSTOMER_MODULES_LIST,
    payload,
    callback,
  };
};
export const setModuleList = (
  payload: customerModules,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_MODULES_LIST,
    payload,
    callback,
  };
};
export const getCustomerModuleList = (
  payload: customerModules,
  callback: any
) => {
  return {
    type: customerActions.GET_CUSTOMER_MODULES_LIST,
    payload,
    callback,
  };
};
export const getAllCustomerModuleList = (
  payload: customerModules,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_ALL_CUSTOMER_MODULES_LIST,
    payload,
    callback,
  };
};
export const createCustomerAMS = (
  payload: customerAMS,
  callback: CallableFunction
) => {
  return {
    type: customerActions.CREATE_CUSTOMER_AMS,
    payload,
    callback,
  };
};
export const getMasterAMS = (payload: any, callback: CallableFunction) => {
  return {
    type: customerActions.GET_AMS_MASTER,
    payload,
    callback,
  };
};
export const getCustomerAMSDetails = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_CUSTOMER_AMS_DETAILS,
    payload,
    callback,
  };
};

export const signupCustomer = (payload: any, callback: CallableFunction) => {
  return {
    type: customerActions.SIGNUP_CUSTOMER,
    payload,
    callback,
  };
};
export const setCustomerFilesList = (
  payload: CustomerFilesPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_CUSTOMER_FILES_LIST,
    payload,
    callback,
  };
};
export const getCustomerFilesList = (
  payload: CustomerFilesPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_CUSTOMER_FILES_LIST,
    payload,
    callback,
  };
};
export const setProjectList = (
  payload: CustomerProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.SET_CUSTOMER_PROJECTS_LIST,
    payload,
    callback,
  };
};
export const getProjectList = (
  payload: CustomerProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_CUSTOMER_PROJECTS_LIST,
    payload,
    callback,
  };
};

export const getCustomerUserByName = (
  payload: searchFilter,
  callback: CallableFunction
) => {
  return {
    type: customerActions.GET_CUSTOMER_USER_BY_NAME,
    payload,
    callback,
  };
};
export const deleteCustomerContact = (
  payload: customerContact,
  callback: CallableFunction
) => {
  return {
    type: customerActions.DELETE_CUSTOMER_CONTACT,
    payload,
    callback,
  };
};

import React, { useDispatch } from "react-redux";
import "./integrationRepository.css";
import {
  FUNCTIONAL_AREA,
  INTEGRATION_TYPE,
  TEMPLATE_DROPDOWNS,
} from "../../constants/common";
import { useForm } from "../../helpers/validations/useForm";
import RepositoryGrid from "../DataGrid";
import MyUtilization from "../../components/dashboard/myUtilization";
import { getByTaskName } from "../../store/actions/Task";
import { useEffect, useRef, useState } from "react";
import {
  getIntegrationRepositoryCatalogue,
  getSourceTargetSystem,
} from "../../store/actions/Repository";
import setLoader from "../../components/CustomLoader/loaderState";
import { FormControlUnstyledContext } from "@mui/base";
import { AnyCnameRecord } from "dns";
import { saveCustomerType } from "../../store/actions/auth";

interface integrationRepository {
  vendor: any;
  source_system: any;
  target_system: any;
  functional_area: any;
  requirement_template: any;
  task_name: any;
  searchTask: any;
  company_name: any;
  project_name: any;
  flow_of_integration: any;
  integration_type: any;
  integrationTypeCount: any;
  sourceTargetSystem: any;
  taskId: any;
}

const IntegrationRepository = () => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [sourceTargetSystem, setSourceTargetSystem] = useState([]);
  const [integrationCount, setIntegrationCount] = useState([]);
  const [sourceLabelTarget, setSourceLabelTarget] = useState([]);
  const [sourceValueTarget, setSourceValueTarget] = useState([]);
  const [vendor, setVendor] = useState("");
  const [taskId, setTaskId] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [requirementTemplate, setRequirementTemplate] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [type, setType] = useState("");
  const find = "_";
  const re = new RegExp(find, "g");

  let suggestRef = useRef();
  const { handleChange, data: repository, setData } = useForm<
    integrationRepository
  >({});
  const handleSearch = (val: any) => {
    dispatch(
      getByTaskName({ task_name: val }, function (res: any) {
        if (res === "Match not found") {
          res = "";
        }
        setSuggestions({
          ...res,
        });
      })
    );
  };
  const requirementDropownList = TEMPLATE_DROPDOWNS.filter((val: any) => {
    return val.key === functionalArea;
  });

  const fetchCatalogueList = (
    task_name: any,
    vendor_id: any,
    requirement_template: any,
    functional_area: any
  ) => {
    dispatch(
      getIntegrationRepositoryCatalogue(
        {
          taskName: task_name,
          requirementTemplate: requirement_template,
          sourceOrTargetSystem: vendor_id,
          functionalArea: functional_area,
        },
        function (res: any) {
          let sourceTarget = res?.sourceTargetChart;
          let sortable = [];
          for (var st in sourceTarget) {
            sortable.push([st, sourceTarget[st]]);
          }
          let resTarget = sortable.sort(function (a, b) {
            return b[1] - a[1];
          });
          let labelTarget = [];

          let valTarget = [];

          resTarget.map((targetData) => {
            if (targetData[0] === "null"||targetData[0]=="1") {
              labelTarget.push("Other");
            } else {
              labelTarget.push(targetData[0]);
            }
            valTarget.push(targetData[1]);
          });
          setSourceLabelTarget(labelTarget);

          setSourceValueTarget(valTarget);
          setData({
            ...res,
            totalItems: res?.totalItems,
            integrationTypeCount: res?.integrationTypeCount,
          });
        }
      )
    );
  };

  useEffect(() => {
    fetchCatalogueList(taskId, vendor, requirementTemplate, functionalArea);
  }, []);

  useEffect(() => {
    dispatch(
      getSourceTargetSystem({}, function (data: any) {
        const rows = data?.map((row: any) => ({
          value: row?.value,
          label: row?.label,
        }));
        setSourceTargetSystem(rows);
      })
    );
  }, []);
  const handleVendorOnChange = (vendorId: any) => {
    setVendor(vendorId);

    fetchCatalogueList(taskId, vendorId, requirementTemplate, functionalArea);
  };

  const handleFunctionalAreaOnChange = (functional: any) => {
    setFunctionalArea(functional);

    fetchCatalogueList(taskId, vendor, requirementTemplate, functional);
  };
  const handleRequirementTemplateOnChange = (template: any) => {
    setRequirementTemplate(template);

    fetchCatalogueList(taskId, vendor, template, functionalArea);
  };
  var s = 0;
  var integration = repository?.integrationTypeCount;
  var sum = Object.values(integration || {});
  const summation = () => {
    for (var i = 0; i < sum.length; i++) {
      s += sum[i];
    }
    return s;
  };
  var typeKeys = Object.keys(integration || {});
  var c = [];
  for (var i = 0; i < typeKeys.length; i++) {
    c[i] = typeKeys[i];
  }
  
  const handleTaskNameOnChange = (task: any) => {
    setTaskId(task);
    fetchCatalogueList(task, vendor, requirementTemplate, functionalArea);
  };
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  return (
    <section className="main-contant inner_page">
      <div className="view_customer">Integration Repository</div>

      <div className="row mt-4">
        <div className="col-md-3">
          <div className="form-group ">
            <label htmlFor="inputGroupSelect02" className="form-label">
              Vendor
            </label>
            <select
              className="form-select"
              id="inputGroupSelect02"
              value={vendor}
              onChange={handleChange("vendor", (val) => {
                handleVendorOnChange(val);
                return val;
              })}
            >
              <option value="" selected>
                All
              </option>

              {sourceTargetSystem?.map((system) => (
                <option value={system.value}>{system.label}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group ">
            <label htmlFor="inputGroupSelect02" className="form-label">
              Functional Area{" "}
            </label>
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="functional_area"
              value={functionalArea}
              onChange={handleChange("functional_area", (val) => {
                handleFunctionalAreaOnChange(val);
                return val;
              })}
            >
              <option value={""} selected>
                All
              </option>
              {FUNCTIONAL_AREA.map((funVal) => {
                let selvaltemp = functionalArea === funVal.label ? true : false;
                return (
                  <>
                    <option value={funVal.key} selected={selvaltemp}>
                      {funVal.label}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="inputGroupSelect02" className="form-label">
              Requirement Template{" "}
            </label>
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="requirement_template"
              value={requirementTemplate}
              onChange={handleChange("requirement_template", (val) => {
                handleRequirementTemplateOnChange(val);
                return val;
              })}
            >
              <option value={""} selected>
                All
              </option>
              {requirementDropownList.map((template) => {
                return template.subFields.map((opt) => {
                  let selvaltemp =
                    requirementTemplate === opt.key ? true : false;
                  return (
                    <option value={opt.key} selected={selvaltemp}>
                      {opt.value}
                    </option>
                  );
                });
              })}{" "}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group ">
            <label htmlFor="inputGroupSelect02" className="form-label">
              Search
            </label>
            <div className="form-group has-search w-100">
              <span className="fa fa-search form-control-feedback mt-0"></span>
              <input
                type="text"
                name="searchTask"
                className="form-control"
                value={repository?.taskId}
                onChange={handleChange("taskId", (val) => {
                  setShowSuggestion(true);
                  handleSearch(val);
                  handleTaskNameOnChange(val);
                  return val;
                })}
                placeholder="Search"
              />
              {showSuggestion && (
                <ul
                  className="list-group"
                  ref={suggestRef}
                  style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                  right: 0,
                  left: 0,
                  width: "100%",
                  height: 130,
                  top:42,
                  
                }}                   >
                  {""}{" "}
                  {Object.entries(suggestions)?.map(([suggest, i]: any) => {
                    return (
                      <li
                        className="list-group-item"
                        onClick={() => {
                          setShowSuggestion(false);

                          fetchCatalogueList(
                            i?.task_name,
                            vendor,
                            requirementTemplate,
                            functionalArea
                          );

                          setData({
                            taskId: i?.task_name,
                          });
                          setTaskId(i?.task_name);
                        }}
                      >
                        {i?.task_name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="view_customer">Integration Catalogue Overview</div>

      <div className="row">
        <div className="col-md-7">
          <div className="main-wrap-inner customer-module mt-4">
            <div className="view_customer">Source / Target System</div>
            <div className="text-center piechart">
              <MyUtilization
                labelTarget={sourceLabelTarget}
                valueTarget={sourceValueTarget}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="main-wrap-inner customer-module mt-4">
            <div className="view_customer">
              Total Count <span className="pull-right">{summation()}</span>
            </div>
            <div className="row mt-3 ">
              <div className="col-md-8">
                <ul className="totalcount">
                  {c.map((type:any) => {
                    if (type == "null") {
                      return null;
                    }

                      return <li>{type?.replace(re," ")}</li>;
                     
                    })}
                  </ul>
                </div>

              <div className="col-md-4">
                <ul className="pull-right totalcount">
                  {c.map((type) => {
                    if (type === "null") {
                      return null;
                    } else if (type === "Other") {
                      return (
                        <li>
                          {" "}
                          {repository?.integrationTypeCount?.Other +
                            repository?.integrationTypeCount?.null ||
                            repository?.integrationTypeCount?.Other ||
                            repository?.integrationTypeCount?.null}
                        </li>
                      );
                    } else {
                      return (
                        <li>{repository?.integrationTypeCount[`${type}`]}</li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive table_wrapper_main">
            <RepositoryGrid
              name={taskId}
              requirement={requirementTemplate}
              area={functionalArea}
              SourceTargetSystem={vendor}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationRepository;

import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Grid, Select, MenuItem } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/CustomPagination";
import { getAllCustomerList } from "../../../store/actions/Customers";
import {
  getTasksByCustomerProjectHealthAction,
  getTasksByCustomerProjectHealthRatioAction,
} from "../../../store/actions/Tasks";
import Healthevpv from "../../../components/DashboardHealth/Healthevpv";
import { getCustomerProject } from "../../../store/actions/Projects";
import { TASK_STATUS } from "../../../constants/common";
import columns from "./columnsDef";
const ProjectHealth = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);
  const [pageLoaded, setPageLoaded] = useState<string>("first");
  const [loading, setLoading] = useState(false);
  const [customerPorjectData, setCustomerProjectData] = useState<object>([]);
  const [filterKeys, setFilterKeys] = useState<any>({
    customerName: "",
    projectName: "",
    taskStatus: "",
  });
  const [sourceLabelTarget, setSourceLabelTarget] = useState(["NA"]);
  const [sourceValueTarget, setSourceValueTarget] = useState([2]);

  const fetchAllCustomerList = () => {
    try {
      dispatch(
        getAllCustomerList({}, (data: any) => {
          if (data?.length > 0) {
            setCustomerList(data);
          } else {
            setCustomerList([]);
          }
        })
      );
    } catch (e) {
      console.log(e, "error");
    }
  };

  const fetTaskEVPV = () => {
    try {
      setLoading(true);
      dispatch(
        getTasksByCustomerProjectHealthRatioAction(
          {
            taskStatus: filterKeys.taskStatus ? filterKeys.taskStatus : "",
            companyName: filterKeys.customerName ? filterKeys.customerName : "",
            projectName: filterKeys.projectName ? filterKeys.projectName : "",
            functionalArea: filterKeys.functionalArea
              ? filterKeys.functionalArea
              : "",
            integrationType: filterKeys.integrationType
              ? filterKeys.integrationType
              : "",
            requirementTemplate: filterKeys.requirementTemplate
              ? filterKeys.requirementTemplate
              : "",
          },
          (data: any) => {
            let labelTarget = [];
            let valTarget = [];
            for (let [key, value] of Object.entries(data)) {
              labelTarget.push(key);
              valTarget.push(value);              
            }
            setSourceLabelTarget(labelTarget);
            setSourceValueTarget(valTarget);
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    }
  };
  const fetchTaskByCustomerProject = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getTasksByCustomerProjectHealthAction(
          {
            taskStatus: filterKeys.taskStatus ? filterKeys.taskStatus : "",
            companyName: filterKeys.customerName ? filterKeys.customerName : "",
            projectName: filterKeys.projectName ? filterKeys.projectName : "",
            functionalArea: filterKeys.functionalArea
              ? filterKeys.functionalArea
              : "",
            integrationType: filterKeys.integrationType
              ? filterKeys.integrationType
              : "",
            requirementTemplate: filterKeys.requirementTemplate
              ? filterKeys.requirementTemplate
              : "",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllCustomerList();
  }, []);

  useEffect(() => {
    if (filterKeys["customerName"]) {
      fetchTaskByCustomerProject(0, "ON_LOAD");
      fetTaskEVPV();
    }
  }, [filterKeys]);

  const getCustomerProjectData = (customer_id: any) => {
    try {
      if (customer_id) {
        setLoading(true);
        dispatch(
          getCustomerProject(
            { company_customer_id: customer_id },
            (res: any) => {
              if (res && res?.totalItems) {
                setCustomerProjectData(res?.rowsReturned);
              }
              setLoading(false);
            }
          )
        );
      } else {
        setCustomerProjectData([]);
      }
    } catch (e) {}
  };

  return (
    <>
      <section className="main-contant inner_page">
        <div className="view_customer">Project health dashboard</div>

        <div className="row">
          <div className="col-md-7 phdpage">
            <div className="main-wrap-inner customer-module mt-4">
              <div className="view_customer">EV/PV</div>
              <div className="text-center piechart pb-5">
                {/* <img src="assets/images/piechart.png" /> */}
                <Healthevpv
                  labelTarget={sourceLabelTarget}
                  valueTarget={sourceValueTarget}
                />
                <div className="pt-5">
                  <div className="overallstatus">
                    Overall status : <span className="statussn"></span>Red
                  </div>
                </div>
                Earned value : <span className="earndv"></span>40 Planned value
                : <span className="palenv"></span>60 EV/PV :{" "}
                <span className="evpvc"></span>66.66%
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="main-wrap-inner customer-module mt-4 ">
              <div className="row mt-3 ">
                <div className="form-group">
                  <label htmlFor="first-name" className="form-label">
                    Customer
                  </label>

                  <Select
                    name="customer"
                    fullWidth
                    variant="outlined"
                    value={filterKeys?.customerName}
                    displayEmpty
                    onChange={(e: any) => {
                      setFilterKeys({
                        ...filterKeys,
                        customerName: e?.target?.value,
                      });
                      setPageLoaded("second");
                      getCustomerProjectData(e?.target?.value);
                    }}
                    sx={{ height: "40px" }}
                  >
                    <MenuItem value="" selected>
                      Select
                    </MenuItem>
                    {customerList.map((customerItems: any) => {
                      return (
                        <MenuItem value={customerItems.id}>
                          {customerItems.company_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <label htmlFor="first-name" className="form-label">
                    Project(s)
                  </label>
                  <Select
                    name="project"
                    fullWidth
                    variant="outlined"
                    value={filterKeys?.projectName}
                    displayEmpty
                    onChange={(e: any) => {
                      setPageLoaded("second");
                      setFilterKeys({
                        ...filterKeys,
                        projectName: e?.target?.value,
                      });
                    }}
                    sx={{ height: "40px" }}
                  >
                    <MenuItem value="" selected>
                      Select
                    </MenuItem>
                    {customerPorjectData.map((projectItems: any) => {
                      return (
                        <MenuItem value={projectItems.id}>
                          {projectItems.project_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <label htmlFor="first-name" className="form-label">
                    Task status
                  </label>
                  <Select
                    name="taskStatus"
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    value={filterKeys?.taskStatus}
                    onChange={(e: any) => {
                      setPageLoaded("second");
                      setFilterKeys({
                        ...filterKeys,
                        taskStatus: e?.target?.value,
                      });
                    }}
                    sx={{ height: "40px" }}
                  >
                    <MenuItem value="" selected>
                      Select
                    </MenuItem>
                    {TASK_STATUS.map((taskStatusItem: any) => {
                      return (
                        <MenuItem value={taskStatusItem.label}>
                          {taskStatusItem.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-wrap-inner customer-module">
          <div className="table-block">
            <div className="frame_outer pt-0">
              <div className="pull-left  mt-2 vendor_contact">Files</div>
              <div className="lead mt-2 lead_download pull-left">
                Download{" "}
                <a className="mx-2" href="#0">
                  <img src="assets/images/PDF_file_icon.svg.png" />
                </a>
                <a className="#0" href="#0">
                  <img src="assets/images/download.png" />
                </a>
                <div className="add_button_table_top pull-right">
                  <a href="#">Add</a>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive table_wrapper_main">
                    <Grid className="table table-sm">
                      {/* <table className="table table-sm">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th style={{ width: 250 }}>
                            Task name{" "}
                            <a href="#">
                              <img
                                className="pull-right"
                                src="assets/images/doggleupdown.png"
                              />{" "}
                            </a>
                          </th>
                          <th style={{ width: 120 }}>
                            Status{" "}
                            <a href="#">
                              <img
                                className="pull-right"
                                src="assets/images/doggleupdown.png"
                              />{" "}
                            </a>
                          </th>
                          <th>Health</th>
                          <th>Est. Hours </th>
                          <th style={{ width: 280 }}>Status summary</th>
                          <th style={{ width: 150 }}>Owned by</th>
                          <th>EV</th>
                          <th>PV</th>
                          <th style={{ width: 60 }}>Pending</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Accelerator</td>
                          <td>Approved</td>
                          <td>Green</td>
                          <td>120</td>
                          <td>Latest health status</td>
                          <td>
                            <a href="#">John doe</a>
                          </td>
                          <td>5</td>
                          <td>2</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Accelerator</td>
                          <td>Approved</td>
                          <td>Green</td>
                          <td>120</td>
                          <td>Latest health status</td>
                          <td>
                            <a href="#">John doe</a>
                          </td>
                          <td>5</td>
                          <td>2</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Accelerator</td>
                          <td>Approved</td>
                          <td>Green</td>
                          <td>120</td>
                          <td>Latest health status</td>
                          <td>
                            <a href="#">John doe</a>
                          </td>
                          <td>5</td>
                          <td>2</td>
                          <td>1</td>
                        </tr>
                      </tbody>
                    </table> */}
                      <DataGrid
                        sx={{
                          border: 0, // also tried setting to none
                          minWidth: 300,
                        }}
                        autoHeight
                        rows={rowData}
                        rowCount={totalPageCount}
                        page={currentPageNo}
                        columns={defaultColumns}
                        pageSize={10}
                        disableSelectionOnClick
                        disableColumnMenu
                        pagination
                        paginationMode="server"
                        loading={loading}
                        components={{
                          Pagination: CustomPagination,
                        }}
                        onPageChange={(data) => {
                          fetchTaskByCustomerProject(data, "NEXT_PAGE");
                        }}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ProjectHealth;

import React, { useEffect, useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FieldRequirementForm from "./FieldRequirementForm";
import { useForm } from "../../../helpers/validations/useForm";
import CustomModal from "../../../components/CustomModalPop";
import { setLoading } from "../../../store/actions/loaderState";
import setLoader from "../../../components/CustomLoader/loaderState";
import {
  updateNoOfField,
  createNoFieldsForm,
  getNoOfField,
  getNoFieldsForm,
  updateNoFieldsForm,
  deleteNoFieldsForm,
} from "../../../store/actions/Requirement";
import { initialPayload } from "./initialType";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { activeTabClass, blankTabClass, TabBoxClass } from "./style";
import FieldRequirementList from "./FieldRequirementList";
import FieldRequirementPopUp from "./FieldRequirementPopUp";
import {sortBy} from "lodash";
import { ProfileType } from "../../../utility/commonTypes";

const FieldRequirement = (props:any) => {
  const dispatch = useDispatch();
  let [reqValue, setreqValue] = useState<any>(0);
  const [RequirementFilesIds, setRequirementFilesIds] = useState<any>([]);
  const [tabValue, setTabValue] = useState(0);
  const [mappingRowData, setMappingRowData] = useState({});
  const [addClick, setAddClick] = useState(false);
  const [errors, seterrors] = useState({});
  const [updatedData, setUpdatedData] = useState(false);
  const [popUp, setPopUP] = useState({
    open: false,
    title: "",
    content: "",
    modalType: "",
  });
  const params: any = useParams();
  const stateData: any = params;

  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const [mappingPopUp, setMappingPopUp] = useState(false);
  const location = useLocation();

  const handleTabChange = (event: any, newValue: any) => {
    setAddClick(false);
    setTabValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    setOpen(false);
  };
  
  let listKeys = [];
  for (let i = 1; i <= RequirementFilesIds?.length; i++) listKeys.push(i);

  const fetchFieldRequirementData = () => {
    try {
      setLoading(true);
      dispatch(
        getNoOfField(
          {
            id: location?.state?.requirementId,
          },
          (data: any) => {
            setreqValue(data?.RequirementFiles?.length);
            setRequirementFilesIds(
              data?.RequirementFiles?.sort((a: any, b: any) => a - b)
            );
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoading(false);
    }
  };

  let requirementIdVal = RequirementFilesIds?.filter(
    (fieldIds: any, index: any) => {
      return index === tabValue;
    }
  );

  const fetchFieldRequirementForm = (reqId?: any) => {
    try {
      setLoading(true);
      if (reqId) {
        dispatch(
          getNoFieldsForm(
            {
              id: location?.state?.requirementId,
              fieldId: reqId,
            },
            (data: any) => {
              setData(data?.RequirementFiles[0]);
            }
          )
        );
      }
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleReqAddClick = () => {
    console.log("Inside value is", reqValue);
    reqValue = reqValue || 0;
    try {
      setLoading(true);
      dispatch(
        updateNoOfField(
          {
            payload: {
              id: location?.state?.requirementId,
              num_files: reqValue + 1,
              test_cases: 11,
            },
          },
          (data: any) => {
            setreqValue(reqValue + 1);
            dispatch(
              createNoFieldsForm(
                {
                  id: location?.state?.requirementId,
                  payload: initialPayload,
                },
                (data: any) => {
                  setLoading(false);
                }
              )
            );
            fetchFieldRequirementForm(requirementIdVal?.[0]?.id);
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    }
  };
  const {
    handleSubmit,
    handleFileUpload,
    handledNestedChange,
    data: fieldRequirementVal,
    setData,
  } = useForm<any>({
    onSubmit: () => {
      setLoader(true);
      let fieldValues: any = {};
      let nextStep: any = 1;
      Object.entries(fieldRequirementVal?.field_data)?.map(
        ([id, value]: any) => {
          let regex = /^[0-9]+$/;
          // let regexSpecial = /^[A-Za-z0-9_\-/@#$%&*!'";,:\[\]{}|.+=()<>^~?\s]*$/;
          let regexSpecial=/(^w|_d)*[A-Za-z0-9].*$/
          if (
            fieldRequirementVal?.field_data?.Number_of_fields?.field_desc &&
            !regex.test(
              fieldRequirementVal?.field_data?.Number_of_fields?.field_desc
            )
          ) {
            seterrors({
              Number_of_fields: "number of fields should be number",
            });
            setOpen(true)
            nextStep = 0;
          }else if (
            fieldRequirementVal?.field_data?.File_Description?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.File_Description?.field_desc
            )
          ) {
            seterrors({
              File_Description: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.File_Description?.field_desc.length> 100){
            seterrors({
              File_Description: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }else if (
          fieldRequirementVal?.field_data?.Filtering_Requirements?.field_desc && 
          !regexSpecial.test(
            fieldRequirementVal?.field_data?.Filtering_Requirements?.field_desc
          )
        ) {
            seterrors({
              Filtering_Requirements: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.Filtering_Requirements?.field_desc.length> 100){
            seterrors({
              Filtering_Requirements: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          
          
          else if (
            fieldRequirementVal?.field_data?.The_expected_volume_of_records_to_be_processed_by_this_integration_is?.field_desc && 
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.The_expected_volume_of_records_to_be_processed_by_this_integration_is?.field_desc
            )
          ) {
            seterrors({
              The_expected_volume_of_records_to_be_processed_by_this_integration_is: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.The_expected_volume_of_records_to_be_processed_by_this_integration_is?.field_desc.length> 100){
            seterrors({
              The_expected_volume_of_records_to_be_processed_by_this_integration_is: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          else if (
            fieldRequirementVal?.field_data?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is?.field_desc
            )
          ) {
            seterrors({
              The_expected_maximum_number_of_records_that_the_integration_will_handle_is: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is?.field_desc.length> 100){
            seterrors({
              The_expected_maximum_number_of_records_that_the_integration_will_handle_is: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          else if (
            fieldRequirementVal?.field_data?.File_Naming_Standard_Prod?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.File_Naming_Standard_Prod?.field_desc
            )
          ) {
            seterrors({
              File_Naming_Standard_Prod: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.File_Naming_Standard_Prod?.field_desc.length> 100){
            seterrors({
              File_Naming_Standard_Prod: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          else if (
            fieldRequirementVal?.field_data?.File_Naming_Standard_Test?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.File_Naming_Standard_Test?.field_desc
            )
          ) {
            seterrors({
              File_Naming_Standard_Test: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.File_Naming_Standard_Test?.field_desc.length> 100){
            seterrors({
              File_Naming_Standard_Test: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          else if (
            fieldRequirementVal?.field_data?.Will_input_or_output_contains_special_characters?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.Will_input_or_output_contains_special_characters?.field_desc
            )
          ) {
              seterrors({
                Will_input_or_output_contains_special_characters: `Only special characters are A-Za-z0-9_@./#&+-`,
              });
              setOpen(true)
              nextStep = 0;
            }else if(fieldRequirementVal?.field_data?.Will_input_or_output_contains_special_characters?.field_desc.length> 100){
              seterrors({
                Will_input_or_output_contains_special_characters: `Value should be less then 100 characters`,
              });
              setOpen(true)
              nextStep = 0;
            }
            
          else if (
            fieldRequirementVal?.field_data?.Encoding?.field_desc && 
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.Encoding?.field_desc
            )
          ) {
              seterrors({
                Encoding: `Only special characters are A-Za-z0-9_@./#&+-`,
              });
              setOpen(true)
              nextStep = 0;
            }else if(fieldRequirementVal?.field_data?.Encoding?.field_desc.length> 100){
              seterrors({
                Encoding: `Value should be less then 100 characters`,
              });
              setOpen(true)
              nextStep = 0;
            }
          else if (
            fieldRequirementVal?.field_data?.what_type_of_data_will_be_sent?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.what_type_of_data_will_be_sent?.field_desc
            )
          ) {
            seterrors({
              what_type_of_data_will_be_sent: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.what_type_of_data_will_be_sent?.field_desc.length> 100){
            seterrors({
              what_type_of_data_will_be_sent: `Value should be less then 100 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
          else if (
            fieldRequirementVal?.field_data?.Sample_File?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.Sample_File?.field_desc
            )
          ) {
            seterrors({
              Sample_File: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.Sample_File?.field_desc.length> 5000){
            seterrors({
              Sample_File: `Value should be less then 5000 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }
            
          else if (
            fieldRequirementVal?.field_data?.Notes_and_Additional_requirements?.field_desc &&
            !regexSpecial.test(
              fieldRequirementVal?.field_data?.Notes_and_Additional_requirements?.field_desc
            )
          ) {
            seterrors({
              Notes_and_Additional_requirements: `Only special characters are A-Za-z0-9_@./#&+-`,
            });
            setOpen(true)
            nextStep = 0;
          }else if(fieldRequirementVal?.field_data?.Notes_and_Additional_requirements?.field_desc.length> 5000){
            seterrors({
              Notes_and_Additional_requirements: `Value should be less then 5000 characters`,
            });
            setOpen(true)
            nextStep = 0;
          }  
          else if (Object.keys(value)?.includes("field_desc")) {
            fieldValues[id] = { ...value };
            seterrors({});
            nextStep = 1;
          } else {
            fieldValues[id] = {
              field_desc: value,
              test_cases: 0,
            };
            seterrors({});
            nextStep = 1;
          }        
        }
      );
      if (nextStep === 1) {
        dispatch(
          updateNoFieldsForm(
            {
              payload: {
                id: location?.state?.requirementId,
                fieldId: requirementIdVal?.[0]?.id,
                reqFiles: {
                  fieldData: fieldValues,
                },
                reqFields: fieldRequirementVal?.RequirementFields,
              },
            },
            onUpdateRequirementFields
          )
        );
      } else {
        
        setLoader(false);
      }
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    setAddClick,
  });

  const onUpdateRequirementFields = (data: any) => {
    if (data === false) {
      setPopUP({
        open: true,
        title: `Field Requirement`,
        content: "Error!Please try again",
        modalType: "error",
      });
    } else {
      setPopUP({
        open: true,
        title: `Field Requirement`,
        content: "Field Requirement data saved!",
        modalType: "success",
      });
      setTimeout(() => {
        setPopUP({
          open: false,
          title: ``,
          content: "",
          modalType: "success",
        });        window.scrollTo(0,0)      
      }, 2000);
    }
    fetchFieldRequirementForm(requirementIdVal?.[0]?.id);
    setLoader(false);
  };
  const deleteFieldRequirementForm = () => {
    try {
      setLoading(true);
      if (requirementIdVal?.[0]?.id) {
        dispatch(
          deleteNoFieldsForm(
            {
              id: location?.state?.requirementId,
              fieldId: requirementIdVal?.[0]?.id,
            },
            (data: any) => {
              setPopUP({
                ...popUp,
                open: false,
              });
              fetchFieldRequirementData();
              fetchFieldRequirementForm(requirementIdVal?.[0]?.id);
            }
          )
        );
      }
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoading(false);
    }
  };
  const ondeleteRequirementFields = () => {
    setPopUP({
      open: true,
      title: `Field Requirement`,
      content: "Are Sure you want to delete the file!",
      modalType: "warning",
    });
  };
  const onFirstAddRow = () => {
    setAddClick('header');
    const rowDetails = {
      type: "header",
      test_cases: 0,
      field_order: 1,
      field_attributes: {
        Target_System_Field_Name: "",
        Description: "",
        Comments: "",
        Transformation_Rules: "",
        Business_Description: "",
        Required: false,
        Max_Length: "",
        Format: "",
        ValuesorField_Logic: "",
        Workday_Field_Name: "",
        Mapping: [],
      },
    };
    setData((prev: any) => ({
      ...prev,
      RequirementFields: [
        ...prev?.RequirementFields?.sort((fieldA: any, fieldB: any) =>
          fieldA?.field_order > fieldB?.field_order ? 1 : -1
        )?.map((arrayData: any) => {
          return {
            ...arrayData,
          };
        }),
        rowDetails,
      ],
    }));
  };

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const sortFieldOrder = async (fieldValue: number, type: any, arr: any) => {
    let index: number = arr.indexOf(fieldValue)+1 >= arr.length ? arr.indexOf(fieldValue) : arr.indexOf(fieldValue)+1
    for (let i = index; i < arr.length; i++) {
      arr[i]['field_order'] = type === arr[i]['type'] &&
                arr[index]['field_order'] <= arr[i]['field_order']
                  ? arr[i]['field_order'] + 1
                  : arr[i]['field_order']
    }
  }

  const onAddRow = async (fieldValue?: any) => {
    setAddClick(fieldValue?.type);
    const rowDetails = {
      type: fieldValue?.type,
      test_cases: 0,
      // id: makeid(35),
      field_order: fieldValue?.field_order,
      field_attributes: {
        Target_System_Field_Name: "",
        Description: "",
        Comments: "",
        Transformation_Rules: "",
        Business_Description: "",
        Required: false,
        Max_Length: "",
        Format: "",
        ValuesorField_Logic: "",
        Workday_Field_Name: "",
        Mapping: [],
      },
    };
    // filter header, footer and detail records
    let headermap: any = []
    let footermap: any = []
    let detailsRecordmap: any = []
    const sortMap = await fieldRequirementVal?.RequirementFields?.map((item:any) => {
      console.log("type", item.type)
      if(item?.type === 'header'){
        headermap.push(item)
      }else if(item?.type === 'DetailsRecord'){
        detailsRecordmap.push(item)
      }else if(item?.type === 'footer'){
        footermap.push(item)
      }
      return item
    })
    // place the new object as per field type and order
    if(fieldValue?.type === 'header'){
      headermap.indexOf(fieldValue)+1 >= headermap.length ? headermap.push(rowDetails): headermap?.splice( headermap.indexOf(fieldValue)+1, 0, rowDetails)
      await sortFieldOrder(fieldValue, fieldValue?.type, headermap)
    }else if(fieldValue?.type === 'DetailsRecord'){
      detailsRecordmap.indexOf(fieldValue)+1 >= detailsRecordmap.length ? detailsRecordmap.push(rowDetails): detailsRecordmap.splice(detailsRecordmap.indexOf(fieldValue)+1, 0, rowDetails)
      await sortFieldOrder(fieldValue, fieldValue?.type, detailsRecordmap)
    }else if(fieldValue?.type === 'footer'){
      footermap.indexOf(fieldValue)+1 >= footermap.length ? footermap.push(rowDetails): footermap?.splice(footermap.indexOf(fieldValue)+1, 0, rowDetails)
      await sortFieldOrder(fieldValue, fieldValue?.type, footermap)
    }
    setData((prev: any) => ({
      ...prev,
      RequirementFields: sortBy(headermap.concat(detailsRecordmap,footermap), ['type', 'field_order'])
      })
    )
  };

  const onDeleteRow = async (fieldValue: any) => {
    const deleteSelectedData = await fieldRequirementVal?.RequirementFields?.splice(fieldRequirementVal?.RequirementFields.indexOf(fieldValue), 1)
    setData((prev: any) => ({
      ...prev,
      RequirementFields: [
        ...prev?.RequirementFields?.sort((fieldA: any, fieldB: any) =>
          fieldA?.field_order > fieldB?.field_order ? 1 : 0
        )
          ?.map((arrayData: any) => {
            return {
              ...arrayData,
              field_order:
                fieldValue?.type === arrayData?.type &&
                fieldValue.field_order < arrayData.field_order
                  ? arrayData.field_order - 1
                  : arrayData.field_order,
            };
          }),
      ],
    }));

    // setData((prev: any) => ({
    //   ...prev,
    //   RequirementFields: [
    //     ...prev?.RequirementFields?.sort((fieldA: any, fieldB: any) =>
    //       fieldA?.field_order > fieldB?.field_order ? 1 : 0
    //     )
    //       ?.filter((val: any) => val.id !== fieldValue.id)
    //       ?.map((arrayData: any) => {
    //         return {
    //           ...arrayData,
    //           field_order:
    //             fieldValue?.type === arrayData?.type &&
    //             fieldValue.field_order < arrayData.field_order
    //               ? arrayData.field_order - 1
    //               : arrayData.field_order,
    //         };
    //       }),
    //   ],
    // }));

    //console.log("row value", fieldRequirementVal?.RequirementFields);

    // dispatch(updateNoFieldsForm({
    //   payload: {
    //     id: location?.state?.requirementId,
    //     fieldId: requirementIdVal?.[0]?.id,
    //     "reqFiles": {
    //       "fieldData": fieldRequirementVal?.field_data
    //     },
    //     "reqFields": fieldRequirementVal?.RequirementFields
    //   }
    // }, (data: any) => {
    // }));
  };

  const onMappingPopUp = (rowsID: any) => {
    setMappingRowData(rowsID);
    setMappingPopUp(true);
  };

  const onAddMappingRow = (fieldValue: any, mappingLength: any) => {
    setLoader(true);
    const mappingRowDetails = {
      field_order: mappingLength + 1,
      Target_Value: "",
      Source_Value: "",
      Notes: "",
    };
    setData((prev: any) => ({
      ...prev,
      RequirementFields: [
        ...prev?.RequirementFields?.map((arrayData: any) => {
          //debugger;
          if (fieldValue?.id === arrayData?.id) {
          }
          return {
            ...arrayData,
            field_attributes: {
              ...arrayData?.field_attributes,
              Mapping:
                fieldValue?.id === arrayData?.id
                  ? [
                      ...fieldValue?.field_attributes?.Mapping?.map(
                        (mappingval: any, index) => {
                          return {
                            ...mappingval,
                            field_order: index + 1,
                          };
                        }
                      ),
                        mappingRowDetails,
                    ]
                  : [...arrayData?.field_attributes?.Mapping],
            },
          };
        }),
      ],
    }));
    //console.log("row value---------->", fieldRequirementVal?.RequirementFields);
    setLoader(false);
  };

  const onMappingDeleteRow = (fieldValue: any, fieldOrder: any) => {
    setData((prev: any) => ({
      ...prev,
      RequirementFields: [
        ...prev?.RequirementFields?.map((arrayData: any) => {
          return {
            ...arrayData,
            field_attributes: {
              ...arrayData?.field_attributes,
              Mapping:
                fieldValue?.id === arrayData?.id
                  ? [
                      ...fieldValue?.field_attributes?.Mapping?.filter(
                        (value: any) => value?.field_order !== fieldOrder
                      )?.map((mappingval: any, index: number) => {
                        return {
                          ...mappingval,
                          field_order: index + 1,
                        };
                      }),
                    ]
                  : [...arrayData?.field_attributes?.Mapping],
            },
          };
        }),
      ],
    }));
  };

  useEffect(() => {
    fetchFieldRequirementData();
  }, [reqValue]);

  useEffect(() => {
    fetchFieldRequirementForm(requirementIdVal?.[0]?.id);
  }, [RequirementFilesIds?.length]);

  // console.log(
  //   "row value---------->----------->",
  //   fieldRequirementVal?.RequirementFields
  // );
  const selector = useSelector((state: any) => {
    return state.requirementDetails.data;
  });
  const isLocked =  selector?.locked=== true       

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="col-xl-10 col-xxl-7">
            <div className="frame_outer">
              <div className="row">
                <div className="col-md-6">
                  <div className="pull-left  mt-2 mb-3 vendor_contact">
                    File Requirements{" "}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 field-requirements-col">
                  <div className="form-group ">
                    <label className="form-label customername">
                      Number of Files
                    </label>
                    <div className="input-group">
                      <span className="input-group-btn">
                      {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ?  

                        <button
                          type="button"
                          className="btn btn-dark btn-number"
                          data-type="minus"
                          data-field="quant[2]"
                          disabled={isLocked}
                        >
                          <span className="glyphicon glyphicon-minus">-</span>
                        </button>: <button
                          type="button"
                          className="btn btn-dark btn-number"
                          data-type="minus"
                          data-field="quant[2]"
                          disabled
                        >
                          <span className="glyphicon glyphicon-minus">-</span>
                        </button>}
                      </span>
                      <input
                        type="text"
                        name="quant[2]"
                        className="form-control input-number field-requirements-input"
                        value={reqValue || 0}
                        min="1"
                        max="100"
                        disabled={isLocked}
                      />
                      <span className="input-group-btn">
                      {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable)|| ProfileType?.ADMIN === getProfileType ?  
    
                        <button
                          type="button"
                          className="btn btn-dark btn-number"
                          data-type="plus"
                          data-field="quant[2]"
                          onClick={() => handleReqAddClick()}
                          disabled={isLocked}
                        >
                          <span className="glyphicon glyphicon-plus">+</span>
                        </button>:
                         <button
                         type="button"
                         className="btn btn-dark btn-number"
                         data-type="plus"
                         data-field="quant[2]"
                         onClick={() => handleReqAddClick()}
                         disabled
                       >
                         <span className="glyphicon glyphicon-plus">+</span>
                       </button>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {RequirementFilesIds?.length === "0" ||
        RequirementFilesIds?.length === 0 ||
        RequirementFilesIds?.length === "" ||
        !Boolean(RequirementFilesIds?.length) ? null : (
          <Box className="my-3" sx={TabBoxClass}>
            <Tabs
              value={tabValue}
              aria-label="project-list"
              variant={reqValue <= 11 ? "standard" : "scrollable"}
              scrollButtons={false}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
            >
              {listKeys.map((listVal, tabIndex) => {
                return (
                  <Tab
                    key={tabIndex}
                    sx={activeTabClass}
                    label={`File ${listVal}`}
                    onClick={(e) => {
                      let listRequirementIds = RequirementFilesIds?.filter(
                        (ids: any, index: any) => {
                          return index === tabIndex;
                        }
                      );
                      fetchFieldRequirementForm(listRequirementIds?.[0]?.id);
                    }}
                  />
                );
              })}
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
              <Tab sx={blankTabClass} disabled />
            </Tabs>
            <div
              style={{
                border: "1px solid #007CB0",
                borderTop: "1px solid transparent",
                padding: "2px",
              }}
            >
              {listKeys?.map((listVal) => {
                return (
                  tabValue + 1 === listVal && (
                    <>
                      <FieldRequirementForm
                        fieldRequirementVal={fieldRequirementVal?.field_data}
                        handleChange={handledNestedChange}
                        indexNo={listVal}
                        handleFileUpload={handleFileUpload}
                        ondeleteRequirementFields={ondeleteRequirementFields}
                        errors={errors}
                      />
                      <FieldRequirementList
                        fielwRquirementList={
                          fieldRequirementVal?.RequirementFields
                            ? fieldRequirementVal?.RequirementFields
                            : []
                        }
                        handleChange={handledNestedChange}
                        onAddRow={onAddRow}
                        onDeleteRow={onDeleteRow}
                        onMappingPopUp={onMappingPopUp}
                        onFirstAddRow={onFirstAddRow}
                        indexNo={listVal}
                        addClick={addClick}
                      />
                      <div className="form-group mt-4">
                        <div className="btn-block">
                      { props?.toggleValue === false && isLocked === false &&    ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ?  
  
                 <button type="submit" className="thame-btn mx-2 w-25">
                            Submit
                          </button>:null}
                          { props?.toggleValue === false && isLocked === false &&  ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType?  
  
                  <button
                            type="button"
                            onClick={ondeleteRequirementFields}
                            data-dismiss="modal"
                            aria-label="Close"
                            className="thame-btn-danger mx-2 w-25"
                          >
                            Delete File
                          </button>:null}
                        </div>
                      </div>
                    </>
                  )
                );
              })}
            </div>
          </Box>
        )}
        <CustomModal
          title={popUp.title}
          onOpen={popUp.open}
          content={popUp.content}
          modalType={popUp.modalType}
          width={"sm"}
          firstBtnName={popUp.modalType === "warning" ? "Delete" : ""}
          OnClickFirstBtn={() => deleteFieldRequirementForm()}
          OnClickCloseIcon={() => {
            setPopUP({
              ...popUp,
              open: false,
            });
          }}
        />
        <CustomModal
          title={"Requirements field details field Mapping"}
          onOpen={mappingPopUp}
          content={
            <FieldRequirementPopUp
              fielwRquirementList={
                fieldRequirementVal?.RequirementFields
                  ? fieldRequirementVal?.RequirementFields
                  : []
              }
              mappingRow={mappingRowData}
              handleChange={handledNestedChange}
              onAddMappingRow={onAddMappingRow}
              onMappingDeleteRow={onMappingDeleteRow}
            />
          }
          width={"sm"}
          OnClickCloseIcon={() => {
            setMappingPopUp(false);
          }}
        />
         <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
      </form>
    </>
  );
};

export default FieldRequirement;

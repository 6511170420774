import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import CustomerForm from "./CustomerForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomerProject from "./CustomerProjects/index";
import CustomerFiles from "./CustomerFiles";
import CustomerContacts from "./CustomerContacts";
import CustomerModules from "./CustomerModules";
import CustomerAMS from "./CustomerAMS";
import { Tab, Tabs } from "@mui/material";
import { activeTabClass, blankTabClass } from "./style";
import CustomTab from "./CustomTab";

const Customer = () => {
  const [value, setValue] = useState(0);
  const location: any = useLocation();
  const params: any = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  const [customerData, setCustomerData] = useState({});

  const [operationDone, setOperationDone] = React.useState(false);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const isDisabled = (tabIndex: number) => {
    if (operationDone) {
      return false;
    } else {
      if (value !== tabIndex) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    setOperationDone(false);
    if (stateData?.customerId) {
      setOperationDone(true);
    }
  }, [stateData]);
  const getCustomerDetail = (customer: object) => {
    
    setCustomerData(customer);
  };

  return (
    <>
      <Box
        className="my-3"
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: "40px",
          width: "100%",
        }}
      >
        <CustomTab
          indexNumber={0}
          title={customerData?.company_name}
          locationPath={location.pathname}
        />
        <Tabs
          value={value}
          aria-label="project-list"
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab
            sx={activeTabClass}
            disabled={isDisabled(1)}
            label="Customer Profile"
          />
          <Tab sx={activeTabClass} disabled={isDisabled(2)} label="Projects" />
          {/* <Tab sx={activeTabClass} label="Files" /> */}
          <Tab
            sx={activeTabClass}
            disabled={isDisabled(3)}
            label="Customer Contacts"
          />
          {/* <Tab
            sx={activeTabClass}
            disabled={isDisabled(4)}
            label="Modules"
          /> */}
          {/* <Tab sx={activeTabClass} label="Modules" /> */}
          {/* <Tab sx={activeTabClass} disabled={isDisabled(5)}
label="AMS" /> */}
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
          <Tab sx={blankTabClass} disabled />
        </Tabs>
        <div
          style={{
            border: "1px solid #007CB0",
            borderTop: "1px solid transparent",
            padding: "2px",
          }}
        >
          {value === 0 ? (
            <CustomerForm customerDetail={getCustomerDetail} />
          ) : null}
          {value === 1 && <CustomerProject />}
          {/* {value === 2 && <CustomerFiles />} */}
          {value === 2 && <CustomerContacts />}
          {/* {value === 3 && <CustomerModules />} */}
          {/* {value === 4 && <CustomerAMS />}  */}
        </div>
      </Box>
    </>
  );
};

export default Customer;

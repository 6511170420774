import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/CustomPagination";
import { getProjectByCustomerList } from "../../../store/actions/Projects";
import { getAllCustomerList } from "../../../store/actions/Customers";

import columns from "./columnsDef";
import { PROJECT_STATUS } from "../../../constants/common";

import { BoxStyle, HeaderStyle } from "./style";

import rows from "./projectByCustomer.json";

const ProjectByCustomerList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [currentPageNo, setCurrentpageNo] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [filterKeys, setFilterKeys] = useState<any>({
    customerName: "",
    ProjectStatus: "",
  });

  const fetchAllCustomerList = () => {
    try {
      dispatch(
        getAllCustomerList({}, (data: any) => {
          if (data?.length > 0) {
            setCustomerList(data);
          } else {
            setCustomerList([]);
          }
        })
      );
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    fetchAllCustomerList();
  }, []);

  const fetchProjectByCustomerList = (
    pageNumber: number,
    buttonType?: string
  ) => {
    try {
      setLoading(true);
      dispatch(
        getProjectByCustomerList(
          {
            projectStatus: filterKeys.ProjectStatus
              ? filterKeys.ProjectStatus
              : "",
            companyName: filterKeys.customerName ? filterKeys.customerName : "",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterKeys.ProjectStatus !== "" || filterKeys.customerName !== "") {
      fetchProjectByCustomerList(0, "ON_LOAD");
    }
  }, [filterKeys.ProjectStatus, filterKeys.customerName]);

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        Project By Customer
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="customer">Customer</InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="customer"
              fullWidth
              variant="outlined"
              value={filterKeys?.customerName}
              displayEmpty
              onChange={(e: any) =>
                setFilterKeys({
                  ...filterKeys,
                  customerName: e?.target?.value,
                })
              }
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {customerList.map((customerItems: any) => {
                return (
                  <MenuItem value={customerItems.id}>
                    {customerItems.company_name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row" sm={6} alignItems="center">
          <Grid item sm={3}>
            <InputLabel htmlFor="projectStatus">Project Status</InputLabel>
          </Grid>
          <Grid item sm={6}>
            <Select
              name="projectStatus"
              fullWidth
              variant="outlined"
              displayEmpty
              value={filterKeys?.ProjectStatus}
              onChange={(e: any) =>
                setFilterKeys({
                  ...filterKeys,
                  ProjectStatus: e?.target?.value,
                })
              }
              sx={{ height: "40px" }}
            >
              <MenuItem value="" selected>
                Select
              </MenuItem>
              {PROJECT_STATUS.map((projectStatusItem: any) => {
                return (
                  <MenuItem value={projectStatusItem?.key}>
                    {projectStatusItem.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchProjectByCustomerList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default ProjectByCustomerList;

import React, { useEffect, useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import columns from "./columnsDef";

const FieldRequirement = ({ requirementFieldPdf }: any) => {
  const defaultColumns: any = useMemo(() => {
    return columns();
  }, []);
  console.log({ requirementFieldPdf });
  if (requirementFieldPdf) {
    const sortedValues = requirementFieldPdf?.sort(function (a, b) {
      return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
    })
  }
  return (
    <>
      {requirementFieldPdf !== undefined &&
        requirementFieldPdf?.map((listVal: any, index: any) => {
          return (
            <div key={index}>
              <div className="doucment_information style-two">
                {/* <h2>4.{index + 1}.1. File {index + 1} - File Requirements</h2> */}
                <h2>4.{index + 1}. File {index + 1}</h2>
                <h2>4.{index + 1}.1. File Requirements</h2>
                <div className="table-responsive">
                  <div className="customer-contact">
                    {index + 1} File requirements{" "}
                  </div>
                  <table className="table table-borderless m-0">
                    <tbody>
                      <tr>
                        <td>
                          <span>Number of fields</span>
                        </td>
                        <td>
                          {listVal?.field_data?.Number_of_fields?.field_desc}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Field Requirements Type</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data?.Field_Requirements_Type
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>File Description</span>
                        </td>
                        <td>
                          {listVal?.field_data?.File_Description?.field_desc}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            A header row will be included in the data file
                          </span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.A_header_row_will_be_included_in_the_data_file
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            A trailer row will be included in the data file
                          </span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.A_trailer_row_will_be_included_in_the_data_file
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            The file being processed is to be delimited by a
                          </span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.The_file_being_processed_is_to_be_delimited_by_a
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>The data transfer will be formatted as</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.The_data_transfer_will_be_formatted_as
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Filtering Requirements</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.Filtering_Requirements
                              ?.field_desc
                          }
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>
                            The expected volume of records to be <br />
                            processed by this integration is
                          </span>
                        </td>
                        <td>
                          {listVal?.field_data?.The_expected_volume_of_records_to_be_processed_by_this_integration_is?.field_desc}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>
                            The expected maximum number of records <br />
                            that the integration will handle is
                          </span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.The_expected_maximum_number_of_records_that_the_integration_will_handle_is
                              ?.field_desc
                          }
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>File Naming Standard – Prod</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data?.File_Naming_Standard_Prod
                              ?.field_desc
                          }
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span>File Naming Standard – Test</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data?.File_Naming_Standard_Test
                              ?.field_desc
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Will input or output contains special characters?</span>
                        </td>
                        <td>{listVal?.field_data?.Will_input_or_output_contains_special_characters?.field_desc}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Encoding</span>
                        </td>
                        <td>{listVal?.field_data?.Encoding?.field_desc}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>What type of data will be sent?</span>
                        </td>
                        <td>{listVal?.field_data?.what_type_of_data_will_be_sent?.field_desc}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Sample File</span>
                        </td>
                        <td>{listVal?.field_data?.Sample_File?.field_desc}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Notes and Additional comments</span>
                        </td>
                        <td>
                          {
                            listVal?.field_data
                              ?.Notes_and_Additional_requirements?.field_desc
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="doucment_information style-three">
                {/* <h2>4.{index+1}.2. File {index + 1} - Field Requirements</h2> */}
                <h2>4.{index+1}.2. Field Requirements</h2>
                <div className="table-responsive">
                  <table className="table table-borderless m-0">
                    <thead>
                      <tr>
                        {defaultColumns?.map((column: any) => (
                          <td key={column.id} style={{fontWeight: 600}}>{column.label}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {listVal?.RequirementFields?.length === 0 && (
                        <tr>
                          <td className="text-center" colSpan={12}>
                            <strong>No Rows</strong>
                          </td>
                        </tr>
                      )}
                      {listVal?.RequirementFields.some(
                        (rowData: any) => rowData.type === "header"
                      ) && (
                        <tr style={{backgroundColor: "#86bc2552"}}>
                          <td className="text-center" colSpan={12}>
                            <strong>Header</strong>
                          </td>
                        </tr>
                      )}
                      {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : 0
                      )?.map((row: any, index: any) => {
                        return (
                          row.type === "header" && (
                            <>
                              <tr tabIndex={-1} key={row?.field_order}>
                                {defaultColumns.map((column: any) => {
                                  let valueData =
                                    row?.[column?.id] ||
                                    row?.field_attributes?.[column?.id]
                                    if(valueData === "on")
                                    {
                                      valueData="Yes"
                                    }
                                    if(valueData === false){
                                      valueData="No"
                                    }
                                    if(valueData === "header")
                                    {
                                      valueData="Header"
                                    }
                                  return (
                                    <>

                                      <td className={column.align == 'center' ? 'text-center' : ''}>{valueData} </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          )
                        );
                      })}
                      {listVal?.RequirementFields.some(
                        (rowData: any) => rowData.type === "DetailsRecord"
                      ) && (
                        <tr style={{backgroundColor: "#86bc2552"}}>
                          <td colSpan={12} className="text-center">
                            <strong>Detail</strong>
                          </td>
                        </tr>
                      )}
                      {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : -1
                      )?.map((row: any, index: any) => {
                        return (
                          row.type === "DetailsRecord" && (
                            <>
                              <tr tabIndex={-1} key={row?.field_order}>
                                {defaultColumns.map((column: any) => {
                                  let valueData =
                                    row?.[column?.id] ||
                                    row?.field_attributes?.[column?.id];
                                    if(valueData === "on")
                                    {
                                      valueData="Yes"
                                    }
                                    if(valueData === false){
                                      valueData="No"
                                    }
                                    if(valueData === "DetailsRecord")
                                    {
                                      valueData="Detail"
                                    }
                                  return (
                                    <>
                                      <td className={column.align == 'center' ? 'text-center' : ''}>{valueData}</td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          )
                        );
                      })}
                      {listVal?.RequirementFields.some(
                        (rowData: any) => rowData.type === "footer"
                      ) && (
                        <tr style={{backgroundColor: "#86bc2552"}}>
                          <td colSpan={12} className="text-center" >
                            <strong>Footer</strong>
                          </td>
                        </tr>
                      )}
                      {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : -1
                      )?.map((row: any, index: any) => {
                        return (
                          row.type === "footer" && (
                            <>
                              <tr tabIndex={-1} key={row?.field_order}>
                                {defaultColumns.map((column: any) => {
                                  let valueData =
                                    row?.[column?.id] ||
                                    row?.field_attributes?.[column?.id];
                                    if(valueData === "on")
                                    {
                                      valueData="Yes"
                                    }
                                    if(valueData === false || valueData == "false"){
                                      valueData="No"
                                    }
                                    if(valueData === "footer")
                                    {
                                      valueData="Footer"
                                    }
                                  return (
                                    <>
                                      <td className={column.align == 'center' ? 'text-center' : ''}>{valueData}</td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <!---New Mapping fields flow--> */}
                <h4 className="field_mappings">Field Mappings:</h4>
                <div className="table-responsive">
                  <table className="table table-borderless m-0">
                    <tbody>
                    {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : 0
                      )?.map((row: any, index: any) => {
                        return (
                          (row.type === "header") && (
                            <>
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                <tr style={{backgroundColor: "#86bc25"}}>
                                <td colSpan={12} className="text-center" >
                                  <strong>Value Map for {row?.field_attributes?.Target_System_Field_Name}</strong>
                                </td>
                              </tr>
                              )}
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr style={{backgroundColor: "#86bc2552", color:"#000"}}>
                                          <th width="25%">Sr. no</th>
                                          <th width="25%">Source Value</th>
                                          <th width="25%">Target Value</th>
                                          <th width="25%">Notes</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {row?.field_attributes?.Mapping.map((item:any, index1:any) => {
                                          return(
                                            <>
                                              <tr>
                                                <td>{index1 + 1}</td>
                                                <td>{item.Source_Value}</td>
                                                <td>{item.Target_Value}</td>
                                                <td>{item.Notes}</td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                            </>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Details Record */}
                <div className="table-responsive">
                  <table className="table table-borderless m-0">
                    <tbody>
                    {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : 0
                      )?.map((row: any, index: any) => {
                        return (
                          (row.type === "DetailsRecord") && (
                            <>
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                <tr style={{backgroundColor: "#86bc25"}}>
                                <td colSpan={12} className="text-center" >
                                  <strong>Value Map for {row?.field_attributes?.Target_System_Field_Name}</strong>
                                </td>
                              </tr>
                              )}
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr style={{backgroundColor: "#86bc2552", color:"#000"}}>
                                          <th width="25%">Sr. no</th>
                                          <th width="25%">Source Value</th>
                                          <th width="25%">Target Value</th>
                                          <th width="25%">Notes</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {row?.field_attributes?.Mapping.map((item:any, index1:any) => {
                                          return(
                                            <>
                                              <tr>
                                                <td>{index1 + 1}</td>
                                                <td>{item.Source_Value}</td>
                                                <td>{item.Target_Value}</td>
                                                <td>{item.Notes}</td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                            </>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Footer mappings */}
                <div className="table-responsive">
                  <table className="table table-borderless m-0">
                    <tbody>
                    {listVal?.RequirementFields?.sort(
                        (fieldA: any, fieldB: any) =>
                          fieldA?.field_order > fieldB?.field_order ? 1 : 0
                      )?.map((row: any, index: any) => {
                        return (
                          (row.type === "footer") && (
                            <>
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                <tr style={{backgroundColor: "#86bc25"}}>
                                <td colSpan={12} className="text-center" >
                                  <strong>Value Map for {row?.field_attributes?.Target_System_Field_Name}</strong>
                                </td>
                              </tr>
                              )}
                              {row?.field_attributes?.Mapping?.length !== 0 && (
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr style={{backgroundColor: "#86bc2552", color:"#000"}}>
                                          <th width="25%">Sr. no</th>
                                          <th width="25%">Source Value</th>
                                          <th width="25%">Target Value</th>
                                          <th width="25%">Notes</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {row?.field_attributes?.Mapping.map((item:any, index1:any) => {
                                          return(
                                            <>
                                              <tr>
                                                <td>{index1 + 1}</td>
                                                <td>{item.Source_Value}</td>
                                                <td>{item.Target_Value}</td>
                                                <td>{item.Notes}</td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                            </>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Ending testing new mapping fields */}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default FieldRequirement;
import React from "react"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import setLoader from "../../../components/CustomLoader/loaderState";
import { useForm } from "../../../helpers/validations/useForm";
import { setLoading } from "../../../store/actions/loaderState";
import {
  getRequirementTemplateQuestionList,
  updateRequirementQuestions,
} from "../../../store/actions/Requirement";

import { CONVERTED_KEYS } from "../../../constants/common";
import CustomModal from "../../../components/CustomModalPop";
import { Field_TYPE } from "./constants";
import { useLocation, useParams } from "react-router-dom";
import { ProfileType } from "../../../utility/commonTypes";

const DynamicTab = (props:any) => {
  const dispatch = useDispatch();
  const [popUp, setPopUP] = useState({
    open: false,
    title: "",
    content: "",
    modalType: "",
  });
  const [questionList, setQuestionList] = useState<any>([]);
  const [validationSchema, setValidationSchema] = useState<any>({});

  const selector = useSelector((state: any) => {
    return state?.requirementDetails?.data;
  });
  const location = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const myValidation = () => {
    let SchemaValidation = {};
    Object.entries(questionList)?.map(([id, value]: any) => {
      const TextValueV = Field_TYPE.TEXT_FIELD.hasOwnProperty(id);
      console.log(id, value, TextValueV);
      if(TextValueV){
        SchemaValidation[id] = {
          pattern: {
            value: "(^w|_d\n)*[A-Za-z0-9\n].*$",
            message:
              "Please enter combination of Alphanumeric and special characters are allowed",
          },
          maxlength: {
            value: 5000,
            message: "Entered value should be less than 5000 character length",
          }
        }
        
      }
    });
    return SchemaValidation;    
  }

  const {
    handleSubmit,
    handleChange,
    data: templateQuestions,
    setData,
    errors,
  } = useForm<any>({
    validations: myValidation(),
    onSubmit: () => {
      setLoader(true);
      let templateVal: any = {};
      Object.entries(templateQuestions)?.map(([id, value]: any) => {
        if (Object.keys(value)?.includes("field_desc")) {
          templateVal[id] = { ...value };
        } else {
          templateVal[id] = {
            field_desc: value,
            test_cases: 0,
          };
        }
      });
      dispatch(
        updateRequirementQuestions(
          {
            payload: {
              id: stateData?.requirementId,
              field_data: templateVal,
            },
          },
          onUpdateTemplateQuestion
        )
      );
    },
  });

  const onUpdateTemplateQuestion = (data: any) => {
    if (data === false) {
      setPopUP({
        open: true,
        title: `${CONVERTED_KEYS[selector?.requirement_template]}`,
        content: "Error!Please try again",
        modalType: "error",
      });
    } else {
      setPopUP({
        open: true,
        title: `${CONVERTED_KEYS[selector?.requirement_template]}`,
        content: "Updated Successfully",
        modalType: "success",
      });
      setTimeout(() => {
        setPopUP({
          open: false,
          title: ``,
          content: "",
          modalType: "success",
        });
        window.scrollTo(0,0)
      }, 2000);
    }
    setLoader(false);
  };

  const fetchRequirementQuestion = () => {
    try {
      setLoading(true);
      dispatch(
        getRequirementTemplateQuestionList(
          { id: stateData?.requirementId },
          (data: any) => {
            setQuestionList(data?.field_data);
            setData(data?.field_data);
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchRequirementQuestion();
  }, [setData, stateData?.requirementId, selector?.requirement_template]);
  const isLocked =  selector?.locked=== true       

  return (
    <>
      <div className="container-fluid ">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left  mt-2 mb-3 vendor_contact">
                  {CONVERTED_KEYS[selector?.requirement_template]}
                </div>
              </div>
              <div className="col-md-2">
                {/* <div className="mt-2 pull-left vendor_contact">Test case</div> */}
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              {Object?.entries(questionList)
                /*?.sort((fieldA: any, fieldB: any) =>
                  fieldA?.field_order > fieldB?.field_order ? 1 : -1
                )*/
                ?.map(([id]: any, index) => {
                  const TextValue = Field_TYPE.TEXT_FIELD.hasOwnProperty(id);
                  const DropDownValue = Field_TYPE.DROP_DOWN.hasOwnProperty(id);

                  const DropDownValue1 = Field_TYPE.DROP_DOWN1.hasOwnProperty(
                    id
                  );

                  const RecruitingDropDown=Field_TYPE.RECRUITING_HIRE_INBOUND_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const SubsetDropDown = Field_TYPE.SUBSET_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const PIConnectorDropDown= Field_TYPE.DROPDOWN_PI_CONNECTOR.hasOwnProperty(id)
                  const PayrollDropDown = Field_TYPE.PAYROLL_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const PaymentDropDown = Field_TYPE.PAYMENT_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const PayrollInputDropDown = Field_TYPE.PAYROLL_INPUT_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const HireProcessDropDown = Field_TYPE.HIRE_PROCESS_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const systemRecordDropDown = Field_TYPE.SYSTEM_RECORD_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const staffingModalRecordDropDown = Field_TYPE.STAFFING_MODAL_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const CreditCardDropDown = Field_TYPE.CREDIT_CARD_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const CreditCardDropDownPR = Field_TYPE.CREDIT_CARD_DROP_DOWN_PR.hasOwnProperty(
                    id
                  );
                  const CurrencyRateDropDown = Field_TYPE.CURRENCY_RATE_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const GLInboundRateDropDown = Field_TYPE.GL_INBOUND_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const GeneralLedgerDropDown1 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const GeneralLedgerDropDown2 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_2.hasOwnProperty(
                    id
                  );
                  const GeneralLedgerDropDown3 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_3.hasOwnProperty(
                    id
                  );
                  const GeneralLedgerDropDown4 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_4.hasOwnProperty(
                    id
                  );
                  const GeneralLedgerDropDown5 = Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_5.hasOwnProperty(
                    id
                  );
                  const ReceivablesDropDown = Field_TYPE.RECEIVABLES_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const TaxDropDown = Field_TYPE.TAX_DROP_DOWN.hasOwnProperty(
                    id
                  );
                  const TaxOutboundDropDown1 = Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const TaxOutboundDropDown2 = Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_2.hasOwnProperty(
                    id
                  );
                  const PayrollInterfaceDropDown = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN.hasOwnProperty(
                    id
                  );
                  const PayrollInterfaceDropDown1 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_1.hasOwnProperty(
                    id
                  );
                  const PayrollInterfaceDropDown2 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_2.hasOwnProperty(
                    id
                  );
                  const PayrollInterfaceDropDown3 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_3.hasOwnProperty(
                    id
                  );
                  const PayrollInterfaceDropDown4 = Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_4.hasOwnProperty(
                    id
                  );
                  const AbsentManagementTimeoff = Field_TYPE.ABSENT_MANAGEMENT_TIMEOFF.hasOwnProperty(
                    id
                  );
                  const BenefitsboundDropDown1 = Field_TYPE.BENEFITS_OUTBOUND_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const BarbidirectionalDropDown1 = Field_TYPE.BAR_BI_DIRECTIONAL_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const LifeDisabilityDropDown1 = Field_TYPE.LIFE_DISABILITY_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const HsaFsaDropDown1 = Field_TYPE.HSAFSA_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
                  const FourZeroDropDown1 = Field_TYPE.FourzeroOne_DROP_DOWN_1.hasOwnProperty(
                    id
                  );
console.log("id",Field_TYPE.DROPDOWN_PI_CONNECTOR[id],PIConnectorDropDown)
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label htmlFor="name" className="form-label">
                            {TextValue
                              ? Field_TYPE.TEXT_FIELD[id]

                              : DropDownValue
                              ? Field_TYPE.DROP_DOWN[id]

                              : DropDownValue1
                              ? Field_TYPE.DROP_DOWN1[id]

                              : SubsetDropDown
                              ? Field_TYPE.SUBSET_DROP_DOWN[id]

                              : PayrollDropDown
                              ? Field_TYPE.PAYROLL_DROP_DOWN[id]

                              : PaymentDropDown
                              ? Field_TYPE.PAYMENT_DROP_DOWN[id]

                              : PayrollInputDropDown 
                              ? Field_TYPE.PAYROLL_INPUT_DROP_DOWN[id]

                              : PIConnectorDropDown
                              ? Field_TYPE.DROPDOWN_PI_CONNECTOR[id]

                              : HireProcessDropDown
                              ? Field_TYPE.HIRE_PROCESS_DROP_DOWN[id]

                              : systemRecordDropDown
                              ? Field_TYPE.SYSTEM_RECORD_DROP_DOWN[id]

                              : staffingModalRecordDropDown
                              ? Field_TYPE.STAFFING_MODAL_DROP_DOWN[id]

                              : CreditCardDropDown
                              ? Field_TYPE.CREDIT_CARD_DROP_DOWN[id]

                              : CreditCardDropDownPR
                              ? Field_TYPE.CREDIT_CARD_DROP_DOWN_PR[id]

                              : CurrencyRateDropDown
                              ? Field_TYPE.CURRENCY_RATE_DROP_DOWN[id]

                              : GLInboundRateDropDown
                              ? Field_TYPE.GL_INBOUND_DROP_DOWN[id]

                              : GeneralLedgerDropDown1
                              ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_1[id]

                              : GeneralLedgerDropDown2
                              ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_2[id]

                              : GeneralLedgerDropDown3
                              ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_3[id]

                              : GeneralLedgerDropDown4
                              ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_4[id]

                              : GeneralLedgerDropDown5
                              ? Field_TYPE.GENERAL_LEDGERS_DROP_DOWN_5[id]

                              : ReceivablesDropDown
                              ? Field_TYPE.RECEIVABLES_DROP_DOWN[id]

                              : TaxDropDown
                              ? Field_TYPE.TAX_DROP_DOWN[id]

                              : TaxOutboundDropDown1
                              ? Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_1[id]

                              : TaxOutboundDropDown2
                              ? Field_TYPE.TAX_RATES_OUTBOUND_DROP_DOWN_2[id]

                              : BenefitsboundDropDown1
                              ? Field_TYPE.BENEFITS_OUTBOUND_DROP_DOWN_1[id]

                              : BarbidirectionalDropDown1
                              ? Field_TYPE.BAR_BI_DIRECTIONAL_DROP_DOWN_1[id]

                              : LifeDisabilityDropDown1
                              ? Field_TYPE.LIFE_DISABILITY_DROP_DOWN_1[id]

                              : HsaFsaDropDown1
                              ? Field_TYPE.HSAFSA_DROP_DOWN_1[id]

                              : FourZeroDropDown1
                              ? Field_TYPE.FourzeroOne_DROP_DOWN_1[id]

                              : PayrollInterfaceDropDown
                              ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN[id]

                              : PayrollInterfaceDropDown1
                              ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_1[id]

                              : PayrollInterfaceDropDown2
                              ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_2[id]

                              : PayrollInterfaceDropDown3
                              ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_3[id]

                              : PayrollInterfaceDropDown4
                              ? Field_TYPE.PAYROLL_INTERFACE_DROPDOWN_4[id]

                              : AbsentManagementTimeoff
                              ? Field_TYPE.ABSENT_MANAGEMENT_TIMEOFF[id]

                              : id
                              ? Field_TYPE.RECRUITING_HIRE_INBOUND_DROP_DOWN[id]
                              : RecruitingDropDown
                              }
                          </label>
                          {TextValue ? (
                            <><textarea
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              value={templateQuestions[id]?.field_desc}
                              // placeholder={Field_TYPE?.TEXT_FIELD?.[id]}
                              disabled={isLocked}
                              className="form-textarea"
                            >
                              {templateQuestions[id]?.field_desc}
                            </textarea>
                            {errors[id] && (
                                <p className="error-text">{errors[id]}</p>
                              )}
                            </>
                          ) : DropDownValue ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"Yes"}
                                selected={
                                  templateQuestions[id]?.field_desc === "Yes"
                                }
                              >
                                {" "}
                                Yes{" "}
                              </option>
                              <option
                                value={"No"}
                                selected={
                                  templateQuestions[id]?.field_desc === "No"
                                }
                              >
                                {" "}
                                No{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          ) 
                          : DropDownValue1 ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"AllTransactions"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "AllTransactions"
                                }
                              >
                                {" "}
                                All Transactions{" "}
                              </option>
                              <option
                                value={"TopofStack"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "TopofStack"
                                }
                              >
                                {" "}
                                Top Of Stack{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          ) 
                          : SubsetDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"AllPaygroups"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "AllPaygroups"
                                }
                              >
                                {" "}
                                All Paygroups{" "}
                              </option>
                              <option
                                value={"Subset"}
                                selected={
                                  templateQuestions[id]?.field_desc === "Subset"
                                }
                              >
                                {" "}
                                Subset{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          ) : PayrollDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"OneTime"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "OneTime"
                                }
                              >
                                {" "}
                                One-Time{" "}
                              </option>
                              <option
                                value={"Ongoing"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "Ongoing"
                                }
                              >
                                {" "}
                                Ongoing{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          ) : PaymentDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"OnDemand"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "OnDemand"
                                }
                              >
                                {" "}
                                On Demand{" "}
                              </option>
                              <option
                                value={"ManualPayment"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "ManualPayment"
                                }
                              >
                                {" "}
                                Manual Payment{" "}
                              </option>
                            </select>
                          ) : PayrollInputDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"Deductions"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "Deductions"
                                }
                              >
                                {" "}
                                Deductions{" "}
                              </option>
                              <option
                                value={"Earnings"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "Earnings"
                                }
                              >
                                {" "}
                                Earnings{" "}
                              </option>
                              <option
                                value={"Both"}
                                selected={
                                  templateQuestions[id]?.field_desc === "Both"
                                }
                              >
                                {" "}
                                Both{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          ) : HireProcessDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"Job Application"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "Job Application"
                                }
                              >
                                {" "}
                                Job Application{" "}
                              </option>
                              <option
                                value={"Hire"}
                                selected={
                                  templateQuestions[id]?.field_desc === "Hire"
                                }
                              >
                                {" "}
                                Hire{" "}
                              </option>
                            </select>
                          ) : systemRecordDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"ATS"}
                                selected={
                                  templateQuestions[id]?.field_desc === "ATS"
                                }
                              >
                                {" "}
                                ATS{" "}
                              </option>
                              <option
                                value={"Workday"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "Workday"
                                }
                              >
                                {" "}
                                Workday{" "}
                              </option>
                              <option
                                value={"NA"}
                                selected={
                                  templateQuestions[id]?.field_desc === "NA"
                                }
                              >
                                {" "}
                                NA{" "}
                              </option>
                            </select>
                          )                          
                        //hireinbound
                        : RecruitingDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Workday"}
                              selected={
                                templateQuestions[id]?.field_desc === "Workday"
                              }
                            >
                              {" "}
                              Workday{" "}
                            </option>
                            <option
                              value={"3rd Party ATS"}
                              selected={
                                templateQuestions[id]?.field_desc === "3rd Party ATS"
                              }
                            >
                              {" "}
                              3rd Party ATS{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) 
                        :PIConnectorDropDown ? (
                          <select
                          id={id}
                          name={id}
                          onChange={handleChange(`${id}`)}
                          className="form-select"
                          aria-label="Default select example"
                          disabled={isLocked}
                        >
                          <option selected>Select</option>
                          <option
                            value={"Earliest Open Pay Period"}
                            selected={
                              templateQuestions[id]?.field_desc === "Earliest Open Pay Period"
                            }
                          >
                            {" "}
                            Earliest Open Pay Period{" "}
                          </option>
                          <option
                            value={"Pay Period for Current Date"}
                            selected={
                              templateQuestions[id]?.field_desc === "Pay Period for Current Date"
                            }
                          >
                            {" "}
                            Pay Period for Current Date{" "}
                          </option>
                          <option
                            value={"NA"}
                            selected={
                              templateQuestions[id]?.field_desc === "NA"
                            }
                          >
                            {" "}
                            NA{" "}
                          </option>
                        </select>
                      )
                        
                        
                         : staffingModalRecordDropDown ? (
                            <select
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              className="form-select"
                              aria-label="Default select example"
                              disabled={isLocked}
                            >
                              <option selected>Select</option>
                              <option
                                value={"PositionManagement"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "PositionManagement"
                                }
                              >
                                {" "}
                                Position Management{" "}
                              </option>
                              <option
                                value={"JobManagement"}
                                selected={
                                  templateQuestions[id]?.field_desc ===
                                  "JobManagement"
                                }
                              >
                                {" "}
                                Job Management{" "}
                              </option>
                            </select>
                          ) 
                          //credit card drop down
                        : CreditCardDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Expense"}
                              selected={
                                templateQuestions[id]?.field_desc === "Expense"
                              }
                            >
                              {" "}
                              Expense{" "}
                            </option>
                            <option
                              value={"Procurement Cards"}
                              selected={
                                templateQuestions[id]?.field_desc === "Procurement Cards"
                              }
                            >
                              {" "}
                              Procurement Cards{" "}
                            </option>
                            <option
                              value={"Both"}
                              selected={
                                templateQuestions[id]?.field_desc === "Both"
                              }
                            >
                              {" "}
                              Both{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //credit card drop down Paying responsibility
                        : CreditCardDropDownPR ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Corporate"}
                              selected={
                                templateQuestions[id]?.field_desc === "Corporate"
                              }
                            >
                              {" "}
                              Corporate{" "}
                            </option>
                            <option
                              value={"Individual"}
                              selected={
                                templateQuestions[id]?.field_desc === "Individual"
                              }
                            >
                              {" "}
                              Individual{" "}
                            </option>
                            <option
                              value={"Shared"}
                              selected={
                                templateQuestions[id]?.field_desc === "Shared"
                              }
                            >
                              {" "}
                              Shared{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //credit card drop down Paying responsibility
                        : CurrencyRateDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            <option
                              value={"Cross Rates"}
                              selected={
                                templateQuestions[id]?.field_desc === "Cross Rates"
                              }
                            >
                              {" "}
                              Cross Rates{" "}
                            </option>
                            <option
                              value={"Triangulation"}
                              selected={
                                templateQuestions[id]?.field_desc === "Triangulation"
                              }
                            >
                              {" "}
                              Triangulation{" "}
                            </option>
                            <option
                              value={"See Notes"}
                              selected={
                                templateQuestions[id]?.field_desc === "See Notes"
                              }
                            >
                              {" "}
                              See Notes{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //GL Inbound drop down
                        : GLInboundRateDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Through BP"}
                              selected={
                                templateQuestions[id]?.field_desc === "Through BP"
                              }
                            >
                              {" "}
                              Through BP{" "}
                            </option>
                            <option
                              value={"Autocomplete"}
                              selected={
                                templateQuestions[id]?.field_desc === "Autocomplete"
                              }
                            >
                              {" "}
                              Autocomplete{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //general ledger drop down 1
                        : GeneralLedgerDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Modify EIB Report"}
                              selected={
                                templateQuestions[id]?.field_desc === "Modify EIB Report"
                              }
                            >
                              {" "}
                              Modify EIB Report{" "}
                            </option>
                            <option
                              value={"Use Workday Delivered Reports"}
                              selected={
                                templateQuestions[id]?.field_desc === "Use Workday Delivered Reports"
                              }
                            >
                              {" "}
                              Use Workday Delivered Reports{" "}
                            </option>
                            <option
                              value={"Custom Audit Report"}
                              selected={
                                templateQuestions[id]?.field_desc === "Custom Audit Report"
                              }
                            >
                              {" "}
                              Custom Audit Report{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //general ledger drop down 2
                        : GeneralLedgerDropDown2 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Same file"}
                              selected={
                                templateQuestions[id]?.field_desc === "Same file"
                              }
                            >
                              {" "}
                              Same file{" "}
                            </option>
                            <option
                              value={"Separate File"}
                              selected={
                                templateQuestions[id]?.field_desc === "Separate File"
                              }
                            >
                              {" "}
                              Separate File{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //general ledger drop down 3
                        : GeneralLedgerDropDown3 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Automatically"}
                              selected={
                                templateQuestions[id]?.field_desc === "Automatically"
                              }
                            >
                              {" "}
                              Automatically{" "}
                            </option>
                            <option
                              value={"Manually"}
                              selected={
                                templateQuestions[id]?.field_desc === "Manually"
                              }
                            >
                              {" "}
                              Manually{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //general ledger drop down 4
                        : GeneralLedgerDropDown4 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Sent separately"}
                              selected={
                                templateQuestions[id]?.field_desc === "Sent separately"
                              }
                            >
                              {" "}
                              Sent separately{" "}
                            </option>
                            <option
                              value={"Same file"}
                              selected={
                                templateQuestions[id]?.field_desc === "Same file"
                              }
                            >
                              {" "}
                              Same file{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //general ledger drop down 5
                        : GeneralLedgerDropDown5 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Period End Date"}
                              selected={
                                templateQuestions[id]?.field_desc === "Period End Date"
                              }
                            >
                              {" "}
                              Period End Date{" "}
                            </option>
                            <option
                              value={"Payment Date"}
                              selected={
                                templateQuestions[id]?.field_desc === "Payment Date"
                              }
                            >
                              {" "}
                              Payment Date{" "}
                            </option>
                            <option
                              value={"Completion Date"}
                              selected={
                                templateQuestions[id]?.field_desc === "Completion Date"
                              }
                            >
                              {" "}
                              Completion Date{" "}
                            </option>
                            <option
                              value={"Month End Date"}
                              selected={
                                templateQuestions[id]?.field_desc === "Month End Date"
                              }
                            >
                              {" "}
                              Month End Date{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //receivables drop down
                        : ReceivablesDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes"
                              }
                            >
                              {" "}
                              Yes{" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            <option
                              value={"Pending Item Open"}
                              selected={
                                templateQuestions[id]?.field_desc === "Pending Item Open"
                              }
                            >
                              {" "}
                              Pending Item Open{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //tax drop down
                        : TaxDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Periodically"}
                              selected={
                                templateQuestions[id]?.field_desc === "Periodically"
                              }
                            >
                              {" "}
                              Periodically{" "}
                            </option>
                            <option
                              value={"Monthly"}
                              selected={
                                templateQuestions[id]?.field_desc === "Monthly"
                              }
                            >
                              {" "}
                              Monthly{" "}
                            </option>
                            <option
                              value={"Quarterly"}
                              selected={
                                templateQuestions[id]?.field_desc === "Quarterly"
                              }
                            >
                              {" "}
                              Quarterly{" "}
                            </option>
                            <option
                              value={"Annually"}
                              selected={
                                templateQuestions[id]?.field_desc === "Annually"
                              }
                            >
                              {" "}
                              Annually{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //tax out bound 1 Home Record/Host Record/Both(see notes)/NA
                        : TaxOutboundDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Home Record"}
                              selected={
                                templateQuestions[id]?.field_desc === "Home Record"
                              }
                            >
                              {" "}
                              Home Record{" "}
                            </option>
                            <option
                              value={"Host Record"}
                              selected={
                                templateQuestions[id]?.field_desc === "Host Record"
                              }
                            >
                              {" "}
                              Host Record{" "}
                            </option>
                            <option
                              value={"Both(see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Both(see notes)"
                              }
                            >
                              {" "}
                              Both(see notes){" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //tax out bound 2 Yes-Multiple/Yes-YTD/Yes-MTD/Yes-QTD/Yes-Rolling Timeframe (see notes)/Yes-Other (see notes)/No/NA
                        : TaxOutboundDropDown2 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) // benefits outbound updations
                        //tax out bound 2 Yes-Multiple/Yes-YTD/Yes-MTD/Yes-QTD/Yes-Rolling Timeframe (see notes)/Yes-Other (see notes)/No/NA
                        : BenefitsboundDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) // bar bidirectional
                        //tax out bound 2 Yes-Multiple/Yes-YTD/Yes-MTD/Yes-QTD/Yes-Rolling Timeframe (see notes)/Yes-Other (see notes)/No/NA
                        : BarbidirectionalDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        )
                        // life 
                        : LifeDisabilityDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        )
                        //hsa fsa
                        // life 
                        : HsaFsaDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        )
                        // four zero dd one
                        // life 
                        : FourZeroDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Yes-Multiple"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Multiple"
                              }
                            >
                              {" "}
                              Yes-Multiple{" "}
                            </option>
                            <option
                              value={"Yes-YTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-YTD"
                              }
                            >
                              {" "}
                              Yes-YTD{" "}
                            </option>
                            <option
                              value={"Yes-MTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-MTD"
                              }
                            >
                              {" "}
                              Yes-MTD{" "}
                            </option>
                            <option
                              value={"Yes-QTD"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-QTD"
                              }
                            >
                              {" "}
                              Yes-QTD{" "}
                            </option>
                            <option
                              value={"Yes-Rolling Timeframe (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Rolling Timeframe (see notes)"
                              }
                            >
                              {" "}
                              Yes-Rolling Timeframe (see notes){" "}
                            </option>
                            <option
                              value={"Yes-Other (see notes)"}
                              selected={
                                templateQuestions[id]?.field_desc === "Yes-Other (see notes)"
                              }
                            >
                              {" "}
                              Yes-Other (see notes){" "}
                            </option>
                            <option
                              value={"No"}
                              selected={
                                templateQuestions[id]?.field_desc === "No"
                              }
                            >
                              {" "}
                              No{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        )
                        //Payroll interface drop down updation
                        : PayrollInterfaceDropDown ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Active Payment Elections"}
                              selected={
                                templateQuestions[id]?.field_desc === "Active Payment Elections"
                              }
                            >
                              {" "}
                              Active Payment Elections{" "}
                            </option>
                            <option
                              value={"Deleted Accounts"}
                              selected={
                                templateQuestions[id]?.field_desc === "Deleted Accounts"
                              }
                            >
                              {" "}
                              Deleted Accounts{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //Payroll interface drop down  1 updation
                        : PayrollInterfaceDropDown1 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Home System"}
                              selected={
                                templateQuestions[id]?.field_desc === "Home System"
                              }
                            >
                              {" "}
                              Home System{" "}
                            </option>
                            <option
                              value={"International Assignment System"}
                              selected={
                                templateQuestions[id]?.field_desc === "International Assignment System"
                              }
                            >
                              {" "}
                              International Assignment System{" "}
                            </option>
                            {/* -- */}
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //Payroll interface drop down  2 updation
                        : PayrollInterfaceDropDown2 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Actual size"}
                              selected={
                                templateQuestions[id]?.field_desc === "Actual size"
                              }
                            >
                              {" "}
                              Actual size{" "}
                            </option>
                            <option
                              value={"Fit to page"}
                              selected={
                                templateQuestions[id]?.field_desc === "Fit to page"
                              }
                            >
                              {" "}
                              Fit to page{" "}
                            </option>
                          </select>
                        ) //Payroll interface drop down 3 updation
                        : PayrollInterfaceDropDown3 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Workday Check Layout"}
                              selected={
                                templateQuestions[id]?.field_desc === "Workday Check Layout"
                              }
                            >
                              {" "}
                              Workday Check Layout{" "}
                            </option>
                            <option
                              value={"Bank Custom Layout"}
                              selected={
                                templateQuestions[id]?.field_desc === "Bank Custom Layout"
                              }
                            >
                              {" "}
                              Bank Custom Layout{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //Payroll interface drop down 4 updation
                        : PayrollInterfaceDropDown4 ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Workday Check Layout"}
                              selected={
                                templateQuestions[id]?.field_desc === "Workday Check Layout"
                              }
                            >
                              {" "}
                              Workday Check Layout{" "}
                            </option>
                            <option
                              value={"Bank Custom Layout"}
                              selected={
                                templateQuestions[id]?.field_desc === "Bank Custom Layout"
                              }
                            >
                              {" "}
                              Bank Custom Layout{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ) //Absent management drop down 1 timeoff updation
                        : AbsentManagementTimeoff ? (
                          <select
                            id={id}
                            name={id}
                            onChange={handleChange(`${id}`)}
                            className="form-select"
                            aria-label="Default select example"
                            disabled={isLocked}
                          >
                            <option selected>Select</option>
                            <option
                              value={"Days"}
                              selected={
                                templateQuestions[id]?.field_desc === "Days"
                              }
                            >
                              {" "}
                              Days{" "}
                            </option>
                            <option
                              value={"Hours"}
                              selected={
                                templateQuestions[id]?.field_desc === "Hours"
                              }
                            >
                              {" "}
                              Hours{" "}
                            </option>
                            <option
                              value={"NA"}
                              selected={
                                templateQuestions[id]?.field_desc === "NA"
                              }
                            >
                              {" "}
                              NA{" "}
                            </option>
                          </select>
                        ): (
                            <input
                              type="text"
                              id={id}
                              name={id}
                              onChange={handleChange(`${id}`)}
                              value={templateQuestions[id]?.field_desc}
                              className="form-control"
                              // placeholder={Field_TYPE?.INPUT_BOX[id]}
                              disabled={isLocked}
                            />
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-2 testcase_list_wrapper"> */}
                        {/* <div className="test_case_list">
                          <div className="test_case_list pt-2"> */}
                            {/* <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#testcaselist"
                            > */}
                              {/* {templateQuestions[id]?.test_cases || "0"} */}
                            {/* </a> */}
                          {/* </div>
                        </div> */}
                      {/* </div> */}
                    </div>
                  );
                })}
              <div className="col-md-4 my-4">
                <div className="form-group">
                  <div className="btn-block">
                  { props?.toggleValue === false && isLocked === false && ((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ? 
   
           <button type="submit" className="thame-btn w-100">
                      Update
                    </button>:null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomModal
        title={popUp.title}
        onOpen={popUp.open}
        content={popUp.content}
        modalType={popUp.modalType}
        width={"sm"}
        OnClickCloseIcon={() => {
          setPopUP({
            ...popUp,
            open: false,
          });
        }}
      />
    </>
  );
};
export default DynamicTab;
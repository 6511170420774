import { call, put, select, takeLatest } from "redux-saga/effects";
import { CustomerFilesAsync } from "../../../api/common";
import { customerActions, setCustomerFilesList } from "../../actions/Customer";
import { APIResponse } from "../../../utility/commonTypes";

function* CustomerFileList(action: any) {
  const { payload, callback } = action;
  try {
    let res: APIResponse = yield call(CustomerFilesAsync, payload);
    console.log(res.data);
    if (res?.status === 201) {
      //yield put(setCustomerFilesList({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}
export function* watchCustomerFiles() {
  yield takeLatest(customerActions.GET_CUSTOMER_FILES_LIST, CustomerFileList);
}

import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getMyProjectList } from "../../../store/actions/Projects";
import CustomPagination from "../../../components/CustomPagination";
import columns from "./columnsDef";
import { BoxStyle, HeaderStyle } from "./style";

import rows from "./myProjects.json";

const MyProjectList = () => {
  const defaultColumns = useMemo(() => {
    return columns();
  }, []);
  const [totalPageCount, setTotalPageCount] = useState<any>(0);
  const userDetail = useSelector((state: any) => {
    return state?.auth?.globalAuthData;
  });

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<any>([]);

  const [currentPageNo, setCurrentpageNo] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const fetchMyProjectList = (pageNumber: number, buttonType?: string) => {
    try {
      setLoading(true);
      dispatch(
        getMyProjectList(
          {
            user_id: userDetail?.id,
            // project_name: "j04",
            size: 10,
            page: pageNumber,
          },
          (data: any) => {
            setTotalPageCount(data?.totalItems);
            if (data?.rowsReturned?.length > 0) {
              setRowData(data?.rowsReturned);
            } else {
              setRowData([]);
            }
          }
        )
      );
    } catch (e) {
      console.log(e, "error");
    } finally {
      if (buttonType === "NEXT_PAGE") {
        setCurrentpageNo(pageNumber);
      } else {
        setCurrentpageNo(0);
      }
      setLoading(false);
    }
  };
  console.log("rowData Length::::", rowData.length);
  useEffect(() => {
    fetchMyProjectList(0, "ON_LOAD");
  }, []);

  return (
    <>
      <Typography sx={{ ...HeaderStyle }} className="my-3 mx-4">
        My Projects
      </Typography>

      <Box sx={{ ...BoxStyle }}>
        <DataGrid
          autoHeight
          rows={rowData}
          rowCount={totalPageCount}
          page={currentPageNo}
          columns={defaultColumns}
          pageSize={10}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          components={{
            Pagination: CustomPagination,
          }}
          onPageChange={(data) => {
            fetchMyProjectList(data, "NEXT_PAGE");
          }}
        />
      </Box>
    </>
  );
};

export default MyProjectList;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  projectScopeAsync,
  projectScopeUpdateAsync,
} from "../../../api/common";
import { APIResponse } from "../../../utility/commonTypes";
import { setAuthToken } from "../../actions/auth";
import { actions, setProjectScope } from "../../actions/Project";
function* ProjectScope(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(projectScopeAsync, payload);
    console.log(res.data);
    if (res?.status === 200) {
      // yield put(setProjectScope({ ...res.data }));
      // yield put({ type: 'GET_BY_PROJECT_SCOPE', payload:{id:"project1"}})

      callback(res?.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* ProjectScopeUpdate(action: any) {
  const { payload, callback } = action;
  try {
    let res:APIResponse = yield call(projectScopeUpdateAsync, payload);
    console.log("updated data", res.data);
    if (res?.status == 201) {
     
    } else if (res?.status === 200) {
      // yield put(setProjectScope({ ...res.data }));
      callback(res?.data);
    } else {
      callback(false,res?.data?.Error);
    }
  } catch (error) {
    callback(false);
  }
}

export function* watchProjectScope() {
  yield takeLatest(actions.GET_PROJECT_SCOPE, ProjectScope);
  yield takeLatest(actions.UPDATE_PROJECT_SCOPE, ProjectScopeUpdate);
}

import React from 'react';
import { Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid';
import moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
import { formatDate } from '../../../utility/idelTimer';

const commonColumnsDef = {
  headerClassName: 'super-app-theme--header',
  sortable: false,
  flex: 1
}

const columns = (editProjectResource: any,deleteProjectResource:any,handleUserDetails:any) => {
  return [

    {
      field: 'ResourceName',
      headerName: 'Resource Name',
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => 
      // params?.row?.User?.ResourceName || "NA",
      {
        return (
            params?.row?.User?.ResourceName ? 
                <a href="#" onClick={(e: any) =>{handleUserDetails(e, params?.row?.User?.id);return false} }
                >
                {params?.row?.User?.ResourceName} 
                </a> 
            : "NA"
        )
    }    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => 
      <Tooltip title={params?.row?.role} followCursor>
        <div>
      {params?.row?.role || "NA"}
      </div>
      </Tooltip>
    },

    {
      field: 'start_date',
      headerName: 'Start Date',
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
         return params?.value === undefined || params?.value===null? 'NA' : formatDate(params?.value)

      },
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      minWidth: 115,
      ...commonColumnsDef,
      valueFormatter: (params: any) => {
        return params?.value === undefined || params?.value === null ? 'NA' : formatDate(params?.value)

      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => 
      <Tooltip title={params?.row?.notes} followCursor>
          <div>{params?.row?.notes}</div>
      </Tooltip>

    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      minWidth: 136,
      ...commonColumnsDef,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={ <EditIcon sx={{ color: "#000000" }} />}
          label="Edit"
          onClick={(e: any) => editProjectResource(e, params?.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#c70000" }} />}
          label="Delete"
          onClick={(e: any) => deleteProjectResource(e,params?.row?.id)}
        />,
      ],
    },

  ];
};

export default columns;
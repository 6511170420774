export const actions = {
  SIGNUP_PROJECT: "SIGNUP_PROJECT",
  SET_PROJECT_SCOPE: "SET_PROJECT_SCOPE",
  GET_PROJECT_SCOPE: "GET_PROJECT_SCOPE",
  GET_BY_PROJECT_SCOPE: "GET_BY_PROJECT_SCOPE",
  SET_PROJECT_DATES: "SET_PROJECT_DATES",
  GET_PROJECT_DATES: "GET_PROJECT_DATES",
  CREATE_PROJECT_DATES: "CREATE_PROJECT_DATES",
  UPDATE_PROJECT_SCOPE: "UPDATE_PROJECT_SCOPE",
  UPDATE_PROJECT_DATES: "UPDATE_PROJECT_DATES",
  SIGNUP_PROJECT_RESOURCES: "SIGNUP_PROJECT_RESOURCES",
  SET_PROJECT_RESOURCES: "SET_PROJECT_RESOURCES",
  GET_PROJECT_RESOURCES: "GET_PROJECT_RESOURCES",
  UPDATE_PROJECT_RESOURCES: "UPDATE_PROJECT_RESOURCES",
  DELETE_PROJECT_RESOURCES: "DELETE_PROJECT_RESOURCES",
  SET_SUB_PROJECT_LIST: "SET_SUB_PROJECT_LIST",
  GET_SUB_PROJECT_LIST: "GET_SUB_PROJECT_LIST",
  GET_MASTER_PROJECT_LIST: "GET_MASTER_PROJECT_LIST",
  SET_MASTER_PROJECT_LIST: "SET_MASTER_PROJECT_LIST",
  SET_PROJECT_DETAILS: "SET_PROJECT_DETAILS",
  GET_PROJECT_DETAILS: "GET_PROJECT_DETAILS",
  UPDATE_PROJECT_LIST: "UPDATE_PROJECT_LIST",
  SET_PROJECT_TASK: "SET_PROJECT_TASK",
  GET_PROJECT_TASK: "GET_PROJECT_TASK",
  SET_PROJECT_LIST: "SET_PROJECT_LIST",
  GET_PROJECT_LIST: "GET_PROJECT_LIST",
  GET_ALL_CUSTOMER_LIST: "GET_ALL_CUSTOMER_LIST",
  SET_ALL_CUSTOMER_LIST: "SET_ALL_CUSTOMER_LIST",
  GET_USER_BY_NAME:"GET_USER_BY_NAME",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  GET_CUSTOMER_PROJECTS: "GET_CUSTOMER_PROJECTS",
};
export interface UserSearch {
  firstname: any;
  lastname: any;
  emailid: any;
}
export interface AllCustomerListPayload {
  id: any;
  company_name: any;
}
export interface createProjectPayload {
  id: any;
  actionType: any;
  active: any;
  company_customer_id: any;
  project_name: any;
  project_level: any;
  project_type: any;
  phase: any;
  billing_type: any;
  total_pool_hours: any;
  project_health: any;
  project_status: any;
  master_project: any;
  engagement_partner: any;
  engagement_director: any;
  engagement_sr_manager: any;
  engagement_manager: any;
  opportunity_id: any;
  engagement_id: any;
  notes: string;
}
export interface subProject {
  master_project: any;
  project_name: String;
  project_type: String;
  project_status: String;
  total_pool_hours: any;
  created_by: any;
  createdAt: any;
}
export interface masterProjectPayload {
  // result:Array<any>,
  id: any;
  project_name: any;
}
export interface projectScope {
  module_ids: Array<number>;
  project_id: any;
  num_countries: number;
  num_employees: number;
  num_payroll_countries: number;
  num_payroll_employees: number;
  total_integrations: number;
  deloitte_owned_integrations: number;
  customer_owned_integrations: number;
  countries: string;
  payroll_countries: string;
}

export interface ProjectDates {
  id: any;
  total_project_weeks: number;
  project_id: any;
  plan_phase_start_date: any;
  plan_phase_end_date: any;
  architect_phase_start_date: any;
  architect_phase_end_date: any;
  cp_phase_start_date: any;
  cp_phase_end_date: any;
  requirement_phase_start_date: any;
  requirement_phase_end_date: any;
  design_phase_start_date: any;
  design_phase_end_date: any;
  build_phase_start_date: any;
  build_phase_end_date: any;
  test_phase_start_date: any;
  end_to_end_phase_start_date: any;
  end_to_end_phase_end_date: any;
  parallel_testing_start_date: any;
  parallel_testing_end_date: any;
  test_phase_end_date: any;
  deploy_phase_start_date: any;
  deploy_phase_end_date: any;
  smoke_test_start_date: any;
  smoke_test_end_date: any;
  original_go_live_date: any;
  original_prod_support_end_date: any;
  go_live_date: any;
  hypercare_end_date: any;
  actionType: any;
}
export interface projectResources {
  id: any;
  project_id:any;
  user_id: any;
  ResourceName:any,
  emailid: string;
  company_customer_id: any;
  start_date: any;
  end_date: any;
  role: string;
  team: string;
  notes: any;
}
export interface searchFilter {
  searchWord:any,
  customerUser:any,
  
}
export interface projectTask {
  id: any;
  task_name: any;
  task_status: any;
  health: any;
  estimated_hours: any;
  flow_of_integration_workday: any;
  requirement_template: any;
  // go_live_date:any,
}

export const signupProject = (
  payload: createProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.SIGNUP_PROJECT,
    payload,
    callback,
  };
};
export const getProject = (
  payload: createProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_PROJECT_LIST,
    payload,
    callback,
  };
};
export const setProject = (
  payload: createProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_PROJECT_LIST,
    payload,
    callback,
  };
};
//Project Scope
export const getProjectScope = (
  payload: projectScope,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_PROJECT_SCOPE,
    payload,
    callback,
  };
};
export const setProjectScope = (
  payload: projectScope,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_PROJECT_SCOPE,
    payload,
    callback,
  };
};
export const getByProjectScope = (
  payload: projectScope,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_BY_PROJECT_SCOPE,
    payload,
    callback,
  };
};
export const signupProjectScope = (
 
  payload: projectScope,
  callback: CallableFunction
) => {
  return {
    type: actions.UPDATE_PROJECT_SCOPE,
    payload,
    callback,
  };
};
// export const signupProjectDates = (

//   payload: ProjectDates,
//   callback: CallableFunction
// ) => {
//   ;
//   return {
//     type: actions.CREATE_PROJECT_DATES,
//     payload,
//     callback,
//   };
// };
export const updateProjectDates = (
  payload: ProjectDates,
  callback: CallableFunction
) => {
  return {
    type: actions.UPDATE_PROJECT_DATES,
    payload,
    callback,
  };
};
export const setProjectDetails = (
  payload: createProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_PROJECT_DETAILS,
    payload,
    callback,
  };
};
export const getProjectDetails = (
  payload: createProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_PROJECT_DETAILS,
    payload,
    callback,
  };
};

export const getCustomerProjectsList = (
  payload: any,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_CUSTOMER_PROJECTS,
    payload,
    callback,
  };
};
export const signupProjectResources = (
  payload: projectResources,
  callback: CallableFunction
) => {
  return {
    type: actions.SIGNUP_PROJECT_RESOURCES,
    payload,
    callback,
  };
};

export const setProjectResources = (
  payload: projectResources,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_PROJECT_RESOURCES,
    payload,
    callback,
  };
};
export const getProjectResources = (
  payload: projectResources,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_PROJECT_RESOURCES,
    payload,
    callback,
  };
};

export const deleteProjectResources = (
  payload: projectResources,
  callback: CallableFunction
) => {
  return {
    type: actions.DELETE_PROJECT_RESOURCES,
    payload,
    callback,
  };
};
export const getProjectDates = (
  payload: ProjectDates,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_PROJECT_DATES,
    payload,
    callback,
  };
};
export const setProjectDates = (
  payload: ProjectDates,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_PROJECT_DATES,
    payload,
    callback,
  };
};
export const getMasterProject = (
  payload: masterProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_MASTER_PROJECT_LIST,
    payload,
    callback,
  };
};
export const setMasterProject = (
  payload: masterProjectPayload,
  callback: CallableFunction
) => {
  return {
    type: actions.SET_MASTER_PROJECT_LIST,
    payload,
    callback,
  };
};
export const getSubProjects = (payload: subProject, callback: any) => {
  return {
    type: actions.GET_SUB_PROJECT_LIST,
    payload,
    callback,
  };
};
export const setSubProjects = (payload: subProject, callback: any) => {
  return {
    type: actions.SET_SUB_PROJECT_LIST,
    payload,
    callback,
  };
};
export const getProjectTask = (payload: projectTask, callback: any) => {
  return {
    type: actions.GET_PROJECT_TASK,
    payload,
    callback,
  };
};
export const setProjectTask = (payload: projectTask, callback: any) => {
  return {
    type: actions.SET_PROJECT_TASK,
    payload,
    callback,
  };
};
export const userSearch = (payload: UserSearch, callback: any) => {
  return {
    type: actions.USER_SEARCH,
    payload,
    callback,
  };
};
export const setUserDetails = (payload: UserSearch, callback: any) => {
  return {
    type: actions.SET_USER_DETAILS,
    payload,
    callback,
  };
};
export const getUserByName = (
  payload: searchFilter,
  callback: CallableFunction
) => {
  return {
    type: actions.GET_USER_BY_NAME,
    payload,
    callback,
  };
};

import { takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "../../actionTypes";
import { FetchAllCustomerLists } from "./AllCustomers";
import {FetchCustomerContactLists} from "./CustomerContacts"

const {
    GET_ALL_CUSTOMER_LIST,
    GET_CUSTOMER_CONTACT_LIST
} = ACTION_TYPES.CUSTOMER;


export function* watchCustomers() {
    yield takeLatest(GET_ALL_CUSTOMER_LIST, FetchAllCustomerLists)
    // yield takeLatest(GET_CUSTOMER_CONTACT_LIST, FetchCustomerContactLists)
}
import React from "react";
import { useState, FC, useEffect } from "react";
import "./register.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../helpers/validations/useForm";
import {
  getUserDetails,
  signupUser,
  updateUser,
} from "../../store/actions/User";
import { REPLACE_REGEX, UserType } from "../../constants/common";
import { getAllCustomerList } from "../../store/actions/Customers";
import Box from "@mui/material/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import setLoader from "../../components/CustomLoader/loaderState";
import CustomModal from "../../components/CustomModalPop";
import { textAlign } from "@mui/system";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

interface Register {
  active: any;
  allow_logon: any;
  allow_notifications: any;
  firstname: string;
  lastname: string;
  company_customer_id: string;
  usertype: string;
  work_phone: any;
  mobile_phone: any;
  emailid: string;
  notes: string;
  company_name: any;
}

const RegisterForm: FC = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [message, setMessage] = useState([]);
  const loggedInId = useSelector((store: any) => {
    return store.auth.globalAuthData.id;
  });
  const [checkedAllowLogOn, setCheckedAllowLogOn] = useState(false);
  const [checkedAllowNotification, setCheckedAllowNotification] = useState(
    false
  );
  const handleActiveCheckBox = (e: any) => {
    setChecked(e.target.checked);
  };
  const handleLogOnCheckBox = (e: any) => {
    setCheckedAllowLogOn(e.target.checked);
  };
  const handleNotificationCheckBox = (e: any) => {
    setCheckedAllowNotification(e.target.checked);
  };
  const [open, setOpen] = useState(false);
const handleCloseAlert = (
  event?: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }

  setOpen(false);
};

  const {
    companyId = "",
    userType = "",
    openCustomerRegisterPopUp = false,
    setOpenCustomerRegisterPopUp,
    setOpenResourceRegisterPopUp,
  } = props;
  console.log("companyId", companyId);
  const { handleSubmit, handleChange, data: user, errors, setData } = useForm<
    Register
  >({
    validations: {
      firstname: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: "Please enter valid firstname",
        },
        minlength:{
          value:1,
          message:"First name should be atleast 1 character length"
        },
        maxlength:{
          value:50,
          message:"First name should be less than 50 character length"
        }
      },
      lastname: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message: "Please enter valid lastname",
        },
        minlength:{
          value:1,
          message:"Last name should be atleast 1 character length"
        },
        maxlength:{
          value:50,
          message:"Last name should be less than 50 character length"
        }
      },
      company_customer_id: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      usertype: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      emailid: {
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: "^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+.)+[A-Za-z]{2,4}$",
          message: "Please provide correct Email ID",
        },
      },
      work_phone:{
        pattern:{
          value:REPLACE_REGEX?.PHONE_NUMBER_FORMAT,
          message:"Please enter valid phone number"
        },
        maxlength:{
          value:20,
          message:"Phone number should be less than 20 numbers"
        }
      },
      mobile_phone:{
        pattern:{
          value:REPLACE_REGEX?.PHONE_NUMBER_FORMAT,
          message:"Please enter valid phone number"
        },
        maxlength:{
          value:20,
          message:"Phone number should be less than 20 numbers"
        }
      },
      notes:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:"Please enter combination of Alphanumeric and special characters are allowed"
        },
        maxlength:{
          value:5000,
          message:"Notes should be less than 5000 character length"
        }
      }
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      console.log(user);
      setLoader(true);
      dispatch(
        signupUser(
          {
            payload: {
              active: checked,
              allow_logon: checkedAllowLogOn,
              allow_notifications: checkedAllowNotification,
              firstname: user?.firstname,
              lastname: user?.lastname,
              company_customer_id: user?.company_customer_id,
              usertype: user?.usertype,
              work_phone: user?.work_phone,
              mobile_phone: user?.mobile_phone,
              emailid: user?.emailid,
              notes:user?.notes,  
              actionType: actionType,
            },
          },
          onSignUpCallback
        )
      );
    },
  });

  useEffect(() => {
    dispatch(
      getAllCustomerList({}, function (data: any) {
        const rows = data?.map((row: any) => ({
          id: row?.id,
          company_name: row?.company_name,
        }));
        setRowData(rows);
        setData({
          company_customer_id: companyId,
          usertype: userType,
        });
      })
    );
  }, []);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const onSignUpCallback = (data: any, error: any) => {
    setLoader(false);
    if (data == false) {
      setMessage(error);
      setErrorPopup(true);
      setTimeout(() => {
        setErrorPopup(false);
      }, 5000);
    } else {
      setMessage(data);
      setSuccessPopup(true);
      setTimeout(() => {
        setOpenCustomerRegisterPopUp(false);
      }, 4000);
      setTimeout(() => {
        setOpenResourceRegisterPopUp(false);
      }, 4000);
    }
  };

  const handleCancel = () => {
    window.location.reload();
    navigate("/register");
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "95%",
            margin: "auto",
            marginTop: 5,
          }}
        >
          <div className="row justify-content-center">
            <form onSubmit={handleSubmit}>
              <div className="col-md-9">
                <h2>User</h2>

                <div className="d-flex">
                <div className="d-flex">
                   
                   
                        <input
                          type="checkbox"
                          id="exampleCheck4"
                          onChange={handleActiveCheckBox}
                          value={user?.active}
                          checked={checked}      
                          className="form-check-input mx-2"

                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                          style={{ width: "80px" }}
                        >
                          Active
                        </label>
                      </div>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          value={user?.allow_logon}
                          checked={checkedAllowLogOn}
                          onChange={handleLogOnCheckBox}
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked"
                          style={{ width: "100px" }}
                        >
                          Allow Logon
                        </label>
                      </div>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          value={user?.allow_notifications}
                          checked={checkedAllowNotification}
                          onChange={handleNotificationCheckBox}

                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="checked">
                          Allow Notification
                        </label>
                      </div>
                    </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">First Name*</label>
                        <div>
                          <input
                            className="form-control"
                            name="firstname"
                            id="firstname"
                            type="text"
                            value={user?.firstname}
                            onChange={handleChange("firstname")}
                          />
                          {errors.firstname && (
                            <p className="error-text">{errors.firstname}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Last Name*</label>
                        <div>
                          <input
                            className="form-control"
                            name="lastname"
                            id="lastname"
                            value={user?.lastname}
                            onChange={handleChange("lastname")}
                          />
                          {errors.lastname && (
                            <p className="error-text">{errors.lastname}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Company/Customer*</label>
                        <div>
                          {companyId==""?
                          <select
                          className="form-select"
                          name="company_customer_id"
                          id="company_customer_id"
                          value={user?.company_customer_id || ""}
                          onChange={handleChange("company_customer_id",(val)=>{
                            return val;
                          })}
                        >
                          <option value="">--Select--</option>
                        {rowData.map((company:any) => {
                        console.log(
                         companyId + "===" + company.id
                        );
                        let companySel =
                        companyId === company.id
                            ? true
                            : false;
                        return (
                          
                          <option value={company.id} selected={companySel}>
                            {company.company_name}
                          </option>
                        );
                      })}
                        </select>:
                          <select
                            className="form-select"
                            name="company_customer_id"
                            id="company_customer_id"
                            value={user?.company_customer_id || ""}
                            onChange={handleChange("company_customer_id",(val)=>{
                              return val;
                            })}
                            disabled
                          >
                            <option value="">--Select--</option>
                          {rowData.map((company:any) => {
                          console.log(
                           companyId + "===" + company.id
                          );
                          let companySel =
                          companyId === company.id
                              ? true
                              : false;
                          return (
                            
                            <option value={company.id} selected={companySel}>
                              {company.company_name}
                            </option>
                          );
                        })}
                          </select>}
                          </div>
                      </div>
                          {errors.company_customer_id && (
                            <p className="error-text">
                              {errors.company_customer_id}
                            </p>
                            
                          )}
                       
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">User Type*</label>
                        <div>
                          {userType===""?
                          <select
                            className="form-select"
                            name="usertype"
                            value={user?.usertype}
                            onChange={handleChange("usertype")}
                          >
                            <option value="">--Select--</option>
                            {UserType.map((user) => (
                              <option value={user.key}>{user.label}</option>
                            ))}
                          
                          </select>:
                           <select
                           className="form-select"
                           name="usertype"
                           value={user?.usertype}
                           onChange={handleChange("usertype")}
                           disabled
                         >
                              <option value={userType} selected>{userType}</option>
                            
                          </select>}
                          </div>
                          </div>
                          {errors.usertype && (
                            <p className="error-text">{errors.usertype}</p>
                          )}
                         
                      
                      
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Work Phone</label>
                        <div>
                          <input
                            className="form-control"
                            name="work_phone"
                            type="text"
                            id="work_phone"
                            value={user?.work_phone}
                            placeholder="+91 XXXXXXXXXX"
                            onChange={handleChange("work_phone")}
                          />

                          {errors.work_phone && (
                            <p className="error-text">{errors.work_phone}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label"> Mobile Phone</label>
                        <div>
                          <input
                            className="form-control"
                            name="mobile_phone"
                            type="text"
                            value={user?.mobile_phone}
                            placeholder="+91 XXXXXXXXXX"
                            onChange={handleChange("mobile_phone")}
                          />
                          {errors.mobile_phone && (
                            <p className="error-text">{errors.mobile_phone}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Email ID*</label>
                        <div>
                          <input
                            className="form-control"
                            name="emailid"
                            type="text"
                            value={user?.emailid}
                            onChange={handleChange("emailid")}
                          />
                          {errors.emailid && (
                            <p className="error-text">{errors.emailid}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Notes</label>
                        <div>
                          <textarea
                            name="notes"
                            value={user?.notes}
                            onChange={handleChange("notes")}
                            className="form-textarea"
                          ></textarea>&nbsp;
                          {errors.notes && (
                            <p className="error-text">{errors.notes}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <button type="submit" className="btn add_button btn-primary mx-0 w-auto" id="create">
                        CREATE
                      </button>  &nbsp;
                      <button
                        type="submit"
                        className="btn add_button btn-primary mx-0 w-auto"
                        id="cancelButton"
                        onClick={handleCancel}
                      >
                        CANCEL
                      </button>
                    
                     
                    </div>
                            
            
            </form>
           
            <CustomModal
              title="User Registration"
              onOpen={errorPopup}
              content={message}
              modalType={"error"}
              width={"sm"}
              OnClickCloseIcon={() => {
                setErrorPopup(false);
              }}
            />
            <CustomModal
              title="User Registration"
              onOpen={successPopup}
              content={"User Created/Updated Successfully"}
              modalType={"success"}
              width={"sm"}
              OnClickCloseIcon={() => {
                setSuccessPopup(false);
              }}
            />
             <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
          </div>
        </Box>
      </Box>
    </div>
  );

};

export default RegisterForm;

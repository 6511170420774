import { ChangeEvent, FormEvent, useState } from "react";
import {sortBy} from "lodash";

interface Validation {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: any;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
  minlength?: {
    value: number;
    message: string;
  };
  maxlength?: {
    value: number;
    message: string;
  };
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
  onError?: () => void;
  setAddClick?: (arg: string) => void;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const [isMasterProject, setIsMasterProject] = useState(false);

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange = <S extends unknown>(
    key: keyof T,
    sanitizeFn?: (value: string) => S
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
    setData({
      ...data,
      [key]: value,
    });
    errors[key] = "";
    setErrors(errors);
  };

  const handledNestedChange = <S extends unknown>(
    key: keyof T,
    objectName?: any,
    arrayName?: any,
    FieldName1?: any,
    FieldName2?: any,
    fieldArrayId?: any,
    isNestedTrue?: any,
    nestedAttribute?: any,
    headerLength?: any,
    footerLength?: any,
    detailOrderLength?: any,
    rowType?: any,
    rowVal?: any,
    isDeepNextedAttribute?: any,
    deepNextedAttribute?: any,
    nestedId?: any,
    nameOfField?: any,
    sanitizeFn?: (value: any) => {}
  ) => (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const targetValue=e.target.type === "checkbox" ?(e.target.checked ? "on":false):e.target.value
    const value = sanitizeFn ? sanitizeFn(targetValue) : targetValue;
    if (objectName !== "") {
      setData((prev: any) => ({
        ...prev,
        [objectName]: {
          ...prev?.[objectName],
          [key]: {
            [FieldName1]: value,
            [FieldName2]: 0,
          },
        },
      }));
    } else if (
      arrayName !== "" &&
      objectName === "" &&
      !isDeepNextedAttribute
    ) {
      options?.setAddClick?.(value);
      setData((prevState: any) => {
        return {
        ...prevState,
        [arrayName]: sortBy([
          ...prevState?.[arrayName]?.map((arrayData: any, index?: any) => {
            return isNestedTrue &&
            arrayData?.field_order === fieldArrayId &&
            arrayData?.type === rowType
                ? arrayData?.type !== value && {
              ...arrayData,
              [key]: value,
              field_order:
                  value === "header"
                      ? headerLength + 1
                      : value === "footer"
                          ? footerLength + 1
                          : value === "DetailsRecord"
                              ? detailOrderLength + 1
                              : arrayData?.field_order,
            }
                : !isNestedTrue &&
                arrayData?.field_order === fieldArrayId &&
                arrayData?.type === rowType
                    ? {
                      ...arrayData,
                      [nestedAttribute]: {
                        ...arrayData?.[nestedAttribute],
                        [key]: value,
                      },
                    }
                    : {
                      ...arrayData,
                    };
          }),
        ], ['type', 'field_order']),
      }});
    } else if (arrayName !== "" && objectName === "" && isDeepNextedAttribute) {
      setData((prev: any) => ({
        ...prev,
        [arrayName]: prev?.[arrayName]?.map((arrayData: any) => {
          return {
            ...arrayData,
            [nestedAttribute]: {
              ...arrayData?.[nestedAttribute],
              [deepNextedAttribute]:
                arrayData?.id === fieldArrayId
                  ? [
                      ...arrayData?.[nestedAttribute]?.[
                        deepNextedAttribute
                      ]?.map((deepArray: any) => {
                        return deepArray[nameOfField] === nestedId
                          ? {
                              ...deepArray,
                              [key]: value,
                            }
                          : {
                              ...deepArray,
                            };
                      }),
                    ]
                  : [...arrayData?.[nestedAttribute]?.[deepNextedAttribute]],
            },
          };
        }),
      }));
    } else {
      setData({
        ...data,
        [key]: value,
      });
    }
    errors[key] = "";
    setErrors(errors);
  };

  const handleFileUpload = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: ErrorRecord<T> = {};
      for (const key in validations) {
        const value = data[key];        
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        const valtrim = (value + "").trim();
        if (
          pattern?.value &&
          pattern?.value != null &&
          typeof value !== "undefined" &&
          valtrim !== "" &&
          valtrim != "null" &&
          valtrim != null &&
          valtrim !== undefined &&
          !RegExp(pattern.value).test(value)
        ) {
          valid = false;
          newErrors[key] = pattern.message;
        }
        
        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }

        const minlength = validation?.minlength;
        const valtrimlen = (value + "").trim();
        // if(valtrimlen)
        // console.log("valtrimlen?.length", key, value, valtrimlen?.length,valtrimlen);
        if (valtrimlen?.length > 0 && valtrim!== "undefined" && valtrimlen?.length < minlength?.value ) {
          valid = false;
          newErrors[key] = minlength?.message;
        }

        const maxlength = validation?.maxlength;
        let valtrimmaxlen = (value + "").trim();
        valtrimmaxlen=valtrimmaxlen?.toString().split(".")[0]        

        if (
          valtrimmaxlen?.length > 0 && valtrim!== "undefined" &&
          valtrimmaxlen?.length > maxlength?.value
        ) {
          valid = false;
          newErrors[key] = maxlength?.message;
        }
      }

      if (!valid) {
        if (options?.onError) {
          options.onError(newErrors);
        }
        setErrors(newErrors);
        return;
      }
    }

    setErrors({});

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handledNestedChange,
    handleFileUpload,
    handleSubmit,
    setData,
    errors,
  };
};

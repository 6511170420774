import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Progress from "../../../components/organism/Tasks/progress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
const TaskProgress: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      <React.Fragment>
        <div className="container-fluid" style={{ minHeight: "300px" }}>
          <div className="frame_outer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left  mt-2 mb-3 vendor_contact">
                  Progress
                </div>
              </div>
              <Progress />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
export default TaskProgress;

export const HeaderStyle = {
  fontSize: '22px',
  fontWeight: '700',
  color: '#000',
  lineHeight: '30px',
}

export const ButtonStyle = {
  paddingRight: "2.5rem",
  paddingLeft: "2.5rem",
  backgroundColor: "#007cb0",
  "&:hover": {
    backgroundColor: "#007cb0"
  },
}
export const TableCellStyle = {
  '&.MuiTableCell-root': {
    padding: "2px !important",
  },

}